import { Application, ApplicationRole } from './Application';

export type FileType = 'pdf' | 'html' | 'html_wyswig' | 'link' | 'file' | 'video';

export enum FileTypeEnum {
	PDF = 'pdf',
	HTML = 'html',
	HTML_WYSWIG = 'html_wyswig',
	LINK = 'link',
	VIDEO = 'video',
	FILE = 'file',
}

export enum MaterialCategoryEnum {
	TOOL = 'tool',
	EXERCISE = 'exercise',
	KNOWLEDGE = 'knowledge',
	NOTE = 'note',
	OTHER = 'other',
}

export interface FileApplicationViewStats {
	fileId: number;
	application?: Application;
	applicationId: number;
	applicationRole: ApplicationRole;
	programId: number;
	views: number;
	firstViewAt: Date;
	lastViewAt: Date;
}

export interface FileProgramViewStats {
	fileId: number;
	views: number;
	applicationViews: number;
	menteeViews: number;
	mentorViews: number;
}

export type GroupedDataItem = {
	applicationId: number;
	application: Application;
	firstView: Date;
	views: number;
	applicationRating: FileRating | null;
};

export interface FileView {
	id: number;
	fileId: number;
	application: Application;
	applicationId: number;
	createdAt: Date;
}

export interface FileStats {
	positive: number;
	negative: number;
}

export interface File {
	id: number;
	name: string;
	directory: string;
	type: FileType;
	url: string | null;
	updatedAt: string;
	storageUrl: string | null;
	originalFilename: string | null;
	canEdit?: boolean;
	menteeAccess: boolean;
	mentorAccess: boolean;
	materialCategory: string;
	sessionNumber: number | null;
	sessionType: string | null;
	description: string | null;
	programViewStats?: FileProgramViewStats;
	aplicationViewStats?: FileApplicationViewStats[];
	stats?: FileStats;
}

export interface FileRating {
	id: number;
	rating: number;
	comment: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface Folder {
	name: string;
	type: 'directory';
	files: File[];
}
