import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { isAfter, isBefore } from 'date-fns';
import { userPanelApi } from 'api';
import { Session } from 'types/Session';
import { Tag } from 'primereact/tag';
import { OverlayElement } from 'components/_new/Overlay';
import { Menu, MenuItem, MenuItemButton } from 'components/_new/Menu';
import { Button } from 'components/_new/Button';
import { useScope } from 'contexts/scopeContext';
import { useGlobal } from 'contexts/globalContext';
import { Dialog } from 'components/_new/Dialog';
import ExtendedBox, { SessionBadgeColor } from './ExtendedBox';
import { EditSessionDetailsDialog } from '../components/EditSessionDetailsDialog';
import { RouterUrlParams } from '../../../App';
import { useSessionsData } from '../Sessions';
import { dateFormat } from '../../../utils/dateFormat';
import { useMapSessionTasksToItems } from './utils/useMapSessionTasksToItems';
import { RemoveSessionDialogConfirm } from '../components/RemoveSessionDialogConfirm';

interface SessionBoxProps {
	session: Session;
	canDisplayTasks: boolean;
	showSetDateButton: boolean;
	nextSession?: Session;
	previousSession?: Session;
}

const SessionBox: FC<SessionBoxProps> = ({
	session,
	canDisplayTasks,
	previousSession,
	nextSession,
	showSetDateButton,
	...restProps
}) => {
	const { t } = useTranslation();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const sessionGoalTrimmed = session?.goal?.length > 100 ? `${session?.goal?.slice(0, 100)}...` : session?.goal;
	const { toastRef } = useGlobal();
	const { pairId } = useParams() as RouterUrlParams;
	const { currentProgramMembership } = useScope();
	const { sessiosRefetch } = useSessionsData();

	const isSessionCompleted = !!session.date && new Date() > new Date(session.date);
	const { mutate: deleteSession } = useMutation(
		() => userPanelApi.deleteSession(+pairId, Number(currentProgramMembership?.id), session.id),
		{
			onSuccess: () => {
				if (sessiosRefetch) {
					sessiosRefetch();
				}
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.removeSuccess'),
				});
			},
		}
	);

	const editItems = [
		{
			key: 'edit',
			type: 'button',
			label: t('userPanel.sessions.editSession'),
			onClick: (event) => {
				if (setIsEditModalOpen) {
					setIsEditModalOpen(true);
				}
				event.preventDefault();
			},
		},
		{
			key: 'delete',
			type: 'button',
			label: t('userPanel.sessions.deleteSession'),
			onClick: () => setRemoveConfirmModalOpen(true),
			// disabled: session.sessionType !== 'standard' || isSessionCompleted,
			title:
				// eslint-disable-next-line no-nested-ternary
				session.sessionType !== 'standard'
					? t('userPanel.sessionsNew.canNotRemoveNotStandardSesion')
					: isSessionCompleted
					? t('userPanel.sessionsNew.canNotRemoveCompletedSesion')
					: undefined,
		},
	] as MenuItem[];
	const sessionDate = dateFormat(session.date, 'namedMonth')?.replace(',', '').split(' ') || [];

	const todoItemsRaw = useMapSessionTasksToItems(session?.sessionTasks || []);
	const todoItems = todoItemsRaw.map((item) => ({
		...item,
		type: 'button',
		to: undefined,
	})) as unknown as MenuItemButton[];

	// const menuItems = items.map((item) => ({ ...item, type: 'link' })) as unknown as MenuItem[];
	const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState(false);

	const todoMenuRef = useRef<OverlayElement>(null);
	const menuRef = useRef<OverlayElement>(null);
	const sessionIsFinished = session.date ? isAfter(new Date(), new Date(session.date)) : null;

	return (
		<>
			<EditSessionDetailsDialog
				previousSession={previousSession}
				nextSession={nextSession}
				session={session}
				visible={isEditModalOpen}
				onHide={() => setIsEditModalOpen(false)}
			/>
			<Dialog
				title={t('misc.info')}
				visible={Boolean(sessionIsFinished) && removeConfirmModalOpen}
				onHide={() => setRemoveConfirmModalOpen(false)}
			>
				{t(`userPanel.sessions.canNotDeleteInfo`)}
			</Dialog>
			<RemoveSessionDialogConfirm
				visible={!sessionIsFinished && removeConfirmModalOpen}
				onHide={() => setRemoveConfirmModalOpen(false)}
				confirmAction={{
					onClick: (event) => {
						deleteSession();
						event.preventDefault();
					},
				}}
			/>
			<Menu ref={menuRef} items={editItems} />
			<Menu
				ref={todoMenuRef}
				items={todoItems}
				header={t(`userPanel.sessions.doBeforeSession`)}
				boxClassName="py-2"
				variant="additional-pads"
			/>
			<ExtendedBox
				flagcolor={
					session?.date && new Date(session.date).getTime() < Date.now()
						? SessionBadgeColor.Green
						: SessionBadgeColor.Blue
				}
				{...restProps}
			>
				<Link
					to={`${session.id}/agenda`}
					onClick={(event) => {
						if (!event.defaultPrevented) {
							const scrollWrapperRef = document.getElementById('scrollWrapperRef');
							if (scrollWrapperRef) {
								scrollWrapperRef.scroll({ top: 0 });
							}
						}
					}}
					className="flex w-full"
					style={{ minHeight: '7rem' }}
				>
					<div className="pt-3 pb-3 flex flex-column justify-content-center xl:p-0 xl:flex-row w-full xl:align-items-center xl:justify-content-between">
						<div className="flex flex-column gap-3">
							<div className="flex flex-row gap-3">
								<span className="text-lg" style={{ color: '#22225E', fontWeight: 700 }}>
									{`${t('userPanel.sessions.session')} ${session.sessionNumber}`}
								</span>

								{session.sessionType !== 'standard' && (
									<Tag
										style={{
											fontSize: '12px',
											lineHeight: '15.08px',
											font: 'Source Sans Pro',
											backgroundColor: '#f8f8ff',
											color: '#5e6d83',
											fontWeight: '400',
											textTransform: 'capitalize',
										}}
										className="pr-2 pl-2"
									>
										{t(`userPanel.sessions.sessionBadge.${session.sessionType}`)}
									</Tag>
								)}
							</div>
							<div>
								{session.sessionType !== 'standard' && (
									<span style={{ fontWeight: '600' }}>
										{`${t(`userPanel.sessions.sessionSubtitle.${session.sessionType}`)}  `}
									</span>
								)}
								{sessionGoalTrimmed || t(`userPanel.sessions.sessionSubtitle.no-title`)}
							</div>
						</div>
						<div className="flex flex-row align-items-center gap-2 mt-3 xl:mt-0">
							{session.date ? (
								<>
									{canDisplayTasks && session.sessionTasks && session.sessionTasks.length > 0 && (
										<Button
											label={String(session.sessionTasks.length)}
											onClick={(event) => {
												event.preventDefault();
												todoMenuRef?.current?.toggle(event.currentTarget);
											}}
											icon="list-check"
											iconSet="fa"
											bold
											rounded
											variant="rose-light"
											variantSize="sm"
										/>
									)}

									<Button
										label={`${sessionDate[0]} ${sessionDate[1]} ${sessionDate[3]}`}
										onClick={(event) => {
											setIsEditModalOpen(true);
											event.preventDefault();
										}}
										bold
										rounded
										variant="grey-light"
										variantSize="sm"
									>
										<div className="flex flex-row gap-2 justify-content-center align-items-center">
											<i className="fa fa-regular fa-calendar" />
											<span className="text-nowrap">{`${sessionDate[0]} ${sessionDate[1]}`}</span>
										</div>
										<div className="flex flex-row gap-2 justify-content-center align-items-center">
											<i className="fa fa-regular fa-clock" />
											<span>{`${sessionDate[3]}`}</span>
										</div>
									</Button>
								</>
							) : (
								// eslint-disable-next-line react/jsx-no-useless-fragment
								<>
									{showSetDateButton && (
										<Button
											label={t('userPanel.sessions.sessionTasks.add-session-date')}
											onClick={(event) => {
												setIsEditModalOpen(true);
												event.preventDefault();
											}}
											icon="pen"
											iconSet="fa"
											bold
											rounded
											variant="priamry-light"
											variantSize="sm"
										/>
									)}
								</>
							)}
						</div>
					</div>
					<div className="ml-0 align-items-start flex xl:align-items-center xl:ml-3">
						<Button
							label="Session menu"
							icon="fa fa-solid fa-ellipsis"
							iconOnly
							rounded
							className="m-2"
							variant="primary-text"
							onClick={(event) => {
								menuRef?.current?.toggle(event.currentTarget);
								event.preventDefault();
							}}
						/>
					</div>
				</Link>
			</ExtendedBox>
		</>
	);
};

export default SessionBox;
