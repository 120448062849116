import { Application } from './Application';
import { User } from './User';

export enum Format {
	HTML = 'html',
	MD = 'md',
}

export type PostedBy = 'user' | 'mentibot' | 'application';

export enum PostAttachmentFileType {
	UNKNOWN = 'unknown',
	PDF = 'pdf',
	IMAGE = 'image',
}

export interface PostAttachment {
	id: number;
	postId: number;
	postUuidLink: string | null;
	fileType: PostAttachmentFileType;
	storageUrl: string | null;
	originalFileName: string;
	originalFileSize: number;
	createdAt: Date;
}

export interface PostAttachmentLocal extends Omit<PostAttachment, 'id' | 'postId' | 'storageUrl'> {
	id?: number;
	postId?: number;
	localUuid: string;
	storageUrl?: string | null;
}

export interface Post {
	id: number;
	language: 'pl' | 'en';
	format: Format;
	menteeAccess: boolean;
	mentorAccess: boolean;
	postedBy: PostedBy;
	programId: number;
	programType: string;
	text: string;
	title: string;
	updatedAt: Date;
	user: UserData;
	userId: number | null;
	applications?: Application[];
	attachments?: PostAttachment[];
}

export interface PostComment {
	id: number;
	postId: number;
	text: string;
	userId: number;
	user: User;
	createdAt: Date;
	updatedAt: Date;
}

export interface UserData {
	id: number;
	avatar: string | null;
	firstName: string;
	lastName: string;
}
