import { httpClient } from 'api';
import { ChatMessage, ChatThreadUnknown, ChatThreadWithMessage } from 'types/Chat';
import { ProgramMembership } from 'types/Membership';

export const chatApi = {
	getAccess: (programMembershipId: number) => httpClient.get<boolean>(`/chat/${programMembershipId}/access`),
	getStatus: (programMembershipId: number) => httpClient.get<number[]>(`/chat/${programMembershipId}/status`),
	getThreads: (programMembershipId: number) =>
		httpClient.get<ChatThreadWithMessage[]>(`/chat/${programMembershipId}/threads`),
	getThread: (programMembershipId: number, interlocutorMembershipId: number) =>
		httpClient.get<ChatThreadUnknown>(`/chat/${programMembershipId}/thread/${interlocutorMembershipId}`),
	getNewChatRequest: (programId: number, programMembershipId: number) =>
		httpClient.get<boolean>(`/chat/${programId}/new/${programMembershipId}`),
	getThreadMessages: (programMembershipId: number, threadId: number, lastMessageId?: number) =>
		httpClient.get<ChatMessage[]>(
			`/chat/${programMembershipId}/messages/${threadId}${lastMessageId ? `/${lastMessageId}` : ''}`
		),
	getUnthreadMembers: (programMembershipId: number) =>
		httpClient.get<ProgramMembership[]>(`/chat/${programMembershipId}/unthread-members`),
	sendChatMessage: (programMembershipId: number, interlocutorMembershipId: number, data: { message: string }) =>
		httpClient.post<ChatMessage>(`/chat/${programMembershipId}/message/${interlocutorMembershipId}`, data),
	readThread: (programMembershipId: number, interlocutorMembershipId: number) =>
		httpClient.post<boolean>(`/chat/read/${programMembershipId}/${interlocutorMembershipId}`),

	// subs
	subChatThread: ({
		params,
		signal,
	}: {
		params: { programMembershipId: number; bump?: number };
		signal: AbortSignal;
	}) =>
		httpClient.subscribe<ChatThreadWithMessage>(
			`/chat/sub/chat-thread/${params.programMembershipId}`,
			// { bump: params.bump || 1 },
			{},
			{ signal }
		),
	subChatThreadSelf: ({
		params,
		signal,
	}: {
		params: { programMembershipId: number; bump?: number };
		signal: AbortSignal;
	}) =>
		httpClient.subscribe<ChatThreadWithMessage>(
			`/chat/sub/chat-thread-self/${params.programMembershipId}`,
			// { bump: params.bump || 1 },
			{},
			{ signal }
		),
	subChatMessage: ({
		params,
		signal,
	}: {
		params: { programMembershipId: number; interlocutorMembershipId: number; bump?: number };
		signal: AbortSignal;
	}) =>
		httpClient.subscribe<ChatMessage>(
			`/chat/sub/chat-message/${params.programMembershipId}/${params.interlocutorMembershipId}`,
			// { bump: params.bump || 1 },
			{},
			{ signal }
		),
};
