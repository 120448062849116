import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Button } from 'components/_common/Button';
import { Pair } from 'types/Pair';
import { Loader } from 'components/_new/Loader';
import { prepareUserAreas } from 'utils/prepareUserAreas';
import { MessageBox } from 'components/_new/MessageBox';
import { Box, BoxSection } from 'components/_new/Box';
import PairItem from './Pair';

const Pairing = () => {
	const { t } = useTranslation();
	const {
		panelType,
		currentProgram,
		currentApplicationData: { systemAnswers, applicationRole },
		currentProgramMembership: { id: currentProgramMembershipId },
		todosRefetch,
	} = usePanel();

	const sortPairs = (pairs: Pair[]) =>
		[...pairs]
			.map((pair) => {
				const statusValueMap = {
					finished: 6,
					accepted: 5,
					open: 3,
					rejected: -1,
					removed: 0,
				};
				const sortVal =
					statusValueMap[pair.status] +
					Number(!!pair.acceptedByMentee) +
					Number(!!pair.acceptedByMentor) +
					Number(!!pair.entryMeeting) +
					Number(pair.scoring && pair.scoring.sum ? pair.scoring.sum : 0) / 1000;
				return { sortVal, ...pair };
			})
			.sort((a, b) => (a.sortVal > b.sortVal ? -1 : 1));

	const {
		data: userPairsData,
		isFetched,
		isLoading,
		isError,
		refetch,
	} = useQuery(
		[
			'userPairs',
			{
				applicationRole,
				currentProgramMembershipId,
			},
		],
		() => userPanelApi.getPairs(String(applicationRole), Number(currentProgramMembershipId)),
		{
			select: sortPairs,
			enabled: !!(applicationRole && currentProgramMembershipId),
		}
	);
	const userPairs = userPairsData || [];
	const numberOfPairs = userPairs?.reduce((sum, curPair) => (curPair.status === 'accepted' ? sum + 1 : sum), 0);

	const userAreas = prepareUserAreas(systemAnswers.areas);

	const limitReached = numberOfPairs >= Number(systemAnswers.pairLimit);

	// const queryClient = useQueryClient();
	const handleAction = () => {
		refetch();
		todosRefetch();
	};

	return (
		<>
			<Helmet title={t('misc.pageTitles.pairing')} />
			<SubPageTitle
				title={t('userPanel.pairing.pairPropositions', {
					type: panelType === 'mentee' ? t('misc.mentors') : t('misc.mentees'),
				})}
				additional={
					userPairs.length > 0
						? t('userPanel.pairing.choosenPairs', {
								curNumber: numberOfPairs,
								max: systemAnswers.pairLimit,
						  })
						: undefined
				}
			/>
			<div className="flex flex-column gap-2">
				{!isError && isFetched && (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{numberOfPairs >= 1 && limitReached ? (
							<MessageBox
								variant="purple"
								message={t('userPanel.pairing.congratulationsPairsDone')}
								highlight
							/>
						) : (
							<>
								{panelType === 'mentee' && (
									<MessageBox
										variant="purple"
										message={[
											t('userPanel.pairing.infoMenteeLine1'),
											t('userPanel.pairing.infoMentorLine2'),
										]}
										highlight
									/>
								)}
								{panelType === 'mentor' && (
									<MessageBox
										variant="purple"
										message={[
											t('userPanel.pairing.infoMentorLine1'),
											t('userPanel.pairing.infoMenteeLine2'),
										]}
										highlight
									/>
								)}
							</>
						)}
					</>
				)}
				{isLoading && (
					<Loader
						text={t('userPanel.pairing.infoLoadingLine1')}
						subtext={t('userPanel.pairing.infoMentorLine2')}
					/>
				)}
				{!isLoading && isError && (
					<div className="flex flex-column justify-content-center align-items-center text-center">
						<p>Wystąpił błąd podczas pobierania danych lub przekroczono maksymalny czas połączenia</p>
						<Button label="Spróbuj ponownie" onClick={() => refetch()} />
					</div>
				)}
				{!isError && isFetched && (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{userPairs.length > 0 ? (
							<div>
								{userPairs.map((pairData) => (
									<PairItem
										key={pairData.id}
										data={pairData}
										userAreas={userAreas}
										disableActions={limitReached}
										withActions
										onAction={handleAction}
									/>
								))}
							</div>
						) : (
							<Box variant="white-bordered">
								<BoxSection>
									{!currentProgram.pairingStatus ? (
										<div>{t('userPanel.pairing.pairingOff')}</div>
									) : (
										<>
											{panelType === 'mentor'
												? t('userPanel.pairing.noPairsMentor')
												: t('userPanel.pairing.noPairsMentee')}
											{panelType === 'mentor' && (
												<div>{t('userPanel.pairing.noPairsMentorRedirect')}</div>
											)}
										</>
									)}
								</BoxSection>
							</Box>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default Pairing;
