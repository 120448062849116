import { httpClient } from 'api';
import { File } from 'types/File';
import { LabelPayload } from '../types/payloads/LabelPayload';

export const labelGenerationApi = {
	getBadgePreview: (applicationId: number, payload: LabelPayload) =>
		httpClient.post<any>(`label-generator/`, payload, {
			responseType: 'blob',
		}),
	updateBadgeData: (applicationId: number, payload: LabelPayload) =>
		httpClient.post<any>(`label-generator/data/${applicationId}`, payload, {
			responseType: 'blob',
		}),
	checkLabel: (applicationId: number) => httpClient.get<File | null>(`label-generator/check/${applicationId}`),
	getLabelFile: (applicationId: number) => httpClient.get<Blob>(`label-generator/file/${applicationId}`),
	getBadgeData: (applicationId: number) => httpClient.get<any>(`label-generator/data/${applicationId}`),
};
