import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FileType } from 'types/File';

import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownProps } from 'primereact/dropdown';

interface FilterOption {
	value: FileType;
	icon: ReactNode;
}

interface Props {
	searchValue: string;
	onSearch: (value: string) => void;
	selectedFilterOption: string;
	onFilter: (value: string) => void;
}
const FilesSearchbar: FC<Props> = (props) => {
	const { t } = useTranslation();
	const { searchValue, onSearch, selectedFilterOption, onFilter } = props;

	const fileTypeOptions: FilterOption[] = [
		{
			value: 'pdf',
			icon: <i className="pi pi-file-pdf" />,
		},
		{
			value: 'html',
			icon: <i className="pi pi-file" />,
		},
		{
			value: 'link',
			icon: <i className="pi pi-globe" />,
		},
		{
			value: 'file',
			icon: <i className="pi pi-cloud-download" />,
		},
		{
			value: 'video',
			icon: <i className="pi pi-video" />,
		},
	];

	const renderOption = (option: FilterOption) => (
		<span className="flex align-items-center column-gap-2">
			{option.icon}
			{t(`userPanel.files.fileTypeTooltips.${option.value}`)}
		</span>
	);

	const renderSelectedOption = (option: FilterOption, { placeholder }: DropdownProps) => {
		if (option) {
			return (
				<span className="flex align-items-center column-gap-2">
					{option.icon}
					{t(`userPanel.files.fileTypeTooltips.${option.value}`)}
				</span>
			);
		}
		return placeholder;
	};

	return (
		<div className="flex sm:flex-row flex-column justify-content-between gap-1">
			<Dropdown
				value={selectedFilterOption}
				options={fileTypeOptions}
				onChange={({ value }) => onFilter(value)}
				optionLabel="value"
				itemTemplate={renderOption}
				valueTemplate={renderSelectedOption}
				placeholder={t('userPanel.files.filteringPlaceholder')}
				style={{ minWidth: '220px' }}
				showClear
			/>

			<span className="w-full p-input-icon-left">
				<i className="pi pi-search" />

				<InputText
					value={searchValue}
					onChange={({ target }) => onSearch(target.value)}
					placeholder={t('userPanel.files.searchbarPlaceholder')}
					className="w-full"
				/>
			</span>
		</div>
	);
};

export default FilesSearchbar;
