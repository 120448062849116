import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import pl from 'dayjs/locale/pl';
import weekday from 'dayjs/plugin/weekday';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(weekday);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(relativeTime);

dayjs.locale(en);
dayjs.locale(pl);

export { dayjs };
