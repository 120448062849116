export enum SurveyQuestionType {
	INFO = 'info',
	TEXT = 'text',
	LONGTEXT = 'longtext',
	SCALE = 'scale',
	BOOLEAN = 'boolean',
	SINGLE_CHOICE = 'single-choice',
	MULTI_CHOICE = 'multi-choice',
}

export enum SurveyQuestionSingleChoicePresentation {
	DROPDOWN = 'dropdown',
	INPUT = 'input',
}

export type SurveyQuestionBaseOption = {
	id: number;
	questionBaseId: number;
	text: string;
};

export type SurveyQuestionBase = {
	id: number;
	type: SurveyQuestionType;
	label: string;
	sequence: number;
	step: number;
	programId: number | null;
	choiceOptionPresentation: SurveyQuestionSingleChoicePresentation | null;
	required: boolean;
	options?: SurveyQuestionBaseOption[];
};

export type SurveyQuestionBasTranslated = SurveyQuestionBase & {
	langaugeCode: string;
	text: string;
};

export type SurveyQuestionAnswerChoice = {
	id: number;
	answerId: number;
	questionBaseOptionId: number;
};

export type SurveyQuestionAnswer = {
	valueText: string | null;
	valueNumber: number | null;
	valueBoolean: boolean | null;
	choiceId: number | null;
	choices?: SurveyQuestionAnswerChoice[];
};

export type SurveyQuestion = {
	id: number;
	questionBaseTranslated?: SurveyQuestionBasTranslated;
	questionBase?: SurveyQuestionBase;
	questionBaseId: number;
	answer?: SurveyQuestionAnswer;
	answerId: number | null;
};

export type SurveyStats = {
	questionCount: number;
	questionFilledCount: number;
};

export type Survey = {
	id: number;
	createdAt: Date;
	questions?: SurveyQuestion[];
	stats?: SurveyStats;
};
