import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { authApi } from 'api';
import banner from 'assets/mentiway-banner.svg';
import { Button } from 'primereact/button';
import Checkbox from 'components/_common/forms/Checkbox';
import { useAuth } from 'contexts/authContext';
import { translatedStaticResourceResolver } from 'utils/translatedStaticResourceResolver';
import { Box, BoxSection } from 'components/_new/Box';

interface RegisterFormObject {
	terms: boolean;
}

const RequireTerms: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { currentUser, currentUserRefetch } = useAuth();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<RegisterFormObject>({
		defaultValues: {
			terms: false,
		},
	});

	const { mutate, isLoading } = useMutation(() => authApi.acceptTerms(), {
		onSuccess: () => {
			currentUserRefetch();
			navigate('/');
		},
	});

	return (
		<>
			<Helmet title={t('auth.register')} />
			<div className="flex flex-column align-items-center justify-content-center row-gap-6 py-3">
				<Box variant="white" dropShadow>
					<BoxSection className="px-6 py-4 text-center" contentClassName="w-360-full">
						<img src={banner} alt="banner" className="mt-2 mb-3 align-self-center" />

						{currentUser?.terms ? (
							<div className="flex flex-column justify-content-center align-items-center">
								<p>{t('auth.termsAccepted')}</p>
								<Button
									type="submit"
									style={{ display: 'unset' }}
									onClick={() => {
										navigate('/');
									}}
									label={t('auth.changeProgram')}
								/>
							</div>
						) : (
							<form onSubmit={handleSubmit(() => mutate())} className="flex flex-column row-gap-4">
								<p>{t('auth.termsAcceptanceRequired')}</p>
								<Checkbox
									name="terms"
									control={control}
									text={
										<label htmlFor="termsOfService" className="text-sm">
											{t('auth.accept')}
											<a
												href={translatedStaticResourceResolver('terms')}
												target="_blank"
												className={errors.terms ? 'text-error' : ''}
												rel="noreferrer"
											>
												{t('auth.termsOfService')}
											</a>
										</label>
									}
									required
								/>
								<Button
									type="submit"
									style={{ display: 'unset' }}
									loading={isLoading}
									label={t('auth.finishSigningProcess')}
								/>
							</form>
						)}
					</BoxSection>
				</Box>
			</div>
		</>
	);
};

export default RequireTerms;
