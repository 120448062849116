import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { ProgramStatus } from './panels/status';
import { ProgramRecruitment } from './panels/recruitment';
import { ProgramProcessSummaries } from './panels/process-summaries';
import { ProgramPairs } from './panels/pairs';

const ProgramDashboard = () => {
	const { t } = useTranslation();

	const { refetch, isStale } = useProgramPanel();

	useEffect(() => {
		if (isStale) {
			refetch?.();
		}
	}, []);

	return (
		<>
			<Helmet title={t('organizationPanel.programDashboard')} />
			<div className="flex flex-row flex-wrap">
				<div className="flex flex-column gap-4 col-12 xl:col-6">
					<ProgramStatus />
					<ProgramProcessSummaries />
				</div>
				<div className="flex flex-column gap-4 col-12 xl:col-6">
					<ProgramRecruitment />
					<ProgramPairs />
				</div>
			</div>
		</>
	);
};

export default ProgramDashboard;
