import { httpClient } from 'api';

export const finalReportApi = {
	updateFinalReportInfo: (pairId: number, data: any) => httpClient.post<any>(`final-report/${pairId}/info`, data),
	updateFinalReportSurvey: (pairId: number, applicationId: number, data: any) =>
		httpClient.post<any>(`final-report/${pairId}/${applicationId}/survey`, data),
	getFinalReport: (pairId: number, applicationId: number) =>
		httpClient.get<any>(`final-report/${pairId}/${applicationId}`),
	prepareFinalReportDownloadLink: ({
		pairId,
		membershipId,
		type,
	}: {
		pairId: number;
		membershipId: number;
		type: string;
	}) => httpClient.post<string>(`final-report/report/${pairId}/${membershipId}/${type}`),
	downloadMaterials: (pairId: number, applicationId: number) =>
		httpClient.get<any>(`final-report/download-materials/${pairId}/${applicationId}`, undefined, {
			responseType: 'blob',
		}),
	checkDownloadMaterials: (pairId: number, applicationId: number) =>
		httpClient.get<boolean>(`final-report/check-download-materials/${pairId}/${applicationId}`),
};
