import React, { useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { PostAttachment, PostAttachmentFileType, PostAttachmentLocal } from 'types/Dashboard';
import { useTranslation } from 'react-i18next';
import { Button, ButtonClean } from '../Button';
import { Icon, IconProps } from '../Icon';
import { Spinner } from '../Spinner';

type AttachemntItemProps = {
	attachment: PostAttachment | PostAttachmentLocal;
	onClick?: (attachment: PostAttachment) => void;
	onRemove?: (attachment: PostAttachment) => void;
	isNew?: boolean;
	isUploaded?: boolean;
	options?: {
		removeAttachmentLabel?: string;
	};
};

export const AttachmentItem = ({
	attachment,
	onClick,
	onRemove,
	isNew = false,
	isUploaded = false,
	options,
}: AttachemntItemProps) => {
	const { t } = useTranslation();
	const { removeAttachmentLabel } = {
		removeAttachmentLabel: 'Remove attachment',
		...options,
	};

	const { fileType, originalFileName, ...restAttachmentProps } = attachment;
	const iconProps: Pick<IconProps, 'name' | 'className'> = useMemo(() => {
		switch (fileType) {
			case PostAttachmentFileType.PDF:
				return {
					name: 'file-pdf',
					className: 'text-pdf',
				};
			case PostAttachmentFileType.UNKNOWN:
			default:
				return {
					name: 'file',
					className: 'text-primary',
				};
		}
	}, []);

	return (
		<div
			className={classNames('attachment-button', {
				'attachment-button-new': !isUploaded && isNew,
			})}
		>
			<ButtonClean
				className={classNames('flex flex-row gap-2', {
					'cursor-normal': !onClick,
				})}
				onClick={(event) => {
					if (onClick && attachment.id) {
						onClick(attachment as PostAttachment);
					} else {
						event.preventDefault();
					}
				}}
			>
				<Icon {...iconProps} className={classNames('post-attachment-icon', iconProps.className)} />
				{originalFileName}
			</ButtonClean>
			{isUploaded && <Spinner size="xs" />}
			{onRemove && (
				<Button
					label={removeAttachmentLabel}
					icon="times"
					iconStyle="solid"
					iconOnly
					variant={isNew ? 'primary-outlined' : 'grey-light'}
					variantSize="xs"
					rounded
					onClick={() => {
						if (attachment.id) {
							onRemove(attachment as PostAttachment);
						}
					}}
				/>
			)}
		</div>
	);
};
