import React, { useCallback, useMemo } from 'react';
import { PostAttachment, PostAttachmentFileType } from 'types/Dashboard';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { postsApi } from 'api';
import { Dialog, DialogBaseProps } from '../Dialog';
import { Button, ButtonProps } from '../Button';
import { Spinner } from '../Spinner';

type AttachemntPreviewDialogProps = DialogBaseProps & {
	attachement: PostAttachment | null;
};

export const AttachemntPreviewDialog = ({ attachement, ...dialogProps }: AttachemntPreviewDialogProps) => {
	const { t } = useTranslation();
	const { visible } = dialogProps;

	const { data: attachmentUrlData, isLoading: attachementUrlLoading } = useQuery(
		['GET_POST_ATTACHMENT_URL', { id: Number(attachement?.id) }],
		() => postsApi.getPostAttachmentUrl(Number(attachement?.id)),
		{
			enabled: visible,
			refetchOnWindowFocus: true,
		}
	);

	const renderContent = () => {
		switch (attachement?.fileType) {
			case PostAttachmentFileType.PDF:
				if (!attachmentUrlData) {
					return <span>ERROR</span>;
				}
				return (
					<object
						data={attachmentUrlData}
						type="application/pdf"
						name={attachement.originalFileName}
						width="100%"
						height="500px"
						aria-label="pdf"
					/>
					// <iframe title={attachement.originalFileName} src={attachmentUrlData} width="100%" height="500px" />
				);
			case PostAttachmentFileType.IMAGE:
				if (!attachmentUrlData) {
					return <span>ERROR</span>;
				}
				return (
					<div className="flex flex-row justify-content-center align-items-center">
						<img src={attachmentUrlData} alt={attachement.originalFileName} style={{ maxHeight: '50vh' }} />
					</div>
				);
			case PostAttachmentFileType.UNKNOWN:
			default:
				return null;
		}
	};

	const handleDownloadClick = useCallback(() => {
		if (attachement) {
			switch (attachement.fileType) {
				case PostAttachmentFileType.PDF:
				case PostAttachmentFileType.IMAGE:
					if (attachmentUrlData) {
						postsApi.getPostAttachmentBlob(Number(attachement?.id)).then((blob) => {
							const link = document.createElement('a');
							link.href = URL.createObjectURL(blob);
							link.download = attachement.originalFileName;
							link.click();
						});
					}
					break;
				case PostAttachmentFileType.UNKNOWN:
				default:
					break;
			}
		}
	}, [attachement, attachmentUrlData]);

	const handleOpenInNewTabnClick = useCallback(() => {
		const anchor = document.createElement('a');
		if (attachement) {
			anchor.target = '_blank';
			switch (attachement.fileType) {
				case PostAttachmentFileType.PDF:
				case PostAttachmentFileType.IMAGE:
					if (attachmentUrlData) {
						window.open(attachmentUrlData, '_blank');
					}
					break;
				case PostAttachmentFileType.UNKNOWN:
				default:
					break;
			}
			anchor.click();
		}
	}, [attachement, attachmentUrlData]);

	const downloadButtonProps: Partial<ButtonProps> = useMemo(() => {
		if (attachement) {
			switch (attachement.fileType) {
				case PostAttachmentFileType.PDF:
				case PostAttachmentFileType.IMAGE:
					return {
						loading: attachementUrlLoading,
						disabled: !attachementUrlLoading && !attachmentUrlData,
					};
				case PostAttachmentFileType.UNKNOWN:
				default:
					return {
						loading: false,
					};
			}
		}
		return {
			loading: false,
		};
	}, [attachement, attachmentUrlData, attachementUrlLoading]);

	const openInNewTabButtonProps: Partial<ButtonProps> = useMemo(() => {
		if (attachement) {
			switch (attachement.fileType) {
				case PostAttachmentFileType.PDF:
				case PostAttachmentFileType.IMAGE:
					return {
						loading: attachementUrlLoading,
						disabled: !attachementUrlLoading && !attachmentUrlData,
					};
				case PostAttachmentFileType.UNKNOWN:
				default:
					return {
						loading: false,
					};
			}
		}
		return {
			loading: false,
		};
	}, [attachement, attachmentUrlData, attachementUrlLoading]);

	return (
		<Dialog
			title={t('programPanel.post.attachmentPreviewTitle')}
			size="lg"
			headerRight={() => (
				<>
					<Button
						label={t('misc.downloadFile')}
						icon="download"
						onClick={handleDownloadClick}
						{...downloadButtonProps}
					/>
					<Button
						label={t('misc.openInNewTab')}
						icon="arrow-up-right-from-square"
						variant="primary-outlined"
						onClick={handleOpenInNewTabnClick}
						{...openInNewTabButtonProps}
					/>
				</>
			)}
			{...dialogProps}
		>
			{attachementUrlLoading && <Spinner center />}
			{!attachementUrlLoading && renderContent()}
		</Dialog>
	);
};
