import React, { Ref, forwardRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown as DropdownOriginal, DropdownProps as DropdownPropsOriginal } from 'primereact/dropdown';
import { IconType, classNames } from 'primereact/utils';
import { SelectItem } from 'primereact/selectitem';
import { getInputBaseClassName } from '../InputBase';
import './Dropdown.scss';

export type DropdownItem = {
	label: string;
	value: string | number | null;
	className?: string;
	icon?: IconType<SelectItem>;
	title?: string;
	disabled?: boolean;
};

type DropdownProps = Omit<DropdownPropsOriginal, 'options' | 'dropdownIcon' | 'optionLabel' | 'optionValue'> & {
	options?: DropdownItem[];
	inline?: boolean;
	withEmpty?: boolean;
	emptyLabel?: string;
};

export const Dropdown = forwardRef(
	(
		{
			className,
			value,
			onChange,
			defaultValue,
			inline,
			options,
			withEmpty = false,
			placeholder,
			emptyLabel,
			...restProps
		}: DropdownProps,
		ref: Ref<DropdownOriginal>
	) => {
		const { t } = useTranslation();
		const [currentValueTemp, setCurrentValue] = useState<string | number | null>(
			typeof value === 'undefined' ? defaultValue : value
		);
		const currentValue = currentValueTemp || -1;

		useEffect(() => {
			if (value !== currentValue) {
				setCurrentValue(value);
			}
		}, [value, currentValue]);

		const emptyOption = { value: 0, label: String(emptyLabel || t('misc.dropdownPleaseSelect')) };
		const finalOptions: DropdownItem[] = useMemo(
			() => (withEmpty ? [emptyOption, ...(options || [])] : options || []),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[withEmpty, options]
		);

		return (
			<DropdownOriginal
				ref={ref}
				value={currentValue}
				defaultValue={defaultValue}
				onChange={(event) => {
					if (onChange) {
						onChange(event);
					} else {
						setCurrentValue(event.value);
					}
				}}
				valueTemplate={(item) => (item ? item.label : placeholder || t('misc.dropdownPleaseSelect'))}
				className={classNames(getInputBaseClassName({ inline }), 'dropdown')}
				dropdownIcon="fa fa-solid fa-chevron-down"
				panelClassName="dropdown-panel"
				optionLabel="label"
				optionValue="value"
				{...restProps}
				options={finalOptions}
			/>
		);
	}
);
