import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useScope } from 'contexts/scopeContext';
import { usePanel } from 'contexts/panelContext';
import { Todo } from 'types/Todo';
import { dateFormat } from 'utils/dateFormat';

const TodoItem = ({ type, variables, ...props }: Todo) => {
	const { t } = useTranslation();
	const { organizationName, programName, type: panelType } = useParams();

	const { currentProgram } = useScope();
	const { currentApplicationData } = usePanel();

	const icon = useMemo(() => {
		switch (type) {
			case 'application_during_deletion':
				return <i className="fa fa-times text-error" />;
			case 'pair_during_deletion':
				return <i className="fa fa-times text-error" />;
			case 'read_guides':
				return <i className="fa fa-light fa-book text-gray" />;
			case 'no_accepted_pair':
				return <i className="fa fa-light fa-handshake text-gray" />;
			case 'wait_for_mentee':
				return <i className="fa fa-light fa-clock text-gray" />;
			case 'confirm_entry_meeting':
				return <i className="fa fa-solid fa-handshake" />;
			case 'arrange_first_session_date':
				return <i className="fa fa-light fa-calendar-days text-primary" />;
			case 'contract_initial_sign_required':
				return <i className="fa fa-solid fa-triangle-exclamation text-rose" />;
			case 'contract_goals_sign_required':
				return <i className="fa fa-solid fa-triangle-exclamation text-rose" />;
			case 'session_reminder':
				return <i className="fa fa-light fa-calendar-days text-primary" />;
			case 'add_session_date':
				return <i className="fa fa-solid fa-calendar-plus text-primary" />;
			case 'final_report_mentee':
			case 'final_report_mentor':
				return <i className="fa-solid fa-flag-checkered" />;
			case 'li_label_info':
				return <i className="pi pi-linkedin text-gray" />;
			default:
				return <i />;
		}
	}, [type]);

	const content = useMemo(() => {
		switch (type) {
			case 'application_during_deletion':
				return (
					<span className="text-error">
						<Trans t={t} i18nKey="userPanel.todo.appliationDuringDeletion" />
					</span>
				);
			case 'pair_during_deletion':
				return (
					<span className="text-error">
						<Trans t={t} i18nKey="userPanel.todo.pairDuringDeletion" />
					</span>
				);
			case 'read_guides':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.readGuides"
						components={[
							<Link
								to={
									currentProgram?.language === 'pl'
										? `/panel/${organizationName}/${programName}/${panelType}/files/przewodnik-po-mentiway`
										: `/panel/${organizationName}/${programName}/${panelType}/files/mentiway-guides `
								}
								className="p-link"
							/>,
							<Link
								to={
									currentProgram?.language === 'pl'
										? `/panel/${organizationName}/${programName}/${panelType}/files/pigulki-wiedzy/rola-mentee-w-procesie-mentoringowym`
										: `/panel/${organizationName}/${programName}/${panelType}/files/knowledge/mentee's-role-in-the-mentoring-process`
								}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'no_accepted_pair':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.noAcceptedPair"
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pairing`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'add_session_date':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.addSessionDate"
						values={{
							...variables,
						}}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/session/${variables.sessionId}`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'wait_for_mentee':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.waitForMentee"
						components={[
							<Link
								to={
									currentProgram?.language === 'pl'
										? `/panel/${organizationName}/${programName}/${panelType}/files/przewodnik-po-mentiway`
										: `/panel/${organizationName}/${programName}/${panelType}/files/mentiway-guides`
								}
								className="p-link"
							/>,
							<Link
								to={
									currentProgram?.language === 'pl'
										? `/panel/${organizationName}/${programName}/${panelType}/files/pigulki-wiedzy/rola-mentora-w-procesie-mentoringowym`
										: `/panel/${organizationName}/${programName}/${panelType}/files/knowledge/mentor's-role-in-the-mentoring-process`
								}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'confirm_entry_meeting':
				return (
					<Trans
						t={t}
						i18nKey={
							currentApplicationData?.applicationRole === 'mentor'
								? 'userPanel.todo.confirmEntryMeetingWithMentee'
								: 'userPanel.todo.confirmEntryMeetingWithMentor'
						}
						values={variables}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pairing`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'arrange_first_session_date':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.arrangeFirstSessionDate"
						values={{
							...variables,
						}}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/session/${variables.sessionId}`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'contract_initial_sign_required':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.contractInitialSignRequired"
						values={variables}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/contract`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'contract_goals_sign_required':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.contractGoalsSignRequired"
						values={variables}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/mentee-plan`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'session_reminder':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.sessionReminder"
						values={{
							...variables,
							sessionDate: dateFormat(String(variables.sessionDate), 'full'),
						}}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/session/${variables.sessionId}`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'final_report_mentee':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.finalReportMentee"
						values={variables}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/process-summary`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'final_report_mentor':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.finalReportMentor"
						values={variables}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/pair/${variables.pairId}/final-report`}
								className="p-link"
							/>,
						]}
					/>
				);
			case 'li_label_info':
				return (
					<Trans
						t={t}
						i18nKey="userPanel.todo.liLabelInfo"
						values={variables}
						components={[
							<Link
								to={`/panel/${organizationName}/${programName}/${panelType}/linkedin-badge`}
								className="p-link"
							/>,
						]}
					/>
				);
			default:
				return '[not formated todo]';
		}
	}, [type, variables]);

	return (
		<li className="p-0 mb-2" {...props}>
			<div className="flex flex-row gap-2">
				<div
					className=" flex-0 flex flex-row justify-content-center align-items-start py-1"
					style={{ minWidth: '20px', width: '20px' }}
				>
					{icon}
				</div>
				<div className="text-left flex-1">{content}</div>
			</div>
		</li>
	);
};

export default TodoItem;
