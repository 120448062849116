import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/authContext';
import { PageLoading } from './PageLoading';

export const Enter = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { search } = useLocation();
	const queryParams = Object.fromEntries(Array.from(new URLSearchParams(search).entries()));

	const { ready, isLogged } = useAuth();

	const getExternalLoginRedirect = () => {
		const getLoginRedirect = (type: string) => {
			const redirectUrl = window.localStorage.getItem(`${type}-auth-redirect`);
			window.localStorage.removeItem(`${type}-auth-redirect`);
			return redirectUrl && redirectUrl.length > 0 ? redirectUrl : null;
		};
		const googleAuthRedirect = getLoginRedirect('google');
		const microsoftAuthRedirect = getLoginRedirect('microsoft');
		return googleAuthRedirect || microsoftAuthRedirect || null;
	};

	useEffect(() => {
		if (ready) {
			const externalLoginRedirect = getExternalLoginRedirect();
			if (externalLoginRedirect) {
				navigate({ pathname: externalLoginRedirect });
			} else if (queryParams?.redirectUrl) {
				navigate({ pathname: queryParams.redirectUrl });
			} else if (isLogged) {
				navigate('/change-organization');
			} else {
				navigate('/login');
			}
		}
	}, [navigate, queryParams, ready, isLogged]);

	return (
		// <CustomLoader text={{ firstLine: , secondLine: `${t('misc.redirect.subtitle')}...` }} />
		<PageLoading text={t('misc.redirect.title')} />
	);
};
