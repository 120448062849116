import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Goal } from 'types/Goal';

import { InputText } from 'primereact/inputtext';
import { useForm } from 'react-hook-form';
import { Button } from 'components/_new/Button';
import { DialogConfirm } from 'components/_new/Dialog';

interface GoalModuleCurrentItemProps {
	index: number;
	goal: Goal;
	onSave: (id: number, value: string) => void;
	onRemove: (id: number) => void;
	disabled: boolean;
}

// eslint-disable-next-line no-empty-pattern
export const GoalModuleCurrentItem = ({ index, goal, onSave, onRemove, disabled }: GoalModuleCurrentItemProps) => {
	const { t } = useTranslation();
	const { id, goal: currentGoal } = goal;
	const [editMode, setEditMode] = useState(false);
	const [incomingValue, setIncomingValue] = useState<string | null>(null);
	const { register, reset, getValues } = useForm({ defaultValues: { goal: currentGoal } });

	const resetValue = useCallback(() => {
		reset({ goal: currentGoal });
		setIncomingValue(null);
	}, [currentGoal, reset]);

	const handleEditClick = useCallback(() => {
		setEditMode(true);
	}, []);

	const handleSaveGoalClick = useCallback(() => {
		const newValue = getValues('goal');
		setIncomingValue(newValue);
		setEditMode(false);
		onSave(id, newValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, currentGoal]);

	const handleCancelClick = useCallback(() => {
		setEditMode(false);
		resetValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, currentGoal]);

	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
	const handleRemoveClick = useCallback(() => {
		setRemoveDialogOpen(true);
	}, []);

	return (
		<>
			<DialogConfirm
				visible={removeDialogOpen}
				onHide={() => setRemoveDialogOpen(false)}
				onConfirm={() => {
					onRemove(id);
				}}
			/>

			<li className="w-full flex flex-row gap-2 align-items-center">
				<span className="no-break mr-1">{`${t('userPanel.contract.goal')} ${index + 1}:`}</span>
				{editMode ? (
					<InputText className="flex-1 h-2rem w-full" {...register('goal')} disabled={disabled} />
				) : (
					<span>{incomingValue || currentGoal}</span>
				)}
				<div className="flex flex-row gap-1">
					{editMode && (
						<>
							<Button
								key="confirm"
								variant="primary"
								variantSize="sm"
								onClick={handleSaveGoalClick}
								icon="check"
								iconSet="pi"
								iconOnly
								disabled={disabled}
								label={t('actions.confirm')}
							/>
							<Button
								key="reject"
								variant="danger-text"
								variantSize="sm"
								onClick={handleCancelClick}
								icon="times"
								iconSet="pi"
								iconOnly
								disabled={disabled}
								label={t('actions.reject')}
							/>
						</>
					)}
					{!editMode && (
						<>
							<Button
								key="edit"
								variant="primary-text"
								variantSize="sm"
								onClick={handleEditClick}
								icon="pencil"
								iconSet="pi"
								iconOnly
								disabled={disabled}
								label={t('actions.edit')}
							/>
							<Button
								key="remove"
								onClick={handleRemoveClick}
								variant="danger-text"
								variantSize="sm"
								icon="trash"
								iconSet="pi"
								iconOnly
								disabled={disabled}
								label={t('actions.remove')}
							/>
						</>
					)}
				</div>
			</li>
		</>
	);
};

export default GoalModuleCurrentItem;
