import { useRefValue } from 'hooks/useRefValue';
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';

export type WebSocketConfig = {
	url: string;
	enabled?: boolean;
};

export type Message = { event: string; data: any };

export interface WebSocketContextData {
	setup: (config: WebSocketConfig) => void;
	sendMessage: (event: string, data?: any) => void;
	addMessageListener: (options: OnMessage) => void;
	removeMessageListener: (options: string | OnMessage) => void;
	messages: Message[];
	close: () => void;
	connected: boolean;
	connection: WebSocketInstance | null;
	parseMessage: (event: MessageEvent<any>) => { event: string; data: any };
}

type WebSocketInstance = {
	socket: WebSocket;
	close: () => void;
};

type EventListener = {
	id?: string;
	eventName: string;
	callback: (data: any) => void;
};

export type OnMessage = EventListener & {
	enabled?: boolean;
};

export const WebSocketContext = createContext<WebSocketContextData>({} as WebSocketContextData);

type WebSocketProviderProps = { children: any };

const WS_PING_INTERVAL = 20000;
const WS_MAX_RECONNECT = 3;
const WS_RECONNECT_INTERVAL = 2500;

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
	const [connected, setConnected] = useState<boolean>(false);
	const [messages, setMessages] = useState<Message[]>([]);
	const connectionRef = useRef<WebSocketInstance | null>(null);

	// const eventListenersRef = useRef<EventListener[]>([]);
	// const [eventListeners, setEventListeners] = useState<EventListener[]>([]);
	const [eventListeners, setEventListeners, getEventListeners] = useRefValue<EventListener[]>([]);
	const [reconnectAttempt, setReconnectAttempt, getReconnectAttempt] = useRefValue(0);

	const handleMessage = useCallback(
		(event: MessageEvent) => {
			const message: Message = JSON.parse(event.data);
			const msgEvent = message?.event as Message['event'];
			const msgData = message?.data as Message['data'];
			getEventListeners()
				.filter(({ eventName }) => eventName === msgEvent)
				.forEach(({ callback }) => callback(msgData));
		},
		[getEventListeners]
	);

	const createConnection = useCallback(
		(url: string) => {
			const socket = new WebSocket(url);

			const handleOpen = () => {
				setConnected(true);
				setReconnectAttempt(0);
			};
			const handleClose = () => {
				setConnected(false);
				if (getReconnectAttempt() > WS_MAX_RECONNECT) {
					console.error('WebSocket maximum number of connection attempts has been reached');
				} else {
					setReconnectAttempt((prev) => prev + 1);
					setTimeout(() => createConnection(url), WS_RECONNECT_INTERVAL);
				}
			};

			socket.addEventListener('open', handleOpen);
			socket.addEventListener('close', handleClose);
			socket.addEventListener('message', handleMessage);

			return {
				socket,
				close: () => {
					socket.removeEventListener('open', handleOpen);
					socket.removeEventListener('close', handleClose);
					socket.removeEventListener('message', handleMessage);
					socket.close();
				},
			};
		},
		[handleMessage, reconnectAttempt, setReconnectAttempt]
	);

	// const [savedConfig, setConfig] = useState<WebSocketConfig>();
	const setup = useCallback(
		(config: WebSocketConfig) => {
			const { url, enabled } = config;
			if (!enabled) {
				close();
			}
			if (enabled && !connectionRef.current) {
				// setConfig(config);
				(connectionRef as any).current = createConnection(url);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[connectionRef]
	);

	const sendMessage = useCallback(
		(event: string, data?: any) => {
			if (connectionRef.current) {
				connectionRef.current.socket.send(JSON.stringify({ event, data }));
			}
		},
		[connectionRef]
	);

	useEffect(() => {
		const interval = setInterval(() => {
			if (connectionRef?.current) {
				sendMessage('ping');
			}
		}, WS_PING_INTERVAL);
		return () => clearInterval(interval);
	}, [connectionRef, sendMessage]);

	const addMessageListener = (options: OnMessage) => {
		setEventListeners((prev) => {
			const idx = prev.findIndex(
				(eventListener) =>
					eventListener.id === (options.id || options.eventName) &&
					eventListener.eventName === options.eventName
			);
			return idx !== -1
				? prev.map((eventListener, index) =>
						index === idx ? { ...eventListener, callback: options.callback } : eventListener
				  )
				: [...prev, options];
		});
	};

	const removeMessageListener = (id: string | OnMessage) => {
		setEventListeners((prev) =>
			prev.filter((eventListener) => eventListener.id === (typeof id === 'string' ? id : id.id || id.eventName))
		);
	};

	const close = () => {
		connectionRef?.current?.close();
	};

	const parseMessage = (mevent: MessageEvent<any>) => {
		const parsed = JSON.parse(mevent.data);
		const event = parsed.event || null;
		const data = parsed.data || null;
		return { event, data };
	};

	return (
		<WebSocketContext.Provider
			value={{
				parseMessage,
				connection: connectionRef.current,
				setup,
				sendMessage,
				addMessageListener,
				removeMessageListener,
				messages,
				close,
				connected,
			}}
		>
			{children}
		</WebSocketContext.Provider>
	);
};
