import React, { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { landingPagesApi, labelGenerationApi, apiUrl } from 'api';
import { RouterUrlParams } from 'App';
import { Application } from 'types/Application';
import { Skeleton } from 'primereact/skeleton';
import { Box, BoxSection } from 'components/_new/Box';
import { useScope } from 'contexts/scopeContext';
import { capitalizeWord } from 'utils/helpers';
import { statsApi } from 'api/stats';
import { usePanel } from 'contexts/panelContext';
import { Spinner } from 'components/_new/Spinner';
import FileDisplayModule from 'components/knowledgeDatabase/FileDisplayModule';
import { useGlobal } from 'contexts/globalContext';
import { Button } from 'components/_new/Button';
import { Dropdown } from '../../_new/Dropdown';
import { InputText } from '../../_new/InputText';
import { LabelPayload } from '../../../types/payloads/LabelPayload';
import { Field } from '../../_new/Field';

export enum ApplicationLabelVariant {
	Dark = 'dark',
	Purple = 'purple',
	Light = 'light',
}

interface Props {
	data: Application;
}

type LabelForm = {
	variant: ApplicationLabelVariant;
	topLine: string;
	bottomLine: string;
};

interface MyWindow extends Window {
	IN: any;
}

declare let window: MyWindow;

const LinkedInCard: FC<Props> = ({ data }) => {
	const { t, i18n } = useTranslation();
	const { organizationName, type } = useParams() as RouterUrlParams;
	const imageBlobRef = useRef<Blob | null>(null);
	const { currentProgram, applicationsRefetch } = useScope();
	const { currentApplicationData } = usePanel();
	const { setAvatarModalIsOpen } = useGlobal();

	const { data: checkIsFileResult, isFetched: labelCheckIsFileFetched } = useQuery(
		['label-check', currentApplicationData.id],
		() => labelGenerationApi.checkLabel(currentApplicationData.id),
		{ enabled: Boolean(currentApplicationData.id) }
	);

	const { data: file } = useQuery(
		['label-check', currentApplicationData.id],
		() => labelGenerationApi.checkLabel(currentApplicationData.id),
		{ enabled: Boolean(labelCheckIsFileFetched && checkIsFileResult !== null) }
	);

	const {
		refetch: refetchBadge,
		data: getBadge,
		isLoading: badgeLoading,
		isError: badgeError,
	} = useQuery(['label-data', data.id], () => labelGenerationApi.getBadgeData(data.id), {
		onSuccess: () => {
			applicationsRefetch();
		},
	});
	const { mutate: badgePreview, isLoading } = useMutation(
		(labelPayload: LabelPayload) => labelGenerationApi.getBadgePreview(data.id, labelPayload),
		{
			onSuccess: (data) => {
				imageBlobRef.current = new Blob([data], { type: 'image/jpeg' });
			},
		}
	);
	const { mutate: updateLabelMutate, isLoading: isSubmitting } = useMutation(
		(labelPayload: LabelPayload) => labelGenerationApi.updateBadgeData(data.id, labelPayload),
		{
			onSuccess: () => {
				applicationsRefetch();
			},
		}
	);
	const {
		programMembership: { user },
	} = data;
	const lang = i18n.resolvedLanguage;
	let defaultTopLine: string;

	if (lang === 'pl' && type === 'mentor') {
		defaultTopLine = `Jestem ${capitalizeWord(type)}em/ką`;
	} else if (lang === 'pl') {
		defaultTopLine = `Jestem ${capitalizeWord(type)}`;
	} else {
		defaultTopLine = `I am ${capitalizeWord(type)}`;
	}
	const defaultBottomLine =
		lang === 'pl'
			? `W programie mentoringowym ${currentProgram?.displayName} w ${organizationName}`
			: `In mentoring program ${currentProgram?.displayName} in ${organizationName}`;
	const { register, control, handleSubmit, setValue, formState } = useForm<LabelForm>({
		defaultValues: {
			topLine: defaultTopLine,
			bottomLine: defaultBottomLine,
			variant: ApplicationLabelVariant.Dark,
		},
	});

	const handleSubmitForm = handleSubmit((values: LabelForm) => {
		updateLabelMutate({ variant: values.variant, top: values.topLine, bottom: values.bottomLine });
		badgePreview({
			variant: values.variant,
			top: values.topLine,
			bottom: values.bottomLine,
		});
	});

	useEffect(() => {
		async function fetchAndSetDefaults() {
			const badgeData = (await refetchBadge()) as any;
			const organizationData = await landingPagesApi.getOrganization(organizationName);
			const defaultBottomLine =
				lang === 'pl'
					? `W programie mentoringowym ${currentProgram?.displayName} w ${organizationData.displayName}`
					: `In mentoring program ${currentProgram?.displayName} in ${organizationData.displayName}`;
			let top = defaultTopLine;
			let bottom = defaultBottomLine;
			let variant = ApplicationLabelVariant.Dark;

			if (badgeData?.data?.labelData) {
				top = badgeData.data.labelData.top || defaultTopLine;
				bottom = badgeData.data.labelData.bottom || defaultBottomLine;
				variant = badgeData.data.labelData.variant || ApplicationLabelVariant.Dark;

				setValue('topLine', top);
				setValue('bottomLine', bottom);
				setValue('variant', variant);
			}
			badgePreview({ variant, top, bottom });
		}

		Promise.resolve(fetchAndSetDefaults());
	}, []);

	if (!labelCheckIsFileFetched) {
		return <Spinner />;
	}

	const downloadImage = () => {
		if (imageBlobRef.current) {
			// Bump statistics
			statsApi.bump({
				applicationId: currentApplicationData.id,
				type: 'linkedin-download',
			});

			const blobUrl = URL.createObjectURL(imageBlobRef.current);
			const a = document.createElement('a');
			a.href = blobUrl;
			a.download =
				lang === 'pl' ? `Etykieta na LinkedIn dla ${user.firstName}` : `LinkedIn Badge for ${user.firstName}`;
			a.click();
			URL.revokeObjectURL(blobUrl);
		}
	};

	return (
		<>
			{/* <ChangeAvatarDialog visible={avatarModalIsOpenLocally} onHide={() => setAvatarModalIsOpenLocally(false)} /> */}
			<div className="flex flex-column row-gap-4 ">
				<Box variant="white-bordered">
					<BoxSection>
						{checkIsFileResult ? (
							<FileDisplayModule selectedFile={checkIsFileResult} disableRating />
						) : (
							<div className="flex flex-column gap-4 lg:flex-row h-full">
								<form
									className="w-full flex flex-column justify-content-between"
									onSubmit={handleSubmitForm}
								>
									<span className="ml-2 mb-2 lg:mt-2">{t('userPanel.linkedInBadge.copy')}</span>
									<span className="ml-2 mb-2">
										<Trans
											t={t}
											i18nKey="userPanel.linkedInBadge.tagReminder"
											components={[
												<a
													className="p-link"
													href="https://www.linkedin.com/company/mentiway/"
													target="_blank"
													rel="noreferrer"
												>
													@mentiway
												</a>,
											]}
										/>
									</span>

									<Field
										key="topLine"
										label={t('userPanel.linkedInBadge.header')}
										htmlFor="topLine"
										containerClassName="mb-2"
									>
										<InputText
											required
											className="mt-2"
											id="topLine"
											{...register('topLine', {
												required: t('misc.forms.fieldIsRequired'),
												maxLength: {
													value: 120,
													message: t('misc.forms.limitNumberOfCharacters', {
														max: 120,
													}),
												},
											})}
										/>
										{formState.errors.topLine && (
											<small className="p-error font-bold ml-3">
												{formState.errors.topLine.message}
											</small>
										)}
									</Field>
									<Field
										key="bottomLine"
										label={t('userPanel.linkedInBadge.footer')}
										htmlFor="bottomLine"
										containerClassName="mb-2"
									>
										<InputText
											required
											className="mt-2"
											id="bottomLine"
											{...register('bottomLine', {
												required: t('misc.forms.fieldIsRequired'),
												maxLength: {
													value: 200,
													message: t('misc.forms.limitNumberOfCharacters', {
														max: 200,
													}),
												},
											})}
										/>{' '}
										{formState.errors.bottomLine && (
											<small className="p-error font-bold ml-3">
												{formState.errors.bottomLine.message}
											</small>
										)}
									</Field>
									<Field
										key="variant"
										label={t('userPanel.linkedInBadge.variantOfBadgeLabel')}
										htmlFor="variant"
										containerClassName="mb-2"
									>
										<Controller
											control={control}
											name="variant"
											render={({ field }) => (
												<Dropdown
													required
													options={[
														{
															label: t('userPanel.linkedInBadge.variantDark'),
															value: ApplicationLabelVariant.Dark,
														},
														{
															label: t('userPanel.linkedInBadge.variantPurple'),
															value: ApplicationLabelVariant.Purple,
														},
														{
															label: t('userPanel.linkedInBadge.variantLight'),
															value: ApplicationLabelVariant.Light,
														},
													]}
													{...field}
													className="mt-2"
												/>
											)}
										/>
									</Field>

									<div
										style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
									>
										<span>{t('userPanel.linkedInBadge.changeAvatar1')} </span>
										<Button
											variant="primary-text"
											className="p-0 pl-1"
											onClick={() => setAvatarModalIsOpen(true)}
											label={t('userPanel.linkedInBadge.changeAvatar2')}
										/>
									</div>

									<Button
										submit
										icon="refresh"
										iconSet="pi"
										className="flex ml-auto mt-2"
										label={t('userPanel.linkedInBadge.refreshBadge')}
										loading={isLoading || isSubmitting}
									/>
								</form>
								<div className=" w-full flex flex-column justify-content-center align-items-center">
									<div
										style={{
											height: '100%',
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											overflow: 'hidden',
											aspectRatio: '1/1',
											maxWidth: '550px',
										}}
									>
										{!imageBlobRef.current || isLoading || isSubmitting ? (
											<Skeleton width="100%" height="100%" borderRadius="10px" />
										) : (
											<img
												src={URL.createObjectURL(imageBlobRef.current)}
												alt="Generated Label"
												style={{
													maxWidth: '100%',
													height: 'auto',
													objectFit: 'contain',
													borderRadius: '10px',
												}}
											/>
										)}
									</div>
									<div className="flex flex-row justify-content-between w-full align-items-center justify-content-between gap-2">
										<Button
											label={t('userPanel.linkedInBadge.publishOnLinkedIn')}
											className="flex mt-5"
											disabled={!data.applicationHash}
											onClick={() => {
												// bump statistics
												statsApi.bump({
													applicationId: currentApplicationData.id,
													type: 'linkedin-publish',
												});
												window.open(
													`https://www.linkedin.com/sharing/share-offsite/?url=${apiUrl}/label-generator/${data.applicationHash}`,
													'pagename',
													'resizable,height=600,width=500'
												);
											}}
										/>
										<Button
											onClick={downloadImage}
											className="flex ml-auto mt-5"
											disabled={!imageBlobRef.current}
											label={t('userPanel.linkedInBadge.downloadBadge')}
										/>
									</div>
								</div>
							</div>
						)}
					</BoxSection>
				</Box>
			</div>
		</>
	);
};

export default LinkedInCard;
