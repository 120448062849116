import React, { HTMLAttributes } from 'react';
import { classNames } from 'primereact/utils';
import './Badge.scss';

export type BadgeContent = string | number | null;
type BadgeProps = Omit<HTMLAttributes<HTMLSpanElement>, 'children'> & {
	children?: BadgeContent;
};

export const Badge = ({ className, children, ...props }: BadgeProps) => {
	const isNumber = typeof children === 'number' && Number.isInteger(children);
	const isString = typeof children === 'string';
	const hasContent = (isNumber && children > 0) || (isString && children.length > 0);

	return hasContent ? (
		<span className={classNames('badge', className)} {...props}>
			{children}
		</span>
	) : (
		// always return jsx
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<></>
	);
};
