import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateGoalPayload, UpdateGoalPayload } from 'types/payloads/GoalPayload';
import { ActionGoalId, Goal } from 'types/Goal';

import TextArea from 'components/_common/forms/TextArea';
import { Button } from 'primereact/button';

import getGoalRows from './components/getGoalRows';

interface Props {
	id: string;
	goalData: Goal;
	editedGoal: number;
	getGoals: () => void;
	updateGoal: (goalValue: UpdateGoalPayload) => void;
	saveNewGoal: (goalValue: CreateGoalPayload) => void;
	setEditedGoal: (id: null | number) => void;
}

interface GoalFormObject {
	goal: string;
	change: string;
	achievement: string;
	result: string;
	priorities: string;
}

const GoalForm = ({ id, goalData, editedGoal, getGoals, updateGoal, saveNewGoal, setEditedGoal }: Props) => {
	const { goal, change, achievement, result, priorities } = goalData;

	const { control, handleSubmit } = useForm<GoalFormObject>({
		defaultValues: {
			goal,
			change,
			achievement,
			result,
			priorities,
		},
	});
	const { t } = useTranslation();

	const rows = getGoalRows(goalData, t);

	const submitGoal = (fields: GoalFormObject) => {
		if (editedGoal === ActionGoalId.new) saveNewGoal(fields);
		else updateGoal({ ...fields, id: editedGoal });

		setEditedGoal(null);
	};

	return (
		<form id={id} className="w-full" onSubmit={handleSubmit((fields) => submitGoal(fields))}>
			{rows?.map(({ label, required, type }) => (
				<div key={type} className="flex flex-row p-3 pr-0 gap-2 flex-wrap">
					<div className="w-full flex-2 lg:flex-1">{label}</div>
					<div className="flex-grow-1 flex-3 lg:flex-1">
						<TextArea
							name={type}
							control={control}
							required={required}
							className="font-bold border-round-xl"
						/>
					</div>
				</div>
			))}
		</form>
	);
};

export default GoalForm;
