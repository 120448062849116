import React, {
	Dispatch,
	SetStateAction,
	RefObject,
	PropsWithChildren,
	createContext,
	useContext,
	useState,
	useRef,
} from 'react';
import { Toast } from 'primereact/toast';

type GlobalContextValue = {
	toastRef: RefObject<Toast>;
	reportIssueModalIsOpen: boolean;
	setReportIssueModalIsOpen: Dispatch<SetStateAction<boolean>>;
	avatarModalIsOpen: boolean;
	setAvatarModalIsOpen: Dispatch<SetStateAction<boolean>>;
	myAccountModalOpen: boolean;
	setMyAccountModalOpen: Dispatch<SetStateAction<boolean>>;
	myCalendarModalOpen: boolean;
	setMyCalendarModalOpen: Dispatch<SetStateAction<boolean>>;
	newChats: number[];
	setNewChats: Dispatch<SetStateAction<number[]>>;
};

const GlobalContext = createContext<GlobalContextValue>({} as GlobalContextValue);

// eslint-disable-next-line @typescript-eslint/ban-types
type GlobalContextProviderProps = PropsWithChildren<{
	value: {
		toastRef: RefObject<Toast>;
	};
}>;

export const GlobalContextProvider = ({ value, children }: GlobalContextProviderProps) => {
	const { toastRef } = value;
	const [reportIssueModalIsOpen, setReportIssueModalIsOpen] = useState(false);
	const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
	const [myAccountModalOpen, setMyAccountModalOpen] = useState(false);
	const [myCalendarModalOpen, setMyCalendarModalOpen] = useState(false);
	const [newChats, setNewChats] = useState<number[]>([]);

	return (
		<GlobalContext.Provider
			value={{
				toastRef,
				reportIssueModalIsOpen,
				setReportIssueModalIsOpen,
				avatarModalIsOpen,
				setAvatarModalIsOpen,
				myAccountModalOpen,
				setMyAccountModalOpen,
				myCalendarModalOpen,
				setMyCalendarModalOpen,
				newChats,
				setNewChats,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobal = () => useContext(GlobalContext);
