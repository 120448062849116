import React, { Ref, forwardRef, useCallback, useMemo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogBaseExtendProps } from './DialogBase';
import { ButtonProps } from '../Button';
import { DialogAction, DialogActionButtonProps } from './DialogAction';

export type DialogConfirmActionProps = Partial<ButtonProps> & {
	key?: string;
};

export type DialogConfirmProps = DialogBaseExtendProps & {
	title?: string;
	cancelAction?: boolean;
	confirmAction?: DialogConfirmActionProps;
	onConfirm?: () => void;
	rejectAction?: DialogConfirmActionProps;
	onReject?: () => void;
	message?: string;
};

export type DialogConfirmExtendProps = Omit<DialogConfirmProps, 'title' | 'childre'>;

export const DialogConfirm = forwardRef(
	(
		{
			title,
			confirmAction,
			onConfirm,
			rejectAction,
			onReject,
			onHide,
			children,
			message,
			...restProps
		}: DialogConfirmProps,
		ref: Ref<HTMLDivElement>
	) => {
		const { t } = useTranslation();
		const handleCloseButtonClick = useCallback(() => {
			if (onHide) {
				onHide();
			}
		}, [onHide]);

		const finalConfirmAction: DialogActionButtonProps = useMemo(
			() => ({
				key: confirmAction?.key || 'confirm',
				label: t('actions.confirm'),
				icon: 'check',
				...confirmAction,
				onClick: (event: MouseEvent<HTMLButtonElement>) => {
					handleCloseButtonClick();
					if (confirmAction?.onClick) {
						confirmAction.onClick(event);
					}
					if (onConfirm) {
						onConfirm();
					}
				},
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[confirmAction, handleCloseButtonClick, onConfirm]
		);

		const finalRejectAction: DialogActionButtonProps = useMemo(
			() => ({
				key: rejectAction?.key || 'reject',
				label: t('actions.reject'),
				icon: 'times',
				variant: 'primary-outlined',
				...rejectAction,
				onClick: (event: MouseEvent<HTMLButtonElement>) => {
					handleCloseButtonClick();
					if (rejectAction?.onClick) {
						rejectAction.onClick(event);
					}
					if (onReject) {
						onReject();
					}
				},
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[handleCloseButtonClick, onReject, rejectAction]
		);

		const defaultChildren = t('programPanel.pairing.removeApplication.reassurance');

		return (
			<DialogAction
				ref={ref}
				title={title || t('programPanel.pairing.removeApplication.confirmAction')}
				actions={[finalRejectAction, finalConfirmAction]}
				withoutCancelAction
				onHide={onHide}
				{...restProps}
			>
				{message || children || defaultChildren}
			</DialogAction>
		);
	}
);
