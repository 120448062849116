import React, { PropsWithChildren } from 'react';

type SegmentProps = PropsWithChildren<{
	title: string;
}>;

export const Segment = ({ title, children }: SegmentProps) => (
	<div className="flex flex-column gap-2">
		<h3>{title}</h3>
		<div className="flex flex-column align-items-start gap-2">{children}</div>
	</div>
);
