import React, { FC, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { RouterUrlParams } from 'App';
import { useScope } from 'contexts/scopeContext';
import { ProgramWithLanding } from 'types/Program';
import { LandingPageFormsAvailability } from 'types/LandingPageFormsAvailability';
import WelcomeMessage from 'components/landingPages/components/WelcomeMessage';
import UserSettings from 'components/_common/UserSettings';
import ChangeOrganization from 'components/_common/panel/ChangeOrganization';
import { fixedT } from 'locale';
import { useAuth } from 'contexts/authContext';
import { border, colors } from 'theme';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import MobileUserSettings from 'components/_common/MobileUserSettings';
import { Dialog } from 'components/_new/Dialog';
import { useNavigate } from 'hooks/useNavigate';

interface TopbarProps {
	programData: ProgramWithLanding;
	formsAvailability?: LandingPageFormsAvailability;
}
const Topbar = ({ programData, formsAvailability }: TopbarProps) => {
	const { organizationName, programName } = useParams() as RouterUrlParams;
	const navigate = useNavigate();
	const location = useLocation();
	const { currentUser } = useAuth();
	const [showOrgDialog, setShowOrgDialog] = useState(false);
	const { applicationsData } = useScope();
	const hasAcceptedApplication = applicationsData.some(({ approved }) => approved === 'accepted');

	const { landingContent } = programData.landingPage;
	const { title, name, logo, pages } = landingContent || { title: '', name: '', logo: '', pages: [] };

	const baseLink = 'name' in programData ? programData.name : organizationName;
	const targetPath = `/${organizationName}/${programName}/index`;
	const isCurrentPath = location.pathname === targetPath;
	return (
		<div>
			<div className="flex justify-content-between landing-page-container py-3">
				<img
					src={logo}
					alt="logo"
					onClick={() => navigate(`/${organizationName}/${programName}/index`)}
					className={classNames('max-h-3rem', { 'cursor-pointer': !isCurrentPath })}
				/>

				<div className="text-right">
					<h3 className="mt-0 mb-1">{name}</h3>
					<small>{title}</small>
				</div>
			</div>

			<div
				style={{ borderBottom: border(colors.lightGray), backgroundColor: colors.purplishBlue }}
				className="z-1"
			>
				<div className="flex justify-content-between landing-page-container py-0">
					<div className="hidden md:flex align-items-center column-gap-4">
						{pages.map((page) => (
							<NavLink
								to={`/${baseLink}/${page.url}`}
								key={page.url}
								className={({ isActive }) =>
									classNames('text-white font-bold text-sm p-3', {
										'bg-dark-purple-blue': isActive,
									})
								}
							>
								{page.name}
							</NavLink>
						))}
						{formsAvailability?.isMentorRecruitmentAvailable && (
							<>
								{!currentUser && <Tooltip target=".link" position="bottom" />}
								<span
									className="hidden md:inline-flex link h-full"
									data-pr-tooltip={fixedT(programData.language, 'landingPages.linkTooltip')}
									id="text-white font-bold text-sm p-3"
								>
									<NavLink
										to={`/${baseLink}/apply-mentor`}
										className={({ isActive }) =>
											classNames('text-white font-bold text-sm p-3', {
												'bg-dark-purple-blue': isActive,
											})
										}
									>
										{fixedT(programData.language, 'landingPages.buttons.mentorApplication')}
									</NavLink>
								</span>
							</>
						)}
						{formsAvailability?.isMenteeRecruitmentAvailable && (
							<>
								{!currentUser && <Tooltip target=".link" position="bottom" />}
								<span
									className="hidden md:inline-flex link"
									data-pr-tooltip={fixedT(programData.language, 'landingPages.linkTooltip')}
									id="text-white font-bold text-sm p-3"
								>
									<NavLink
										to={`/${baseLink}/apply-mentee`}
										className={({ isActive }) =>
											classNames('text-white font-bold text-sm p-3', {
												'bg-dark-purple-blue': isActive,
											})
										}
									>
										{fixedT(programData.language, 'landingPages.buttons.menteeApplication')}
									</NavLink>
								</span>
							</>
						)}
						{currentUser && hasAcceptedApplication && (
							<span
								className="hidden md:inline-flex link"
								data-pr-tooltip={fixedT(programData.language, 'landingPages.linkTooltip')}
								id="text-white font-bold text-sm p-3"
							>
								<NavLink
									to={`/panel/${programData.name}`}
									className={classNames('text-white font-bold text-sm p-3')}
								>
									{fixedT(programData.language, 'landingPages.buttons.userArea')}
								</NavLink>
							</span>
						)}
					</div>

					<div className="flex align-items-center column-gap-4 py-2">
						<WelcomeMessage pageDetails={programData} />

						{currentUser && (
							<div className="hidden md:inline-flex">
								<UserSettings
									currentUser={currentUser}
									setShowOrgDialog={setShowOrgDialog}
									source="landingPage"
									optionsLanguage={programData.language}
									hasApplicationSubmitted={!!applicationsData?.length}
								/>
							</div>
						)}
					</div>

					{currentUser && (
						<div className="sm:inline flex align-items-center column-gap-4 py-2 md:hidden ">
							<MobileUserSettings
								currentUser={currentUser}
								setShowOrgDialog={setShowOrgDialog}
								source="landingPage"
								optionsLanguage={programData.language}
								hasApplicationSubmitted={!!applicationsData?.length}
								formsAvailability={formsAvailability}
							/>
						</div>
					)}
				</div>
			</div>

			<Dialog title="Zmiana organizacji" size="sm" onHide={() => setShowOrgDialog(false)} visible={showOrgDialog}>
				<ChangeOrganization card onCancel={() => setShowOrgDialog(false)} />
			</Dialog>
		</div>
	);
};

export default Topbar;
