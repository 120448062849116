import React, { PropsWithChildren, createContext, useContext } from 'react';

type ContractContextValue = {
	pairId: number;
};

export const ContractContext = createContext<ContractContextValue>({
	pairId: 0,
});

type ContractContextProviderProps = PropsWithChildren<{
	value: {
		pairId: number;
	};
}>;

const ContractContextProvider = ({ value, children }: ContractContextProviderProps) => (
	<ContractContext.Provider value={value}>{children}</ContractContext.Provider>
);

export const useContractContext = () => useContext(ContractContext);

export default ContractContextProvider;
