import React from 'react';
import { useTranslation } from 'react-i18next';

import { Pair } from 'types/Pair';
import PairRejected from 'assets/icons/pair-cancel.svg';
import PairStatus from 'assets/icons/pair-status.svg';
import PairAccept from 'assets/icons/pair-accept.svg';

import { ConfirmPairItem } from './ConfirmPairItem';
import { IconWrapper } from './IconWrapper';

interface MentorActionsProps {
	data: Pair;
	setInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
	onAction?: () => void;
}

const MentorActions = ({ data, setInfoDialogOpen, disabled, onAction }: MentorActionsProps) => {
	const { t } = useTranslation();
	const { rejectedByMentee, rejectedByMentor, acceptedByMentee, acceptedByMentor, id, mentorMembershipId, status } =
		data;

	const noRelationship =
		acceptedByMentor === null &&
		acceptedByMentee === null &&
		rejectedByMentee === null &&
		rejectedByMentor === null &&
		status !== 'rejected';

	return (
		<div className="flex justify-content-between w-full align-content-center">
			<div className="flex-1 flex flex-column justify-content-center">
				{noRelationship && status === 'open' && (
					<IconWrapper
						imgSrc={PairStatus}
						text={t('userPanel.pairing.meetingInvitationSent')}
						alt={t('userPanel.pairing.meetingInvitationSent')}
					/>
				)}
				{acceptedByMentee && !acceptedByMentor && (
					<IconWrapper
						imgSrc={PairStatus}
						text={t('userPanel.pairing.oppositeSideAccepted', { type: 'Mentee' })}
						alt={t('userPanel.pairing.oppositeSideAccepted')}
					/>
				)}
				{!noRelationship && (
					<>
						{rejectedByMentee && (
							<IconWrapper
								imgSrc={PairRejected}
								alt={t('userPanel.pairing.altPairRejected')}
								text={t('userPanel.pairing.pairRejected', { type: 'Mentee' })}
							/>
						)}
						{rejectedByMentor && (
							<div className="flex w-full justify-content-between">
								<IconWrapper
									imgSrc={PairRejected}
									alt={t('userPanel.pairing.altPairRejected')}
									text={t('userPanel.pairing.youRejected')}
								/>
							</div>
						)}
						{acceptedByMentee && acceptedByMentor && (
							<IconWrapper
								imgSrc={PairAccept}
								alt={t('userPanel.pairing.altPairAccepted')}
								text={t('userPanel.pairing.pairAccepted')}
							/>
						)}
						{acceptedByMentor && !acceptedByMentee && (
							<IconWrapper
								imgSrc={PairStatus}
								alt={t('userPanel.pairing.altPairStatus')}
								text={t('userPanel.pairing.waitForAnswer', { type: 'Mentee' })}
							/>
						)}
						{status === 'rejected' && !rejectedByMentor && !rejectedByMentee && (
							<IconWrapper
								imgSrc={PairRejected}
								alt={t('userPanel.pairing.pairLimitReached')}
								text={t('userPanel.pairing.pairLimitReached')}
							/>
						)}
					</>
				)}
			</div>
			<div className="flex-1 flex justify-content-end align-content-center">
				{((noRelationship && status === 'open') || (acceptedByMentee && !acceptedByMentor)) && (
					<ConfirmPairItem
						id={id}
						membershipId={mentorMembershipId}
						setInfoDialogOpen={setInfoDialogOpen}
						hideButton={disabled}
						onAction={onAction}
					/>
				)}
			</div>
		</div>
	);
};

export default MentorActions;
