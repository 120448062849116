import { useEffect } from 'react';
import { useScope } from 'contexts/scopeContext';
import { chatApi } from 'api/chat';
import { ChatMessage } from 'types/Chat';
import { useLongPollingQuery } from './useLP';
// import { useQueryClient } from '@tanstack/react-query';

type UseChatConversationSubscriberOptions = {
	interlocutorMembershipId: number | null;
	onMessage: (data: ChatMessage) => void;
};

export const useChatConversationSubscriber = ({
	interlocutorMembershipId,
	onMessage,
}: UseChatConversationSubscriberOptions) => {
	const { currentProgramMembership } = useScope();

	const { cancel } = useLongPollingQuery(
		['sub-chat-message', { programMembership: Number(currentProgramMembership?.id), interlocutorMembershipId }],
		({ signal }) =>
			chatApi.subChatMessage({
				params: {
					programMembershipId: Number(currentProgramMembership?.id),
					interlocutorMembershipId: Number(interlocutorMembershipId),
					// bump: pageParam || 1,
				},
				signal,
			}),
		{
			enabled: Boolean(interlocutorMembershipId),
			onMessage,
		}
	);

	useEffect(() => {
		return () => {
			cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [interlocutorMembershipId]);

	return null;
};
