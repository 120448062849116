import { httpClient } from 'api';

type FormType = 'questions' | 'techniques' | 'smart' | 'homework';

type GetReplyOutput = {
	status: 'error' | 'waiting' | 'ready';
	content: string | null;
	prompt: string;
	info: {
		applicationId: number;
		max: number;
		current: number;
		left: number;
		rating: number;
		comment: string | null;
	};
};

export const mentibotAiApi = {
	getLastReply: (pairId: number, applicationId: number, formType: FormType) =>
		httpClient.get<GetReplyOutput>(`mentibot-ai/${pairId}/${applicationId}/${formType}`),
	getNewReply: (pairId: number, applicationId: number, formType: FormType, body: any) =>
		httpClient.post<GetReplyOutput>(`mentibot-ai/${pairId}/${applicationId}/${formType}`, body),
	addRating: (
		pairId: number,
		applicationId: number,
		formType: FormType,
		{ rating, comment }: { rating?: number; comment?: string }
	) => httpClient.put<GetReplyOutput>(`mentibot-ai/${pairId}/${applicationId}/${formType}`, { rating, comment }),
	// addFeedback: (applicationId: number, { comment }: { comment: string }) =>
	// 	axiosInstance.post(`mentibot-ai/${applicationId}/feedback`, { comment }),
};
