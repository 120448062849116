import { httpClient } from 'api';
import { AuthData } from 'types/Auth';
import { AuthPayload } from 'types/payloads/AuthPayload';
import { PasswordSetupPayload } from 'types/payloads/PasswordSetupPayload';
import { LoginPayload } from 'types/payloads/LoginPayload';
import { RegisterPayload } from 'types/payloads/RegisterPayload';
import { SendResetPasswordPayload } from 'types/payloads/SendResetPasswordPayload';
import { UpdatePasswordPayload } from 'types/payloads/UpdatePasswordPayload';
// import { ExternalAuthType } from 'components/auth/ExternalAuth';
import { RegisterOrganizationPayload } from 'types/payloads/RegisterOrganizationPayload';
import { CurrentUser } from 'types/CurrentUser';

export const authApi = {
	me: () => httpClient.get<CurrentUser>('auth/me'),
	invalidateToken: () => httpClient.get('auth/invalidate'), // TODO remove, for testing purposes only
	refreshToken: (refreshToken: string) => httpClient.post<AuthPayload>('auth/refresh', { refreshToken }),

	loginUser: (payload: LoginPayload) => httpClient.post<AuthData | { message: string }>('auth/login', payload),
	finishExternalAuth: (token: string, headers: any) =>
		httpClient.post<boolean>(`auth/finishExternalAuth`, { token }, { headers }),
	// externalLogin: (type: ExternalAuthType, search: string) => {
	// 	const params = Object.fromEntries(new URLSearchParams(search).entries());
	// 	return axiosInstance.get<AuthData>(`auth/${type}/callback`, params);
	// },

	registerUser: (payload: RegisterPayload) => httpClient.post('auth/register', payload),
	createUser: (payload: PasswordSetupPayload) => httpClient.post<AuthData>('auth/create', payload),
	registerOrganization: (payload: RegisterOrganizationPayload) =>
		httpClient.post('auth/register/organization', payload),
	confirmRegisterOrganization: (token: string) => httpClient.post<AuthData>(`auth/confirm/${token}`),

	sendResetPassword: (payload: SendResetPasswordPayload) => httpClient.post('auth/send_reset', payload),
	validateResetUserPassword: (uuid: string) =>
		httpClient.get<{ message: string } | boolean>(`auth/reset_password/${uuid}`),
	resetUserPassword: (uuid: string, payload: PasswordSetupPayload) =>
		httpClient.post<AuthData>(`auth/reset_password/${uuid}`, payload),

	changePassword: (payload: UpdatePasswordPayload) => httpClient.post('auth/update_password', payload),

	acceptTerms: () => httpClient.post('auth/terms'),
};
