import React, { useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Avatar, AvatarProps, AvatarSize } from '../Avatar/Avatar';
import './AvatarSet.scss';

type AvatarSetAvatarProp = Omit<AvatarProps, 'size'>;

export type AvatarSetProps = {
	avatars?: AvatarSetAvatarProp[];
	size?: AvatarSize;
	className?: string;
};

export const AvatarSet = ({ avatars, size = 'md', className }: AvatarSetProps) => {
	const finalAvatars = useMemo(
		() => (avatars || []).map((avatarProps, index) => ({ key: index, ...avatarProps })),
		[avatars]
	);
	return (
		<div className={classNames('avatar-set', `avatar-set-size-${size}`, className)}>
			{finalAvatars.map(({ key, ...avatarProps }, index) => (
				<div key={key} className="avatar-set-node">
					<Avatar size={size} style={{ zIndex: index }} {...avatarProps} />
				</div>
			))}
		</div>
	);
};
