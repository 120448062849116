/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Link } from 'react-router-dom';
import { Panel, PanelItem } from '../../partials/panel';
import { Icon } from '../../../../_new/Icon';

export const ProgramRecruitment = () => {
	const { t } = useTranslation();
	const { data: programData } = useProgramPanel();

	return (
		<Panel title={t('programPanel.dashboard.recruitment.title')}>
			<>
				<PanelItem title={t('programPanel.dashboard.recruitment.title')} />
				<PanelItem
					title={t('misc.mentees')}
					value={programData?.menteeRecruitment}
					type="boolean"
					transformValue="on/off"
				/>
				<PanelItem
					title={t('misc.mentors')}
					value={programData?.mentorRecruitment}
					type="boolean"
					transformValue="on/off"
				/>
			</>
			<>
				<PanelItem title="&nbsp;" className="flex flex-row gap-1">
					<div>Mentee</div>
					<div>Mentor</div>
				</PanelItem>
				<PanelItem
					title={t('programPanel.dashboard.recruitment.applicationCount')}
					className="flex flex-row gap-1"
				>
					<div className="bold text-lg">
						{programData?.applicationStats?.menteeApplicationCount}
						<Link
							to={{
								pathname: `/program/${programData?.name}/application/list`,
								search: '?applicationRole=mentee',
							}}
							className="text-purplish-blue ml-2"
						>
							<i className="fa-solid fa-arrow-up-right-from-square" />
						</Link>
					</div>
					<div className="bold text-lg">
						{programData?.applicationStats?.mentorApplicationCount}
						<Link
							to={{
								pathname: `/program/${programData?.name}/application/list`,
								search: '?applicationRole=mentor',
							}}
							className="text-purplish-blue ml-2"
						>
							<i className="fa-solid fa-arrow-up-right-from-square" />
						</Link>
					</div>
				</PanelItem>
				<PanelItem
					title={t('programPanel.dashboard.recruitment.withPendingApproved')}
					className="flex flex-row gap-1"
				>
					<div className="text-muted">
						{programData?.applicationStats?.menteeApplicationPendingCount}
						<Link
							to={{
								pathname: `/program/${programData?.name}/application/list`,
								search: '?applicationRole=mentee&approved=not+accepted',
							}}
							className="text-purplish-blue ml-2"
						>
							<i className="fa-solid fa-arrow-up-right-from-square" />
						</Link>
					</div>
					<div className="text-muted">
						{programData?.applicationStats?.mentorApplicationPendingCount}
						<Link
							to={{
								pathname: `/program/${programData?.name}/application/list`,
								search: '?applicationRole=mentor&approved=not+accepted',
							}}
							className="text-purplish-blue ml-2"
						>
							<i className="fa-solid fa-arrow-up-right-from-square" />
						</Link>
					</div>
				</PanelItem>
			</>
			<>
				<PanelItem
					title={t('programPanel.dashboard.recruitment.usersWithoutApplicationCount')}
					value={programData?.stats?.usersWithoutApplicationCount}
					transformValue={({ value }) => (
						<>
							{Number(value) > 0 ? <span className="text-danger">{Number(value)}</span> : 0}
							<Link
								to={{
									pathname: `/program/${programData?.name}/membership/list`,
									search: '?application=without-application',
								}}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
				/>
			</>
		</Panel>
	);
};
