import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { userPanelApi, landingPagesApi } from 'api';
import { useScope } from 'contexts/scopeContext';
import { Question } from 'types/Question';
import { Answer, Application, ApplicationType } from 'types/Application';
import { ApplicationPayload } from 'types/payloads/ApplicationPayload';
import { Button } from 'primereact/button';
import FormQuestion from 'components/landingPages/components/Question';
import ConfirmDialog from 'components/_common/ConfirmDialog';
import { colors } from 'theme';
import { useLocalStorage } from 'primereact/hooks';
import { useGlobal } from 'contexts/globalContext';
import { Spinner } from 'components/_new/Spinner';
import PageMissing from 'components/PageMissing';
import { useAuth } from 'contexts/authContext';

export interface ApplyMembershipFormObject {
	form: Answer[];
}

interface FormQuestionsProps {
	setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
	type: 'mentor' | 'mentee';
	programMembershipId: number;
	questionsData: Question[];
	membershipData: Application;
	applicationType: ApplicationType;
}

const FormQuestions: FC<FormQuestionsProps> = (props) => {
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();

	const { currentUser } = useAuth();
	const { toastRef } = useGlobal();
	const { currentProgramMembership, currentProgram, applicationsRefetch } = useScope();

	const [isLoading, setIsLoading] = useState(true);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [isError, setIsError] = useState(false);
	const { setIsSubmitted, type, programMembershipId, questionsData, membershipData, applicationType } = props;

	const { data: userPairs } = useQuery(
		['userPairsCurrent', currentProgram, type],
		() => userPanelApi.getCurrentPairs(String(type), Number(currentProgramMembership?.id)),
		{
			enabled: !!currentProgramMembership,
		}
	);

	const applicationFormKey = `application-form__${applicationType}-${currentProgramMembership?.id || '0'}`;
	const [temp] = useLocalStorage<Record<number, any>>({}, applicationFormKey);
	const hasApplication = Boolean(membershipData);

	const { control, handleSubmit, reset, trigger, setValue, getValues } = useForm<ApplyMembershipFormObject>({
		defaultValues: {
			form: questionsData.map((question, index) => {
				const value = !hasApplication ? temp?.[question.id] : '';

				// fill firstname
				if (
					currentUser &&
					(!value || String(value).length === 0) &&
					index === questionsData.findIndex((qd) => qd.systemQuestionType === 'name')
				) {
					return {
						value: currentUser.firstName,
						questionId: question.id,
					};
				}

				// fill lastname
				if (
					currentUser &&
					(!value || String(value).length === 0) &&
					index === questionsData.findIndex((qd) => qd.systemQuestionType === 'surname')
				) {
					return {
						value: currentUser.lastName,
						questionId: question.id,
					};
				}

				return {
					// eslint-disable-next-line no-nested-ternary
					value: value === 'true' ? true : value === 'false' ? false : value,
					questionId: question.id,
				};
			}),
		},
	});

	const { fields } = useFieldArray({
		name: 'form',
		control,
	});

	const navigate = useNavigate();

	const { mutate: cancelApplication } = useMutation(
		() => landingPagesApi.cancelProgramApplication(programMembershipId, { applicationType, applicationRole: type }),
		{
			onSuccess: () => {
				navigate(`/${organizationName}/${programName}`);
				applicationsRefetch();
			},
		}
	);

	const { mutate: postFormAnswers, isLoading: isSubmitting } = useMutation(
		(data: ApplicationPayload) => landingPagesApi.postProgramApplications(programMembershipId, data),
		{
			onSuccess: () => {
				setIsSubmitted(true);
				if (currentProgramMembership) {
					window.localStorage.removeItem(applicationFormKey);
					applicationsRefetch();
				}
			},
		}
	);

	// loading current value
	useEffect(() => {
		setIsLoading(true);

		if (currentUser && membershipData) {
			reset({
				form: questionsData.map((question, index) => {
					const value =
						membershipData.answers.find((answer) => answer.questionId === question.id)?.value ?? '';

					// fill firstname
					if (
						(!value || String(value).length === 0) &&
						index === questionsData.findIndex((qd) => qd.systemQuestionType === 'name')
					) {
						return {
							value: currentUser.firstName,
							questionId: question.id,
						};
					}

					// fill lastname
					if (
						(!value || String(value).length === 0) &&
						index === questionsData.findIndex((qd) => qd.systemQuestionType === 'surname')
					) {
						return {
							value: currentUser.lastName,
							questionId: question.id,
						};
					}

					return {
						// eslint-disable-next-line no-nested-ternary
						value: value === 'true' ? true : value === 'false' ? false : value,
						questionId: question.id,
					};
				}),
			});
		}

		setIsLoading(false);
	}, [currentUser, questionsData, membershipData, reset]);

	const handleFormData = (event: any) => {
		event.preventDefault();
		trigger(undefined, { shouldFocus: true }).then((valid) => {
			if (!valid) {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: t('misc.error'),
					detail: t('landingPages.firstFillAllFieldsInForm'),
				});
			} else {
				handleSubmit((data: ApplyMembershipFormObject) => {
					const preparedPayload = {
						applicationRole: type,
						applicationType: membershipData?.applicationType ?? questionsData[0].applicationType,
						answers: data.form,
					};
					postFormAnswers(preparedPayload);
				})(event);
			}
		});
	};

	if (isError) {
		return <PageMissing />;
	}

	if (fields.length !== questionsData.length || isLoading) {
		return <Spinner />;
	}

	return (
		<form className="flex flex-column row-gap-4 landing-page-content" onSubmit={handleFormData}>
			{fields.map((field, index) => (
				<FormQuestion
					applicationType={applicationType}
					key={field.id}
					field={field}
					index={index}
					questionsData={questionsData}
					setIsError={setIsError}
					control={control}
					saveTemp={!hasApplication}
					setValue={setValue}
					getValues={getValues}
				/>
			))}

			<Button
				type="submit"
				label={t('landingPages.buttons.sendForm')}
				className="border-round-3xl align-self-end w-6 mt-3"
				loading={isSubmitting}
			/>

			{!!membershipData && membershipData.approved !== 'rejected' && (
				<div className="flex justify-content-end">
					<Button
						type="button"
						disabled={(userPairs || [])?.length > 0}
						tooltip={(userPairs || [])?.length > 0 ? t('landingPages.cantWithdrawTooltip') : undefined}
						tooltipOptions={{ showOnDisabled: true, position: 'top' }}
						onClick={() => setConfirmationOpen(true)}
						className="p-button-link underline"
						style={{ color: colors.gray }}
					>
						{t('landingPages.buttons.cancelFormApplication')}
					</Button>
				</div>
			)}

			<ConfirmDialog
				confirmationOpen={confirmationOpen}
				setConfirmationOpen={setConfirmationOpen}
				handleAccept={cancelApplication}
			/>
		</form>
	);
};

export default FormQuestions;
