import React, { useState, useRef, useCallback, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { usersApi } from 'api';
import { useGlobal } from 'contexts/globalContext';
import { Button } from 'components/_new/Button';
import { useAuth } from 'contexts/authContext';
import { Avatar } from 'components/_new/Avatar';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';
import { userDisplayName } from 'utils/userDisplayName';

type ChangeAvatarForm = {
	avatar: File | null;
};

type ChangeAvatarDialogProps = DialogBaseExtendProps;

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

export const ChangeAvatarDialog = ({ onHide, visible, ...props }: ChangeAvatarDialogProps) => {
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();
	const { currentUser, currentUserRefetch } = useAuth();

	const { mutate, isLoading } = useMutation(usersApi.editMyAvatar, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.myAccount.changeAvatarSuccessMessage'),
			});
			currentUserRefetch();
			onHide?.();
		},
	});

	const { handleSubmit, setValue } = useForm<ChangeAvatarForm>({
		defaultValues: { avatar: null },
	});
	const handleSubmitForm = handleSubmit(({ avatar }) => {
		if (avatar) {
			mutate({ avatar });
		}
	});

	const [incommingAvatar, setIncommingAvatar] = useState<string | null>(null);
	const fileRef = useRef<HTMLInputElement>(null);
	const handleFileChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const {
				target: { files },
			} = event;
			if (files?.length) {
				const file = files[0];
				if (file) {
					const maxAllowedSize = 8 * 1024 * 1024;
					const isSizeAllowed = file.size <= maxAllowedSize;
					const isTypeAllowed = ALLOWED_FILE_TYPES.includes(file.type);
					if (!isTypeAllowed || !isSizeAllowed) {
						setValue('avatar', null);
						if (fileRef?.current) {
							fileRef.current.value = '';
						}
						if (!isTypeAllowed) {
							toastRef?.current?.show({ severity: 'warn', detail: t('misc.fileInvalidFileType') });
						}
						if (!isSizeAllowed) {
							toastRef?.current?.show({
								severity: 'warn',
								detail: t('misc.fileSizeExceeds', { count: 5 }),
							});
						}
						return;
					}

					setValue('avatar', file);
					if (fileRef?.current) {
						fileRef.current.value = '';
					}
					const reader = new FileReader();
					reader.onload = ((file) => (event) => {
						if (event?.target?.result) {
							setIncommingAvatar(String(event.target.result));
						}
					})(file);
					reader.readAsDataURL(file);
				}
			}
		},
		[setValue]
	);
	const handlePickFile = useCallback(() => {
		if (fileRef?.current) {
			fileRef.current.click();
		}
	}, []);
	const handleCancelIncommingAvatar = useCallback(() => {
		setIncommingAvatar(null);
	}, []);

	const { mutate: deleteMyAvatarMutate, isLoading: deleteMyAvatarLoading } = useMutation(usersApi.deleteMyAvatar, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.myAccount.deleteAvatarSuccessMessage'),
			});
			currentUserRefetch();
			onHide?.();
		},
	});
	const handleDeleteCurrentAvatar = useCallback(() => {
		deleteMyAvatarMutate();
	}, [deleteMyAvatarMutate]);

	return (
		<>
			<input
				ref={fileRef}
				type="file"
				accept={ALLOWED_FILE_TYPES.join(',')}
				onChange={handleFileChange}
				className="hidden-accessible"
			/>
			<DialogAction
				title={t('userPanel.myAccount.changeAvatar')}
				size="sm"
				actions={[
					{
						key: 'save',
						submit: true,
						form: 'changeAvatarForm',
						label: t('actions.save'),
						loading: isLoading,
						icon: 'check',
						iconSet: 'pi',
					},
				]}
				visible={visible}
				onHide={onHide}
				{...props}
			>
				<form
					id="changeAvatarForm"
					onSubmit={handleSubmitForm}
					className="flex flex-column align-items-center gap-2"
				>
					{!incommingAvatar && (
						<div className="flex flex-column align-items-cetner">
							<div
								className="avatar-current-container"
								onClick={handlePickFile}
								role="button"
								tabIndex={0}
							>
								<Avatar
									src={currentUser?.avatar}
									name={userDisplayName(currentUser!, null)}
									size="xl"
								/>
							</div>
							<div className="flex flex-row justify-content-center gap-2 w-full mt-2">
								<Button
									onClick={handlePickFile}
									label={t('userPanel.myAccount.pickFile')}
									variantSize="sm"
									loading={deleteMyAvatarLoading}
								/>
								{currentUser?.avatar && (
									<Button
										onClick={handleDeleteCurrentAvatar}
										label={t('actions.remove')}
										variant="danger"
										variantSize="sm"
										loading={deleteMyAvatarLoading}
									/>
								)}
							</div>
						</div>
					)}

					{incommingAvatar && (
						<div className="flex flex-column align-items-center gap-2">
							<img src={incommingAvatar} className="incoming-avatar-img" alt="Incoming avatar" />
							<Button
								onClick={handleCancelIncommingAvatar}
								label={t('actions.cancel')}
								variant="danger"
								variantSize="sm"
							/>
						</div>
					)}
				</form>
			</DialogAction>
		</>
	);
};
