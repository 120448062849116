import React, { useMemo } from 'react';
import { Dialog, DialogBaseExtendProps } from 'components/_new/Dialog';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { programsApi } from 'api';
import { Spinner } from 'components/_new/Spinner';
import { Table } from 'components/_new/Table/Table';
import { userDisplayName } from 'utils/userDisplayName';
import { Application } from 'types/Application';
import { dateFormat } from 'utils/dateFormat';
import { Tooltip } from 'primereact/tooltip';
import { Link } from 'components/_new/Link';
import { Avatar } from 'components/_new/Avatar';
import { classNames } from 'primereact/utils';
import { Program } from 'types/Program';
import { GroupedDataItem } from 'types/File';
import { Icon } from 'components/_new/Icon';

type ProgramKnowledgeFileViewsModalProps = DialogBaseExtendProps & {
	program: Program;
	fileId: number;
};

export const ProgramKnowledgeFileViewsModal = ({
	program,
	fileId,
	visible,
	...modalProps
}: ProgramKnowledgeFileViewsModalProps) => {
	const { t } = useTranslation();

	const {
		data: grouppedData,
		isSuccess,
		isLoading,
	} = useQuery(
		['programKnowledgeFileViews', { fileId }],
		() => programsApi.getProgramKnowledgeFileViews(program.id, fileId),
		{
			enabled: visible,
		}
	);

	return (
		<Dialog title={t('programPanel.knowledge.views')} visible={visible} size="lg" {...modalProps}>
			{isLoading && <Spinner center />}
			{isSuccess && !isLoading && (
				<Table<GroupedDataItem>
					keyOptionProp="applicationId"
					columns={[
						{
							label: t('programPanel.application.applicationRole'),
							name: 'role',
							body: ({ application }) => t(`misc.${application.applicationRole}`),
						},
						{
							label: t('programPanel.application.name'),
							name: 'name',
							body: ({ application }) => {
								return (
									<>
										<Tooltip target={`#application-${application.id}`} position="bottom" />
										<Link
											id={`application-${application.id}`}
											to={`/program/${program?.name}/application/${application.id}`}
											className="w-full"
											contentClassName="flex flex-row gap-2 flex-nowrap justify-items-start align-items-center"
											data-pr-tooltip={
												application.deletedAt
													? t('programPanel.application.willBeDeleted', {
															date: dateFormat(application.deletedAt, 'date'),
													  })
													: undefined
											}
										>
											<Avatar
												src={application.programMembership.user.avatar}
												size="md"
												name={userDisplayName(application.programMembership.user, null)}
											/>
											<span className={classNames({ 'text-error': application.deletedAt })}>
												{userDisplayName(application.programMembership.user)}
											</span>
										</Link>
									</>
								);
							},
						},
						{ label: t('programPanel.knowledge.views'), name: 'views' },
						{
							label: t('programPanel.knowledge.firstView'),
							name: 'firstView',
							body: ({ firstView }) => dateFormat(firstView, 'full'),
						},
						{
							label: t('programPanel.knowledge.ratings'),
							name: 'rating',
							body: ({ applicationId, applicationRating }) => {
								const hasComment =
									applicationRating?.comment && String(applicationRating.comment).length > 0;
								return applicationRating !== null ? (
									<>
										<Tooltip
											target={`#rating-${applicationId}`}
											position="bottom"
											disabled={!hasComment}
										/>
										<div
											id={`rating-${applicationId}`}
											data-pr-tooltip={applicationRating.comment}
											className="relative inline-block"
										>
											{applicationRating.rating === 1 && (
												<Icon
													name="thumbs-up"
													iconStyle="regular"
													className="text-success text-xl"
												/>
											)}
											{applicationRating.rating === -1 && (
												<Icon
													name="thumbs-down"
													iconStyle="regular"
													className="w-full text-error text-xl"
												/>
											)}
											{hasComment && (
												<Icon
													name="comment-dots"
													iconStyle="solid"
													className="absolute text-muted text-xs"
													style={{ bottom: -6, right: -12 }}
												/>
											)}
										</div>
									</>
								) : (
									<span>-</span>
								);
							},
						},
					]}
					data={grouppedData}
				/>
			)}
		</Dialog>
	);
};
