import i18n from 'locale';
import { resolveSiteUrl, resolveStaticsUrl } from './urlBuilder';

type ResourceName = 'terms' | 'privacy';
type Langs = 'pl' | 'en';

const urlMap: Record<ResourceName, Record<Langs, string>> = {
	terms: {
		pl: '/terms-of-service/RegulaminAplikacjiMentiway.pdf',
		en: '/terms-of-service/MentiwayTermsOfUse.pdf',
	},
	privacy: {
		pl: '/polityka-prywatnosci',
		en: '/polityka-prywatnosci',
	},
};

export const translatedStaticResourceResolver = (name: ResourceName, lang?: 'pl' | 'en') => {
	const finalLang: Langs = (lang || i18n.language) as Langs;
	switch (name) {
		case 'terms':
			return resolveStaticsUrl(urlMap.terms[finalLang] || '/');
		case 'privacy':
			return resolveSiteUrl(urlMap.privacy[finalLang] || '/');
		default:
			return '/';
	}
};
