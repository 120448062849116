/* eslint-disable jsx-a11y/anchor-has-content */
import React, { ReactElement } from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	id?: string;
	text: string | ReactElement;
	underLabel?: string;
	required?: boolean;
	links?: string[] | null;
	disabled?: boolean;
}
const ValidateCheckbox = <T extends FieldValues>(props: Props<T>) => {
	const { id, name, control, rules, text, underLabel, required = false, links, disabled = false } = props;

	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required, ...rules },
	});

	return (
		<div className="inline-flex gap-2 align-items-center px-3 mb-0">
			<Checkbox
				inputId={field.name}
				onChange={(e) => field.onChange(e.checked)}
				checked={field.value}
				className={classNames({ 'p-invalid': !!error })}
				disabled={disabled}
			/>
			<div>
				<label htmlFor={field.name} className={classNames({ 'p-error': !!error })}>
					<Trans
						components={
							links
								? // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
								  links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{text}
					</Trans>
					{required && <span className="text-red-500">*</span>}
				</label>
				{underLabel ? (
					<p className="mt-1 mb-2 text-sm text-muted">
						{t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')}
					</p>
				) : null}
			</div>
		</div>
	);
};

export default ValidateCheckbox;
