import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import { programsApi, applicationsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { userDisplayName } from 'utils/userDisplayName';
import { Button } from 'components/_new/Button';
import { Table, TableColumn } from 'components/_new/Table/Table';
import { dateFormat } from 'utils/dateFormat';
import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Avatar } from 'components/_new/Avatar';
import { Tooltip } from 'primereact/tooltip';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/_new/InputText';
import { Dropdown } from 'components/_new/Dropdown';
import { Loader } from 'components/_new/Loader';
import { useUrlSearch } from 'utils/useUrlSearch';
import { Application } from 'types/Application';
import { Box, BoxSection } from 'components/_new/Box';

const ProgramPairingList = () => {
	const { t } = useTranslation();
	const { programName, data: program, isSuccess } = useProgramPanel();

	const { set, setMany, params } = useUrlSearch<{
		search?: string;
		applicationRole?: 'mentee' | 'mentor';
		approved?: 'accepted' | 'rejected' | 'not_accepted';
		advanced?: 'without-last-signin' | 'without-pair' | 'with-pair';
		page: number;
		'sort[field]': string;
		'sort[order]': 'asc' | 'desc';
	}>({ page: 1, 'sort[field]': 'createdAt', 'sort[order]': 'desc' });

	const { search, applicationRole, approved, advanced, page } = params;
	const querySort = {
		field: params['sort[field]'],
		order: params['sort[order]'],
	};
	const queryFilter = { search, applicationRole, approved, advanced };

	const { handleSubmit, control } = useForm<{
		applicationRole: 'mentor' | 'mentee';
		approved: 'accepted' | 'rejected' | 'not_accepted';
		search: string;
		advanced?: 'without-last-signin' | 'without-pair' | 'with-pair';
	}>({
		mode: 'onBlur',
		defaultValues: {
			applicationRole: params.applicationRole,
			approved: params.approved,
			search: params.search,
			advanced: params.advanced,
		},
	});

	const handlePageChange = (page: number) => {
		set('page', page);
	};

	const handleSort = async (field: string, order: 'desc' | 'asc' | null) => {
		setMany({
			page: undefined,
			'sort[field]': String(field && order ? field : undefined),
			'sort[order]': String(field && order ? order : undefined),
		});
	};

	const {
		data: applicationsData,
		isLoading: applicationsLoading,
		isSuccess: applicationsSuccess,
		isFetching: applicationsFetching,
		refetch: applicationsRefetch,
	} = useQuery(
		['getProgramApplications', { programName, page, filter: queryFilter, sort: querySort }],
		() =>
			programsApi.getProgramAdminApplicationsPaginated({
				programId: Number(program?.id),
				page,
				filter: queryFilter,
				sort: querySort,
			}),
		{
			enabled: isSuccess,
		}
	);

	// first refetch
	useEffect(() => {
		if (isSuccess) {
			applicationsRefetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	const getApprovedText = (status: string) => {
		switch (status) {
			case 'accepted':
				return t('programPanel.application.statusOptions.accepted');
			case 'rejected':
				return t('programPanel.application.statusOptions.rejected');
			case 'not accepted':
			default:
				return t('programPanel.application.statusOptions.notAccepted');
		}
	};

	const columns: TableColumn<Application>[] = [
		{
			label: t('programPanel.application.name'),
			name: 'displayName',
			sortable: true,
			body: (application: Application) => (
				<>
					<Tooltip target={`#application-${application.id}`} position="bottom" />
					<Link
						id={`application-${application.id}`}
						to={`/program/${program?.name}/application/${application.id}`}
						className="flex flex-row gap-2 align-items-center"
						data-pr-tooltip={
							application.deletedAt
								? t('programPanel.application.willBeDeleted', {
										date: dateFormat(application.deletedAt, 'date'),
								  })
								: undefined
						}
					>
						<Avatar
							src={application.programMembership.user.avatar}
							size="md"
							name={userDisplayName(application.programMembership.user, null)}
						/>
						<span className={classNames({ 'text-error': application.deletedAt })}>
							{userDisplayName(application.programMembership.user)}
						</span>
					</Link>
				</>
			),
		},
		{
			label: t('programPanel.application.applicationRole'),
			name: 'applicationRole',
			body: (application: Application) => t(`misc.${application.applicationRole}`),
		},
		{
			label: t('programPanel.application.email'),
			name: 'email',
			select: 'programMembership.user.email',
			sortable: true,
			bodyStyle: { overflowWrap: 'break-word' },
		},
		{
			label: t('programPanel.application.position'),
			name: 'answersStats.position',
			// sortable: true
		},
		{
			label: t('programPanel.application.joinDate'),
			name: 'createdAt',
			sortable: true,
			body: (application: Application) => dateFormat(application.createdAt, 'date'),
			isDate: true,
		},
		program?.requiresApplicationApproval
			? {
					label: t('programPanel.application.accepted'),
					name: 'accepted',
					// sortable: true,
					body: (application: Application) =>
						application.finished
							? t('programPanel.application.statusOptions.finished')
							: getApprovedText(application.approved),
			  }
			: undefined,
		{
			label: t('programPanel.application.generatedPairs'),
			name: 'generatedPairsCount',
			select: 'stats.generatedPairsCount',
			sortable: true,
			body: (application: Application) =>
				application.finished ? undefined : application.stats?.generatedPairsCount,
		},
		{
			label: t('programPanel.application.pairsWithEntryMeeting'),
			name: 'withEntryMeetingPairsCount',
			select: 'stats.withEntryMeetingPairsCount',
			sortable: true,
			body: (application: Application) =>
				application.finished ? undefined : application.stats?.withEntryMeetingPairsCount,
		},
		{
			label: t('programPanel.application.pairsAccepted'),
			name: 'acceptedPairsCount',
			select: 'stats.acceptedPairsCount',
			sortable: true,
			body: (application: Application) =>
				application.finished ? undefined : application.stats?.acceptedPairsCount,
		},
		{
			label: t('programPanel.application.pairLimit'),
			name: 'answersStats.pairLimit',
			// sortable: true,
		},
		{
			label: t('programPanel.lastLogin'),
			name: 'lastLoginDate',
			select: 'programMembership.user.lastLoginDate',
			sortable: true,
			body: (application: Application) =>
				application.programMembership.user.lastLoginDate
					? dateFormat(application.programMembership.user.lastLoginDate, 'date')
					: undefined,
			isDate: true,
		},
	].filter((c) => c !== undefined) as TableColumn[];

	const [downloadXlsLoading, setDownoadXlsLoading] = useState(false);
	const handleDownloadXLS = useCallback(
		(applicationRole: 'mentee' | 'mentor') => {
			if (!downloadXlsLoading) {
				setDownoadXlsLoading(true);
				applicationsApi
					.adminDownloadApplicationsXLS({ programId: Number(program?.id), applicationRole })
					.then((responseData: string) => {
						download(responseData, `${applicationRole}-applications.xlsx`, 'application/xlsx');
						setTimeout(() => {
							setDownoadXlsLoading(false);
						}, 3000);
					});
			}
		},
		[downloadXlsLoading, program]
	);

	const handleSubmitForm = handleSubmit(({ search, applicationRole, approved, advanced }) => {
		const prepared = {
			page: undefined,
			search: search && String(search).trim().length >= 3 ? String(search).trim() : undefined,
			applicationRole: applicationRole || undefined,
			approved: approved || undefined,
			advanced: advanced || undefined,
		};
		setMany(prepared);
	});

	return (
		<>
			<Helmet title={t('organizationPanel.programPairingTests')} />
			<SubPageTitle
				title={t('organizationPanel.programPairingTests')}
				additional={program?.displayName}
				right={() => (
					<div className="flex flex-row gap-1">
						<Button
							label={t('programPanel.application.downloadXlsMentees')}
							onClick={() => handleDownloadXLS('mentee')}
							loading={downloadXlsLoading}
							disabled={applicationsFetching}
						/>
						<Button
							label={t('programPanel.application.downloadXlsMentors')}
							onClick={() => handleDownloadXLS('mentor')}
							loading={downloadXlsLoading}
							disabled={applicationsFetching}
						/>
					</div>
				)}
			/>

			<Tooltip target=".tooltip-src" position="bottom" />
			<Box variant="white-bordered">
				<BoxSection>
					<form onSubmit={handleSubmitForm} className="flex flex-row gap-2 flex-wrap justify-content-between">
						<div className="flex flex-row gap-2 flex-wrap">
							<Controller
								control={control}
								name="applicationRole"
								render={({ field }) => (
									<Dropdown
										{...field}
										inline
										options={[
											{
												label: t(
													'programPanel.application.applicationRoleFilterOptions.mentor'
												),
												value: 'mentor',
											},
											{
												label: t(
													'programPanel.application.applicationRoleFilterOptions.mentee'
												),
												value: 'mentee',
											},
										]}
										withEmpty
										emptyLabel={t('programPanel.application.applicationRoleFilterOptions.all')}
										placeholder={t('programPanel.application.applicationRoleFilterOptions.all')}
										disabled={applicationsFetching}
									/>
								)}
							/>
							<Controller
								control={control}
								name="approved"
								render={({ field }) => (
									<Dropdown
										{...field}
										inline
										options={[
											{
												label: t('programPanel.application.statusFilterOptions.accepted'),
												value: 'accepted',
											},
											{
												label: t('programPanel.application.statusFilterOptions.rejected'),
												value: 'rejected',
											},
											{
												label: t('programPanel.application.statusFilterOptions.notAccepted'),
												value: 'not accepted',
											},
										]}
										withEmpty
										emptyLabel={t('programPanel.application.statusFilterOptions.all')}
										placeholder={t('programPanel.application.statusFilterOptions.all')}
										disabled={applicationsFetching}
									/>
								)}
							/>
							<Controller
								control={control}
								name="advanced"
								render={({ field }) => (
									<Dropdown
										{...field}
										inline
										options={[
											{
												label: t(
													'programPanel.application.advancedFilter.options.withoutSignin'
												),
												value: 'without-last-signin',
											},
											{
												label: t('programPanel.application.advancedFilter.options.withoutPair'),
												value: 'without-pair',
											},
											{
												label: t('programPanel.application.advancedFilter.options.withPair'),
												value: 'with-pair',
											},
										]}
										withEmpty
										placeholder={t('programPanel.application.advancedFilter.placeholder')}
										disabled={applicationsFetching}
									/>
								)}
							/>
							<Controller
								control={control}
								name="search"
								render={({ field }) => (
									<InputText
										type="search"
										maxLength={50}
										placeholder={t('programPanel.application.searchFilterOptionPlaceholder')}
										inline
										disabled={applicationsFetching}
										{...field}
									/>
								)}
							/>
						</div>
						<Button submit label={t('programPanel.application.filter')} loading={applicationsFetching} />
					</form>
					{applicationsSuccess && (
						<div className="my-3">
							<Trans
								i18nKey="programPanel.application.summary2"
								components={{ b: <b /> }}
								values={{
									count: applicationsData?.pagination.all,
									currentPage: applicationsData?.pagination.currentPage,
									pages: applicationsData?.pagination.pages,
								}}
							/>
						</div>
					)}
					{applicationsLoading && (
						<div className="flex justify-content-center">
							<Loader />
						</div>
					)}
					{!applicationsLoading && (
						<div>
							{applicationsData?.data?.length === 0 && (
								<p className="text-gray">{t('programPanel.application.noApplications')}</p>
							)}
							{applicationsData?.data && applicationsData?.data?.length > 0 && (
								<Table
									columns={columns}
									data={applicationsData?.data || []}
									defaultSort={querySort}
									onSort={handleSort}
									pagination={{
										current: Number(applicationsData?.pagination.currentPage),
										itemsPerPage: Number(applicationsData?.pagination.itemsPerPage),
										total: Number(applicationsData?.pagination.all),
										onPageChange: handlePageChange,
									}}
								/>
							)}
						</div>
					)}
				</BoxSection>
			</Box>
		</>
	);
};

export default ProgramPairingList;
