import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, ButtonGroupProps } from './ButtonGroup';

type ButtonGroupYesNoProps = Omit<ButtonGroupProps, 'options' | 'value'> & { value?: boolean | null };

export const ButtonGroupYesNo = ({ value, ...props }: ButtonGroupYesNoProps) => {
	const { t } = useTranslation();
	return (
		<ButtonGroup
			{...props}
			options={[
				{ key: 'yes', label: t('misc.yes'), value: true, variant: 'primary' },
				{ key: 'no', label: t('misc.no'), value: false, variant: 'primary' },
			]}
			value={typeof value !== 'undefined' && value !== null ? value : null}
		/>
	);
};
