import { httpClient, objectToFormData } from 'api';
import { ApplicationPayload } from '../types/payloads/ApplicationPayload';

export const applicationsApi = {
	adminDownloadApplicationsXLS: ({
		programId,
		applicationRole,
	}: {
		programId: number;
		applicationRole: 'mentee' | 'mentor';
	}) =>
		httpClient.get<string>(`programMemberships/admin/${programId}/xls/${applicationRole}`, undefined, {
			responseType: 'blob',
		}),

	adminApplicationEdit: (programId: number, programMembershipId: number, payload: ApplicationPayload) => {
		const formData = objectToFormData(payload);
		return httpClient.post(`programMemberships/admin/${programId}/${programMembershipId}/applications`, formData);
	},
};
