import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../Spinner';

type LoaderProps = {
	text?: string;
	subtext?: string;
};

export const Loader = ({ text, subtext }: LoaderProps) => {
	const { t } = useTranslation();
	return (
		<div
			className="w-full flex flex-column align-items-center justify-content-center gap-2 my-4 mx-auto"
			style={{ maxWidth: 500 }}
		>
			<Spinner />
			<div className="text-center flex flex-column">
				<div className="text-lg">{text || t('misc.loading')}</div>
				<div>{subtext}</div>
			</div>
		</div>
	);
};
