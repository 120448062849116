import React, { FC } from 'react';
import { format, addDays } from 'date-fns';

import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
	date: Date;
}
const DateCell: FC<Props> = ({ date }) => {
	const { t } = useTranslation();
	const addedLessThanSevenDaysAgo = new Date() < addDays(date, 7);

	return (
		<div className="flex align-items-center column-gap-2">
			<Tooltip target=".icon-tooltip" position="right" />
			{format(date, 'dd-MM-yyyy')}
			{addedLessThanSevenDaysAgo && (
				<i
					data-pr-tooltip={t('userPanel.files.newFileTooltip')}
					className="pi pi-exclamation-circle icon-tooltip text-red-300"
				/>
			)}
		</div>
	);
};

export default DateCell;
