export enum SessionAgendaContentFormat {
	HTML = 'html',
	MD = 'md',
}

export type SessionAgenda = {
	id: number;
	programType: string;
	sessionType: string;
	language: string;
	content: string | null;
	format: SessionAgendaContentFormat;
	createdAt: string;
	updatedAt: string;
};
