import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/authContext';
import { Spinner } from 'components/_new/Spinner';

const ALLOWED_HOST = ['mentiway.com'];

const NEXT_REDIRECT_URL_NAME = 'verify-redirect-url';

const validateUrl = (urlString: string) => {
	try {
		const url = new URL(urlString);
		if (ALLOWED_HOST.indexOf(url.host) !== -1) {
			return url.href;
		}
	} catch {
		console.error('invalid url');
		return null;
	}
	return null;
};

export const getNextUrlRedirect = (url: null | string = null) => {
	const nextRedirectUrlLS = window.localStorage.getItem(NEXT_REDIRECT_URL_NAME);
	const nextRedirectUrl = url || nextRedirectUrlLS;
	if (nextRedirectUrl && validateUrl(nextRedirectUrl)) {
		const newUrl = new URL(nextRedirectUrl);
		const newUrlSearch = new URLSearchParams(newUrl.search);
		newUrlSearch.append('valid', 'true');
		newUrl.search = newUrlSearch.toString();
		return newUrl.href;
	}
	return null;
};

export const redirectToNextUrl = (url: null | string = null) => {
	const nextUrl = getNextUrlRedirect(url);
	if (nextUrl) {
		window.localStorage.removeItem(NEXT_REDIRECT_URL_NAME);
		window.location.href = nextUrl;
	}
};

export const Verify = () => {
	const { loading, isLogged } = useAuth();

	const { search } = useLocation();
	const searchParams = Object.fromEntries(Array.from(new URLSearchParams(search).entries()));

	// const [nextRedirectUrl, setNextRedirectUrl] = useLocalStorage<null | false | string>(null, NEXT_REDIRECT_URL_NAME);
	const nextRedirectUrlLS = window.localStorage.getItem(NEXT_REDIRECT_URL_NAME);
	const nextRedirectUrl = nextRedirectUrlLS && String(nextRedirectUrlLS).length > 0 ? nextRedirectUrlLS : null;

	useEffect(() => {
		const redirectUrl = validateUrl(searchParams?.redirectUrl);
		// setNextRedirectUrl(redirectUrl || false);
		window.localStorage.setItem(NEXT_REDIRECT_URL_NAME, redirectUrl || '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	const navigate = useNavigate();

	useEffect(() => {
		if (!loading) {
			if (nextRedirectUrl) {
				if (isLogged) {
					redirectToNextUrl(nextRedirectUrl);
				} else {
					navigate('/login');
				}
			}
		}
	}, [isLogged, loading, navigate, nextRedirectUrl]);

	return <Spinner />;
};
