import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Button } from '../Button';

const MAX_VISIBLE_ITEMS = 4;

interface MessageBoxProps {
	tip?: boolean;
	header?: string;
	content: {
		text: string;
		icon?: ReactNode;
		link?: string;
		onClick?: (event: any) => void;
	}[];
	className?: string;
	expandable?: boolean;
}

const MessageBox: FC<MessageBoxProps> = (props) => {
	const { t } = useTranslation();
	const { tip, header, content, className, expandable } = props;

	const finalContent = useMemo(() => {
		if (!expandable) {
			return content;
		}
		if (expandable && content.length > MAX_VISIBLE_ITEMS) {
			return content.slice(0, MAX_VISIBLE_ITEMS);
		}
		return content;
	}, [content, expandable]);

	return (
		<Message
			severity="info"
			content={
				<div className="flex flex-column row-gap-2 w-full">
					<b>{tip ? t('misc.tip') : header}</b>
					{finalContent.map((item) => (
						<div key={item.text} className="flex align-items-center column-gap-3 text-sm">
							<div style={{ minWidth: '20px' }}>
								{tip ? <i className="pi pi-info-circle" /> : item.icon}
							</div>
							{item.link ? (
								<Link onClick={item.onClick} to={item.link} className="underline" target="_blank">
									{item.text}
								</Link>
							) : (
								<div>{item.text}</div>
							)}
						</div>
					))}
					{expandable && (
						<Button
							className="p-link text-pink-300 column-gap-3 hover:bg-purple-50 text-sm text-left"
							onClick={() => {
								const scrollWrapperRef = document.getElementById('scrollWrapperRef');
								if (scrollWrapperRef) {
									scrollWrapperRef.scroll({ top: scrollWrapperRef.scrollHeight, behavior: 'smooth' });
								}
							}}
						>
							<div style={{ minWidth: '20px' }}>
								<i className="pi pi-angle-double-down" />
							</div>
							{t('misc.showMore')}
						</Button>
					)}
				</div>
			}
			className={`justify-content-start pl-4 ${className}`}
		/>
	);
};

export default MessageBox;
