import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MenuItem } from 'components/_new/Menu';
import { RouterUrlParams } from '../../../../App';
import { useNavigate } from '../../../../hooks/useNavigate';

export const useMapSessionTasksToItems = (sessionTasks: any[]) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName, type, pairId } = useParams() as RouterUrlParams;

	const items: Partial<MenuItem>[] = sessionTasks.map((task) => {
		switch (task.type) {
			case `session-questionnaire-${task?.variables?.sessionType}-${type}`:
				return {
					key: 'sessionQuestionnaire',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.session-questionnaire-${type}`),
					onClick: () => {
						navigate(
							`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${task.variables.sessionId}/evaluation-${type}#content`
						);
					},
					icon: 'fa-solid fa-list-check',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${task.variables.sessionId}/evaluation-${type}#content`,
				};
			case 'session-final-report':
				return {
					key: 'sessionFinalReport',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/final-report`, {
							scroll: ['scrollWrapperRef'],
						});
						// const scrollWrapperRef = document.getElementById('scrollWrapperRef');
						// if (scrollWrapperRef) {
						// 	scrollWrapperRef.scroll({ top: 0 });
						// }
					},
					icon: 'fa-solid fa-flag-checkered',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/final-report`,
				};
			case 'last-session-evaluation':
				return {
					key: 'lastSessionEvaluation',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/process-summary`);
					},
					icon: 'fa-regular fa-star',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/process-summary`,
				};
			case 'session-define-upcoming':
				return {
					key: 'sessionDefineUpcoming',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(
							`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${task.variables.nextSessionId}`,
							{ scroll: ['scrollWrapperRef'] }
						);
						// const scrollWrapperRef = document.getElementById('scrollWrapperRef');
						// if (scrollWrapperRef) {
						// 	scrollWrapperRef.scroll({ top: 0 });
						// }
					},
					icon: 'fa-solid fa-calendar-days',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${task.variables.nextSessionId}`,
				};
			case 'session-rating':
				return {
					key: 'sessionRating',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(
							`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${task.variables.sessionId}`,
							{ scroll: ['scrollWrapperRef'] }
						);
						// const scrollWrapperRef = document.getElementById('scrollWrapperRef');
						// if (scrollWrapperRef) {
						// 	scrollWrapperRef.scroll({ top: 0 });
						// }
					},
					icon: 'fa-regular fa-face-smile',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${task.variables.sessionId}`,
				};
			case 'contract-sign':
				return {
					key: 'contractSign',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/contract`, {
							scroll: ['scrollWrapperRef'],
						});
						// const scrollWrapperRef = document.getElementById('scrollWrapperRef');
						// if (scrollWrapperRef) {
						// 	scrollWrapperRef.scroll({ top: 0 });
						// }
					},
					icon: 'fa-solid fa-file-signature',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/contract`,
				};
			case 'contract-goals': {
				return {
					key: 'contractGoals',
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentee-plan`, {
							scroll: ['scrollWrapperRef'],
						});
						// const scrollWrapperRef = document.getElementById('scrollWrapperRef');
						// if (scrollWrapperRef) {
						// 	scrollWrapperRef.scroll({ top: 0 });
						// }
					},
					icon: 'fa-solid fa-file-signature',
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentee-plan`,
				};
			}
			default:
				return {
					key: task.type,
					// type: 'button',
					label: t(`userPanel.sessions.sessionTasks.${task.type}`),
					onClick: () => {
						navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/contract`);
					},
					to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/contract`,
				};
		}
	});

	return items;
};
