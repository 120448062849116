import React from 'react';
import { Box, BoxSection } from 'components/_new/Box';
import './StatsItem.scss';
import { classNames } from 'primereact/utils';

type StatColorVariant = 'lpurplish' | 'purplish' | 'rose';

type ProgressBarProps = { current: number; variant?: StatColorVariant };

const ProgressBar = ({ current, variant }: ProgressBarProps) => {
	const width = `${current}%`;
	return (
		<div
			className={classNames('stats-progress', {
				'stats-progress-rose': variant === 'rose',
				'stats-progress-purplish': variant === 'purplish',
				'stats-progress-lpurplish': variant === 'lpurplish',
			})}
		>
			<div className="stats-progress-bar" style={{ width }} />
		</div>
	);
};

type StatsProgressProps = StatsItemProps & { progressValue?: number };

export const StatsItemProgress = ({ name, value, progressValue, variant }: StatsProgressProps) => {
	return (
		<Box
			variant="white-bordered"
			className={classNames('stats-item', {
				'stats-item-rose': variant === 'rose',
				'stats-item-purplish': variant === 'purplish',
				'stats-item-lpurplish': variant === 'lpurplish',
			})}
			slim
		>
			<BoxSection className="">
				<ProgressBar current={progressValue || Number(value) || 0} variant={variant} />
			</BoxSection>
			<BoxSection contentClassName="flex flex-row gap-3 justify-items-center align-items-center">
				<div className="num">{value}</div>
				<div>{name}</div>
			</BoxSection>
		</Box>
	);
};

type StatsItemProps = {
	name: string;
	value?: string | number | null;
	variant?: StatColorVariant;
};

export const StatsItem = ({ name, value, variant }: StatsItemProps) => {
	return (
		<Box
			variant="white-bordered"
			className={classNames('stats-item', {
				'stats-item-rose': variant === 'rose',
				'stats-item-purplish': variant === 'purplish',
				'stats-item-lpurplish': variant === 'lpurplish',
			})}
			slim
		>
			<BoxSection contentClassName="num">{value}</BoxSection>
			<BoxSection className="">{name}</BoxSection>
		</Box>
	);
};
