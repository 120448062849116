import React, { useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { calendarApi } from 'api/calendar';
import { Button } from 'components/_new/Button';
import { InputTextArea } from 'components/_new/InputTextArea';
import { useForm } from 'react-hook-form';
import { Field } from 'components/_new/Field';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { userDisplayName } from 'utils/userDisplayName';
import { Link, useParams } from 'react-router-dom';
import { usePanel } from 'contexts/panelContext';
import { useScope } from 'contexts/scopeContext';
import { urlValidator } from 'utils/urlValidator';
import { Icon } from 'components/_new/Icon';
import { classNames } from 'primereact/utils';
import { dayjs } from 'utils/dayjs';
import { Tooltip } from 'primereact/tooltip';

type AddNewCalendarProps = {
	bump?: number;
	addMode: boolean;
	setAddMode: (value: boolean) => void;
	onAddCalendar?: () => void;
	onRemoveCalendar?: () => void;
};

export const ManageCalendars = (props: AddNewCalendarProps) => {
	const { bump, addMode = false, setAddMode, onAddCalendar, onRemoveCalendar } = props;

	const { t } = useTranslation();
	const { organizationName, programName } = useParams();

	const { currentProgram } = useScope();
	const { panelType } = usePanel();

	const { handleSubmit, register, reset, formState } = useForm({ defaultValues: { url: '' } });

	const { data, refetch } = useQuery(['calendars'], () => calendarApi.getCalendars(), {
		// enabled: true,
		// refetchInterval: 5000,
	});
	const { mutate, isLoading } = useMutation((data: { type: string; url: string }) => calendarApi.addCalendar(data), {
		onSuccess: () => {
			setAddMode(false);
			refetch();
			reset();
			onAddCalendar?.();
		},
	});

	useEffect(() => {
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bump]);

	const detectCalendarSource = (url: string) => {
		// detect google
		if (url.indexOf('google') !== -1) {
			return 'google';
		}

		// detect ms
		if (['microsoft', 'outlook', 'office365'].some((str) => url.indexOf(str) !== -1)) {
			return 'microsoft';
		}

		return null;
	};

	const handleSubmitForm = handleSubmit(({ url }) => {
		const type = detectCalendarSource(url);
		if (type) {
			mutate({ type, url });
		}
	});

	const handleCancelClick = () => {
		setAddMode(false);
		reset();
	};

	const {
		mutate: removeCalendarMutate,
		variables: removeCalendarVariables,
		isLoading: removeCalendarLoading,
	} = useMutation((id: number) => calendarApi.removeCalendar(id), {
		onSuccess: () => {
			refetch();
			onRemoveCalendar?.();
		},
	});

	const {
		mutate: syncMutate,
		isLoading: syncLoading,
		variables: syncVariables,
	} = useMutation(({ id }: { id: number }) => calendarApi.syncCalendar(id));

	const handleSyncClick = useCallback((id: number) => syncMutate({ id }), []);

	const userCalendars = data?.filter((calendar) => calendar.user === undefined);
	const sharedCalendars = data?.filter((calendar) => calendar.user !== undefined);

	return (
		<div className="flex flex-column gap-4 w-full">
			{addMode && (
				<form onSubmit={handleSubmitForm}>
					<Field label={t('userPanel.myCalendar.pasteIcsUrl')}>
						<InputTextArea
							cols={2}
							{...register('url', {
								validate: {
									type: (value) => {
										if (!urlValidator(value)) {
											return t('userPanel.myCalendar.addUrlInvalidType');
										}
										if (detectCalendarSource(value) === null) {
											return t('userPanel.myCalendar.addUrlInvalidType');
										}
										return true;
									},
								},
							})}
						/>
						{formState?.errors?.url && <span className="text-error">{formState?.errors?.url.message}</span>}
					</Field>
					<div>
						<Link
							className="p-link"
							target="_blank"
							to={`/panel/${organizationName}/${programName}/${panelType}/files/${
								currentProgram?.language === 'pl'
									? `przewodnik-po-mentiway/integracja-z-kalendarzami`
									: `mentiway-guides/calendar-integrations`
							}`}
						>
							{t('userPanel.myCalendar.howToInfo')}
						</Link>
					</div>
					<div className="flex flex-row justify-content-end">
						<Button label={t('actions.cancel')} onClick={handleCancelClick} variant="grey-text" />
						<Button submit label={t('actions.add')} loading={isLoading} />
					</div>
				</form>
			)}

			{!addMode && (
				<div className="flex flex-column gap-2">
					<div className="flex flex-column gap-2">
						<p>
							<Trans
								t={t}
								i18nKey="userPanel.myCalendar.addIntro"
								components={[
									<Link
										to={`/panel/${organizationName}/${programName}/${panelType}/files/${
											currentProgram?.language === 'pl'
												? `przewodnik-po-mentiway/integracja-z-kalendarzami`
												: `mentiway-guides/calendar-integrations`
										}`}
										className="p-link underline"
										target="_blank"
									/>,
								]}
							/>
						</p>
					</div>

					<div className="flex flex-column gap-2">
						<div>{t('userPanel.myCalendar.yourCalendars')}:</div>
						<DataTable value={userCalendars}>
							<Column field="type" header={t('userPanel.myCalendar.calendarSource')} />
							<Column field="name" header={t('userPanel.myCalendar.calendarName')} />
							<Column
								field="syncAt"
								header={t('userPanel.myCalendar.lastSync')}
								body={({ id, syncAt, errorAt, canDelete, sync }) => {
									const canSync = dayjs().diff(syncAt, 'minutes') > 10;
									const elementId = `err-${id}`;
									const showSyncWarning =
										errorAt &&
										dayjs(errorAt).isBefore(syncAt) &&
										dayjs(syncAt).diff(errorAt, 'minutes') < 30;
									const showSyncError =
										errorAt && (dayjs(errorAt).isAfter(syncAt) || showSyncWarning);
									return (
										<div className="flex flex-row gap-2 align-items-center">
											{syncAt ? <span>{new Date(syncAt).toLocaleString()}</span> : <span>-</span>}
											{showSyncError && (
												<>
													<Tooltip target={`#${elementId}`} position="bottom" />
													<Icon
														id={elementId}
														name="triangle-exclamation"
														iconSet="fa"
														iconStyle="solid"
														className={classNames({
															'text-warning': showSyncWarning,
															'text-danger':
																dayjs(errorAt).isAfter(syncAt) && !showSyncWarning,
														})}
														data-pr-tooltip={
															showSyncWarning
																? t('userPanel.myCalendar.syncErrorInfoWarn')
																: t('userPanel.myCalendar.syncErrorInfo')
														}
													/>
												</>
											)}
											{canDelete && (
												<>
													<Tooltip target={`#calendar-sync-${id}`} position="bottom" />
													<span
														id={`calendar-sync-${id}`}
														data-pr-tooltip={
															sync ? t('userPanel.myCalendar.whileSync') : undefined
														}
													>
														<Button
															variant="primary-text"
															label={t('userPanel.myCalendar.syncNow')}
															icon="sync"
															className={sync ? 'fa-spin' : undefined}
															iconSet="fa"
															iconOnly
															iconStyle="solid"
															onClick={() => handleSyncClick(id)}
															loading={syncLoading && syncVariables?.id === id}
															disabled={!canSync}
														/>
													</span>
												</>
											)}
											{canSync}
										</div>
									);
								}}
							/>
							<Column
								header={t('actions.remove')}
								body={({ id, canDelete }) =>
									canDelete && (
										<Button
											onClick={() => removeCalendarMutate(id)}
											label={t('actions.remove')}
											icon="trash"
											variant="primary"
											loading={removeCalendarLoading && removeCalendarVariables === id}
										/>
									)
								}
							/>
						</DataTable>
					</div>

					<div className="flex flex-column gap-2">
						<div>{t('userPanel.myCalendar.sharedCalendars')}:</div>
						<DataTable value={sharedCalendars}>
							<Column
								field="type"
								header={t('userPanel.myCalendar.calendarSource')}
								body={({ type, user }) => (
									<div>
										<div>{userDisplayName(user)}</div>
										<div>{type}</div>
									</div>
								)}
							/>
							<Column field="name" header={t('userPanel.myCalendar.calendarName')} />
							<Column
								field="syncAt"
								header={t('userPanel.myCalendar.lastSync')}
								body={({ syncAt }) => {
									return syncAt ? <span>{new Date(syncAt).toLocaleString()}</span> : <span>-</span>;
								}}
							/>
						</DataTable>
					</div>
				</div>
			)}
		</div>
	);
};
