import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { RouterUrlParams } from 'App';
import { useScope } from 'contexts/scopeContext';
import { Program } from 'types/Program';
import { Organization } from 'types/Organization';
import { fixedT } from 'locale';
import { colors } from 'theme';
import { useAuth } from 'contexts/authContext';
import { usePanel } from 'contexts/panelContext';

interface WelcomeMessageProps {
	pageDetails: Program | Organization;
}
const WelcomeMessage = ({ pageDetails }: WelcomeMessageProps) => {
	const { currentUser } = useAuth();
	const { organizationName, programName } = useParams() as RouterUrlParams;

	const { hasPanelAccess, acceptedApplicationsData } = useScope();

	if (!currentUser) {
		return (
			<Link to="/login" className="p-button-text font-bold underline p-0" style={{ color: colors.pink }}>
				{fixedT(pageDetails.language, 'auth.buttons.login')}
			</Link>
		);
	}

	const getWelcomeMessage = (name: string) =>
		name
			? fixedT(pageDetails.language, 'landingPages.welcome', { name })
			: fixedT(pageDetails.language, 'landingPages.welcomeBack');

	return hasPanelAccess && acceptedApplicationsData?.length > 0 ? (
		<Link
			to={`/panel/${organizationName}/${programName}`}
			className="p-button-text font-bold underline p-0 text-sm"
			style={{ color: colors.pink }}
		>
			{getWelcomeMessage(currentUser.firstName)}
		</Link>
	) : (
		<div className="p-button-text font-bold underline p-0 text-sm" style={{ color: colors.pink }}>
			{getWelcomeMessage(currentUser.firstName)}
		</div>
	);
};

export default WelcomeMessage;
