import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePanel } from 'contexts/panelContext';
import { Page } from 'components/_new/Page';
import { Button } from 'components/_new/Button';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Link } from 'components/_new/Link';
import { MessageBox } from 'components/_new/MessageBox';
import { useGlobal } from 'contexts/globalContext';
import { usePair } from 'contexts/pairContext';

export const MentibotAI = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { organizationName, programName, type } = useParams();

	const { currentProgram } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const isList = pathname.endsWith('/list');

	const { reportIssueModalIsOpen, setReportIssueModalIsOpen } = useGlobal();
	// const { currentApplicationData } = useContext(PanelContext);

	// const [feadbackModalOpen, setFeadbackModalOpen] = useState(false);
	// const { mutate: addFeedbackMutate, isLoading: addFeedbackLoading } = useMutation(
	// 	({ comment }: any) => mentibotAi.addFeedback(Number(currentApplicationData?.id), { comment }),
	// 	{
	// 		onSuccess: () => {
	// 			setFeadbackModalOpen(false);
	// 		},
	// 	}
	// );
	// const { register: feedbackRegister, handleSubmit: feedbackHandleSubmit } = useForm({
	// 	defaultValues: { comment: '' },
	// });
	// const feedbackHandleSubmitForm = feedbackHandleSubmit(({ comment }) => {
	// 	addFeedbackMutate({ comment });
	// });

	return (
		<>
			{/* <DialogAction
				title="Pozostaw opinię"
				visible={feadbackModalOpen}
				onHide={() => {
					setFeadbackModalOpen(false);
				}}
				actions={[
					{
						key: 'save',
						form: 'feedback-form',
						label: t('actions.save'),
						loading: addFeedbackLoading,
						submit: true,
					},
				]}
			>
				<p>{t('userPanel.files.rating.thanks2')}</p>
				<form
					id="feedback-form"
					onSubmit={feedbackHandleSubmitForm}
					className="w-full flex flex-column gap-1 mt-2"
				>
					<InputTextArea
						{...feedbackRegister('comment')}
						required
						placeholder={t('userPanel.files.rating.commentPlaceholder')}
					/>
				</form>
			</DialogAction> */}
			<SubPageTitle
				title={
					<>
						<i className="fa fa-solid fa-brain pr-2 text-purple" />
						{t('userPanel.navigation.mentibotAi')}
					</>
				}
				additional="(beta)"
				right={
					!isList
						? () => (
								<Link
									to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/list`}
									className="font-bold"
									icon="arrow-left"
									iconSet="fa"
									iconStyle="solid"
									noUnderline
									responsive="sm"
								>
									{t('userPanel.mentibotAi.backToList')}
								</Link>
						  )
						: undefined
				}
			/>
			<Page className="flex flex-column gap-4">
				{!currentProgram.mentibotEnabled && (
					<MessageBox message={t('userPanel.mentibotAi.isDisabledForProgram')} />
				)}

				<Outlet />

				<div className="flex flex-column gap-4 mt-4">
					<div>{t('userPanel.mentibotAi.feedback.desc')}</div>
					<div className="flex flex-row justify-content-center">
						<Button
							onClick={() => {
								setReportIssueModalIsOpen?.(true);
							}}
							label={t('userPanel.mentibotAi.feedback.action')}
							rounded
						/>
					</div>
				</div>
			</Page>
		</>
	);
};
