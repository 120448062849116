import React, { useMemo } from 'react';
import { Button as ButtonOriginal, ButtonProps as ButtonPropsOriginal } from 'primereact/button';
import { classNames } from 'primereact/utils';

type ButtonProps = ButtonPropsOriginal & {
	size?: string;
};

export const Button = ({ size, className, ...props }: ButtonProps) => {
	const finalClassName = useMemo(
		() =>
			classNames(
				{
					'py-0 px-0': size === 'link',
					'py-1 px-2 text-sm': size === 'xs',
					'p-button-sm': size === 'sm',
					'p-button-lg': size === 'lg',
				},
				className
			),
		[className, size]
	);

	return <ButtonOriginal {...props} className={finalClassName} />;
};
