/* eslint-disable react/jsx-no-target-blank */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { RouterUrlParams } from 'App';
import banner from 'assets/mentiway-banner.svg';
import { border, colors } from 'theme';
import { usePanel } from 'contexts/panelContext';
import { resolveSiteUrl, resolveStaticsUrl } from 'utils/urlBuilder';
import { useScope } from 'contexts/scopeContext';
import { Box, BoxSection } from 'components/_new/Box';
import { ArrowList } from 'components/_new/List';
import { translatedStaticResourceResolver } from 'utils/translatedStaticResourceResolver';
import TodoItem from './TodoItem';

const Tools = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams() as RouterUrlParams;
	const { currentProgram } = useScope();
	const { todosData } = usePanel();

	const knowledgeUrls = useMemo(() => {
		switch (currentProgram?.language) {
			case 'en':
				return {
					guide: 'files/mentiway-guides',
					knowledgePills: 'files/knowledge',
					toolsAndTechniques: 'files/tools-and-techniques',
					knowledgeDatabaseFull: 'files',
				};
			case 'pl':
			default:
				return {
					guide: 'files/przewodnik-po-mentiway',
					knowledgePills: 'files/pigulki-wiedzy',
					toolsAndTechniques: 'files/techniki-i-narzedzia',
					knowledgeDatabaseFull: 'files',
				};
		}
	}, [currentProgram?.language]);

	const pairTodosCount = todosData.pair.length || 0;

	return (
		<div className="flex flex-column gap-4 pl-3 pr-3" style={{ borderLeft: border(colors.lightGray) }}>
			<Box variant="white-bordered">
				<BoxSection header={t('userPanel.todo.header')}>
					{todosData.common.length === 0 && todosData.pair.length === 0 ? (
						<p>{t('userPanel.todo.noNotification')}</p>
					) : (
						<>
							{todosData.common.length > 0 && (
								<ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
									{todosData.common.map(({ key, ...todo }) => (
										<TodoItem {...todo} key={key} />
									))}
								</ul>
							)}

							{todosData.pair.map(
								({ name, items }, i) =>
									items.length > 0 && (
										<div key={name} className="flex flex-column gap-1">
											{pairTodosCount > 1 && (
												<div>
													{t('userPanel.todo.pairWith')}: <span className="bold">{name}</span>
												</div>
											)}
											<ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
												{items.map(({ key, ...todo }) => (
													<TodoItem {...todo} key={key} />
												))}
											</ul>
										</div>
									)
							)}
						</>
					)}
				</BoxSection>
			</Box>

			<Box variant="white-bordered">
				<BoxSection header={t('userPanel.navigation.seeAlso')}>
					<ArrowList style={{ lineHeight: '1.6rem' }}>
						<li>
							<Link to={`/panel/${organizationName}/${programName}/${type}/${knowledgeUrls.guide}`}>
								{t('temp.guide')}
							</Link>
						</li>
						<li>
							<Link
								to={`/panel/${organizationName}/${programName}/${type}/${knowledgeUrls.knowledgePills}`}
							>
								{t('temp.knowledgePills')}
							</Link>
						</li>
						<li>
							<Link
								to={`/panel/${organizationName}/${programName}/${type}/${knowledgeUrls.knowledgeDatabaseFull}`}
							>
								{t('temp.fullKnowledgeDatabase')}
							</Link>
						</li>
						{currentProgram?.language === 'pl' && (
							<>
								<li>
									<a href="https://mentiway.com/blog/" target="_blank">
										Blog o mentoringu
									</a>
								</li>
								<li>
									<a
										href="https://www.linkedin.com/company/mentiway/"
										target="_blank"
										rel="noreferrer"
									>
										Mentiway na Linkedin
									</a>
								</li>
							</>
						)}
					</ArrowList>
				</BoxSection>
			</Box>

			<div className="flex align-items-center align-self-end text-color-secondary relative">
				<p
					style={{
						position: 'absolute',
						marginTop: '-25px',
						marginLeft: '25px',
						fontSize: '8px',
					}}
				>
					powered by
				</p>
				<a href="https://mentiway.com/" target="_blank">
					<img src={banner} alt="banner" className="w-8rem" />
				</a>
			</div>
			<div className="flex justify-content-around text-sm text-color-secondary px-3">
				<div>
					<a href={translatedStaticResourceResolver('privacy')} target="_blank">
						{t('userPanel.footer.privacyPolicy')}
					</a>
				</div>
				<div>
					<a href={translatedStaticResourceResolver('terms')} target="_blank" rel="noreferrer">
						{t('userPanel.footer.termsAndConditions')}
					</a>
				</div>
			</div>
		</div>
	);
};

export default Tools;
