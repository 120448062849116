import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import { RouterUrlParams } from 'App';

import banner from 'assets/mentiway-banner.svg';
import { Box, BoxSection } from './_new/Box';

interface PageMissingProps {
	withRedirect?: boolean;
	debug?: string;
}
export const PageMissing = ({ withRedirect, debug }: PageMissingProps) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const { organizationName, programName } = useParams() as RouterUrlParams;

	return (
		<div className="flex flex-column align-items-center justify-content-center row-gap-6 pt-8 pb-7">
			<Box variant="white" dropShadow>
				<BoxSection className="px-6 py-4 text-center" contentClassName="w-360-full">
					<img src={banner} alt="banner" className="mt-2 mb-6 align-self-center" />
					<p>{pathname === '/no-org-found' ? t('misc.registerOrgMissing') : t('misc.missingPage')}</p>
					{withRedirect && (
						<Link to={`/${organizationName}/${programName}`} className="p-link underline text-center">
							{t('misc.buttons.backToMainPage')}
						</Link>
					)}
					{debug && process.env.NODE_ENV === 'development' && <h1>{debug}</h1>}
				</BoxSection>
			</Box>
		</div>
	);
};

export default PageMissing;
