import React, { Ref, forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogBase, DialogBaseExtendProps } from './DialogBase';
import { Button, ButtonProps } from '../Button';

export type DialogActionButtonProps = ButtonProps & {
	key: string;
};

export type DialogActionProps = DialogBaseExtendProps & {
	title: string;
	withoutCancelAction?: boolean;
	actions?: DialogActionButtonProps[];
};

export const DialogAction = forwardRef(
	(
		{ title, withoutCancelAction = false, actions, onHide, ...restProps }: DialogActionProps,
		ref: Ref<HTMLDivElement>
	) => {
		const { t } = useTranslation();
		const handleCloseButtonClick = useCallback(() => {
			if (onHide) {
				onHide();
			}
		}, [onHide]);

		const finalActions = useMemo(
			() =>
				[
					...(!withoutCancelAction
						? [
								{
									key: 'cancel',
									label: t('actions.cancel'),
									onClick: handleCloseButtonClick,
									variant: 'primary-outlined' as ButtonProps['variant'],
								},
						  ]
						: []),
					...(actions || []),
				].map(({ key, ...buttonProps }) => <Button key={key} {...buttonProps} />),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[actions, withoutCancelAction, handleCloseButtonClick]
		);

		return (
			<DialogBase
				ref={ref}
				header={
					<div className="flex flex-row gap-4 justify-content-between align-items-center">
						<div>{title}</div>
						<Button
							label={t('actions.close')}
							icon="times"
							iconOnly
							onClick={handleCloseButtonClick}
							variantSize="lg"
							variant="grey-text"
							rounded
						/>
					</div>
				}
				footer={
					finalActions ? (
						<div className="flex flex-row gap-2 justify-content-end align-items-center">{finalActions}</div>
					) : undefined
				}
				onHide={onHide}
				{...restProps}
			/>
		);
	}
);
