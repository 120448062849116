import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { authApi } from 'api';
import { RouterUrlParams } from 'App';
import { useAuth } from 'contexts/authContext';
import { Spinner } from 'components/_new/Spinner';
import { PageLoading } from 'components/PageLoading';
import { capitalizeWord } from 'utils/helpers';

export enum ExternalAuthType {
	google = 'google',
	microsoft = 'microsoft',
}

const ExternalAuth: FC = () => {
	const { authenticate } = useAuth();
	const { type } = useParams() as RouterUrlParams<ExternalAuthType>;
	const { search } = useLocation();
	const queryParams = Object.fromEntries(new URLSearchParams(search).entries());
	const navigate = useNavigate();

	const { mutateAsync: validateExternalAuth } = useMutation(
		['afterAuth'],
		({ token, headers }: { token: string; headers: any }) => authApi.finishExternalAuth(token, headers)
	);

	const [runLogin, setRunLogin] = useState(false);
	const login = useCallback(
		(type: string) => {
			if (!runLogin) {
				setRunLogin(true);
				const additionalData = window.localStorage.getItem(`${type}-auth-data`);
				if (additionalData && String(additionalData).length && queryParams.accessToken) {
					validateExternalAuth({
						token: additionalData,
						headers: {
							Authorization: `Bearer ${queryParams.accessToken}`,
						},
					})
						.then(() => {
							window.localStorage.removeItem(`${type}-auth-data`);
							authenticate(
								{
									refreshToken: queryParams.refreshToken,
									accessToken: queryParams.accessToken,
								},
								() => {
									navigate({ pathname: '/' });
								}
							);
						})
						.catch(() => {
							navigate('/login', { replace: true });
						});
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[runLogin]
	);

	useEffect(() => {
		login(type);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <PageLoading text={`Logowanie ${capitalizeWord(type)}`} />;
};

export default ExternalAuth;
