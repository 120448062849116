import React, { ButtonHTMLAttributes } from 'react';
import { classNames } from 'primereact/utils';

import './Button.scss';

type ButtonCleanProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonClean = (props: ButtonCleanProps) => {
	const { className, children, ...restProps } = props;
	return (
		<button type="button" className={classNames('button-clean', className)} {...restProps}>
			{children}
		</button>
	);
};
