import { t } from 'i18next';
import { Pair } from 'types/Pair';

// eslint-disable-next-line consistent-return
export const getPairStatusInfo = (pair: Pair) => {
	// removed
	if (pair.status === 'removed') {
		return t('programPanel.application.statusInfo.removed');
	}

	// finished
	if (pair.status === 'finished') {
		return t('programPanel.application.statusInfo.finished');
	}

	// rejected
	if (pair.status === 'rejected') {
		if (pair.rejectedByMentee && !pair.rejectedByMentor) {
			return t('programPanel.application.statusInfo.rejectedByMentee');
		}
		if (pair.rejectedByMentor && !pair.rejectedByMentee) {
			return t('programPanel.application.statusInfo.rejectedByMentor');
		}
		return t('programPanel.application.statusInfo.rejectedDueToPairLimit');
	}
	// accepted
	if (pair.acceptedByMentee || pair.acceptedByMentor) {
		if (pair.acceptedByMentee && !pair.acceptedByMentor) {
			return t('programPanel.application.statusInfo.acceptedByMenteeWaiting');
		}
		if (pair.acceptedByMentor && !pair.acceptedByMentee) {
			return t('programPanel.application.statusInfo.acceptedByMentorWaiting');
		}
		return t('programPanel.application.statusInfo.acceptedBoth');
	}

	// entry meeting
	if (pair.entryMeeting) {
		return t('programPanel.application.statusInfo.entryMeeting');
	}

	// other cases

	return t('programPanel.application.statusInfo.generatedAndVisibleForMentee');
};

function getNestedProperty(object: any, propertyPath: string) {
	return propertyPath.split('.').reduce((obj, prop) => obj?.[prop], object);
}

export const sortMemberships = (
	memberships: Array<any>,
	adminRole: string,
	secondarySortProperty: string
): Array<any> => {
	return [...memberships].sort((a, b) => {
		// Compare by role first
		if (a.role === adminRole && b.role !== adminRole) {
			return -1;
		}
		if (a.role !== adminRole && b.role === adminRole) {
			return 1;
		}
		const aValue = getNestedProperty(a, secondarySortProperty);
		const bValue = getNestedProperty(b, secondarySortProperty);

		if (aValue && bValue) {
			return String(aValue).localeCompare(bValue);
		}
		if (aValue) {
			return -1;
		}
		if (bValue) {
			return 1;
		}
		return 0;
	});
};
