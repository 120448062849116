import { IconProps } from 'components/_new/Icon';
import { LinkProps } from 'components/_new/Link';

export const getMaterialIconPropsForLink = (fileType: string): Pick<LinkProps, 'icon' | 'iconStyle'> => {
	switch (fileType) {
		case 'knowledge':
			return { icon: 'file-lines', iconStyle: 'regular' };
		case 'tool':
			return { icon: 'file-code', iconStyle: 'regular' };
		// case 'forms':
		// 	return { icon: 'file-pen' };
		// case 'note':
		// 	return { icon: 'file-lines' };
		case 'other':
		default:
			return { icon: 'file', iconStyle: 'regular' };
	}
};

export const getMaterialIconProps = (fileType: string): Pick<IconProps, 'name' | 'iconStyle'> => {
	switch (fileType) {
		case 'knowledge':
			return { name: 'file-lines', iconStyle: 'regular' };
		case 'tool':
			return { name: 'file-code', iconStyle: 'regular' };
		// case 'forms':
		// 	return { name: 'file-pen' };
		// case 'note':
		// 	return { name: 'file-lines' };
		case 'other':
		default:
			return { name: 'file', iconStyle: 'regular' };
	}
};
