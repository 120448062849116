import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { stepsApi } from 'api';
import { Pair } from 'types/Pair';
import { usePanel } from 'contexts/panelContext';
import { userDisplayName } from 'utils/userDisplayName';
import { useAuth } from 'contexts/authContext';

type UseProgressStepsProps = {
	staticSteps?: any[];
	staticActiveStep?: number;
};

export const useProgressSteps = ({ staticSteps, staticActiveStep }: UseProgressStepsProps = {}) => {
	// const { } = useAuth();
	const [pairId, setPairId] = useState<number | undefined>(undefined);
	const { currentApplicationData } = usePanel();

	const [selectedPairOption, setSelectedPairOption] = useState<any | null>(null);
	const forms = staticSteps !== undefined && staticActiveStep !== undefined;
	const [activeIndex, setActiveIndex] = useState<number>(staticActiveStep || 0);
	const [currentPairsOptions, setCurrentPairsOptions] = useState<Pair[] | null>(null);

	useEffect(() => {
		setCurrentPairsOptions(null);
	}, [currentApplicationData?.id]);

	const { data, refetch, isLoading, isFetching } = useQuery(
		['steps', { pairId: selectedPairOption?.value, applicationId: currentApplicationData?.id }],
		() =>
			stepsApi.getSteps({
				applicationId: Number(currentApplicationData?.id),
				pairId,
			}),
		{
			refetchOnWindowFocus: false,
			enabled: !forms && Boolean(currentApplicationData?.id),
			onSuccess: (data) => {
				setActiveIndex(data?.activeStep);
				if (!currentPairsOptions && data?.currentPairs) {
					setCurrentPairsOptions(
						data.currentPairs.map((pair) => {
							return {
								...pair,
								label: userDisplayName(
									currentApplicationData.applicationRole === 'mentee'
										? pair.mentorMembership.user
										: pair.menteeMembership.user
								),
							};
						})
					);
				}
			},
		}
	);
	return { forms, refetch, data, activeIndex, pairId, setPairId, isLoading, isFetching, currentPairsOptions };
};
