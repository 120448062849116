import { CurrentUser } from 'types/CurrentUser';

export interface AuthState {
	loading: boolean;
	currentUser?: CurrentUser;
	accessToken?: string;
}

export interface AuthData {
	accessToken: string;
	refreshToken: string;
	rememberToken?: string;
	// user: {
	// 	id: number;
	// 	email: string;
	// 	terms: boolean;
	// };
}

export enum StoredKeys {
	ProgramName = 'programName',
	// LastVisitedUrl = 'lastVisitedUrl', // temp disable redirecting with lastVisitUrl
}
