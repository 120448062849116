import React, { Ref, forwardRef, useMemo } from 'react';
import { Link as LinkOriginal, LinkProps as LinkPropsOriginal } from 'react-router-dom';
import { universalRenderer } from 'utils/universalRenderer';
import { classNames } from 'primereact/utils';
import { Icon, IconSet, IconStyle } from '../Icon';
import './Link.scss';

type LinkVariantResponsive = 'sm' | 'md';

type LinkVariant = 'standard' | 'purple' | 'grey';

export type LinkProps = LinkPropsOriginal & {
	icon?: string;
	iconSet?: IconSet;
	iconStyle?: IconStyle;
	iconClassName?: string;
	iconPosition?: 'left' | 'right';
	iconOnly?: boolean;
	variant?: LinkVariant;
	label?: string;
	noUnderline?: boolean;
	disabled?: boolean;
	responsive?: LinkVariantResponsive;
	contentClassName?: string;
};

export type LinkExtendProps = LinkPropsOriginal;

export const Link = forwardRef(
	(
		{
			icon,
			iconSet,
			iconStyle,
			iconClassName,
			iconPosition = 'left',
			iconOnly = false,
			variant = 'standard',
			label,
			children,
			className,
			contentClassName,
			noUnderline = false,
			disabled = false,
			responsive,
			...restProps
		}: LinkProps,
		ref: Ref<HTMLAnchorElement>
	) => {
		const renderIcon = () => {
			if (typeof icon === 'string') {
				return (
					<Icon
						name={icon}
						iconSet={iconSet}
						iconStyle={iconStyle}
						className={classNames('link-icon', iconClassName, {
							'mr-1': !iconOnly && iconPosition !== 'left',
							'ml-1': !iconOnly && iconPosition !== 'right',
						})}
					/>
				);
			}
			return <span className={classNames('link-icon', iconClassName)}>{universalRenderer(icon)}</span>;
		};

		const finalClassName = useMemo(
			() =>
				classNames(
					'link',
					{ 'link-underline': !noUnderline, 'link-disabled': disabled },
					`link-variant-${variant}`,
					className
				),
			[noUnderline, disabled, variant, className]
		);

		return (
			<LinkOriginal ref={ref} className={finalClassName} aria-label={label} {...restProps}>
				{icon && iconPosition === 'left' && renderIcon()}
				{!iconOnly && (
					<span
						className={classNames({
							[`hidden ${responsive}:${contentClassName || 'inline-block'}`]: Boolean(responsive),
							[contentClassName || 'inline-block']: !responsive,
						})}
					>
						{children}
					</span>
				)}
				{icon && iconPosition === 'right' && renderIcon()}
			</LinkOriginal>
		);
	}
);
