import { NavigateOptions as NavigateOptionsOriginal, To, useNavigate as useNavigateOriginal } from 'react-router-dom';

type UseNavigateOptions = {
	scroll?: string[];
};

type NavigateOptions = NavigateOptionsOriginal & {
	scroll?: string[];
};

export const useNavigate = (options?: UseNavigateOptions) => {
	const navigateOriginal = useNavigateOriginal();
	const scrollElement = (element: HTMLElement) => {
		setTimeout(() => {
			element.scrollTo({ top: 0 });
		}, 1);
	};
	const scrollElementsByIds = (elementsIds: string[]) => {
		elementsIds.forEach((elementId) => {
			const element = document.getElementById(elementId);
			if (element) {
				scrollElement(element);
			}
		});
	};
	const navigate = (to: To, navigateOptions?: NavigateOptions): void => {
		if (options?.scroll && options.scroll.length > 0) {
			scrollElementsByIds(options.scroll);
		}
		if (navigateOptions?.scroll && navigateOptions.scroll.length > 0) {
			scrollElementsByIds(navigateOptions.scroll);
		}
		return navigateOriginal(to, {
			replace: navigateOptions?.replace,
			state: navigateOptions?.state,
		});
	};
	return navigate;
};
