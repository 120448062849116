import React, { FC, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'contexts/authContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { landingPagesApi } from 'api';
import { PageLoading } from 'components/PageLoading';
import PageMissing from 'components/PageMissing';
import { AxiosError } from 'axios';
import { useGlobal } from 'contexts/globalContext';
import { useTranslation } from 'react-i18next';

const OrganizationLandingPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName } = useParams();

	const { ready, isLogged, currentUser, currentUserRefetch } = useAuth();
	const { toastRef } = useGlobal();
	const [checkMembershipCompleted, setCheckMembershipCompleted] = useState(false);

	const {
		data: organizationData,
		refetch: organizationRefetch,
		isLoading: organizationLoading,
		isFetching: organizationFetching,
		isFetched: organizationFetched,
		isError: organizationError,
	} = useQuery(['organization', organizationName], () => landingPagesApi.getOrganization(String(organizationName)), {
		enabled: Boolean(ready && organizationName),
		onError: (error) => {
			if (error instanceof AxiosError) {
				const { response } = error;
				if (response?.status === 403) {
					if (!currentUser) {
						toastRef?.current?.show({ severity: 'info', detail: t('auth.pageAuthRequired') });
						navigate({
							pathname: '/login',
							search: `?redirectUrl=/${encodeURI(String(organizationName))}`,
						});
					} else if (!joinOrganizationLoading) {
						joinOrganizationMutate();
					}
				}
			}
		},
	});

	const { mutate: joinOrganizationMutate, isLoading: joinOrganizationLoading } = useMutation(
		() => landingPagesApi.joinOrganization(String(organizationName)),
		{
			onSuccess: () => {
				toastRef?.current?.show({ severity: 'success', detail: 'Dołączyłeś do organizacji' });
				currentUserRefetch();
				organizationRefetch();
				setCheckMembershipCompleted(true);
			},
			onError: () => {
				setCheckMembershipCompleted(true);
			},
		}
	);

	const hasCurrentOrganizationMembership = useMemo(() => {
		if (!ready || !organizationFetched) {
			return undefined;
		}
		return Boolean(
			currentUser &&
				organizationData &&
				currentUser.organizationMemberships.find(({ organizationId }) => organizationData.id === organizationId)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, organizationData]);

	useEffect(() => {
		if (ready && hasCurrentOrganizationMembership !== undefined) {
			if (
				!checkMembershipCompleted &&
				!joinOrganizationLoading &&
				hasCurrentOrganizationMembership === false &&
				currentUser
			) {
				joinOrganizationMutate();
			} else {
				setCheckMembershipCompleted(true);
			}
		}
	}, [
		ready,
		currentUser,
		hasCurrentOrganizationMembership,
		joinOrganizationMutate,
		joinOrganizationLoading,
		navigate,
		checkMembershipCompleted,
	]);

	// organization loading state
	if (organizationLoading || organizationFetching) {
		return <PageLoading text={t('landingPages.loadOrganizationData')} />;
	}

	// joining state
	if (joinOrganizationLoading || !checkMembershipCompleted) {
		return <PageLoading text={t('landingPages.joiningTheOrganization')} />;
	}

	// organization not found, or other error
	if ((organizationFetched && !organizationData) || organizationError) {
		return <PageMissing />;
	}

	// organization require auth, but we have not membership
	if (ready && isLogged && checkMembershipCompleted && !hasCurrentOrganizationMembership) {
		return <PageMissing />;
	}

	return (
		<Navigate
			to={{
				pathname: `/${organizationData.redirectUrl}`,
			}}
		/>
	);
};

export default OrganizationLandingPage;
