import { httpClient } from 'api';
import { Organization, OrganizationInfo } from 'types/Organization';

export const organizationsApi = {
	getOrganizationByNameInfo: ({ organizationName }: { organizationName: string }) =>
		httpClient.get<OrganizationInfo>(`organizations/${organizationName}/info`),
	getOrganizationByName: ({ organizationName }: { organizationName: string }) =>
		httpClient.get<Organization>(`organizations/${organizationName}`),
	getMyOrganizations: () => httpClient.get<Organization[]>(`organizations/admin/list`),
	getOrganizationDetails: ({ organizationName }: { organizationName: string }) =>
		httpClient.get<Organization>(`organizations/admin/${organizationName}`),
	editOrganization: ({
		organizationName,
		displayName,
		language,
	}: {
		organizationName: string;
		displayName: string;
		language: string;
	}) => httpClient.put<any>(`organizations/admin/${organizationName}/edit`, { displayName, language }),
	addProgramToOrganization: ({
		organizationName,
		displayName,
		authRequired,
		name,
		landingContent,
	}: {
		organizationName: string;
		displayName: string;
		authRequired: boolean;
		name: string;
		landingContent: string;
	}) =>
		httpClient.post<any>(`organizations/admin/${organizationName}/program`, {
			displayName,
			name,
			authRequired,
			landingContent,
		}),
	getOrganizationMemberships: ({ organizationName }: { organizationName: string }) =>
		httpClient.get<any[]>(`organizations/admin/${organizationName}/memberships`),
	getOrganizationPrograms: ({ organizationName }: { organizationName: string }) =>
		httpClient.get<any[]>(`organizations/admin/${organizationName}/programs`),
	manageMyOrganizationMembership: ({
		organizationName,
		membershipId,
		role,
	}: {
		organizationName: string;
		membershipId: number;
		role: string;
	}) =>
		httpClient.put<any>(`organizations/admin/${organizationName}/membership/${membershipId}`, {
			role,
		}),
	removeMyOrganizationMembership: ({
		organizationName,
		membershipId,
	}: {
		organizationName: string;
		membershipId: number;
	}) => httpClient.delete<any>(`organizations/admin/${organizationName}/membership/${membershipId}`),
};
