import React from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { InputTextareaProps } from 'primereact/inputtextarea';
import { InputTextarea } from './InputTextarea';
import CustomLabel from './Label';

type ValidateTextAreaProps<T extends FieldValues> = Omit<InputTextareaProps, 'name'> &
	UseControllerProps<T> & {
		containerClassName?: string;
		placeholder?: string;
		label?: string;
		underLabel?: string;
		required?: boolean;
		rows?: number;
		links?: string[] | null;
	};

const ValidateTextArea = <T extends FieldValues>({
	name,
	label,
	underLabel,
	placeholder,
	control,
	required = false,
	rules,
	rows,
	links,
	onChange,
	containerClassName,
	...restProps
}: ValidateTextAreaProps<T>) => {
	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: {
			required: required
				? `${t('misc.forms.field')} ${label?.toLowerCase() ?? placeholder?.toLowerCase() ?? ''} ${t(
						'misc.forms.isRequired'
				  )}`
				: false,
			...rules,
		},
	});

	return (
		<div className={containerClassName}>
			{label && (
				<CustomLabel name={name} required={required}>
					<Trans
						components={
							links
								? links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{label}
					</Trans>
				</CustomLabel>
			)}
			{underLabel && <p className="mt-0 mb-2 px-3 text-sm text-muted">{underLabel}</p>}
			<InputTextarea
				{...field}
				onChange={(event) => {
					if (onChange) {
						onChange(event);
					}
					field.onChange(event);
				}}
				value={field.value && field.value !== 'undefined' ? field.value : ''}
				id={field.name}
				hasError={error}
				rounded
				placeholder={placeholder}
				// @ts-ignore - TODO: remove after primereact version bump (curr @8.5.0), error caused by some mistake in types for library, attribute works normally
				rows={rows ?? 4}
				autoResize={Boolean(rows)}
				{...restProps}
			/>
			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidateTextArea;
