import React from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Password } from './Password';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	placeholder?: string;
	required?: boolean;
}
const ValidatePassword = <T extends FieldValues>(props: Props<T>) => {
	const { name, placeholder, control, required = false, rules } = props;

	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: required ? `${placeholder} ${t('misc.forms.isRequired')}` : false, ...rules },
	});

	return (
		<div>
			<Password
				{...field}
				id={field.name}
				className="w-full"
				inputClassName="w-full border-round-3xl px-3"
				placeholder={placeholder}
				feedback={false}
				hasError={!!error}
				toggleMask
			/>
			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidatePassword;
