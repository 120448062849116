import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Session } from 'types/Session';
import { SessionQuestionnaire } from 'types/SessionQuestionnaire';
import { useParams } from 'react-router-dom';
import { RouterUrlParams } from 'App';
import { LinkDoc } from '../components/LinkDoc';
import ProgressSurveyModule from '../components/ProgressSurveyModule';

type EvaluationMenteeSubpageProps = {
	session: Session;
	questionnairesData?: SessionQuestionnaire[];
	refetchQuestionnaires?: any;
};

export const EvaluationMenteeSubpage = ({
	session,
	questionnairesData,
	refetchQuestionnaires,
}: EvaluationMenteeSubpageProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type, pairId } = useParams() as RouterUrlParams;

	return (
		<>
			<h2 className="my-5">
				{t(`userPanel.sessionsNew.evaluationSurvey.mentee.${session.sessionType}.heading`)}
			</h2>
			<p>
				<Trans
					t={t}
					i18nKey={`userPanel.sessionsNew.evaluationSurvey.mentee.${session.sessionType}.desc`}
					components={[
						<LinkDoc
							to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/process-summary`}
							target="_blank"
						/>,
					]}
				/>
			</p>
			<ProgressSurveyModule
				pairId={session.pairId}
				sessionType={session.sessionType}
				questionnairesData={questionnairesData}
				refetchQuestionnaires={refetchQuestionnaires}
			/>
		</>
	);
};
