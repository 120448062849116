import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { authApi } from 'api';
import ValidatePassword from 'components/_common/forms/ValidatePassword';
import CustomLabel from 'components/_common/forms/Label';
import { useGlobal } from 'contexts/globalContext';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';

type ChangePasswordForm = {
	currentPassword: string;
	newPassword: string;
	repeatPassword: string;
};

type ChangePasswordDialogProps = DialogBaseExtendProps;

export const ChangePasswordDialog = ({ onHide, ...props }: ChangePasswordDialogProps) => {
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<ChangePasswordForm>();
	const { mutate } = useMutation(authApi.changePassword, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
			});
			onHide?.();
		},
	});
	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});
	const externalAuth = window.localStorage.getItem('externalLoginType');
	const actions = [
		...(!externalAuth
			? [
					{
						key: 'save',
						submit: true,
						form: 'changePasswordForm',
						label: t('actions.save'),
					},
			  ]
			: []),
	];

	return (
		<DialogAction
			title={t('userPanel.myAccount.changePassword')}
			size="md"
			actions={actions}
			onHide={onHide}
			{...props}
		>
			{externalAuth ? (
				t('userPanel.myAccount.externalAuthPrompt', { type: externalAuth })
			) : (
				<form id="changePasswordForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
					<div className="flex flex-column mb-2">
						<p>{t('auth.passwordComplexityRequirement')}</p>
					</div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="currentPassword" label={t('userPanel.myAccount.currentPassword')} required />
						<ValidatePassword
							name="currentPassword"
							control={control}
							placeholder={t('userPanel.myAccount.passwordRequired')}
							required
						/>
						{errors?.currentPassword && (
							<small className="p-error">{errors?.currentPassword?.message}</small>
						)}
					</div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="newPassword" label={t('userPanel.myAccount.newPassword')} required />
						<ValidatePassword
							name="newPassword"
							control={control}
							placeholder={t('userPanel.myAccount.passwordRequired')}
							rules={{
								minLength: { value: 8, message: t('auth.passwordMinLength') },
								maxLength: { value: 30, message: t('auth.passwordMaxLength') },
								validate: {
									customRequired: () => {
										const regex =
											/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]{8,}$/;
										if (!regex.test(watch('newPassword'))) {
											if (!/(?=.*[a-z])/.test(watch('newPassword'))) {
												return t('auth.passwordRequiresLowercase');
											}

											if (!/(?=.*[A-Z])/.test(watch('newPassword'))) {
												return t('auth.passwordRequiresUppercase');
											}

											if (!/(?=.*\d)/.test(watch('newPassword'))) {
												return t('auth.passwordRequiresDigit');
											}

											if (!/(?=.*[@$#!%*?&])/.test(watch('newPassword'))) {
												return t('auth.passwordRequiresSpecialChar');
											}
										}
										return true;
									},
								},
							}}
							required
						/>
						{/* {errors?.newPassword && <small className="p-error">{errors?.newPassword?.message}</small>} */}
					</div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="repeatPassword" label={t('userPanel.myAccount.repeatPassword')} required />
						<ValidatePassword
							name="repeatPassword"
							control={control}
							rules={{
								validate: {
									matching: (v) => v === watch('newPassword') || t('misc.forms.notMatchingPassword'),
								},
							}}
							required
							placeholder={t('userPanel.myAccount.repeatPassword')}
						/>
						{/* {errors?.repeatPassword && <small className="p-error">{errors?.repeatPassword?.message}</small>} */}
					</div>
				</form>
			)}
		</DialogAction>
	);
};
