import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NullableObject } from './typescript';

type ConvertOptionalToNullable<T> = {
	[K in keyof T]-?: T[K] extends undefined ? Exclude<T[K], undefined> | null : T[K];
};

const isEmpty = (value: any) =>
	typeof value === 'undefined' || value === null || value === 'null' || String(value).trim() === '';

export const useUrlSearch = <
	T extends Record<string, string | number | boolean | null>,
	TD = ConvertOptionalToNullable<T>
>(
	defaultParams?: Record<string, string | number | boolean>
) => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const currentSearchParams = new URLSearchParams(search);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const currentSearchParamsEntries = [...currentSearchParams.entries()];

	useEffect(() => {}, [search]);

	const set = (name: string, value: any) => {
		if (isEmpty(value)) {
			currentSearchParams.delete(name);
		} else {
			currentSearchParams.set(name, String(value));
		}
		navigate({ search: decodeURIComponent(currentSearchParams.toString()) });
	};

	const setMany = (properties: Record<string, any>) => {
		Object.entries(properties).forEach(([name, value]) => {
			if (isEmpty(value)) {
				currentSearchParams.delete(name);
			} else {
				currentSearchParams.set(name, String(value));
			}
		});
		navigate({ search: decodeURIComponent(currentSearchParams.toString()) });
	};

	const get = (name: string, as: any = String) => {
		let value: any = currentSearchParams.get(name);
		if (isEmpty(value)) {
			value = defaultParams?.[name] || null;
		}
		if (as) {
			return as(value);
		}
		return value;
	};

	const params: TD = useMemo(() => {
		const currentParams = Object.fromEntries(
			currentSearchParamsEntries.map(([name, value]) => {
				let fvalue = value || defaultParams?.[name];
				const defaultValueType = typeof defaultParams?.[name];
				if (defaultValueType !== 'undefined') {
					switch (defaultValueType) {
						case 'number':
							fvalue = Number(fvalue);
							break;
						case 'boolean':
							fvalue = Boolean(fvalue === 'true');
							break;
						default:
							fvalue = String(fvalue);
							break;
					}
				}
				return [name, fvalue || null];
			})
		);
		return { ...defaultParams, ...currentParams } as TD;
	}, [currentSearchParamsEntries, defaultParams]);

	return {
		set,
		setMany,
		get,
		params,
	};
};
