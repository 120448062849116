import React, { useState, useEffect, useId, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { InputTextArea } from '../../_new/InputTextArea';
import { InputText } from '../../_new/InputText';

interface Section {
	title: string;
	options: string[];
}

type SectionedMultipleChoiceEditWrapperProps = {
	initialItems: Section[];
	initialLabel: string;
	initialText: string;
	required: boolean;
	hiddenFromUsers: boolean;
	minAnswers: number;
	maxAnswers: number;
	onSave: (
		items: Section[],
		label: string,
		text: string,
		required: boolean,
		hiddenFromUsers: boolean,
		min: number,
		max: number
	) => void;
	onCancel: () => void;
};

interface EditFormObject {
	option: string;
	sectionTitle: string;
	questionLabel: string;
	questionText: string;
	isRequired: boolean;
	hiddenFromUsers: boolean;
	minAnswers: number;
	maxAnswers: number;
}

export const SectionedMultipleChoiceEditWrapper: React.FC<SectionedMultipleChoiceEditWrapperProps> = ({
	initialItems,
	initialLabel,
	initialText,
	required,
	hiddenFromUsers,
	minAnswers,
	maxAnswers,
	onSave,
	onCancel,
}) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<Section[]>(initialItems);
	const [editing, setEditing] = useState<{
		sectionIndex: number | null;
		optionIndex: number | null;
		editingSectionTitle: boolean;
	}>({ sectionIndex: null, optionIndex: null, editingSectionTitle: false });

	const requiredCheckboxId = useId();
	const hiddenFromUsersCheckboxId = useId();
	const inputRef = useRef<HTMLInputElement | null>(null);

	const { control, setValue, handleSubmit, getValues } = useForm<EditFormObject>({
		defaultValues: {
			questionText: initialText,
			questionLabel: initialLabel,
			isRequired: required,
			hiddenFromUsers,
			minAnswers,
			maxAnswers,
		},
	});

	useEffect(() => {
		setValue('questionLabel', initialLabel);
		setValue('questionText', initialText);
		setValue('isRequired', required);
		setValue('hiddenFromUsers', hiddenFromUsers);
		setValue('minAnswers', minAnswers);
		setValue('maxAnswers', maxAnswers);
	}, [initialLabel, initialText, required, minAnswers, maxAnswers, setValue]);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleSubmit(handleFormSubmit)();
		}
	};

	const handleAddOption = (sectionIndex: number) => {
		const currentOptionCount = items[sectionIndex].options.length;

		setItems((prevItems) => {
			const updatedSections = [...prevItems];
			updatedSections[sectionIndex].options.push('');
			return updatedSections;
		});

		setEditing({
			sectionIndex,
			optionIndex: currentOptionCount,
			editingSectionTitle: false,
		});

		setValue('option', '');
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleEditOption = (sectionIndex: number, optionIndex: number) => {
		const option = items[sectionIndex].options[optionIndex];
		setEditing({ sectionIndex, optionIndex, editingSectionTitle: false });
		setValue('option', option);
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleEditSectionTitle = (sectionIndex: number) => {
		const section = items[sectionIndex];
		setEditing({ sectionIndex, optionIndex: null, editingSectionTitle: true });
		setValue('sectionTitle', section.title);
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleDeleteOption = (sectionIndex: number, optionIndex: number) => {
		setItems((prevItems) => {
			const updatedSections = [...prevItems];
			updatedSections[sectionIndex].options.splice(optionIndex, 1);
			return updatedSections;
		});
		setEditing({ sectionIndex: null, optionIndex: null, editingSectionTitle: false });
		setValue('option', '');
	};

	const handleDeleteSection = (sectionIndex: number) => {
		setItems((prevItems) => prevItems.filter((_, index) => index !== sectionIndex));
	};

	const handleFormSubmit = (data: EditFormObject) => {
		setItems((prevItems) => {
			const updatedSections = [...prevItems];
			if (editing.editingSectionTitle && editing.sectionIndex !== null) {
				updatedSections[editing.sectionIndex].title = data.sectionTitle.trim();
			} else if (editing.sectionIndex !== null) {
				if (editing.optionIndex !== null) {
					updatedSections[editing.sectionIndex].options[editing.optionIndex] = data.option.trim();
				}
			}
			return updatedSections;
		});

		// Open add option automatically with focus
		if (editing.sectionIndex !== null) {
			handleAddOption(editing.sectionIndex);
		} else {
			setEditing({ sectionIndex: null, optionIndex: null, editingSectionTitle: false });
		}
		setValue('option', '');
		setValue('sectionTitle', '');
	};

	const handleAddSection = (e: React.MouseEvent) => {
		e.preventDefault();
		setItems((prevItems) => [...prevItems, { title: '', options: [] }]);
		setEditing({ sectionIndex: items.length, optionIndex: null, editingSectionTitle: true });
		setValue('sectionTitle', '');
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleSave = (data: EditFormObject) => {
		onSave(
			items,
			data.questionLabel,
			data.questionText,
			data.isRequired,
			data.hiddenFromUsers,
			data.minAnswers,
			data.maxAnswers
		);
	};

	return (
		<div>
			<div className="field mt-2">
				<Controller
					name="questionText"
					control={control}
					rules={{ required: 'Question label is required' }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="questionText">
								{t('programPanel.editQuestion.questionContent')}
							</label>
							<InputText {...field} placeholder="Enter question text" />
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			<div className="field mt-2">
				<Controller
					name="questionLabel"
					control={control}
					rules={{ required: 'Question text is required' }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="questionLabel">
								{t('programPanel.editQuestion.additionalInformations')}
							</label>
							<InputTextArea {...field} placeholder="Enter question description" />
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			<div>
				<div className="field-checkbox mb-3">
					<Controller
						name="isRequired"
						control={control}
						render={({ field }) => (
							<Checkbox
								id={requiredCheckboxId}
								{...field}
								inputId="required-checkbox"
								checked={field.value}
								onChange={(e) => setValue('isRequired', e.checked)}
							/>
						)}
					/>
					<label htmlFor={requiredCheckboxId} className="ml-2">
						{t('programPanel.editQuestion.isRequired')}
					</label>
				</div>
				<div className="mt-2 mb-3">
					<Controller
						name="hiddenFromUsers"
						control={control}
						render={({ field }) => (
							<Checkbox
								id={hiddenFromUsersCheckboxId}
								{...field}
								inputId="hiddenFromUsers-checkbox"
								checked={field.value}
								onChange={(e) => setValue('hiddenFromUsers', e.checked)}
							/>
						)}
					/>
					<label className="ml-2 field-label" htmlFor={hiddenFromUsersCheckboxId}>
						{t('programPanel.editQuestion.hiddenFromUsers')}
					</label>
				</div>
			</div>
			<div className="flex gap-2">
				<div className="field mb-3">
					<label htmlFor="min-answers" className="mr-2">
						Min Answers
					</label>
					<Controller
						name="minAnswers"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								inputId="min-answers"
								onChange={({ value: newValue }) => {
									field?.onChange(newValue);
								}}
								mode="decimal"
								showButtons
								min={0}
								max={maxAnswers}
								step={1}
								inputClassName="w-4rem py-0"
							/>
						)}
					/>
				</div>
				<div className="field mb-3">
					<label htmlFor="max-answers" className="mr-2">
						Max Answers
					</label>
					<Controller
						name="maxAnswers"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								inputId="max-answers"
								onChange={({ value: newValue }) => {
									field?.onChange(newValue);
								}}
								mode="decimal"
								showButtons
								min={minAnswers}
								max={100}
								step={1}
								inputClassName="w-4rem py-0"
							/>
						)}
					/>
				</div>
			</div>

			{items.map((section, sectionIndex) => (
				<div key={`${section.title}-${section.options.join('-')}`} className="my-3">
					{editing.sectionIndex === sectionIndex && editing.editingSectionTitle ? (
						<div className="flex justify-between items-center mb-3">
							<Controller
								name="sectionTitle"
								control={control}
								rules={{ required: 'Section title is required' }}
								render={({ field, fieldState }) => (
									<>
										<InputText
											{...field}
											placeholder="Enter section title"
											onKeyDown={handleKeyDown} // Add this to handle key down event
											ref={inputRef}
										/>
										{fieldState.error && (
											<small className="p-error">{fieldState.error.message}</small>
										)}
										<Button
											type="button"
											icon="pi pi-check"
											className="p-button-primary p-button-sm ml-2"
											onClick={handleSubmit(handleFormSubmit)}
											disabled={!field.value.trim()}
										/>
										<Button
											type="button"
											icon="pi pi-times"
											className="p-button-secondary p-button-sm ml-2"
											onClick={() =>
												setEditing({
													sectionIndex: null,
													optionIndex: null,
													editingSectionTitle: false,
												})
											}
										/>
									</>
								)}
							/>
						</div>
					) : (
						<div className="inline-flex align-items-center">
							<h3 className="m-0">{section.title}</h3>
							<div>
								<Button
									className="p-button-text p-button-sm"
									icon="pi pi-pencil text-purple-500"
									onClick={() => handleEditSectionTitle(sectionIndex)}
								/>
								<Button
									className="p-button-text p-button-sm"
									icon="pi pi-trash text-red-500"
									onClick={() => handleDeleteSection(sectionIndex)}
								/>
							</div>
						</div>
					)}
					<ul>
						{section.options.map((option, optionIndex) => (
							<li key={`${section.title}-${option}`} className="flex justify-between items-center mb-2">
								{editing.sectionIndex === sectionIndex && editing.optionIndex === optionIndex ? (
									<Controller
										name="option"
										control={control}
										rules={{ required: 'Option is required' }}
										render={({ field, fieldState }) => (
											<>
												<InputText
													{...field}
													placeholder="Enter option"
													onKeyDown={handleKeyDown} // Add this to handle key down event
													ref={inputRef}
												/>
												{fieldState.error && (
													<small className="p-error">{fieldState.error.message}</small>
												)}
												<Button
													type="button"
													icon="pi pi-check"
													className="p-button-primary p-button-sm ml-2"
													onClick={handleSubmit(handleFormSubmit)}
													disabled={!field.value.trim()}
												/>
												<Button
													type="button"
													icon="pi pi-times"
													className="p-button-secondary p-button-sm ml-2"
													onClick={() => handleDeleteOption(sectionIndex, optionIndex)}
												/>
											</>
										)}
									/>
								) : (
									<div className="inline-flex align-items-center">
										<span>{option}</span>
										<div className="inline-flex align-items-center">
											<Button
												className="p-button-text p-button-sm"
												icon="pi pi-pencil text-purple-500"
												onClick={() => handleEditOption(sectionIndex, optionIndex)}
											/>
											<Button
												className="p-button-text p-button-sm"
												icon="pi pi-trash text-red-500"
												onClick={() => handleDeleteOption(sectionIndex, optionIndex)}
											/>
										</div>
									</div>
								)}
							</li>
						))}
					</ul>
					<Button
						className="p-button-text p-button-sm ml-4"
						icon="pi pi-plus"
						onClick={() => handleAddOption(sectionIndex)}
						disabled={editing.sectionIndex !== null || editing.optionIndex !== null}
						label={t('programPanel.editQuestion.addAnswer')}
					/>
				</div>
			))}
			<Button
				className="p-button-text p-button-sm mb-3"
				icon="pi pi-plus"
				onClick={handleAddSection}
				disabled={editing.sectionIndex !== null || editing.optionIndex !== null}
				label={t('programPanel.editQuestion.addSection')}
			/>

			<div className="flex flex-row mt-2">
				<Button label={t('actions.save')} className="p-button p-button-sm" onClick={handleSubmit(handleSave)} />
				<Button
					label={t('actions.cancel')}
					className="p-button p-button-sm p-button-secondary ml-2"
					onClick={onCancel}
				/>
			</div>
		</div>
	);
};
