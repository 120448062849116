import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ProgramWithLanding } from 'types/Program';
import { LandingPageFormsAvailability } from 'types/LandingPageFormsAvailability';
import Topbar from 'components/landingPages/components/Topbar';
import Footer from 'components/_common/Footer';

interface LandingPageWrapperProps {
	programData: ProgramWithLanding;
	formsAvailability?: LandingPageFormsAvailability;
}

const LandingPageWrapper: FC<LandingPageWrapperProps> = ({
	programData,
	formsAvailability,
}: LandingPageWrapperProps) => (
	<>
		<Topbar programData={programData} formsAvailability={formsAvailability} />
		<Outlet />
		<Footer />
	</>
);

export default LandingPageWrapper;
