import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import banner from 'assets/mentiway-banner.svg';
import { StoredKeys } from 'types/Auth';
import { Box, BoxSection } from 'components/_new/Box';
import { useAuth } from 'contexts/authContext';

const AuthContainer: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isLogged } = useAuth();

	const location = useLocation();
	const urlPath = location.pathname.split('/')[1];
	const queryParams = Object.fromEntries(Array.from(new URLSearchParams(location.search).entries()));
	const redirectUrl = queryParams?.redirectUrl || null;
	const programName = queryParams?.programName || null;

	const authTabs = [
		{
			value: 'login',
			label: t('auth.login'),
		},
		{
			value: 'register',
			label: t('auth.register'),
		},
	];
	const [activeTab, setActiveTab] = useState<string | null>(urlPath ?? authTabs[0].value);

	const handleTabChange = (value: string) => {
		setActiveTab(value);
		navigate({
			pathname: `/${value}`,
			search: location.search,
		});
	};

	useEffect(() => {
		if (!authTabs.find((tab) => tab.value === urlPath)) {
			setActiveTab(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlPath]);

	useEffect(() => {
		if (programName && programName.length > 0) {
			window.localStorage.setItem(StoredKeys.ProgramName, programName);
		}
	}, [programName]);

	const redirectUrlIsApplyPage = redirectUrl
		? redirectUrl.endsWith('apply-mentor') || redirectUrl.endsWith('apply-mentee')
		: false;

	return (
		<div className="flex flex-column align-items-center justify-content-center row-gap-6 py-3">
			<Box variant="white" dropShadow className="w-480-auto">
				<BoxSection contentClassName="flex flex-column justify-content-center alignx-items-center sm:px-7 py-3">
					<img src={banner} alt="banner" className="mt-2 mb-3 align-self-center" />

					{!isLogged && (
						<>
							{!['register-organization', 'verify', 'confirm'].includes(urlPath) && (
								<div>
									<p className="text-center text-sm mt-2 mb-4">
										{redirectUrlIsApplyPage && t('auth.applyInfo')}
										{!redirectUrlIsApplyPage && t('auth.authRequired')}
									</p>
								</div>
							)}
							{urlPath !== 'verify' &&
								(window.localStorage.getItem(StoredKeys.ProgramName) || programName) && (
									<SelectButton
										value={activeTab}
										options={authTabs}
										onChange={({ value }) => handleTabChange(value)}
										unselectable={false}
										className="auth-splitbuttons flex w-full mb-5 mt-2"
									/>
								)}
						</>
					)}

					<Outlet />
				</BoxSection>
			</Box>
		</div>
	);
};

export default AuthContainer;
