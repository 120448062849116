import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Spinner } from 'components/_new/Spinner';
import { Button } from 'components/_new/Button';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import { classNames } from 'primereact/utils';
import ContractCard from './partials/ContractModuleItem';
import { Box } from '../_new/Box';
import { Divider } from '../_new/Divider';
import { InputText } from '../_new/InputText';
import { Dropdown } from '../_new/Dropdown';
import { MarkdownEditor } from '../_common/MarkdownEditor/MarkdownEditor';
import { userPanelApi } from '../../api';
import MessageBoxFull from '../_common/panel/MessageBoxFull';
import './partials/ContractModuleItem.scss';

export interface Setting {
	header: string;
	id: number;
	moduleType: string;
	priority: number;
	programId: number;
	text?: string;
}

export enum ModuleTypes {
	text = 'text',
	editText = 'edit text',
	meetings = 'meetings',
	meetingSettings = 'meeting settings',
	goals = 'goals',
}

const EditContract = () => {
	const { t } = useTranslation();
	const { data: program, organizationName, programName, refetch: refetchProgram } = useProgramPanel();
	const [staleSettings, setStaleSettings] = useState(new Set());
	if (!program?.id) {
		return <Spinner />;
	}
	const newElementRef = useRef<HTMLDivElement>(null);
	const {
		data: apiData,
		isLoading: apiDataLoading,
		error,
	} = useQuery(['contractData', program?.id], () => userPanelApi.getContractSettings(program?.id), {
		enabled: !!program?.id,
		onSuccess: (data) => {
			if (!loadedData.length) {
				const sortedData = data.sort((a, b) => a.priority - b.priority);
				setLoadedData(sortedData);
			}
		},
	});

	const { data: usedSettings, isLoading: usedSettingsLoading } = useQuery(
		['contractGeneratedCheck', program.id],
		() => userPanelApi.checkIfContractGenerated(program.id),
		{
			enabled: !!program?.id && !apiDataLoading,
			onSuccess: (data) => {
				setStaleSettings(new Set(data));
			},
		}
	);

	const [loadedData, setLoadedData] = useState<Setting[]>([]);
	const [isAddingNew, setIsAddingNew] = useState(false);
	const { control, handleSubmit, register, reset } = useForm<Setting>();

	const { mutate: updateSettings, isLoading: isUpdating } = useMutation(
		(newData: Setting[]) => userPanelApi.updateContractSettings(program?.id, newData),
		{
			onSuccess: (data, variables) => {
				// console.log('Settings updated successfully!');
			},
			onError: (error: any) => {
				// console.error('Error updating settings:', error);
			},
		}
	);

	useEffect(() => {
		if (loadedData.length > 0 && program?.id) {
			updateSettings(loadedData);
		}
	}, [loadedData, updateSettings, program?.id]);

	const onDragEnd = (result: { destination?: { index: number } | null; source: { index: number } }) => {
		if (!result.destination) return;

		const items = Array.from(loadedData);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		const updatedItems = items.map((item, index) => ({
			...item,
			priority: index + 1,
		}));

		setLoadedData(updatedItems);
	};

	useEffect(() => {
		if (isAddingNew && newElementRef.current) {
			newElementRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [isAddingNew]);

	const handleSwap = (currentIndex: number, swapWithIndex: number) => {
		setLoadedData((currentItems) => {
			const items = [...currentItems];
			[items[currentIndex], items[swapWithIndex]] = [items[swapWithIndex], items[currentIndex]];

			const updatedItems = items.map((item, index) => ({
				...item,
				priority: index + 1,
			}));

			updateSettings(updatedItems);
			return updatedItems;
		});
	};

	const handleDelete = (itemId: number) => {
		setLoadedData((currentItems) => currentItems.filter((item) => item.id !== itemId));
	};
	const handleEdit = (editedSetting: Partial<Setting>) => {
		const updatedData = loadedData.map((item) =>
			item.id === editedSetting.id ? { ...item, ...editedSetting } : item
		);
		setLoadedData(updatedData);
	};
	const handleAddNewClick = () => {
		setIsAddingNew(true);
	};

	const onAddNewSubmit = (data: Setting) => {
		setLoadedData((currentData) => [
			...currentData,
			{ ...data, priority: loadedData.length + 1, programId: program?.id || 0 },
		]);
		setIsAddingNew(false);
		reset();
	};

	const handleCancelNew = () => {
		setIsAddingNew(false);
		reset();
	};
	const moduleTypeOptions = [
		{ label: t('programPanel.editContract.textLabel'), value: ModuleTypes.text },
		{ label: t('programPanel.editContract.editTextLabel'), value: ModuleTypes.editText },
	];

	if (apiDataLoading) {
		return <Spinner />;
	}

	return program ? (
		<div>
			<Helmet title={t('programPanel.editContract.helmetTitle')} />
			<SubPageTitle
				title={t('programPanel.editContract.subtitle')}
				additional={program?.displayName}
				right={
					program.isProgramAdmin || program.isProgramOrganizationAdmin ? (
						<Link
							to={`/program/${organizationName}/${programName}/dashboard`}
							className="text-purplish-blue text-center"
						>
							{t('programPanel.backToProgramSettings')}
						</Link>
					) : undefined
				}
			/>
			<p>{t('programPanel.editContract.description')}</p>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="contracts">
					{(provided: any) => (
						<div className="flex flex-row" {...provided.droppableProps} ref={provided.innerRef}>
							<div className="w-10 mr-3">
								{staleSettings.size > 0 && (
									<MessageBoxFull
										severity="warn"
										content={[
											{
												text: (
													<Trans
														t={t}
														i18nKey="programPanel.editContract.formSubmittedByOnePerson"
													/>
												),
											},
										]}
									/>
								)}

								{loadedData.map((item, index) => (
									<Draggable key={item.id} draggableId={`item-${item.id}`} index={index}>
										{(provided: any) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<ContractCard
													{...item}
													onSwap={handleSwap}
													index={index}
													canMoveUp={index > 0}
													canMoveDown={index < loadedData.length - 1}
													onDelete={() => handleDelete(item.id)}
													onEdit={handleEdit}
													contractModified={staleSettings.has(item.id)}
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
								{isAddingNew && (
									<div
										className={classNames(
											'p-module-item-container',
											'flex flex-column b-bottom pl-4 pr-4 pt-2 pb-4  mb-4 border-round-lg'
										)}
									>
										<div ref={newElementRef} className="p-fluid">
											<form onSubmit={handleSubmit(onAddNewSubmit)}>
												<div className="field">
													<label htmlFor="header">
														{t('programPanel.editContract.header')}
													</label>
													<InputText
														id="header"
														{...register('header', { required: true })}
													/>
												</div>
												<div className="field">
													<label htmlFor="moduleType">
														{t('programPanel.editContract.moduleType')}
													</label>
													<Controller
														control={control}
														name="moduleType"
														rules={{ required: true }}
														render={({ field }) => (
															<Dropdown
																id="moduleType"
																value={field.value}
																onChange={(e) => field.onChange(e.value)}
																options={moduleTypeOptions}
																placeholder={t(
																	'programPanel.editContract.chooseModuleType'
																)}
															/>
														)}
													/>
												</div>
												<div className="field">
													<label htmlFor="text">Tekst</label>
													<Controller
														name="text"
														control={control}
														render={({ field }) => <MarkdownEditor {...field} />}
													/>
												</div>
												<div className="flex flex-row">
													<Button
														label={t('actions.cancel')}
														onClick={handleCancelNew}
														className="mt-2 p-button-secondary"
													/>
													<Button
														submit
														label={t('programPanel.editContract.saveNew')}
														className="mt-2 ml-2"
													/>
												</div>
											</form>
										</div>
									</div>
								)}
							</div>
							<div className="h-2 w-2">
								<Box variant="white-bordered" className="p-3 align-items-center">
									<h3>{t('programPanel.knowledge.edit')}</h3>
									<Divider />
									<Button label={t('programPanel.editContract.addNew')} onClick={handleAddNewClick} />
								</Box>
							</div>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	) : (
		<Spinner />
	);
};

export default EditContract;
