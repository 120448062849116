import React, { useCallback } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { statisticsApi } from 'api/statistics';
import { Button } from 'components/_common/Button';
import { useProgramPanel } from 'contexts/programPanelContext';
import { NavTabs } from 'components/_new/NavTabs';
import { Spinner } from 'components/_new/Spinner';
import { Summary } from './subpages/Summary';
import { Mentee } from './subpages/Mentee';
import { Mentor } from './subpages/Mentor';
import { Feedback } from './subpages/Feedback';

export const ProgramStatisticsNew = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const { data: program } = useProgramPanel();

	const { mutate: reloadStatistics, isLoading: reloadStatisticsLoading } = useMutation(
		() => statisticsApi.clearCache(Number(program?.id)),
		{
			onSuccess: () => {
				queryClient.removeQueries(['programStatistics']);
			},
		}
	);

	const { refetch: downloadXlsx, isFetching: downloadXlsxLoading } = useQuery(
		['programStatisticsDownload', { programId: Number(program?.id) }],
		() => statisticsApi.downloadXlsx(Number(program?.id)),
		{
			onSuccess: (blob) => {
				const downloadLink = document.createElement('a');
				downloadLink.target = '_blank';
				downloadLink.download = 'statistics.xlsx';
				const downloadUrl = URL.createObjectURL(blob);
				downloadLink.href = downloadUrl;
				document.body.append(downloadLink);
				downloadLink.click();
			},
		}
	);

	const handleReload = useCallback(() => {
		reloadStatistics();
	}, [reloadStatistics]);

	const handleDownload = useCallback(() => {
		downloadXlsx();
	}, []);

	const subpages = [
		{
			key: 'summary',
			to: `/program/${program?.name}/statistics/summary`,
			label: t('programPanel.statistics.applicationsSummary'),
			path: 'summary',
			element: <Summary />,
		},
		{
			key: 'mentee',
			to: `/program/${program?.name}/statistics/mentee`,
			label: t('programPanel.statistics.menteeGoalsRealization'),
			path: 'mentee',
			element: <Mentee />,
		},
		{
			key: 'mentor',
			to: `/program/${program?.name}/statistics/mentor`,
			label: t('programPanel.statistics.mentorDevelopmentSurwey'),
			path: 'mentor',
			element: <Mentor />,
		},
		{
			key: 'mentor',
			to: `/program/${program?.name}/statistics/feedback`,
			label: t('programPanel.statistics.feedback'),
			path: 'feedback',
			element: <Feedback />,
		},
	];

	return (
		<>
			<Helmet title={t('organizationPanel.programStatisticsFull')} />

			<NavTabs
				tabs={subpages.map(({ key, to, label }) => ({
					key,
					to,
					label,
				}))}
				disabled={reloadStatisticsLoading}
				large
				right={() => (
					<>
						<Button onClick={handleReload} label={t('actions.refresh')} loading={reloadStatisticsLoading} />
						<Button onClick={handleDownload} label={t('misc.downloadXls')} loading={downloadXlsxLoading} />
					</>
				)}
			/>

			{reloadStatisticsLoading ? (
				<Spinner />
			) : (
				<div className="my-4">
					<Routes>
						<Route index element={<Navigate to={`/program/${program?.name}/statistics/summary`} />} />
						{subpages.map(({ path, element }) => (
							<Route key={path} path={path} element={element} />
						))}
					</Routes>
				</div>
			)}
		</>
	);
};
