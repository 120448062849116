import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, BoxSection } from 'components/_new/Box';
import { Icon } from 'components/_new/Icon';
import { usePair } from 'contexts/pairContext';
import mentibotQuestionsSvg from '../../assets/icons/mentibot-questions.svg';
import mentibotTechniquesSvg from '../../assets/icons/mentibot-techniques.svg';
import mentibotSmartSvg from '../../assets/icons/mentibot-smart.svg';
import mentibotHomeworkSvg from '../../assets/icons/mentibot-homework.svg';

export const MentibotAIList = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();

	const {
		pair: { id: pairId },
	} = usePair();

	const list = [
		{
			key: 'questions',
			iconUrl: mentibotQuestionsSvg,
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/form/questions`,
			title: t('userPanel.mentibotAi.form.questions.title'),
			desc: t('userPanel.mentibotAi.form.questions.desc'),
		},
		{
			key: 'techniques',
			iconUrl: mentibotTechniquesSvg,
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/form/techniques`,
			title: t('userPanel.mentibotAi.form.techniques.title'),
			desc: t('userPanel.mentibotAi.form.techniques.desc'),
		},
		{
			key: 'smart',
			iconUrl: mentibotSmartSvg,
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/form/smart`,
			title: t('userPanel.mentibotAi.form.smart.title'),
			desc: t('userPanel.mentibotAi.form.smart.desc'),
		},
		{
			key: 'homework',
			iconUrl: mentibotHomeworkSvg,
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/form/homework`,
			title: t('userPanel.mentibotAi.form.homework.title'),
			desc: t('userPanel.mentibotAi.form.homework.desc'),
		},
	];

	return (
		<>
			<p className="m-0">{t('userPanel.mentibotAi.list.longDesc')}</p>
			<div>
				<div className="flex flex-column gap-3">
					<p className="text-purple font-bold mb-4">{t('userPanel.mentibotAi.list.beforeList')}</p>
					{list.map(({ key, iconUrl, to, title, desc }) => (
						<Link key={key} to={to}>
							<Box variant="blue" vertical dropShadow hoverable className="line-height-22">
								<BoxSection className="flex-0 hidden md:block" style={{ width: '100px' }}>
									<img src={iconUrl} alt={`${key} icon`} />
								</BoxSection>
								<BoxSection header={title} headerClassName="text-lg font-bold">
									<div>{desc}</div>
									<div className="flex flex-row gap-2 justify-content-end align-items-center">
										<span>{t('actions.go')}</span>
										<Icon name="arrow-right" className="text-purple" />
									</div>
								</BoxSection>
							</Box>
						</Link>
					))}
				</div>
			</div>
		</>
	);
};
