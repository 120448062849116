/* eslint-disable no-console */
import axios from 'axios';

export const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
	baseURL: apiUrl,
	timeout: 15000,
});

axiosInstance.interceptors.response.use((response) => response.data);

export const httpClient = {
	get: async <T>(url: string, params?: any, options?: any): Promise<T> =>
		axiosInstance.get(url, { params, ...options }),

	post: async <T = void>(url: string, payload?: any, options?: any): Promise<T> =>
		axiosInstance.post(url, payload, { ...options }),

	patch: async <T = void>(url: string, payload?: any): Promise<T> => axiosInstance.patch(url, payload),

	put: async <T = void>(url: string, payload?: any): Promise<T> => axiosInstance.put(url, payload),

	delete: async <T = void>(url: string, payload?: any): Promise<T> => axiosInstance.delete(url, payload),

	subscribe: async <T>(url: string, params?: any, options?: any): Promise<T> =>
		axiosInstance.get(url, {
			params,
			httpsAgent: { keepAlive: true, timeout: 25000 },
			timeout: 30000,
			validateStatus: (status) => status >= 200 && status <= 204,
			...options,
		}),
};
