import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usersApi } from 'api';
import { InputText } from 'components/_common/forms/InputText';
import CustomLabel from 'components/_common/forms/Label';
import { useGlobal } from 'contexts/globalContext';
import { useAuth } from 'contexts/authContext';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';

type ChangeNameForm = {
	firstName: string;
	lastName: string;
};

type ChangeNameDialogProps = DialogBaseExtendProps;

export const ChangeNameDialog = ({ onHide, ...props }: ChangeNameDialogProps) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();
	const { currentUser, currentUserRefetch } = useAuth();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ChangeNameForm>({
		defaultValues: {
			firstName: currentUser?.firstName,
			lastName: currentUser?.lastName,
		},
	});
	const { mutate, isLoading } = useMutation(usersApi.editMyData, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
			});
			currentUserRefetch();
			onHide?.();
		},
	});

	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});

	return (
		<DialogAction
			title={t('userPanel.myAccount.changeName')}
			size="sm"
			actions={[
				{
					key: 'save',
					submit: true,
					form: 'changeNameForm',
					label: t('actions.save'),
					loading: isLoading,
				},
			]}
			onHide={onHide}
			{...props}
		>
			<form id="changeNameForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
				<div className="flex flex-column gap-1">
					<CustomLabel name="firstName" label={t('userPanel.myAccount.firstName')} required />
					<InputText
						{...register('firstName', {
							required: t(t('userPanel.myAccount.firstNameRequired')),
						})}
						hasError={errors?.firstName}
						rounded
					/>
					{errors?.firstName && <small className="p-error">{errors?.firstName?.message}</small>}
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="lastName" label={t('userPanel.myAccount.lastName')} required />
					<InputText
						{...register('lastName', {
							required: t(t('userPanel.myAccount.lastNameRequired')),
						})}
						hasError={errors?.lastName}
						rounded
					/>
					{errors?.lastName && <small className="p-error">{errors?.lastName?.message}</small>}
				</div>
			</form>
		</DialogAction>
	);
};
