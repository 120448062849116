import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from '@tanstack/react-query';
import { emailChangesApi } from 'api';
import { useAuth } from 'contexts/authContext';
import { PageLoading } from 'components/PageLoading';

const EmailChangeConfirm = () => {
	const { t } = useTranslation();
	const { logout } = useAuth();

	const { token } = useParams();

	const {
		mutate,
		isSuccess,
		isError: confirmationError,
		isLoading: confirmationLoading,
	} = useMutation(['confirmEmailChange'], emailChangesApi.confirmEmailChange, {
		onSuccess: () => {
			logout();
		},
		onError: () => {},
	});

	const { isLoading: validatingLoading, isError: validatingError } = useQuery<boolean>(
		['validateEmailChange'],
		() => emailChangesApi.validateEmailChange({ token: String(token) }),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			onSuccess: () => {
				if (token) {
					mutate({ token });
				}
			},
		}
	);

	if (validatingLoading || confirmationLoading) {
		return <PageLoading />;
	}
	if (validatingError || confirmationError) {
		return <div>{t('auth.emailChangeError')}</div>;
	}
	if (isSuccess) {
		return <div>{t('auth.emailChangeSuccess')}</div>;
	}

	return <Helmet title={t('auth.emailChangeTitle')} />;
};

export default EmailChangeConfirm;
