/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userFilesApi } from 'api';
import { Button } from 'components/_new/Button';
import { IconProps, IconStyle } from 'components/_new/Icon';
import { Session } from 'types/Session';
import { MaterialCategory, SessionFile, FileType } from 'types/SessionFile';
import { DividerList } from 'components/_new/Divider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DispalyModule } from 'components/userFiles/DisplayModule';
import { UploadUserFiles } from 'components/_common/UploadUserFiles';
import { Link, LinkProps } from 'components/_new/Link';
import { usePanel } from 'contexts/panelContext';
import { Dialog, DialogConfirm } from 'components/_new/Dialog';
import { RouterUrlParams } from 'App';
import '../../../style.scss';
import { getMaterialIconProps, getMaterialIconPropsForLink } from 'utils/materialIconProps';

type YourNotesSubpageProps = {
	session: Session;
	files: SessionFile[];
	getUserFilesRefetch: () => void;
};

export const YourNotesSubpage = ({ session, files, getUserFilesRefetch }: YourNotesSubpageProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type: panelType, pairId } = useParams() as RouterUrlParams;

	const { currentApplicationData } = usePanel();
	const [openModal, setOpenModal] = useState(false);
	const [selectedUserfile, setSelectedUserfile] = useState<SessionFile | undefined>(undefined);

	const { mutateAsync: deleteUserFileMutateAsync, isLoading: deleteUserFileLoading } = useMutation(
		(id: number) => userFilesApi.deleteUserFile(id),
		{
			onSuccess: (res: any) => {
				getUserFilesRefetch();
				// queryClient.refetchQueries([
				// 	'userfiles',
				// 	{
				// 		programMembershipId: currentProgramMembership.id,
				// 		applicationId: currentApplicationData.id,
				// 		pairId,
				// 		sessionId: session.id,
				// 	},
				// ]);
			},
		}
	);
	const handleDeleteUserFile = () => {
		if (selectedUserfile) {
			deleteUserFileMutateAsync(selectedUserfile.id);
		}
	};

	const list = files.map((file) => {
		const { id, type, category, name } = file;
		const iconProps = { ...getMaterialIconPropsForLink(category), iconClassName: 'text-xl text-purple' };
		const canMenage = currentApplicationData.id === file.authorApplicationId;
		const to =
			`/panel/${organizationName}/${programName}/${panelType}/pair/${pairId}/userfiles/display/${file.id}`.toLowerCase();
		return (
			<div key={id} className="flex flex-row gap-2 justify-content-between hover-focus-area">
				<Link
					to={to}
					onClick={(event) => {
						if (type !== FileType.LINK) {
							event.preventDefault();
							setSelectedUserFile(id);
						}
					}}
					{...iconProps}
					iconClassName="text-lg text-purple"
					noUnderline
					variant="grey"
					className="font-bold"
				>
					{name}
				</Link>
				<div className="flex flex-row gap-2 hover-focus-view">
					<Button
						label="View"
						icon="eye"
						iconOnly
						variant="primary-text"
						variantSize="sm"
						onClick={(event) => {
							if (type !== FileType.LINK) {
								event.preventDefault();
								setSelectedUserFile(id);
							}
						}}
						disabled={deleteUserFileLoading}
					/>
					<Button
						label="Open in new tab"
						icon="arrow-up-right-from-square"
						iconOnly
						variant="primary-text"
						variantSize="sm"
						onClick={() => {
							const a = document.createElement('a');
							a.target = '_blank';
							a.href = to;
							a.click();
						}}
						disabled={deleteUserFileLoading}
					/>
					{canMenage && (
						<>
							<Button
								label="Edit"
								icon="pencil"
								iconOnly
								variant="rose-text"
								variantSize="sm"
								onClick={() => {
									setSelectedUserfile(file);
									setOpenModal(true);
								}}
								disabled={deleteUserFileLoading}
							/>
							<Button
								label="Remove"
								icon="trash"
								iconOnly
								variant="rose-text"
								variantSize="sm"
								onClick={() => {
									setSelectedUserfile(file);
									setDeleteUserFileModalOpen(true);
								}}
								disabled={deleteUserFileLoading}
							/>
						</>
					)}
				</div>
			</div>
		);
	});

	const [deleteUserFileModalOpen, setDeleteUserFileModalOpen] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);

	const [selectedUserFile, setSelectedUserFile] = useState<number | null>(null);
	const { data: selectedUserFileData } = useQuery(
		['userfile-file', { selectedUserFile }],
		() => userFilesApi.getUserfile(Number(selectedUserFile)),
		{
			enabled: Boolean(selectedUserFile),
		}
	);

	return (
		<>
			<Dialog
				title={selectedUserFileData?.name || ''}
				size="lg"
				visible={Boolean(selectedUserFile)}
				onHide={() => {
					setSelectedUserFile(null);
				}}
			>
				{selectedUserFile && selectedUserFileData && <DispalyModule userFileId={selectedUserFile} />}
			</Dialog>
			<Dialog
				title={selectedUserfile ? t('userPanel.userFiles.modal.edit') : t('userPanel.userFiles.modal.add')}
				onHide={async () => {
					if (hasChanges) {
						// eslint-disable-next-line no-alert
						if (window.confirm(t('userPanel.userFiles.abandonChanges'))) {
							setSelectedUserfile(undefined);
							setOpenModal(false);
						}
					} else {
						setSelectedUserfile(undefined);
						setOpenModal(false);
					}
				}}
				visible={openModal}
			>
				<UploadUserFiles
					userFile={selectedUserfile}
					setUserFile={setSelectedUserfile}
					onFinish={() => {
						getUserFilesRefetch();
						setHasChanges(false);
					}}
					onClose={() => {
						setOpenModal(false);
					}}
					setHasChanges={setHasChanges}
					session={session}
				/>
			</Dialog>
			<DialogConfirm
				title={t('userPanel.userFiles.confirmDeleteTitle')}
				onConfirm={handleDeleteUserFile}
				onReject={() => {
					setSelectedUserfile(undefined);
				}}
				visible={deleteUserFileModalOpen}
				onHide={() => {
					setDeleteUserFileModalOpen(false);
					setSelectedUserfile(undefined);
				}}
			>
				{t('userPanel.userFiles.confirmDeleteDesc')}
			</DialogConfirm>
			<div className="flex flex-column gap-2">
				<div className="flex flex-row gap-4 align-items-center justify-content-between">
					<h2 className="my-5">{t('userPanel.sessionsNew.yourNotes.heading')}</h2>
					<Button
						icon="plus"
						label={t('userPanel.sessionsNew.yourNotes.addNew')}
						variant="primary-text"
						onClick={() => {
							setSelectedUserfile(undefined);
							setOpenModal(true);
						}}
					/>
				</div>
				<div className="flex flex-column md:flex-row gap-6">
					{list.length === 0 ? (
						<span className="text-muted">{t('userPanel.sessionsNew.yourNotes.noNotes')}</span>
					) : (
						<>
							<DividerList className="md:flex-1">{list.filter((_, i) => !(i % 2))}</DividerList>
							<DividerList className="md:flex-1">{list.filter((_, i) => i % 2)}</DividerList>
						</>
					)}
				</div>
			</div>
		</>
	);
};
