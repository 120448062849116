import { QueryFunction, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, GenericAbortSignal } from 'axios';
import { useCallback, useEffect } from 'react';

type UseLongPollingQueryOptions<TData = unknown> = {
	enabled?: boolean;
	onMessage?: (data: TData) => void;
};

export const useLongPollingQuery = <
	TQueryFnData = unknown,
	TError = AxiosError<any>,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	queryKey: TQueryKey,
	queryFn: ({ params, signal }: { params: any; page?: any; signal: AbortSignal }) => Promise<TQueryFnData>,
	options?: UseLongPollingQueryOptions<TData>
) => {
	const { data, refetch, remove, error } = useQuery<TQueryFnData, AxiosError, TData, TQueryKey>(
		queryKey,
		({ pageParam, queryKey, signal }) => queryFn({ params: queryKey[1], page: pageParam, signal: signal! }),
		{
			enabled: false,
			retry: false,
			onSuccess: (data) => {
				if (data) {
					options?.onMessage?.(data);
				}
			},
		}
	);

	useEffect(() => {
		const isAborted = error?.code === 'ECONNABORTED';
		const isTimeout = error?.response?.status === 504 || error?.response?.status === 408;
		const isNoContent = error?.response?.status === 204;
		if (isAborted || isTimeout || isNoContent) {
			remove();
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		if (data) {
			remove();
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (options?.enabled !== undefined) {
			if (options?.enabled === false) {
				remove();
			} else {
				refetch();
			}
		} else {
			refetch();
		}
		return () => {
			remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options?.enabled]);

	const queryClient = useQueryClient();
	const cancel = useCallback(() => {
		queryClient.cancelQueries(queryKey);
		remove();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryKey]);

	return { data, cancel };
};
