import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogBaseProps } from 'components/_new/Dialog';
import { Button } from 'components/_new/Button';
import { dayjs } from 'utils/dayjs';
import { calendarApi } from 'api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Calendar, CalendarEventBase } from 'components/_new/Calendar/Calendar';
import { Spinner } from 'components/_new/Spinner';
// import { usePanel } from 'contexts/panelContext';
// import { useNavigate } from 'react-router-dom';
import { ManageCalendars } from './ManageCalendars';
import { mapEvent } from './utils';

export const MyCalendarDialog = (props: DialogBaseProps) => {
	const { visible } = props;

	// hooks
	const { t } = useTranslation();
	// const navigate = useNavigate();
	// const { panelLinkPrefix } = usePanel();

	// state
	const [mode, setMode] = useState<'calendar' | 'manage'>('calendar');
	const [addMode, setAddMode] = useState<boolean>(false);
	const [events, setEvents] = useState<CalendarEventBase[]>([]);
	const [lastSync, setLastSync] = useState<{ date: number; calendars: number; init: boolean }>({
		date: 0,
		calendars: 0,
		init: false,
	});
	const [bump, setBump] = useState<number>(0);

	// handlers
	const handleAddCalendar = useCallback(() => {
		setAddMode(true);
	}, []);
	const handleCancelAddCalendar = useCallback(() => {
		setAddMode(false);
	}, []);

	// on update
	useEffect(() => {
		if (mode === 'calendar' || !visible) {
			setAddMode(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, visible]);
	useEffect(() => {
		if (visible) {
			userEventsRefetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	const { isLoading: userEventsLoading, refetch: userEventsRefetch } = useQuery(
		['calendar-events'],
		() => calendarApi.getCalendarEvents(),
		{
			cacheTime: 1000,
			onSuccess: (data) => {
				setEvents([
					...data.map((item) => ({
						...mapEvent(item, ({ source }) => ({
							bg: source === 'internal' ? '#6466e9' : '#bdc6ff',
							text: source === 'internal' ? 'white' : 'black',
						})),
					})),
				]);
			},
			// enabled: visible && mode === 'calendar',
			enabled: false, // prevent evry-time refetch
		}
	);

	// sync info handle
	const { data: syncInfoData, refetch: syncInfoRefetch } = useQuery(
		['calendars-info'],
		calendarApi.getCalendarSyncInfo,
		{
			enabled: false,
			// enabled: visible && !userEventsLoading,
			refetchInterval: 3000,
			cacheTime: 3000,
			initialData: { whileSync: true, calendars: 0, lastSyncDate: null },
		}
	);
	useEffect(() => {
		if (visible) {
			const syncInfoDate = syncInfoData.lastSyncDate ? new Date(syncInfoData.lastSyncDate).getTime() : 0;
			if (syncInfoDate !== lastSync.date || syncInfoData.calendars !== lastSync.calendars) {
				userEventsRefetch();
				setBump((prev) => prev + 1);
				setLastSync({
					calendars: syncInfoData.calendars,
					date: syncInfoDate,
					init: true,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define
	}, [visible, syncInfoData, lastSync]);

	const { mutate: syncMutate, isLoading: syncLoading } = useMutation(calendarApi.syncAllCalendars, {
		onSuccess: () => {
			syncInfoRefetch();
		},
	});

	// computed
	const canSync =
		syncInfoData?.calendars > 0 &&
		(!syncInfoData.lastSyncDate ||
			(syncInfoData.lastSyncDate && dayjs().diff(syncInfoData.lastSyncDate, 'minutes') >= 5));

	return (
		<Dialog
			title={t('userPanel.myCalendar.header')}
			size="lg"
			bodyClassName={mode === 'calendar' ? 'p-0 overflow-hidden' : undefined}
			headerRight={
				<div className="flex flex-row justify-content-between gap-2">
					{mode === 'calendar' && (
						<>
							<span
								className="font-normal text-gray text-sm text-right hidden md:block"
								style={{ maxWidth: 200 }}
							>
								<span>{t('userPanel.myCalendar.lastSync')}: </span>
								<span className="no-break">
									{syncInfoData.calendars === 0 && t('userPanel.myCalendar.noCalendars')}
									{syncInfoData.lastSyncDate ? dayjs(syncInfoData.lastSyncDate).fromNow() : ''}
								</span>
								{syncInfoData.calendars > 0 && (
									<Button
										variant="primary-text"
										variantSize="sm"
										label={t('userPanel.myCalendar.syncNow')}
										className="inline-flex p-0 ml-2"
										onClick={() => syncMutate()}
										loading={syncLoading || syncInfoData.whileSync}
										disabled={!canSync}
									/>
								)}
							</span>
							<Button
								onClick={() => setMode('manage')}
								label={t('userPanel.myCalendar.manage')}
								variant="primary"
							/>
						</>
					)}
					{mode === 'manage' && (
						<>
							{!addMode && <Button label={t('userPanel.myCalendar.add')} onClick={handleAddCalendar} />}
							{addMode && <Button label={t('actions.cancel')} onClick={handleCancelAddCalendar} />}
							<Button
								onClick={() => setMode('calendar')}
								label={t('userPanel.myCalendar.backToCalendar')}
							/>
						</>
					)}
				</div>
			}
			{...props}
		>
			<div className="flex flex-column overflow-hidden h-full">
				{mode === 'calendar' && (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{userEventsLoading ? (
							<div className="flex flex-row justify-content-center">
								<Spinner />
							</div>
						) : (
							<Calendar events={events} />
						)}
					</>
				)}
				{mode === 'manage' && (
					<ManageCalendars
						bump={bump}
						addMode={addMode}
						setAddMode={setAddMode}
						onAddCalendar={() => {
							syncInfoRefetch();
						}}
						onRemoveCalendar={() => {
							syncInfoRefetch();
						}}
					/>
				)}
			</div>
		</Dialog>
	);
};
