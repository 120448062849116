/* eslint-disable react/no-array-index-key */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { CreateGoalPayload, UpdateGoalPayload } from 'types/payloads/GoalPayload';
import { Goal } from 'types/Goal';
import { ModuleType } from 'types/Contract';
import { InputText } from 'components/_common/forms/InputText';
import { Button } from 'components/_new/Button';
import { GoalModuleCurrentItem } from './GoalModuleCurrentItem';
import { ContractModule, ContractModuleCommonProps } from './ContractModule';

type GoalsModuleEditHandler = (module: ModuleType, hasChanges?: boolean) => boolean;
type GoalsModuleCancelHandler = (module: ModuleType) => void;
type GoalsModuleSaveHandler = (module: ModuleType) => Promise<boolean>;

type GoalsModuleProps = ContractModuleCommonProps & {
	onEdit?: GoalsModuleEditHandler;
	onCancel?: GoalsModuleCancelHandler;
	onSave?: GoalsModuleSaveHandler;
	goals: Goal[];
	pairId: number;
	getContract: () => void;
};

const GoalsModule = ({
	onInit,
	module,
	loading,
	editMode,
	onEdit,
	onCancel,
	onSave,
	goals,
	pairId,
	getContract,
	...contractModuleBoxProps
}: GoalsModuleProps) => {
	const { t } = useTranslation();
	const { currentProgramMembership } = usePanel();

	const { mutate: updateGoal, isLoading: updateGoalLoading } = useMutation(
		({ goal, id }: UpdateGoalPayload) =>
			userPanelApi.updateGoal(+pairId, currentProgramMembership.id, { goal, id }),
		{
			onSuccess: () => {
				getContract();
			},
		}
	);

	const { mutate: deleteGoal, isLoading: deleteGoalLoading } = useMutation(
		(goalId: number) => userPanelApi.deleteGoal(+pairId, currentProgramMembership.id, goalId),
		{
			onSuccess: () => {
				getContract();
			},
		}
	);

	const { mutate: saveNewGoal, isLoading: saveNewLoading } = useMutation(
		({ goal }: CreateGoalPayload) => userPanelApi.postGoal(+pairId, currentProgramMembership.id, { goal }),
		{
			onSuccess: () => {
				setAddGoalMode(false);
				getContract();
				reset({ newGoal: '' });
			},
		}
	);

	const isLoading = updateGoalLoading || deleteGoalLoading || saveNewLoading;

	const handleGoalSave = useCallback(
		(id: number, value: string) => {
			updateGoal({ id, goal: value });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateGoal, module]
	);

	const handleGoalRemove = useCallback(
		(id: number) => {
			deleteGoal(id);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[deleteGoal, module]
	);

	// handle new goal
	const { register, getValues, setValue, trigger, reset } = useForm({ defaultValues: { newGoal: '' } });

	const [addGoalMode, setAddGoalMode] = useState(false);
	const handleAddGoalClick = useCallback(() => {
		trigger('newGoal').then((isValid) => {
			if (isValid) {
				const newGoalText = getValues('newGoal');
				saveNewGoal({ goal: newGoalText });
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onEdit, module]);
	const handleAddGoalModeClick = useCallback(() => {
		setAddGoalMode(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleCancelAddGoalModeClick = useCallback(() => {
		setAddGoalMode(false);
		setValue('newGoal', '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onEdit, module]);

	// pseudo-ref
	// useEffect(() => {
	// 	if (onInit) {
	// 		onInit({
	// 			save: handleSave,
	// 		});
	// 	}
	// }, [onInit, handleSave]);

	const sortedGoals = useMemo(() => goals.sort((a, b) => (a.id > b.id ? 1 : -1)) || [], [goals]);

	return (
		<ContractModule module={module} {...contractModuleBoxProps}>
			{({ editMode }) => (
				<ul className="flex flex-column gap-1">
					{sortedGoals.length > 0 &&
						sortedGoals?.map((goal, index) => (
							<GoalModuleCurrentItem
								key={`current-${goal.id}`}
								index={index}
								goal={goal}
								onSave={handleGoalSave}
								onRemove={handleGoalRemove}
								disabled={isLoading}
							/>
						))}

					<li className="w-full flex flex-row gap-2 align-items-center">
						{addGoalMode ? (
							<>
								<span className="no-break mr-1">{`${t('userPanel.contract.goal')} ${
									sortedGoals.length + 1
								}:`}</span>
								<InputText
									className="flex-1 h-2rem w-full"
									{...register('newGoal')}
									disabled={isLoading}
								/>
								<div className="flex flex-row gap-1">
									<Button
										variant="primary-text"
										variantSize="sm"
										onClick={handleAddGoalClick}
										icon="check"
										iconSet="pi"
										iconOnly
										label={t('actions.confirm')}
										disabled={isLoading}
									/>
									<Button
										variant="danger-text"
										variantSize="sm"
										onClick={handleCancelAddGoalModeClick}
										icon="times"
										iconSet="pi"
										iconOnly
										label={t('actions.reject')}
										disabled={isLoading}
									/>
								</div>
							</>
						) : (
							<>
								<Button
									variant="primary-text"
									variantSize="sm"
									onClick={handleAddGoalModeClick}
									icon="plus"
									iconSet="pi"
									iconOnly
									disabled={isLoading}
									label={t('actions.add')}
								/>
								<span>{t('userPanel.contract.addGoal')}</span>
							</>
						)}
					</li>
				</ul>
			)}
		</ContractModule>
	);
};

export default GoalsModule;
