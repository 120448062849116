import React, { Ref, HTMLAttributes, forwardRef } from 'react';
import { classNames } from 'primereact/utils';

import './List.scss';

export type OrderListProps = HTMLAttributes<HTMLOListElement>;

export const OrderList = forwardRef(
	({ children, className, ...restProps }: OrderListProps, ref: Ref<HTMLOListElement>) => {
		return (
			<ol ref={ref} className={classNames('list order-list', className)} {...restProps}>
				{children}
			</ol>
		);
	}
);
