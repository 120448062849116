import React, { useMemo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { finalReportApi, userPanelApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import MessageBoxFull from 'components/_common/panel/MessageBoxFull';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/_new/Button';
import { InputTextArea } from 'components/_new/InputTextArea';
import { useScope } from 'contexts/scopeContext';
import { useGlobal } from 'contexts/globalContext';
import { usePanel } from 'contexts/panelContext';
import { StepSlider } from 'components/_new/Slider';
import { Box, BoxSection } from 'components/_new/Box';
import { classNames } from 'primereact/utils';
import { Page } from 'components/_new/Page';
import { Spinner } from 'components/_new/Spinner';
import { usePair } from 'contexts/pairContext';
import { MessageBox } from 'components/_new/MessageBox';
import { FinalSurveyPanel } from 'components/finalSurveyPage/FinalSurveyPanel';
import { surveyApi } from 'api/survey';
import CustomConfirmDialog from '../_common/ConfirmDialog';

export const ProcessSummary = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();

	const { toastRef } = useGlobal();
	const { currentProgram } = useScope();
	const { currentApplicationData, currentProgramMembership } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const membershipId = Number(currentProgramMembership.id);
	// const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const queryClient = useQueryClient();
	const {
		mutate: prepareDownloadLinkMutate,
		data: downloadLink,
		isLoading: prepareDownloadLinkLoading,
	} = useMutation(finalReportApi.prepareFinalReportDownloadLink);
	useEffect(() => {
		prepareDownloadLinkMutate({ pairId, membershipId, type: String(type) });
	}, [membershipId, pairId, prepareDownloadLinkMutate, type]);

	const navigate = useNavigate();

	const filesUrls = useMemo(() => {
		switch (currentProgram?.language) {
			case 'en':
				return {
					finalProcess: `/panel/${organizationName}/${programName}/${type}/files/knowledge/the-end-of-the-process-for-the-${type}-cheat-sheet`,
				};
			case 'pl':
			default:
				return {
					finalProcess: `/panel/${organizationName}/${programName}/${type}/files/pigulki-wiedzy/zakonczenie-procesu-dla-${
						type === 'mentor' ? 'mentora' : type
					}-o-czym-pamietac`,
				};
		}
	}, [currentProgram?.language, organizationName, programName, type]);

	const { refetch: finishProcess } = useQuery(
		['finishProcess'],
		() => userPanelApi.finishProcessForPair(pairId, currentProgramMembership.id),
		{
			onSuccess: () => {
				navigate(
					currentApplicationData.applicationRole === 'mentee'
						? `/${organizationName}/${programName}/apply-mentee`
						: `/${organizationName}/${programName}/`
				);
				setTimeout(() => {
					window.location.reload();
				}, 100);
			},
		}
	);
	const {
		data: finalReportData,
		isLoading: finalReportLoading,
		refetch: finalReportRefetch,
	} = useQuery(['final-report', pairId], () => finalReportApi.getFinalReport(pairId, currentApplicationData?.id), {
		enabled: Boolean(pairId),
		refetchOnWindowFocus: false,
		onSuccess: () => {},
	});
	const { refetch: downloadMaterials, isFetching: downloadMaterialsLoading } = useQuery(
		['download-materials', pairId, currentApplicationData.id],
		() => finalReportApi.downloadMaterials(pairId, currentApplicationData.id),
		{
			onSuccess: (blob) => {
				const downloadLink = document.createElement('a');
				downloadLink.target = '_blank';
				downloadLink.download = 'files.zip';
				const downloadUrl = URL.createObjectURL(blob);
				downloadLink.href = downloadUrl;
				document.body.append(downloadLink);
				downloadLink.click();
			},
		}
	);

	const { data: checkDownloadMaterials, isFetching: checkDownloadMaterialsFetching } = useQuery(
		['check-download-materials', pairId, currentApplicationData.id],
		() => finalReportApi.checkDownloadMaterials(pairId, currentApplicationData.id),
		{
			enabled: true,
		}
	);

	const downloadMaterialsActionLoading = checkDownloadMaterialsFetching || downloadMaterialsLoading;

	const [finalSurveyDialogOpen, setFinalSurveyDialogOpen] = useState(false);
	const { data, isLoading: finalSurveyLoading } = useQuery(
		['FINAL_SURVEY', { programMembershipId: Number(currentProgramMembership?.id) }],
		() => surveyApi.getSurveyForProgramMembership(Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(currentProgramMembership),
		}
	);
	const hasAnswers = (data?.stats?.questionFilledCount || 0) > 0;

	return (
		<>
			<SubPageTitle title={t('userPanel.navigation.processSummary')} />
			<Page>
				<div>
					<h3 className="text-purplish-blue">{t('userPanel.processSummary.thanks')}</h3>
					<p>{t('userPanel.processSummary.desc')}</p>
				</div>
				<div>
					<h3 className="text-purplish-blue">{t('userPanel.processSummary.whatNext')}</h3>
					<div className="w-full">
						<ul className="flex flex-column gap-4 p-list-unstylled sm:pl-3">
							<li>
								<i
									className="pi pi-angle-right text-3xl text-purplish-blue"
									style={{ verticalAlign: 'middle' }}
								/>
								{type === 'mentor' && (
									<Trans
										t={t}
										i18nKey="userPanel.processSummary.readInKnowledgeForMentor"
										components={[<Link to={filesUrls.finalProcess} className="p-link" />]}
									/>
								)}
								{type === 'mentee' && (
									<Trans
										t={t}
										i18nKey="userPanel.processSummary.readInKnowledgeForMentee"
										components={[<Link to={filesUrls.finalProcess} className="p-link" />]}
									/>
								)}
							</li>
							<li>
								<i
									className="pi pi-angle-right text-3xl text-purplish-blue"
									style={{ verticalAlign: 'middle' }}
								/>
								<p className="inline-block">
									{type === 'mentor'
										? t('userPanel.processSummary.downloadReportForMentor')
										: t('userPanel.processSummary.downloadReportForMentee')}
								</p>
								{finalReportLoading ? <Spinner size="sm" /> : null}
								{type === 'mentor' && !finalReportData?.visibleForMentee && !finalReportLoading && (
									<MessageBox
										variant="warn"
										message={
											<Trans
												t={t}
												i18nKey="userPanel.processSummary.reportNotFilledForMentor"
												components={[
													<Link
														to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/final-report`}
														className="underline"
													/>,
												]}
											/>
										}
										highlight
									/>
								)}
								{type === 'mentee' && !finalReportData?.visibleForMentee && !finalReportLoading && (
									<MessageBox
										variant="warn"
										message={t('userPanel.processSummary.reportNotFilledForMentee')}
										highlight
									/>
								)}
								{finalReportData?.visibleForMentee && !finalReportLoading && (
									<div className="flex flex-col mt-4 justify-content-center align-items-center w-full">
										<Button
											label={t('userPanel.processSummary.downloadReport')}
											variant="primary"
											loading={prepareDownloadLinkLoading}
											onClick={() => {
												window.open(downloadLink, '_blank');
											}}
										/>
									</div>
								)}
							</li>
							{/* <li> */}
							{/* 	<i */}
							{/* 		className="pi pi-angle-right text-3xl text-purplish-blue" */}
							{/* 		style={{ verticalAlign: 'middle' }} */}
							{/* 	/> */}
							{/* 	<p className="inline-block">{t('userPanel.processSummary.downloadSticker')}</p> */}
							{/* 	<div className="flex flex-col mt-4 justify-content-center align-items-center w-full"> */}
							{/* 		<img */}
							{/* 			src="https://via.placeholder.com/500x300.png" */}
							{/* 			alt="sticker" */}
							{/* 			className="img-fluid" */}
							{/* 		/> */}
							{/* 	</div> */}
							{/* </li> */}
							<li>
								<div className={classNames({ 'mb-4': !hasAnswers })}>
									<i
										className="pi pi-angle-right text-3xl text-purplish-blue"
										style={{ verticalAlign: 'middle' }}
									/>
									{t('userPanel.processSummary.pleaseRateAndFillSurvey')}
								</div>

								{finalSurveyLoading ? (
									<Spinner center size="sm" />
								) : (
									<>
										{hasAnswers && (
											<>
												<MessageBox
													variant="success"
													message={t('userPanel.processSummary.survey.thanks')}
													highlight
												/>
												<Button
													label={t(
														'userPanel.processSummary.survey.openFinalSurveyAndCheckAnswers'
													)}
													onClick={() => {
														setFinalSurveyDialogOpen(true);
													}}
													className="px-auto py-2"
												/>
											</>
										)}

										{!hasAnswers && (
											<>
												<MessageBox
													variant="rose"
													message={t('userPanel.processSummary.survey.fillFinalSurvey')}
													highlight
												/>
												<Button
													label={t('userPanel.processSummary.survey.openFinalSurvey')}
													onClick={() => {
														setFinalSurveyDialogOpen(true);
													}}
													className="px-auto py-2"
												/>
											</>
										)}
									</>
								)}

								<FinalSurveyPanel
									visible={finalSurveyDialogOpen}
									onHide={() => setFinalSurveyDialogOpen(false)}
								/>
								{/* <form onSubmit={handleSubmitForm} className="flex flex-column gap-4">
									<Box variant="white-bordered">
										{[
											{
												name: 'mentring_as_development_method',
												label: t('userPanel.processSummary.survey.mentringAsDevelopmentMethod'),
											},
											{
												name: 'mentoring_tool_satisfaction',
												label: t('userPanel.processSummary.survey.mentoringToolSatisfaction'),
											},
											{
												name: 'mentoring_tool_recommendation',
												label: t('userPanel.processSummary.survey.mentoringToolRecommendation'),
											},
										].map(({ name, label }) => (
											<Controller
												key={name}
												control={control}
												name={name}
												render={({ field: { ref, onChange, value } }) => (
													<BoxSection header={label} key={name}>
														<StepSlider
															ref={ref}
															min={0}
															max={10}
															onChange={onChange}
															defaultValue={Number(value || 0)}
														/>
													</BoxSection>
												)}
											/>
										))}

										<BoxSection header={t('userPanel.processSummary.survey.comment')}>
											<Controller
												control={control}
												name="comment"
												render={({ field }) => <InputTextArea {...field} />}
											/>
										</BoxSection>
									</Box>

									<div className="flex flex-row justify-content-end">
										<Button
											submit
											variant="primary"
											loading={updateFinalReportLoading}
											label={t('actions.save')}
										/>
									</div>
								</form> */}
							</li>
							<li>
								<i
									className="pi pi-angle-right text-3xl text-purplish-blue"
									style={{ verticalAlign: 'middle' }}
								/>
								<Trans
									t={t}
									i18nKey="userPanel.processSummary.followUsOnLinkedIn"
									components={[
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a
											href="https://www.linkedin.com/company/mentiway/"
											rel="noreferrer"
											target="_blank"
											className="p-link"
										/>,
									]}
								/>
							</li>
							<li>
								<div className="mb-3">
									<i
										className="pi pi-angle-right text-3xl text-purplish-blue"
										style={{ verticalAlign: 'middle' }}
									/>
									{t('userPanel.processSummary.downloadAllSessionMaterials')}
								</div>

								<div className="text-center flex justify-content-center">
									<Button
										variant="primary"
										onClick={() => {
											downloadMaterials();
										}}
										disabled={!checkDownloadMaterials}
										title={
											!checkDownloadMaterials
												? t('userPanel.userFiles.downloadAllMaterialsDisabled')
												: undefined
										}
										loading={downloadMaterialsActionLoading}
										label={t('userPanel.processSummary.downloadMentoringMaterials')}
									/>
								</div>
							</li>
							{/* <li>
								<div className="mb-4">
									<i
										className="pi pi-angle-right text-3xl text-purplish-blue"
										style={{ verticalAlign: 'middle' }}
									/>
									<Trans
										t={t}
										i18nKey={
											currentApplicationData.applicationRole === 'mentee'
												? t('userPanel.processSummary.closeCurrentMentoringProcessMentee')
												: t('userPanel.processSummary.closeCurrentMentoringProcessMentor')
										}
										components={{ bold: <strong /> }}
									/>
								</div>
								<div className="text-center flex justify-content-center">
									<Button
										variant="primary"
										onClick={() => setConfirmDialogOpen(true)}
										label={t('userPanel.processSummary.finishProcessEarly')}
									/>
								</div>
							</li> */}
						</ul>
					</div>
				</div>
			</Page>
		</>
	);
};

export default ProcessSummary;
