import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { authApi } from 'api';
import { RouterUrlParams } from 'App';
import { PasswordSetupPayload } from 'types/payloads/PasswordSetupPayload';
import { Button } from 'primereact/button';
// import { Checkbox } from 'primereact/checkbox';
import { useAuth } from 'contexts/authContext';
import ValidatePassword from 'components/_common/forms/ValidatePassword';
import { useGlobal } from 'contexts/globalContext';
import { PageLoading } from 'components/PageLoading';
import { AxiosError } from 'axios';

interface PasswordSetupFormObject {
	password: string;
	confirmPassword: string;
}

type PasswordSetupProps = {
	mode: 'confirm' | 'reset';
};

const PasswordSetup = ({ mode }: PasswordSetupProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { token } = useParams() as RouterUrlParams;
	const location = useLocation();
	const { authenticate } = useAuth();
	const { toastRef } = useGlobal();

	const { control, handleSubmit, watch } = useForm<PasswordSetupFormObject>({
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});
	const password = watch('password', '');

	// const [persistLogin, setPersistLogin] = useState<boolean>(false);
	const viewMode = location.pathname.split('/')[1];

	const prepareValidationResetMessage = (message: string) => {
		switch (message) {
			case 'Validation failed (uuid is expected)':
			case 'Invalid reset code (not found)':
				return t('auth.resetPasswordInvalidLink');
			case 'Invalid reset code (expired)':
				return t('auth.resetPasswordExpired');
			case 'Invalid reset code (used)':
				return t('auth.resetPasswordIsUsed');
			default:
				return null;
		}
	};

	const isResetPassword = viewMode === 'reset';
	const [validateResetMessage, setValidateResetMessage] = useState<string | null>(null);
	const {
		isSuccess: validateResetSuccess,
		isError: validateResetError,
		isLoading: validateResetLoading,
	} = useQuery(['RESET_VALIDATE', { token }], () => authApi.validateResetUserPassword(token), {
		enabled: isResetPassword,
		onError: ({ response }: AxiosError<{ message: string } | boolean>) => {
			const message = (response?.data as any)?.message || null;
			setValidateResetMessage(prepareValidationResetMessage(message));
		},
	});

	const resetUserPassword = useMutation((data: PasswordSetupPayload) => authApi.resetUserPassword(token, data), {
		onSuccess: ({ refreshToken, accessToken }) => {
			authenticate({ refreshToken, accessToken }, () => {
				toastRef?.current?.show({ severity: 'success', detail: 'Zalogowano pomyślnie' });
				navigate({ pathname: '/' });
			});
		},
	});
	const createUser = useMutation((data: PasswordSetupPayload) => authApi.createUser(data), {
		onSuccess: ({ refreshToken, accessToken }) => {
			authenticate({ refreshToken, accessToken }, () => {
				toastRef?.current?.show({ severity: 'success', detail: 'Zalogowano pomyślnie' });
				navigate({ pathname: '/' });
			});
		},
	});

	const onSubmit = ({ password }: PasswordSetupFormObject) => {
		if (viewMode === 'reset') {
			return resetUserPassword.mutate({ password, token });
		}
		return createUser.mutate({ password, token });
	};

	const isLoading = (isResetPassword && validateResetLoading) || resetUserPassword.isLoading;

	return (
		<>
			<Helmet title={t('auth.passwordSetup')} />
			{isLoading ? (
				<PageLoading text="Resetowanie hasła..." />
			) : (
				<>
					{isResetPassword && validateResetError && (
						<div className="flex flex-column gap-2 align-items-center">
							<p className="text-danger">{validateResetMessage}</p>
							<Link className="p-button" to="/password-reset">
								{t('auth.resetPasswordAgain')}
							</Link>
						</div>
					)}
					{(!isResetPassword || (isResetPassword && validateResetSuccess)) && (
						<form onSubmit={handleSubmit((data) => onSubmit(data))} className="flex flex-column row-gap-4">
							<span className="text-sm">{t('auth.passwordStepTooltip')}</span>
							<span className="text-sm mt-2">
								<p>{t('auth.passwordComplexityRequirement')}</p>
							</span>
							<ValidatePassword
								name="password"
								placeholder={t('auth.inputs.password')}
								control={control}
								required
								rules={{
									minLength: { value: 8, message: t('auth.passwordMinLength') },
									maxLength: { value: 30, message: t('auth.passwordMaxLength') },
									validate: {
										customRequired: () => {
											const regex =
												/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]{8,}$/;
											if (!regex.test(watch('password'))) {
												if (!/(?=.*[a-z])/.test(watch('password'))) {
													return t('auth.passwordRequiresLowercase');
												}

												if (!/(?=.*[A-Z])/.test(watch('password'))) {
													return t('auth.passwordRequiresUppercase');
												}

												if (!/(?=.*\d)/.test(watch('password'))) {
													return t('auth.passwordRequiresDigit');
												}

												if (!/(?=.*[@$#!%*?&])/.test(watch('password'))) {
													return t('auth.passwordRequiresSpecialChar');
												}
											}
											return true;
										},
									},
								}}
							/>

							<ValidatePassword
								name="confirmPassword"
								placeholder={t('auth.inputs.confirmPassword')}
								control={control}
								rules={{
									validate: {
										matching: (v) => v === password || t('misc.forms.invalidRepeatPassword'),
									},
								}}
								required
							/>

							<Button
								type="submit"
								style={{ display: 'unset' }}
								label={
									mode === 'reset'
										? t('auth.buttons.savePassword')
										: t('auth.buttons.finishRegistration')
								}
								loading={resetUserPassword.isLoading || createUser.isLoading}
							/>
						</form>
					)}
				</>
			)}
		</>
	);
};

export default PasswordSetup;
