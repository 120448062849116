/* eslint-disable no-case-declarations */
import React, { FC, ClipboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { programsApi } from 'api';
import { File, FileType } from 'types/File';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { useProgramPanel } from 'contexts/programPanelContext';
import { classNames } from 'primereact/utils';
import { Box, BoxSection } from 'components/_new/Box';
import PageMissing from 'components/PageMissing';
import { PageLoading } from 'components/PageLoading';
import { slugify } from 'utils/helpers';

interface Props {
	selectedFile: File;
	className?: string;
}
const ProgramKnowledgeFileDisplayModule: FC<Props> = ({ selectedFile, className }) => {
	const { t } = useTranslation();
	const { organizationName, data: program } = useProgramPanel();

	const responseAsBlob = Boolean(selectedFile.type === 'file' || selectedFile.type === 'pdf');
	const {
		data: fileData,
		isLoading,
		isError,
		isRefetching,
		isFetched,
	} = useQuery(
		['knowledge-file-data', { id: Number(selectedFile?.id), responseAsBlob }],
		() => programsApi.getProgramKnowledgeFileBlob(Number(program?.id), Number(selectedFile?.id), responseAsBlob),
		{
			enabled: Boolean(selectedFile),
			refetchOnWindowFocus: false,
		}
	);

	const handleHtmlCopy = (event: ClipboardEvent) => {
		const selection = document.getSelection() as Selection;
		event.preventDefault();
		event.clipboardData.setData('text', `${selection.toString()} - ${t('userPanel.files.contentCopyMessage')}`);
	};

	if (isError) return <PageMissing />;

	if (isLoading || isRefetching) return <PageLoading />;

	const renderFileContent = (fileType: FileType) => {
		switch (fileType) {
			case 'html_wyswig':
				// eslint-disable-next-line consistent-return, no-case-declarations
				const parseFileData2 = () => {
					// eslint-disable-next-line no-case-declarations
					if (isFetched) {
						const preParser = (_data: string) => {
							let data = _data;
							if (organizationName) {
								data = data.replaceAll('{{organization}}', organizationName);
							}
							if (organizationName) {
								data = data.replaceAll('{{program}}', organizationName);
							}
							// if (type) {
							// 	data = data.replaceAll('{{usertype}}', type);
							// }
							return data;
						};
						try {
							return parse(preParser(String(selectedFile.description || '')));
						} catch {
							return 'Wystąpił błąd podczas wyświetlania zawartości.';
						}
					}
				};
				return <div onCopy={(event) => handleHtmlCopy(event)}>{parseFileData2()}</div>;
			case 'html':
				// eslint-disable-next-line consistent-return, no-case-declarations
				const parseFileData = () => {
					if (isFetched) {
						// eslint-disable-next-line no-case-declarations
						const preParser = (_data: string) => {
							let data = _data;
							if (organizationName) {
								data = data.replaceAll('{{organization}}', organizationName);
							}
							if (organizationName) {
								data = data.replaceAll('{{program}}', organizationName);
							}
							// if (type) {
							// 	data = data.replaceAll('{{usertype}}', type);
							// }
							return data;
						};
						try {
							return parse(preParser(fileData));
						} catch {
							return 'Wystąpił błąd podczas wyświetlania zawartości.';
						}
					}
				};
				return <div onCopy={(event) => handleHtmlCopy(event)}>{parseFileData()}</div>;
			case 'file':
				const prepareUrl1 = (file: any) => {
					const slugName = slugify(file.name);
					const extension = ['file', 'pdf'].includes(file.type) ? `.pdf` : '';
					return file.originalFilename || `${slugName}${extension}`;
				};
				const fileName1 = prepareUrl1(selectedFile);
				return (
					<a
						href={URL.createObjectURL(fileData)}
						download={fileName1}
						rel="noreferrer"
						target="_blank"
						className="text-white no-underline"
					>
						<Button>{t('misc.downloadFile')}</Button>
					</a>
				);
			case 'pdf':
				// const prepareUrl2 = (file: any) => {
				// 	const slugName = slugify(file.name);
				// 	const extension = ['file', 'pdf'].includes(file.type) ? `.pdf` : '';
				// 	return file.originalFilename || `${slugName}${extension}`;
				// };
				// const fileName2 = prepareUrl2(selectedFile);
				return (
					<object
						data={URL.createObjectURL(new Blob([fileData], { type: 'application/pdf' }))}
						type="application/pdf"
						name={selectedFile.name}
						width="100%"
						height="500px"
						aria-label="pdf"
					/>
				);
			case 'video':
				// eslint-disable-next-line no-case-declarations
				let src = null;
				try {
					src = `https://www.youtube.com/embed/${(selectedFile.url as string).split('=')[1]}`;
				} catch {
					return 'Wystąpił błąd podczas ładwania filmu';
				}
				return (
					<iframe
						className="iframe-pdf"
						src={src}
						frameBorder="0"
						title={selectedFile.name}
						allowFullScreen
					/>
				);
			case 'link':
				try {
					if (selectedFile.url === null) {
						throw new Error('invalid url');
					} else {
						const url = new URL(selectedFile.url);
						// window.location.href = url.href;
						return (
							<a href={url.href} target="_blank" className="p-link" rel="noreferrer">
								{url.href}
							</a>
						);
					}
				} catch {
					return t('userPanel.userFiles.file.invalidUrl');
				}

			default:
				return (
					<Message
						severity="error"
						text={t('misc.genericError')}
						className="w-full justify-content-start pl-4"
					/>
				);
		}
	};

	return (
		<Box variant="white-bordered" className={className}>
			<BoxSection header={selectedFile.name}>
				{['html', 'file', 'pdf'].includes(selectedFile.type) && String(selectedFile.description).length > 0 && (
					<div>{parse(String(selectedFile.description))}</div>
				)}
				<div className={classNames('p-knowledge-base-article')}>{renderFileContent(selectedFile.type)}</div>
			</BoxSection>
		</Box>
	);
};

export default ProgramKnowledgeFileDisplayModule;
