import React, { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { Button } from 'primereact/button';
import { InitialMeetingPayload } from 'types/payloads/PairPayload';
import { usePanel } from 'contexts/panelContext';
import TextArea from 'components/_common/forms/TextArea';
import { useScope } from 'contexts/scopeContext';

interface InitialMeetingFormObject {
	message: string;
	date: string;
}

interface Props {
	menteeMembershipId: number;
	id: number;
	setModalOff: () => void;
	onAction?: () => void;
}
export const InitialMeetingForm = ({ id, menteeMembershipId, setModalOff, onAction }: Props) => {
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();

	const { userPairsRefetch } = usePanel();
	const { currentProgram } = useScope();
	const { panelType } = usePanel();
	const [afterSend, setAfterSend] = useState(false);

	const { mutate: requestEntryMeeting, isLoading: isSubmitting } = useMutation(
		({ message, dates }: InitialMeetingPayload) =>
			userPanelApi.postEntryMeeting(id, menteeMembershipId, { message, dates }),
		{
			onSuccess: () => {
				if (onAction) {
					onAction();
				}
				userPairsRefetch();
				setAfterSend(true);
			},
		}
	);

	const { control, handleSubmit } = useForm<InitialMeetingFormObject>({
		defaultValues: {
			message: '',
			date: '',
		},
	});

	const pairingAfterSendMessageLink = useMemo(() => {
		switch (currentProgram?.language) {
			case 'en':
				return `/panel/${organizationName}/${programName}/${panelType}/files/preparation-forms/preparation-for-the-initial-session`;
			case 'pl':
			default:
				return `/panel/${organizationName}/${programName}/${panelType}/files/formularze-przygotowawcze/przed-sesja-zerowa`;
		}
	}, [panelType, currentProgram?.language, organizationName, programName]);

	return (
		<div>
			{afterSend ? (
				<>
					<div>
						<p>{t('userPanel.pairing.pairingAfterSendLine1')}</p>
						<p>{t('userPanel.pairing.pairingAfterSendLine2')}</p>
						<p>
							<Trans
								i18nKey="userPanel.pairing.pairingAfterSendLine3"
								components={[
									<Link
										to={{
											pathname: pairingAfterSendMessageLink,
										}}
										className="underline"
									/>,
								]}
							/>
						</p>
					</div>
					<div className="flex flex-row justify-content-end">
						<Button
							label={t('actions.close')}
							onClick={() => {
								setModalOff();
							}}
						/>
					</div>
				</>
			) : (
				<form
					onSubmit={handleSubmit(({ date, message }) =>
						requestEntryMeeting({ dates: date.toString(), message })
					)}
				>
					<div className="flex flex-column row-gap-3">
						<div className="mb-3 ml-2">
							<span>
								<Trans i18nKey="userPanel.pairing.initialMeetingInfo" components={{ b: <b /> }} />
							</span>
							<p>
								<Trans i18nKey="userPanel.pairing.initialMeetingSubInfo" components={{ b: <b /> }} />
							</p>
						</div>
						<TextArea
							label={t('userPanel.pairing.proposedDateOfMeeting')}
							control={control}
							name="date"
							required
						/>
						<TextArea
							label={t('userPanel.pairing.optionalMessageForMentor')}
							name="message"
							control={control}
						/>
						<Button
							className="align-self-end"
							type="submit"
							label={t('userPanel.pairing.sendMessageToMentor')}
							loading={isSubmitting}
						/>
					</div>
				</form>
			)}
		</div>
	);
};
