import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { useProgramPanel } from 'contexts/programPanelContext';
import { DialogAction, DialogBaseExtendProps, DialogBaseProps } from '../../_new/Dialog';
import { useGlobal } from '../../../contexts/globalContext';
import { organizationsApi, usersApi } from '../../../api';
import CustomLabel from '../forms/Label';
import TextField from '../forms/TextField';
import { Dropdown } from '../forms/Dropdown';
import Checkbox from '../forms/Checkbox';
import { Program } from '../../../types/Program';
import { useOrganizationPanel } from '../../../contexts/organizationPanelContext';

type AddUserForm = {
	firstName: string;
	lastName: string;
	email: string;
	language: string;
	notifyCreatedUser: boolean;
	organisationProgramId?: number;
};

interface AddUserDialogProps extends DialogBaseProps {
	isOrganisationPanel: boolean;
	program?: Program | undefined;
	onUserAddSuccess: () => void;
}

// todo Extend this to check if the Dialog is displayed in program Panel

export const AddUserDialog = ({
	onHide,
	isOrganisationPanel,
	program,
	onUserAddSuccess,
	...props
}: AddUserDialogProps) => {
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();
	const { control, handleSubmit, watch, reset } = useForm<AddUserForm>({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			language: program?.language || '',
			notifyCreatedUser: false,
		},
	});

	const handleSubmitForm = handleSubmit((values) => {
		mutate({ programId: values.organisationProgramId || Number(program?.id), data: values });
	});
	const { mutate, isLoading } = useMutation(
		(input: { programId: number; data: any }) => usersApi.createUser(input.programId, input.data),
		{
			onError: ({ response }) => {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: t(`misc.error`),
					detail: t(`userPanel.myAccount.${response.data.message}`),
				});
			},
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
				});
				reset({
					firstName: '',
					lastName: '',
					email: '',
					language: program?.language || '',
					notifyCreatedUser: false,
				});
				onHide?.();
				onUserAddSuccess();
			},
		}
	);

	const actions = [
		{
			key: 'save',
			submit: true,
			form: 'addUserForm',
			label: t('actions.save'),
			loading: isLoading,
		},
	];

	const {
		currentOrganization: { organizationName },
	} = useOrganizationPanel();

	const { data: organizationPrograms } = useQuery(
		['getOrganizationPrograms', organizationName],
		() => organizationsApi.getOrganizationPrograms({ organizationName: String(organizationName) }),
		{
			enabled: Boolean(organizationName),
		}
	);

	return (
		<DialogAction
			title={t('userPanel.myAccount.addNewUser')}
			size="md"
			actions={actions}
			onHide={onHide}
			{...props}
		>
			<form id="addUserForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
				<div className="flex flex-column mb-2">
					<p>{t('userPanel.myAccount.addNewUserSubtitle')}</p>
				</div>
				<div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="firstname" label={t('userPanel.myAccount.firstName')} required />
						<TextField
							name="firstName"
							control={control}
							placeholder={t('userPanel.myAccount.firstName')}
							required
						/>
					</div>
					<div className="flex flex-column gap-1">
						<CustomLabel name="lastname" label={t('userPanel.myAccount.lastName')} required />
						<TextField
							name="lastName"
							control={control}
							placeholder={t('userPanel.myAccount.lastName')}
							required
						/>
					</div>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="email" label="Email" required />
					<TextField
						name="email"
						control={control}
						placeholder={t('userPanel.myAccount.email')}
						rules={{
							validate: {
								customRequired: () => {
									const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
									if (!regex.test(watch('email'))) {
										return t('userPanel.myAccount.invalidEmail');
									}
									return true;
								},
							},
						}}
						required
					/>
				</div>
				<div className="flex flex-column gap-1">
					<CustomLabel name="selectLanugage" label={t('userPanel.myAccount.selectLanguage')} required />
					<Controller
						name="language"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={[
									{ value: 'pl', label: 'PL' },
									{ value: 'en', label: 'EN' },
								]}
								rounded
								required
								placeholder={t('userPanel.myAccount.selectLanguage')}
							/>
						)}
					/>
				</div>
				<div>
					<Checkbox
						text={t('userPanel.myAccount.notifyCreatedUser')}
						control={control}
						name="notifyCreatedUser"
					/>
				</div>
				{isOrganisationPanel && (
					<div className="flex flex-column gap-1">
						<CustomLabel
							name="organisationProgramId"
							label={t('userPanel.myAccount.chooseProgram')}
							required
						/>
						<Controller
							name="organisationProgramId"
							control={control}
							render={({ field }) => (
								<Dropdown
									rounded
									{...field}
									options={organizationPrograms?.map((program) => ({
										value: program.id,
										label: program.displayName || '',
									}))}
									required
									placeholder={t('userPanel.myAccount.chooseProgram')}
								/>
							)}
						/>
					</div>
				)}
			</form>
		</DialogAction>
	);
};
