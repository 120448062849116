import React from 'react';

const RedCrossIcon = () => (
	<i
		className="pi pi-times text-lg font-bold text-white bg-red-500 p-2 align-self-end"
		style={{
			borderRadius: '50%',
		}}
	/>
);

export default RedCrossIcon;
