import React, { useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { Session } from 'types/Session';
import { Spinner } from 'components/_new/Spinner';
import PageMissing from 'components/PageMissing';
import { usePair } from 'contexts/pairContext';

const Sessions = () => {
	const { userPairs, currentProgramMembership } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();
	const pairUserData = userPairs?.find((pair) => pair.id === pairId);

	const {
		refetch: sessiosRefetch,
		data: sessionsData,
		isLoading,
		isError,
	} = useQuery(['sessions', { pairId: Number(pairId) }], () =>
		userPanelApi.getSessions(pairId, currentProgramMembership.id)
	);
	const {
		refetch: getGoalsData,
		data: goalsData,
		isLoading: isGoalsLoading,
		isError: isGoalsError,
	} = useQuery(['goals', pairId], () => userPanelApi.getGoals(pairId, currentProgramMembership.id), {
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (!goalsData) {
			getGoalsData();
		}
	}, [goalsData, getGoalsData]);

	useEffect(() => {
		if (!sessionsData && !!pairUserData) {
			sessiosRefetch();
		}
	}, [sessionsData, pairUserData, sessiosRefetch]);

	if (isLoading || isGoalsLoading) return <Spinner />;

	if (isError || isGoalsError || !pairUserData) return <PageMissing />;

	return <div>{sessionsData && <Outlet context={{ sessionsData, sessiosRefetch }} />}</div>;
};

export default Sessions;

interface OutletContext {
	sessionsData: Session[];
	sessiosRefetch: () => void;
}
export function useSessionsData() {
	return useOutletContext<OutletContext>();
}
