import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, useParams } from 'react-router-dom';
import { usePanel } from 'contexts/panelContext';
import Tools from 'components/userPanel/Tools';
import UserSettings from 'components/_common/UserSettings';
import PanelLayout from 'components/_layouts/panel/PanelLayout';
import { border, colors } from 'theme';
import { MenuItemExt, PanelMenu } from 'components/_common/PanelMenu';
import { Pair } from 'types/Pair';
import ReportIssues from 'components/_common/ReportIssues';
import ChangeOrganization from 'components/_common/panel/ChangeOrganization';
import { useScope } from 'contexts/scopeContext';
import { useGlobal } from 'contexts/globalContext';
import { useNavigate } from 'hooks/useNavigate';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'components/_new/Button';
import { Divider } from 'components/_new/Divider';
import { useAuth } from 'contexts/authContext';
import { Dialog } from 'components/_new/Dialog';
import { MyAccountDialog } from 'components/myAccount/MyAccountDialog';
import { MyCalendarDialog } from 'components/myCalendar/MyCalendarDialog';
import { ChangeAvatarDialog } from 'components/myAccount/components/ChangeAvatarDialog';
import { ChatButton } from 'components/chat/ChatButton';
import { ChatProvider } from 'components/chat/ChatProvider';
import { surveyApi } from 'api/survey';
import { FinalSurveyPanel } from 'components/finalSurveyPage/FinalSurveyPanel';
import { ProgramFinishedPage } from '../ProgramFinishedPage';
import { WelcomeDialog } from './components/WelcomeDialog';

const UserPanelLayout = () => {
	const { t, i18n } = useTranslation();
	const { organizationName, programName, pairId } = useParams();
	const navigate = useNavigate({
		scroll: ['scrollWrapperRef'],
	});

	const { currentUser } = useAuth();
	const { currentProgram } = useScope();
	const { panelType, canSwitchPanels, userPairs, panelScopePath, currentProgramMembership } = usePanel();
	const [showOrgDialog, setShowOrgDialog] = useState(false);
	const [visibleLeft, setVisibleLeft] = useState(false);
	const { hasPanelAccess } = useScope();
	const {
		toastRef,
		reportIssueModalIsOpen,
		setReportIssueModalIsOpen,
		myAccountModalOpen,
		setMyAccountModalOpen,
		myCalendarModalOpen,
		setMyCalendarModalOpen,
		avatarModalIsOpen,
		setAvatarModalIsOpen,
	} = useGlobal();

	useEffect(() => {
		if (hasPanelAccess === false) {
			navigate('/');
			toastRef?.current?.show({
				severity: 'error',
				life: 3000,
				summary: t('misc.error'),
				detail: 'Aplikacja wymaga akceptacji administratora programu',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasPanelAccess]);

	if (!currentProgram) {
		return null;
	}

	if (currentProgram.finished) {
		return <ProgramFinishedPage nextProgramName={currentProgram.nextProgramName} />;
	}

	const { landingPage, language } = currentProgram;

	const { title, name, logo } = landingPage.landingContent || { title: '', name: '', logo: '' };

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const mapPairMenuItem = (pair: Pair, currentIndex: number) => {
		const {
			application: {
				systemAnswers: { name, surname },
			},
			id,
		} = pair;

		const lastSessionId = pair?.stats?.lastSessionId || null;

		const evaluationLink = lastSessionId
			? `${panelScopePath}/pair/${id}/session/${lastSessionId}/evaluation-mentee`
			: null;

		return {
			id: `pair-${id}`,
			label: `${name} ${surname}`,
			expanded: Boolean(Number(pairId) === pair.id) || (!pairId && currentIndex === 0),
			items: [
				{
					id: `pair-${id}-session`,
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa-regular fa-calendar-check fa-xs pr-2" />,
					label: t('userPanel.navigation.sessions'),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/session`),
				},
				{
					id: `pair-${id}-contract`,
					className: 'text-0',
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa-solid fa-file-signature fa-xs pr-2" />,
					label: t('userPanel.navigation.contract'),
					command: () => {
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/contract`);
					},
				},
				{
					id: `pair-${id}-plan`,
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa-solid fa-list fa-xs pr-2" />,
					label: t('userPanel.navigation.mentoringPlan'),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/mentee-plan`),
				},
				panelType === 'mentor' && {
					id: `pair-${id}-file`,
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa-solid fa-seedling fa-xs pr-2 pb-2 " />,
					label: t('userPanel.navigation.mentorPlan'),
					command: () =>
						navigate(
							`/panel/${organizationName}/${programName}/${panelType}/files/${
								language === 'pl' ? 'pigulki-wiedzy/rozwoj-mentora' : 'knowledge/mentor-development'
							}`
						),
				},
				{
					separator: true,
				},
				{
					id: `pair-${id}-final-report`,
					icon: <i className="fa-regular fa-file-lines fa-xs pr-2 pb-2 " />,
					label: t('userPanel.navigation.finalReport'),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/final-report`),
				},
				evaluationLink
					? {
							id: `pair-${id}-evaluation`,
							icon: <i className="fa-solid fa-list-check fa-xs pr-2 pb-2 " />,
							label: t('userPanel.navigation.evaluation'),
							command: () =>
								navigate({
									pathname: evaluationLink,
									hash: '#content',
								}),
					  }
					: null,
				{
					id: `pair-${id}-process-summary`,
					icon: <i className="fa-solid fa-check fa-xs pr-2 pb-2 " />,
					label: t('userPanel.navigation.processSummary'),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/process-summary`),
				},
				{
					separator: true,
				},
				{
					id: `pair-${id}-materials`,
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa-regular fa-clipboard fa-xs pr-2" />,
					label: t('userPanel.navigation.userFiles'),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/userfiles`),
				},
				currentProgram.mentibotEnabled && {
					id: `pair-${id}-mentibot`,
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa fa-solid fa-brain pr-2 fa-xs" />,
					label: t('userPanel.navigation.mentibotAi'),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/mentibot-ai`),
				},
				{
					id: `pair-${id}-mentors`,
					// icon: <i className="pi pi-caret-right pr-2" />,
					icon: <i className="fa-regular fa-address-card  pr-2 fa-xs" />,
					label: t('userPanel.navigation.pairData', {
						type: panelType === 'mentor' ? t('misc.menteess') : t('misc.mentorss'),
					}),
					command: () =>
						navigate(`/panel/${organizationName}/${programName}/${panelType}/pair/${id}/pair-details`),
				},
			].filter(Boolean) as MenuItemExt[],
		};
	};

	const pairsMenu = useMemo(() => (userPairs || []).map(mapPairMenuItem), [mapPairMenuItem, userPairs]);

	const browseMembersMentorAccess = currentProgram.browseMembersByMentor && panelType === 'mentor';
	const browseMembersMenteeAccess = currentProgram.browseMembersByMentee && panelType === 'mentee';

	const navigationOptions: MenuItemExt[] = useMemo(
		() =>
			[
				{
					id: 'dashboard',
					icon: <i className="pi pi-home mr-3" />,
					command: () => navigate(`/panel/${organizationName}/${programName}/${panelType}`),
					label: t('userPanel.navigation.dashboard'),
					divider: true,
				},
				{
					id: 'divider1',
					className: 'p-panelmenu-divider',
				},
				{
					id: 'pairing',
					icon: <i className="pi pi-users mr-3" />,
					label: t('userPanel.navigation.pairing'),
					expanded: true,
					items: [
						...pairsMenu,
						{
							id: 'yourPairs',
							command: () => navigate(`/panel/${organizationName}/${programName}/${panelType}/pairing`),
							label: t('userPanel.navigation.yourPairs'),
						},
						// only if program has enabled option
						(browseMembersMentorAccess || browseMembersMenteeAccess) && {
							id: 'browseMembers',
							command: () =>
								navigate(`/panel/${organizationName}/${programName}/${panelType}/browseMembers`),
							label:
								panelType === 'mentee'
									? t('userPanel.navigation.potentialMembers.allPairMentors')
									: t('userPanel.navigation.potentialMembers.allPairMentees'),
						},
					].filter(Boolean),
				},
				{
					id: 'divider2',
					className: 'p-panelmenu-divider',
				},
				{
					id: 'your-data',
					label: t('userPanel.navigation.yourData'),
					icon: <i className="pi pi-user mr-3" />,
					expanded: true,
					items: [
						{
							id: 'personal-details',
							command: () =>
								navigate(`/panel/${organizationName}/${programName}/${panelType}/personal-details`),
							label: t('userPanel.navigation.personalDetails'),
							icon: <i className="pi pi-credit-card mr-3" />,
						},
						{
							id: 'linkedin-badge',
							command: () =>
								navigate(`/panel/${organizationName}/${programName}/${panelType}/linkedIn-badge`),
							label: t('userPanel.navigation.linkedInBadge'),
							icon: <i className="pi pi-linkedin mr-3" />,
						},
						{
							id: 'edit-your-data',
							command: () =>
								navigate(`/panel/${organizationName}/${programName}/${panelType}/application`),
							label: t('userPanel.personalDetails.editData'),
							icon: <i className="pi pi-pencil mr-3" />,
						},
						{
							id: 'my-calendar',
							command: () => {
								setMyCalendarModalOpen(true);
							},
							label: t('userPanel.navigation.myCalendar'),
							icon: <i className="pi pi-calendar mr-3" />,
						},
						{
							id: 'account-settings',
							command: () => setMyAccountModalOpen?.(true),
							label: t('userPanel.navigation.accountSettings'),
							icon: <i className="pi pi-cog mr-3" />,
						},
					],
				},
				{
					id: 'divider3',
					className: 'p-panelmenu-divider',
				},
				{
					id: 'files',
					command: () => navigate(`/panel/${organizationName}/${programName}/${panelType}/files`),
					label: t('userPanel.navigation.files'),
					icon: <i className="pi pi-book mr-3" />,
				},
				(currentProgram as any).hasContact && {
					id: 'contact',
					command: () => navigate(`/panel/${organizationName}/${programName}/${panelType}/contact`),
					label: t('userPanel.navigation.contact'),
					icon: <i className="pi pi-comments mr-3" />,
				},
			].filter(Boolean),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			browseMembersMenteeAccess,
			browseMembersMentorAccess,
			currentProgram,
			organizationName,
			pairsMenu,
			panelType,
			programName,
		]
	);

	const navigationBottomOptions: MenuItemExt[] = useMemo(
		() => [
			{
				id: 'programPage',
				icon: <i className="pi pi-send mr-3" />,
				label: t('userPanel.navigation.programPage'),
				command: () => navigate(`/${organizationName}/${programName}`),
			},
			{
				id: 'reportIssue',
				icon: <i className="pi pi-info-circle mr-3" />,
				label: t('userPanel.navigation.reportIssue'),
				command: () => setReportIssueModalIsOpen?.(true),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const [surveyPopupDialogOpen, setSurveyPopupDialogOpen] = useState(false);
	useQuery(
		['FINAL_SURVEY_POPUP', { programMembershipId: Number(currentProgramMembership?.id) }],
		() => surveyApi.checkSurveyPopupForProgramMembership(Number(currentProgramMembership?.id)),
		{
			enabled: true,
			onSuccess: (displayPopup: boolean) => {
				if (displayPopup) {
					setSurveyPopupDialogOpen(true);
				}
			},
		}
	);

	return (
		<>
			<Helmet title={t('misc.pageTitles.panel')} />
			<WelcomeDialog />
			<FinalSurveyPanel
				withIntro
				visible={surveyPopupDialogOpen}
				onHide={() => setSurveyPopupDialogOpen(false)}
			/>
			<Dialog
				title={t('landingPages.buttons.changeOrganization')}
				size="sm"
				onHide={() => setShowOrgDialog(false)}
				visible={showOrgDialog}
			>
				<ChangeOrganization card onCancel={() => setShowOrgDialog(false)} />
			</Dialog>
			<Dialog
				title={t('userPanel.navigation.reportIssue')}
				size="lg"
				onHide={() => setReportIssueModalIsOpen?.(false)}
				visible={Boolean(reportIssueModalIsOpen)}
			>
				<ReportIssues />
			</Dialog>

			<MyAccountDialog
				visible={myAccountModalOpen}
				onHide={() => {
					setMyAccountModalOpen(false);
				}}
			/>
			<ChangeAvatarDialog visible={avatarModalIsOpen} onHide={() => setAvatarModalIsOpen(false)} />
			<MyCalendarDialog
				visible={myCalendarModalOpen}
				onHide={() => {
					setMyCalendarModalOpen(false);
				}}
			/>

			<ChatProvider>
				<PanelLayout
					navigationOptions={navigationOptions}
					navigationTop={
						<div className="h-5rem p-2">
							<div className="flex flex-column align-items-end justify-content-center h-full w-full text-base text-right">
								<span>
									{t('userPanel.youAreInPanel')}{' '}
									<b>{panelType === 'mentor' ? t('misc.mentors') : t('misc.mentees')}</b>
								</span>

								{canSwitchPanels && (
									<Link
										// onClick={window.location.reload}
										to={`/panel/${organizationName}/${programName}/${
											panelType === 'mentor' ? 'mentee' : 'mentor'
										}`}
										className="text-xs underline"
										style={{ color: colors.purplishBlue }}
									>
										{`${t('userPanel.goTo')} ${
											panelType === 'mentor' ? t('misc.mentees') : t('misc.mentors')
										}`}
									</Link>
								)}
							</div>
						</div>
					}
					navigationBottom={<PanelMenu model={navigationBottomOptions} multiple />}
					header={
						<>
							<Button
								icon="fa fa-bars fa-2x"
								iconOnly
								label="Toggle menu"
								onClick={() => setVisibleLeft(true)}
								variant="primary-text"
								className="mx-2 flex md:hidden"
							/>
							<Sidebar
								role="navigation"
								visible={visibleLeft}
								onHide={() => setVisibleLeft(false)}
								showCloseIcon={false}
								style={{
									backgroundColor: `${colors.purplishBlue}`,
									borderRadius: '0px 20px 20px 0px',
									maxWidth: '70%',
								}}
							>
								<PanelMenu model={navigationOptions} multiple />
								<Divider margin={4} />
								<PanelMenu model={navigationBottomOptions} multiple />
							</Sidebar>
							<div className="flex md:hidden h-5rem p-2">
								<div className="flex flex-column align-items-start justify-content-center h-full w-full text-base text-right">
									<h4 className="mt-0 mb-1">{title}</h4>
									<span className="text-xs">
										{t('userPanel.youAreInPanel')}{' '}
										<b>{panelType === 'mentor' ? t('misc.mentors') : t('misc.mentees')}</b>
									</span>

									{canSwitchPanels && (
										<Link
											to={`/panel/${organizationName}/${programName}/${
												panelType === 'mentor' ? 'mentee' : 'mentor'
											}`}
											className="text-xs underline"
											style={{ color: colors.purplishBlue }}
										>
											{`${t('userPanel.goTo')} ${
												panelType === 'mentor' ? t('misc.mentees') : t('misc.mentors')
											}`}
										</Link>
									)}
								</div>
							</div>
							<img
								src={logo}
								alt="logo"
								className="hidden sm:flex max-h-3rem mr-3 cursor-pointer"
								onClick={() => navigate(`/${organizationName}/${programName}/index`)}
							/>
							<div
								className=" h-3rem pl-3 hidden md:block"
								style={{ borderLeft: border(colors.lightGray) }}
							>
								<h3 className="mt-0 mb-1">{name}</h3>
								<small>{title}</small>
							</div>

							{currentUser && (
								<div
									className="flex align-items-center h-3rem ml-auto pr-3 pl-4 gap-2"
									style={{ borderLeft: border(colors.lightGray) }}
								>
									<ChatButton />
									<UserSettings
										currentUser={currentUser}
										setShowOrgDialog={setShowOrgDialog}
										optionsLanguage={i18n.resolvedLanguage}
										source="panel"
									/>
								</div>
							)}
						</>
					}
					right={<Tools />}
				>
					<Outlet />
				</PanelLayout>
			</ChatProvider>
		</>
	);
};

export default UserPanelLayout;
