import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import './Spinner.scss';

type SpinnerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type SizeObj = {
	size: number;
};
type SizeMap = {
	xs: SizeObj;
	sm: SizeObj;
	md: SizeObj;
	lg: SizeObj;
	xl: SizeObj;
};

type SpinnerProps = {
	size?: SpinnerSize;
	center?: boolean;
	dark?: boolean;
};

export const Spinner = ({ size = 'md', center = false, dark = false }: SpinnerProps) => {
	const sizeMap: SizeMap = {
		xs: { size: 14 },
		sm: {
			size: 45,
		},
		md: {
			size: 75,
		},
		lg: {
			size: 100,
		},
		xl: {
			size: 150,
		},
	};
	const { size: finalSize } = sizeMap[size];
	if (center) {
		return (
			<div className="flex-1 w-full flex flex-row justify-content-center">
				<ProgressSpinner
					className={classNames('spinner', `spinner-${size}`, 'mx-auto', {
						'spinner-dark': dark,
						'spinner-light': !dark,
					})}
					style={{
						width: finalSize,
						height: finalSize,
					}}
				/>
			</div>
		);
	}
	return (
		<ProgressSpinner
			className={classNames('spinner', `spinner-${size}`, {
				'spinner-dark': dark,
				'spinner-light': !dark,
			})}
			style={{
				width: finalSize,
				height: finalSize,
			}}
		/>
	);
};
