import React, { Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';
import {
	InputNumber as InputNumberOriginal,
	InputNumberProps as InputNumberPropsOriginal,
} from 'primereact/inputnumber';

type InputNumberProps = InputNumberPropsOriginal & {
	rounded?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	hasError?: any;
};

export const InputNumber = forwardRef(
	({ hasError, rounded, className, ...props }: InputNumberProps, ref: Ref<InputNumberOriginal>) => {
		const finalClassName = classNames('w-full', className, {
			'border-round-3xl px-3': rounded,
			'p-invalid': Boolean(hasError),
		});
		return <InputNumberOriginal ref={ref} className={finalClassName} {...props} />;
	}
);
