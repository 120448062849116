import React, { useState, useEffect, useId } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { InputText } from '../../_new/InputText';

type SingleChoiceEditWrapperProps = {
	initialItems: string[];
	initialLabel: string;
	initialText: string;
	required: boolean;
	hiddenFromUsers: boolean;
	onSave: (items: string[], label: string, text: string, required: boolean, hiddenFromUsers: boolean) => void;
	onCancel: () => void;
};

interface EditFormObject {
	option: string;
	questionLabel: string;
	questionText: string;
	isRequired: boolean;
	hiddenFromUsers: boolean;
}

export const SingleChoiceEditWrapper: React.FC<SingleChoiceEditWrapperProps> = ({
	initialItems,
	initialLabel,
	initialText,
	required,
	hiddenFromUsers,
	onSave,
	onCancel,
}) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<string[]>(initialItems);
	const [editing, setEditing] = useState<number | null>(null);
	const requiredCheckboxId = useId();
	const hiddenFromUsersCheckboxId = useId();
	const { control, handleSubmit, reset, setValue } = useForm<EditFormObject>({
		defaultValues: {
			questionLabel: initialLabel,
			questionText: initialText,
			isRequired: required,
			hiddenFromUsers,
		},
	});

	useEffect(() => {
		setValue('questionLabel', initialLabel);
		setValue('isRequired', required);
		setValue('hiddenFromUsers', hiddenFromUsers);
	}, [initialLabel, required, hiddenFromUsers, setValue]);

	const handleAddOption = (e: React.MouseEvent) => {
		e.preventDefault();
		setItems((prevItems) => [...prevItems, '']);
		setEditing(items.length);
		setValue('option', '');
	};

	const handleEditOption = (index: number) => {
		const option = items[index];
		setEditing(index);
		setValue('option', option);
	};

	const handleDeleteOption = (index: number) => {
		setItems((prevItems) => prevItems.filter((_, i) => i !== index));
		setEditing(null);
	};

	const handleFormSubmit = (data: EditFormObject) => {
		setItems((prevItems) => {
			const updatedItems = [...prevItems];
			if (editing !== null) {
				updatedItems[editing] = data.option.trim();
			}
			return updatedItems;
		});
		setEditing(null);
		setValue('option', '');
	};

	const handleSave = (data: EditFormObject) => {
		onSave(items, data.questionLabel, data.questionText, data.isRequired, data.hiddenFromUsers);
	};

	return (
		<div>
			<div className="field mt-2">
				<Controller
					name="questionText"
					control={control}
					rules={{ required: 'Question text is required' }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="text">
								{t('programPanel.editQuestion.questionContent')}
							</label>
							<InputText {...field} placeholder="Enter question text" />
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			<div className="field mt-2">
				<Controller
					name="questionLabel"
					control={control}
					rules={{ required: 'Question label is required' }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="questionLabel">
								{t('programPanel.editQuestion.fieldPlaceholder')}
							</label>
							<InputText {...field} placeholder="Enter question label" />
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			<div className="mt-3 ml-2">
				<Controller
					name="isRequired"
					control={control}
					render={({ field }) => (
						<Checkbox
							id={requiredCheckboxId}
							{...field}
							inputId="required-checkbox"
							checked={field.value}
							onChange={(e) => setValue('isRequired', e.checked)}
						/>
					)}
				/>
				<label htmlFor={requiredCheckboxId} className="ml-2">
					{t('programPanel.editQuestion.isRequired')}
				</label>
			</div>
			<div className="mt-3 ml-2">
				<Controller
					name="hiddenFromUsers"
					control={control}
					render={({ field }) => (
						<Checkbox
							id={hiddenFromUsersCheckboxId}
							{...field}
							inputId="hiddenFromUsers-checkbox"
							checked={field.value}
							onChange={(e) => setValue('hiddenFromUsers', e.checked)}
						/>
					)}
				/>
				<label className="ml-2 field-label" htmlFor={hiddenFromUsersCheckboxId}>
					{t('programPanel.editQuestion.hiddenFromUsers')}
				</label>
			</div>
			<ul className="list-none mt-3">
				{items.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={`${initialLabel}-${item}-${index}`}>
						{editing === index ? (
							<div className="flex align-items-center gap-2">
								<Controller
									name="option"
									control={control}
									rules={{ required: 'Option is required' }}
									render={({ field, fieldState }) => (
										<>
											<InputText {...field} placeholder="Enter option" />
											{fieldState.error && (
												<small className="p-error">{fieldState.error.message}</small>
											)}

											<Button
												type="button"
												icon="pi pi-check"
												className="p-button-primary p-button-sm"
												onClick={handleSubmit(handleFormSubmit)}
												disabled={!field.value.trim()}
											/>
											<Button
												type="button"
												icon="pi pi-times"
												className="p-button-secondary p-button-sm"
												onClick={() => handleDeleteOption(index)}
											/>
										</>
									)}
								/>
							</div>
						) : (
							<div className="inline-flex align-items-center ml-4">
								<span className="flex justify-center">{item}</span>
								<Button
									className="p-button-text p-button-sm"
									icon="pi pi-pencil text-purple-500"
									onClick={() => handleEditOption(index)}
								/>
								<Button
									className="p-button-text p-button-sm"
									icon="pi pi-trash text-red-500"
									onClick={() => handleDeleteOption(index)}
								/>
							</div>
						)}
					</li>
				))}
			</ul>
			<Button
				className="p-button-text p-button-sm"
				icon="pi pi-plus"
				onClick={handleAddOption}
				disabled={editing !== null}
				label={t('programPanel.editQuestion.addAnswer')}
			/>

			<div className="flex flex-row mt-2">
				<Button label={t('actions.save')} className="p-button p-button-sm" onClick={handleSubmit(handleSave)} />
				<Button
					label={t('actions.cancel')}
					className="p-button p-button-sm p-button-secondary ml-2"
					onClick={onCancel}
				/>
			</div>
		</div>
	);
};
