import { httpClient } from 'api';
import { Application } from 'types/Application';
import { Organization } from 'types/Organization';
import { Program, ProgramWithLanding } from 'types/Program';
import { Question } from 'types/Question';
import { ProgramMembershipPayload } from 'types/payloads/ProgramMembershipPayload';
import { ApplicationPayload } from 'types/payloads/ApplicationPayload';
import { Memberships } from 'types/Memberships';

export function objectToFormData(
	obj: Record<string, any>,
	formDataObj: FormData | null = null,
	namespace = ''
): FormData {
	// eslint-disable-next-line no-param-reassign
	let formData = formDataObj || new FormData();
	// eslint-disable-next-line no-restricted-syntax
	for (const property in obj) {
		if (!Object.prototype.hasOwnProperty.call(obj, property)) {
			// eslint-disable-next-line no-continue
			continue;
		}
		const key = namespace ? `${namespace}[${property}]` : property;
		if (obj[property] instanceof Date) {
			formData.append(key, obj[property].toISOString());
		} else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
			objectToFormData(obj[property], formData, key);
		} else if (Array.isArray(obj[property])) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < obj[property].length; i++) {
				const arrayKey = `${key}[${i}]`;
				if (typeof obj[property][i] === 'object' && !(obj[property][i] instanceof File)) {
					formData = objectToFormData(obj[property][i], formData, arrayKey);
				} else {
					formData.append(arrayKey, obj[property][i]);
				}
			}
		} else {
			formData.append(key, obj[property]);
		}
	}
	return formData;
}

export const landingPagesApi = {
	getOrganization: (organization: string) => httpClient.get<Organization>(`organizations/${organization}`),
	joinOrganization: (organization: string) => httpClient.post(`organizations/${organization}/join`),
	// clearOrganizations: () => httpClient.get('organizations/clear'), // TODO remove, for testing purposes only

	getProgram: (organization: string, program: string) =>
		httpClient.get<Program>(`programs/${organization}/${program}`),

	getProgramWithLanding: (organization: string, program: string) =>
		httpClient.get<ProgramWithLanding>(`programs/${organization}/${program}`, {
			landing: true,
		}),

	joinProgram: (organization: string, program: string) => httpClient.post(`programs/${organization}/${program}/join`),
	// clearPrograms: () => axiosInstance.get('programs/clear'), // TODO remove, for testing purposes only

	getProgramApplications: (programMembershipId: number, payload: ProgramMembershipPayload) =>
		httpClient.get<Application[]>(`programMemberships/${programMembershipId}/applications`, payload),
	cancelProgramApplication: (programMembershipId: number, payload: ProgramMembershipPayload) =>
		httpClient.post<Application[]>(`programMemberships/${programMembershipId}/cancel`, payload),

	postProgramApplications: (programMembershipId: number, payload: ApplicationPayload) => {
		const formData = objectToFormData(payload);
		return httpClient.post(`programMemberships/${programMembershipId}/applications`, formData);
	},

	getFormQuestions: (programId: number, payload: ProgramMembershipPayload) =>
		httpClient.get<Question[]>(`questions/${programId}`, payload),
};
