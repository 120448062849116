import React, { FC, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import Files from 'components/knowledgeDatabase/Files';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { RouterUrlParams } from 'App';
import { capitalizeWord } from 'utils/helpers';
import { PageLoading } from 'components/PageLoading';
import PageMissing from 'components/PageMissing';

const FilesContainer: FC = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams() as RouterUrlParams;

	const { currentApplicationData, currentProgramMembership } = usePanel();
	const programTitle = programName ? capitalizeWord(programName) : undefined;

	const {
		refetch: getFiles,
		data: filesData,
		isLoading,
		isError,
	} = useQuery(['files'], () => userPanelApi.getFiles(currentApplicationData.id, currentProgramMembership.id));

	useEffect(() => {
		getFiles();
	}, [getFiles]);

	if (isError) return <PageMissing />;

	if (isLoading || !filesData) return <PageLoading />;

	return (
		<>
			<Helmet title={t('userPanel.files.header')} titleTemplate={`%s - ${programTitle} | Mentiway`} />
			<div>
				<SubPageTitle title={t('userPanel.files.header')} />
				<Files
					filesData={filesData}
					breadcrumb={{ home: `/panel/${organizationName}/${programName}/${type}/files` }}
				/>
			</div>
		</>
	);
};

export default FilesContainer;
