import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from './_new/Spinner';

type PageLoadingProps = {
	text?: string;
};

export const PageLoading = ({ text }: PageLoadingProps) => {
	const { t } = useTranslation();
	return (
		<div className="w-full h-full flex justify-content-center align-items-center">
			<div className="flex flex-column gap-2 align-items-center text-center">
				<Spinner size="lg" />
				<span>{text || t('misc.loading')}</span>
			</div>
		</div>
	);
};
