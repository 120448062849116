import React, { useState, useCallback } from 'react';
import { Button } from 'components/_new/Button';

interface RatingProps {
	value?: number;
	onChange: (value: number) => void;
	unselectable?: boolean;
}

export const Rating = ({ value, onChange, unselectable = false }: RatingProps) => {
	const [hover, setHover] = useState<number>(0);

	const faces = ['tired', 'frown', 'meh', 'smile', 'grin-stars'];

	const handleRatingClick = useCallback(
		(index: number) => {
			if (unselectable && index === value) {
				if (onChange) {
					onChange(0);
				}
			} else if (onChange) {
				onChange(index + 1);
			}
			setHover(0);
		},
		[onChange, unselectable, value]
	);

	return (
		<div className="flex flex-row gap-2 justify-content-between">
			{faces.map((face, index) => {
				const actualIndex = index + 1;
				const variant = actualIndex === (value || hover) ? 'primary-text' : 'grey-text';
				return (
					<Button
						key={face}
						onClick={() => {
							handleRatingClick(index);
						}}
						variant={variant}
						unactive
						label={face}
						noTitle
						icon={`face-${face} fa-2x`}
						iconStyle="regular"
						iconOnly
						variantSize="sm"
						onMouseEnter={() => {
							setHover(actualIndex);
						}}
						onMouseLeave={() => {
							setHover(0);
						}}
						rounded
					/>
				);
			})}
		</div>
	);
};
