/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useState, Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { ModuleType, ModuleTypes } from 'types/Contract';
import { dateFormat } from 'utils/dateFormat';
import { ContractPayload } from 'types/payloads/ContractPayload';
import SigningField from 'components/_common/panel/SigningField';
import { usePair } from 'contexts/pairContext';
import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { Box, BoxSection } from 'components/_new/Box';
import { userDisplayName } from 'utils/userDisplayName';
import { useAuth } from 'contexts/authContext';
import { MessageBox } from 'components/_new/MessageBox';
import { TextModule } from './components/TextModule';
import { EditTextModule } from './components/EditTextModule';
import HarmonogramModule from './components/HarmonogramModule';
import ContractContextProvider from './ContractContext';
import GuideToMeetingsModule from './components/GuideToMeetingsModule';
import ContractGoalsModule from './components/GoalsModule';
import { ContractModuleBox } from './components/ContractModuleBox';

export const Contract = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { currentUser } = useAuth();
	const { currentProgramMembership, currentApplicationData, panelType, userPairs, todosRefetch } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();
	const {
		data: contractData,
		isLoading: contractLoading,
		isError: contractError,
		refetch: getContract,
	} = useQuery(['contract', pairId], () => userPanelApi.getContract(pairId, currentProgramMembership.id), {
		onSuccess: () => getGoals(),
	});

	const {
		data: goalsData,
		isLoading: goalLoading,
		isError: goalError,
		refetch: getGoals,
	} = useQuery(['goals', pairId], () => userPanelApi.getGoals(pairId, currentProgramMembership.id));

	useEffect(() => {
		getContract();
		getGoals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const checkForEmptyFields = () => {
	// 	let isAnyFieldEmpty = false;
	// 	if (contractData) {
	// 		contractData.modules.forEach((module) => {
	// 			const {
	// 				setting: { moduleType },
	// 				value,
	// 			} = module;
	// 			if (moduleType === ModuleTypes.editText && !value) {
	// 				isAnyFieldEmpty = true;
	// 			}
	// 			if (moduleType === ModuleTypes.goals && !goalsData) {
	// 				isAnyFieldEmpty = true;
	// 			}
	// 		});
	// 	}
	// 	return isAnyFieldEmpty;
	// };

	const signContract = async () => {
		if (editedModule) {
			setIncomingEditedModule(0);
			setConfirmChangeEditedModuleDialogOpen(true);
		} else {
			// const previousContractData = JSON.stringify(contractData);
			// const newData = await userPanelApi.getContract(+pairId, currentProgramMembership.id);
			// if (previousContractData === JSON.stringify(newData)) {
			setSignValidationError('');
			mutatePostSignContract();
			// } else {
			// 	getContract();
			// 	setSignValidationError('change');
			// }
		}
	};

	const { mutate: mutatePostSignContract } = useMutation(
		() => userPanelApi.signContract(+pairId, currentProgramMembership.id),
		{
			onSuccess: () => {
				getContract();
				todosRefetch();
				queryClient.refetchQueries({ queryKey: ['sessions', { pairId }] });
			},
		}
	);

	const [signValidationError, setSignValidationError] = useState<'empty' | ''>('');
	const {
		// mutate: mutatePostContractData,
		mutateAsync: mutatePostContractData,
		variables: postContractVariables,
		isLoading: postContractLoading,
	} = useMutation(
		(payload: ContractPayload) => userPanelApi.postContract(+pairId, currentProgramMembership.id, payload),
		{
			onSuccess: () => {
				getContract();
			},
		}
	);

	const [editedModule, setEditedModule] = useState<number | null>(null);
	const [incomingEditedModule, setIncomingEditedModule] = useState<number | null>(null);
	const [hasChanges, setHasChanges] = useState(false);
	const handleEdit = useCallback(
		(module: ModuleType, moduleHasChanges?: boolean) => {
			if (editedModule && editedModule !== module.id && hasChanges === true) {
				setIncomingEditedModule(module.id);
				setConfirmChangeEditedModuleDialogOpen(true);
			} else {
				setEditedModule(module.id);
				setHasChanges(Boolean(moduleHasChanges));
				return true;
			}
			return false;
		},
		[editedModule, hasChanges]
	);

	const handleCancel = useCallback(
		(module: ModuleType) => {
			if (editedModule) {
				setEditedModule(null);
				setHasChanges(false);
			}
		},
		[editedModule]
	);

	const handleSave = useCallback(
		(module: ModuleType, value: any) =>
			mutatePostContractData({ id: module.id, value })
				.then(() => {
					getContract();
					setEditedModule(null);
					setHasChanges(false);
					return true;
				})
				.catch(() => false),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const sortedModules =
		contractData?.modules.sort((a, b) => (a.setting.priority > b.setting.priority ? 1 : -1)) || [];

	const moduleRefs = useRef<Record<number, any>>({});
	const renderModule = (module: ModuleType, i: number) => {
		const prevModule = sortedModules[i - 1];
		const nextModule = sortedModules[i + 1];
		const hasSimilarPrevModule = prevModule && prevModule.setting.moduleType === module.setting.moduleType;
		const hasSimilarNextModule = nextModule && nextModule.setting.moduleType === module.setting.moduleType;
		const mergeLayout = (() => {
			if (hasSimilarPrevModule && hasSimilarNextModule) {
				return 'both';
			}
			if (hasSimilarPrevModule && !hasSimilarNextModule) {
				return 'top';
			}
			if (!hasSimilarPrevModule && hasSimilarNextModule) {
				return 'bottom';
			}
			return undefined;
		})();
		if (contractData) {
			switch (module.setting.moduleType) {
				case ModuleTypes.text:
					return <TextModule module={module} mergeLayout={mergeLayout} contentInsideHeader />;
				case ModuleTypes.editText:
					return (
						<EditTextModule
							onInit={(ref) => {
								moduleRefs.current[module.id] = ref;
							}}
							module={module}
							onEdit={handleEdit}
							editMode={editedModule === module.id}
							onCancel={handleCancel}
							onSave={handleSave}
							loading={postContractLoading && postContractVariables?.id === module?.id}
						/>
					);
				case ModuleTypes.meetingSettings:
					return (
						<HarmonogramModule
							onInit={(ref) => {
								moduleRefs.current[module.id] = ref;
							}}
							module={module}
						/>
					);
				case ModuleTypes.meetings:
					return (
						<GuideToMeetingsModule
							onInit={(ref) => {
								moduleRefs.current[module.id] = ref;
							}}
							pairId={pairId}
							contractData={contractData}
							module={module}
							onSave={() => {
								return new Promise((resolve) => {
									getContract();
									setEditedModule(null);
									setHasChanges(false);
									resolve(true);
								});
							}}
							onEdit={handleEdit}
							onCancel={handleCancel}
							editMode={editedModule === module.id}
						/>
					);
				case ModuleTypes.goals:
					if (goalsData) {
						return (
							<ContractGoalsModule
								onInit={(ref) => {
									moduleRefs.current[module.id] = ref;
								}}
								module={module}
								goals={goalsData.goals}
								getContract={getContract}
								pairId={pairId}
								onSave={() => {
									return new Promise((resolve) => {
										getContract();
										setEditedModule(null);
										setHasChanges(false);
										resolve(true);
									});
								}}
								onEdit={handleEdit}
								onCancel={handleCancel}
								editMode={editedModule === module.id}
							/>
						);
					}
					return null;
				default:
					return null;
			}
		}
		return null;
	};

	const findLastInitialSign = () => {
		const menteeSign = contractData?.initialSignDateMentee && contractData?.initialSignDateMentee;
		const mentorSign = contractData?.initialSignDateMentor && contractData?.initialSignDateMentor;
		if (menteeSign && mentorSign) {
			const furtherDate = menteeSign >= mentorSign ? menteeSign : mentorSign;
			return dateFormat(new Date(furtherDate), 'full');
		}
		return null;
	};

	const pairData = (userPairs || []).find((pair) => pair.id === +pairId);
	const pairUser = pairData?.application.programMembership.user;
	const pairFullName = pairUser && userDisplayName(pairUser);
	const userFullName = currentUser && userDisplayName(currentUser);

	const [confirmChangeEditedModuleDialogOpen, setConfirmChangeEditedModuleDialogOpen] = useState(false);

	const lastModulePriority = sortedModules[sortedModules.length - 1]?.setting?.priority || 1;

	const handleAcceptConfirmModal = useCallback(() => {
		const moduleRef = editedModule && moduleRefs.current[editedModule];
		if (moduleRef) {
			moduleRef?.save();
		} else {
			setEditedModule(incomingEditedModule);
			setConfirmChangeEditedModuleDialogOpen(false);
			setIncomingEditedModule(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moduleRefs, editedModule, incomingEditedModule]);

	if (!currentUser || !pairUser) {
		return null;
	}

	return (
		<ContractContextProvider value={{ pairId }}>
			<CustomConfirmDialog
				confirmationOpen={confirmChangeEditedModuleDialogOpen}
				setConfirmationOpen={setConfirmChangeEditedModuleDialogOpen}
				handleAccept={handleAcceptConfirmModal}
				handleReject={() => {
					// saveOrCancelEdit(newValue, false, moduleId);
					setConfirmChangeEditedModuleDialogOpen(false);
				}}
				message={t('userPanel.contract.unsavedChanges')}
			/>
			<SubPageTitle title={t('userPanel.contract.header')} />
			<Box variant="white-bordered">
				<BoxSection>
					<div className="flex flex-column gap-4">
						<div>
							{contractData?.finalSignDateMentee && contractData?.finalSignDateMentor ? (
								<div className="flex flex-column gap-1 p-4 strong">
									<div>
										{t('userPanel.contract.signDate')} {findLastInitialSign()}
									</div>
									<div>
										{t('userPanel.contract.editDate')}{' '}
										{contractData?.lastSignedDate
											? dateFormat(contractData?.lastSignedDate, 'full')
											: '-'}
									</div>
								</div>
							) : (
								<MessageBox
									icon="info-circle"
									iconSet="pi"
									variant="purple"
									message={t('userPanel.contract.fillAndSign', {
										type: panelType === 'mentor' ? t('misc.withMentor') : 'Mentee',
									})}
								/>
							)}
						</div>
						<div className="flex flex-row gap-4 flex-wrap">
							<div className="flex-1 rounded-xl bg-new-gray p-4 flex flex-column gap-1">
								<div className="strong">{panelType === 'mentor' ? 'Mentee' : 'Mentor(-ka)'}:</div>
								<div className="flex flex-column text-purplish-blue">
									<div className="strong">{pairFullName}</div>
									<div>{pairUser.email}</div>
								</div>
							</div>
							<div className="flex-1 rounded-xl bg-new-gray p-4 flex flex-column gap-1">
								<div className="strong">
									{panelType === 'mentor' ? t('userPanel.contract.mentor') : 'Mentee'}:
								</div>
								<div className="flex flex-column text-purplish-blue">
									<div className="strong">{userFullName}</div>
									<div>{currentUser.email}</div>
								</div>
							</div>
						</div>
						<div>
							{sortedModules.map((module, i) => (
								<Fragment key={module.id}>{renderModule(module, i)}</Fragment>
							))}

							<ContractModuleBox
								num={lastModulePriority + 1}
								title={t('userPanel.contract.contractSignedBy')}
							>
								<SigningField
									signValidationError={signValidationError}
									signContract={signContract}
									pairFullName={pairFullName}
									userFullName={userFullName}
									menteeSign={contractData?.finalSignDateMentee}
									mentorSign={contractData?.finalSignDateMentor}
									panelType={panelType}
								/>
							</ContractModuleBox>
						</div>
					</div>
				</BoxSection>
			</Box>
		</ContractContextProvider>
	);
};
