import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { authApi } from 'api';
import { SendResetPasswordPayload } from 'types/payloads/SendResetPasswordPayload';
import { Button } from 'primereact/button';
import TextField from 'components/_common/forms/TextField';

const PasswordReset: FC = () => {
	const { t } = useTranslation();

	const { control, handleSubmit, setError } = useForm<SendResetPasswordPayload>({
		defaultValues: {
			email: '',
		},
	});

	const {
		mutate,
		isSuccess,
		isLoading: isSubmitting,
	} = useMutation((data: SendResetPasswordPayload) => authApi.sendResetPassword(data), {
		onError: ({ response }) => {
			setError('email', { type: 'custom', message: response.data.message });
		},
	});

	if (isSuccess) {
		return (
			<>
				<div>{t('auth.resetPasswordEmail')}</div>
				<div>{t('auth.resetPasswordCloseTooltip')}</div>
			</>
		);
	}

	return (
		<>
			<Helmet title={t('auth.resetPasswordTitle')} />
			<form onSubmit={handleSubmit((data) => mutate(data))} className="flex flex-column row-gap-4">
				<span className="text-sm">{t('auth.resetPasswordTooltip')}</span>

				<TextField name="email" placeholder={t('auth.inputs.email')} control={control} required />

				<Button
					type="submit"
					label={t('auth.buttons.sendLink')}
					style={{ display: 'unset' }}
					loading={isSubmitting}
				/>
			</form>
		</>
	);
};

export default PasswordReset;
