import { httpClient } from 'api';
import { Program } from 'types/Program';
import { Survey } from 'types/Survey';

export const surveyApi = {
	getSurveyByUuid: (uuid: string) => httpClient.get<{ survey: Survey; program: Program }>(`survey/uuid/${uuid}`),
	getSurveyForProgramMembership: (programMembership: number) =>
		httpClient.get<Survey>(`survey/programMembership/${programMembership}`),
	updateSurveyByUuid: (uuid: string, data: { step: number; answers: Array<any> }) =>
		httpClient.put<Survey>(`survey/uuid/${uuid}`, data),
	updateSurveyForProgramMembership: (programMembership: number, data: { step: number; answers: Array<any> }) =>
		httpClient.put<Survey>(`survey/programMembership/${programMembership}`, data),
	checkSurveyPopupForProgramMembership: (programMembership: number) =>
		httpClient.get<boolean>(`survey/popup/${programMembership}`),
};
