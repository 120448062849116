import React, { Ref, forwardRef, useState, useEffect } from 'react';
import { IconType, classNames } from 'primereact/utils';
import { SelectItem } from 'primereact/selectitem';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import { getInputBaseClassName } from '../InputBase';
import './Dropdown.scss';

type DropdownMultiGroupItem = {
	title: string;
	options: DropdownMultiItem[] | string[];
};

type DropdownMultiItem = {
	label: string;
	value: string | number;
	className?: string;
	icon?: IconType<SelectItem>;
	title?: string;
	disabled?: boolean;
};

type DropdownMultiProps = Omit<MultiSelectProps, 'options' | 'dropdownIcon'> & {
	options?: DropdownMultiGroupItem[] | DropdownMultiItem[];
	inline?: boolean;
};

export const DropdownMulti = forwardRef(
	(
		{
			className,
			value,
			onChange,
			defaultValue,
			inline,
			optionGroupLabel = 'title',
			optionGroupChildren = 'options',
			optionLabel = 'label',
			optionValue = 'value',
			multiple = false,
			...restProps
		}: DropdownMultiProps,
		ref: Ref<MultiSelect>
	) => {
		const [currentValue, setCurrentValue] = useState<string[] | number[] | null>(
			typeof value === 'undefined' ? defaultValue : value
		);

		useEffect(() => {
			if (value !== currentValue) {
				setCurrentValue(value);
			}
		}, [value, currentValue]);

		return (
			<MultiSelect
				ref={ref}
				value={currentValue}
				defaultValue={defaultValue}
				onChange={(event) => {
					event.preventDefault();
					if (onChange) {
						onChange(event);
					} else {
						setCurrentValue(event.value);
					}
				}}
				className={classNames(getInputBaseClassName({ inline }), 'dropdown', className)}
				dropdownIcon="fa fa-solid fa-chevron-down"
				panelClassName="dropdown-panel"
				optionGroupLabel={optionGroupLabel}
				optionGroupChildren={optionGroupChildren}
				optionLabel={optionLabel}
				optionValue={optionValue}
				multiple={multiple}
				// value
				{...restProps}
			/>
		);
	}
);
