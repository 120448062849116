/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { programsApi } from 'api';
import { useProgramPanel } from 'contexts/programPanelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { PageLoading } from 'components/PageLoading';
import PageMissing from 'components/PageMissing';
import ProgramKnowledgeFiles from './knowledge/ProgramKnowledgeFiles';

export const ProgramKnowledgeBrowser = () => {
	const { t } = useTranslation();
	const { data: program, isSuccess } = useProgramPanel();

	const { data, isError, refetch, isLoading } = useQuery(
		['programKnowledgeFiles', program?.id],
		() => programsApi.getProgramKnowledgeFiles(Number(program?.id)),
		{
			enabled: isSuccess,
			refetchOnWindowFocus: true,
		}
	);

	return (
		<>
			<Helmet title={t('organizationPanel.knowledgeFiles')} />
			<SubPageTitle
				title={t('organizationPanel.knowledgeFiles')}
				additional={program?.displayName}
				right={() => (
					<Link to={`/program/${program?.name}/knowledge/add`} className="text-purplish-blue">
						{t('programPanel.knowledge.addNewFile')}
					</Link>
				)}
			/>
			{isError ? (
				<PageMissing />
			) : isLoading || !data ? (
				<PageLoading />
			) : (
				<ProgramKnowledgeFiles
					filesData={data}
					refetch={refetch}
					breadcrumb={{ home: `/program/${program?.name}/knowledge` }}
				/>
			)}
		</>
	);
};
