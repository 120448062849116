import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { programsApi } from 'api';
import { useProgramPanel } from 'contexts/programPanelContext';
import { UpdateProgramStatusParametersPayload } from 'types/Program';
import { Panel, PanelItem } from '../../partials/panel';

type ProgramStatusForm = {
	displayName: string;
	finished: boolean;
	endDate?: Date;
};

export const ProgramStatus = () => {
	const { t } = useTranslation();
	const { data: programData, refetch: programRefetch } = useProgramPanel();

	const editModeState = useState(false);
	const form = useForm<ProgramStatusForm>({
		defaultValues: {
			displayName: programData?.displayName,
			finished: programData?.finished || false,
			endDate: programData?.endDate || undefined,
		},
	});

	const { mutateAsync, isLoading } = useMutation(
		(data: UpdateProgramStatusParametersPayload) =>
			programsApi.putProgramAdminStatusParameters(Number(programData?.id), data),
		{
			onSuccess: () => {
				programRefetch?.();
			},
		}
	);

	const handleSave = ({ displayName, finished, endDate: inputEndDate }: ProgramStatusForm) => {
		const endDate = inputEndDate ? new Date(inputEndDate).toISOString().split('T')[0] : null;
		mutateAsync({ displayName, finished, endDate }).then(() => {
			editModeState[1](false);
		});
	};

	const programUrl = `${process.env.REACT_APP_FRONT_URL}/${programData?.name}`;

	return (
		<Panel
			title={t('programPanel.dashboard.status.titleInConfig')}
			form={form}
			editModeState={editModeState}
			isLoading={isLoading}
			onSave={handleSave}
		>
			<>
				<PanelItem
					title={t('programPanel.dashboard.status.programNameDescription', {
						program: programData?.displayName,
						organization: programData?.organization?.displayName,
					})}
					value={programData?.organization?.displayName}
					controller={{ control: form.control, name: 'displayName' }}
					transformValue={() => ''}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programIsActive')}
					type="boolean"
					value={!programData?.finished}
					controller={{ control: form.control, name: 'finished' }}
					transformValue="active/unactive"
					inputCheckboxProps={{ invertValue: true }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programUrlAddress')}
					value={programUrl}
					transformValue={({ value }) => (
						<a href={value} target="_blank" rel="noreferrer" className=" underline">
							{value}
						</a>
					)}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.endDate')}
					value={programData?.endDate}
					type="date"
					controller={{ control: form.control, name: 'endDate' }}
					clearable
					transformValue="date-only"
					withEmptyText
					bold
				/>
			</>
		</Panel>
	);
};
