import React, { Fragment, ReactNode } from 'react';
import { classNames } from 'primereact/utils';
import { Divider, DividerProps } from './Divider';

export type DividerListProps = DividerProps & {
	children: ReactNode[];
};

export const DividerList = ({ children, className, ...dividerProps }: DividerListProps) => {
	const { length } = children;
	return (
		<div className={classNames('flex flex-column gap-2', className)}>
			{children.map((node, index) => {
				const isLast = index + 1 === length;
				return (
					// eslint-disable-next-line react/react-in-jsx-scope, react/no-array-index-key
					<Fragment key={index}>
						{node}
						{!isLast && <Divider {...dividerProps} />}
					</Fragment>
				);
			})}
		</div>
	);
};
