import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import slugify from 'slugify';
import { programsApi } from 'api';
import ValidateTextField from 'components/_common/forms/TextField';
import ValidateDropdown from 'components/_common/forms/ValidateDropdown';
import { Box, BoxSection } from 'components/_new/Box';
import { Button } from 'components/_new/Button';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { FileTypeEnum, MaterialCategoryEnum } from 'types/File';
import { useGlobal } from 'contexts/globalContext';
import { MarkdownEditor } from 'components/_common/MarkdownEditor/MarkdownEditor';
import { FileUpload } from 'primereact/fileupload';

export const ProgramKnowledgeEdit = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
	const fileId = Number(params.id);
	const { data: program } = useProgramPanel();
	const { toastRef } = useGlobal();

	const { control, watch, handleSubmit, reset, setValue, getValues } = useForm<{
		name: string;
		directory: string;
		materialCategory: string;
		type: string;
		url: string;
		content: string;
		recipients: string;
		sessionType: string;
		sessionNumber: string | number;
		file: File | null;
	}>({
		defaultValues: {
			name: '',
			directory: '',
			materialCategory: '',
			type: '',
			url: '',
			content: '',
			recipients: 'all',
			sessionType: 'any',
			sessionNumber: 'any',
			file: null,
		},
	});

	const categoryOptions = Object.keys(MaterialCategoryEnum).map((categoryString) => ({
		label: t(`userPanel.userFiles.file.categoryOptions.${categoryString.toLocaleLowerCase()}`),
		value: categoryString.toLowerCase(),
	}));

	const typeOptions = Object.keys(FileTypeEnum)
		.filter((typeString) => typeString !== 'HTML')
		.map((typeString) => ({
			label: t(`userPanel.userFiles.file.typeOptions.${typeString.toLocaleLowerCase()}`),
			value: typeString.toLocaleLowerCase(),
		}));
	const type = watch('type');
	const isLink = [String(FileTypeEnum.VIDEO), String(FileTypeEnum.LINK)]
		.map((v) => String(v).toLowerCase())
		.includes(type);
	// const isHtml = type.toLocaleLowerCase() === String(FileTypeEnum.HTML).toLowerCase();
	// const isHtmlWyswig = type.toLocaleLowerCase() === String(FileTypeEnum.HTML_WYSWIG).toLowerCase();

	const { data: fileData } = useQuery(
		['knowledge-file', { id: Number(fileId) }],
		() => programsApi.getProgramKnowledgeFile(Number(program?.id), Number(fileId)),
		{
			enabled: Boolean(fileId),
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const prepareRecipients = () => {
					if (data.menteeAccess && data.mentorAccess) {
						return 'all';
					}
					if (data.menteeAccess && !data.mentorAccess) {
						return 'mentees';
					}
					if (data.mentorAccess && !data.menteeAccess) {
						return 'mentors';
					}
					return '';
				};
				reset({
					name: data.name,
					directory: data.directory,
					type: data.type,
					materialCategory: data.materialCategory,
					url: data.url || '',
					sessionNumber:
						data.sessionNumber && Number(data.sessionNumber) > 0 ? Number(data.sessionNumber) : 'any',
					sessionType: data.sessionType ? String(data.sessionType) : 'any',
					recipients: prepareRecipients(),
					content: ['file', 'pdf', 'video', 'html_wyswig'].includes(data.type)
						? String(data.description || '')
						: '',
				});
				if (['html_wyswig', 'file', 'video', 'pdf'].includes(data.type)) {
					setValue('content', data.description || '');
					setContentDefault(data.description || '');
				}
			},
		}
	);

	const [contentDefault, setContentDefault] = useState('');
	const { data: fileDataBlob } = useQuery(
		['knowledge-file-blob', { id: Number(fileId) }],
		() => programsApi.getProgramKnowledgeFileBlob(Number(program?.id), Number(fileId)),
		{
			enabled: Boolean(fileId),
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				if (fileData && data) {
					if (['html_wyswig', 'file', 'video', 'pdf'].includes(fileData.type)) {
						setValue('content', fileData.description || '');
						setContentDefault(fileData.description || '');
					} else {
						setValue('content', data);
						setContentDefault(data);
					}
				}
			},
		}
	);

	const { data: paths } = useQuery(
		['nowledge-paths', { programId: Number(program?.id) }],
		() => programsApi.getProgramKnowledgePaths(Number(program?.id)),
		{
			enabled: Boolean(program?.id),
			refetchOnWindowFocus: false,
			initialData: [],
			onSuccess: (paths) => {
				if (getValues('directory') === '') {
					setValue('directory', paths.length > 0 ? paths[0] : '');
				}
			},
		}
	);

	const { mutate, isLoading } = useMutation(
		(data: any) => programsApi.editProgramKnowledgeFile(Number(program?.id), Number(fileId), data),
		{
			onSuccess: (file) => {
				if (program && file) {
					const slug = String(`${slugify(file.directory)}/${slugify(file.name)}`).toLowerCase();
					navigate(`/program/${program.name}/knowledge/browse/${slug}`);
					toastRef?.current?.show({
						severity: 'success',
						life: 3000,
						summary: t('misc.success'),
						detail: t('programPanel.knowledge.updatedSuccessfully'),
					});
				}
			},
		}
	);

	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});

	return (
		<>
			<Helmet title={t('organizationPanel.knowledgeFilesAdd')} />
			<SubPageTitle title={t('organizationPanel.knowledgeFilesAdd')} additional={program?.displayName} />
			<Box variant="white-bordered">
				<BoxSection>
					<form onSubmit={handleSubmitForm} className="flex flex-column gap-2">
						<ValidateTextField
							control={control}
							name="name"
							label={t('programPanel.knowledge.name')}
							required
						/>
						<Controller
							name="directory"
							control={control}
							render={({ field: { value, onChange } }) => (
								<>
									{paths.includes(value) && (
										<ValidateDropdown
											name="directory"
											label={t('programPanel.knowledge.folder')}
											control={control}
											options={[
												{ label: 'Inny', value: '' },
												...paths.map((path) => ({ label: path, value: path })),
											]}
											required
										/>
									)}
									{!paths.includes(value) && (
										<div className="flex flex-row gap-1 w-full align-items-end">
											<ValidateTextField
												className="flex-1"
												name="directory"
												label={t('programPanel.knowledge.folder')}
												control={control}
											/>
											{paths.length > 0 && (
												<Button
													submit
													onClick={() => {
														if (paths.length > 0) {
															onChange(paths[0]);
														}
													}}
													className="p-button-danger p-button-rounded"
													variantSize="sm"
													label={t('actions.cancel')}
												/>
											)}
										</div>
									)}
								</>
							)}
						/>
						<ValidateDropdown
							name="recipients"
							label={t('programPanel.recipients')}
							control={control}
							options={[
								{
									label: t('programPanel.recipientOptions.all'),
									value: 'all',
								},
								{
									label: t('programPanel.recipientOptions.mentorsOnly'),
									value: 'mentors',
								},
								{
									label: t('programPanel.recipientOptions.menteesOnly'),
									value: 'mentees',
								},
							]}
							required
						/>
						<ValidateDropdown
							name="sessionType"
							label={t('programPanel.knowledge.toSessionType')}
							control={control}
							options={[
								{
									label: t('misc.any'),
									value: 'any',
								},
								{
									label: t('userPanel.sessions.first.title'),
									value: 'first',
								},
								{
									label: t('userPanel.sessions.second.title'),
									value: 'second',
								},
								{
									label: t('userPanel.sessions.middle.title'),
									value: 'middle',
								},
								{
									label: t('userPanel.sessions.penultimate.title'),
									value: 'penultimate',
								},
								{
									label: t('userPanel.sessions.last.title'),
									value: 'last',
								},
							]}
						/>
						<ValidateDropdown
							name="sessionNumber"
							label={t('programPanel.knowledge.toSessionNumber')}
							control={control}
							options={[
								{
									label: t('misc.any'),
									value: 0,
								},
								...Array(10)
									.fill(null)
									.map((_, i) => i + 1)
									.map((sessionNumber) => ({
										label: t(`userPanel.sessions.sessionNo`, {
											no: sessionNumber,
										}),
										value: sessionNumber,
									})),
							]}
						/>
						<ValidateDropdown
							control={control}
							name="materialCategory"
							label={t('programPanel.knowledge.category')}
							options={categoryOptions}
							required
						/>
						<ValidateDropdown
							control={control}
							name="type"
							label={t('programPanel.knowledge.materialType')}
							options={typeOptions}
							required
						/>
						{isLink && <ValidateTextField control={control} name="url" label="Link" required={isLink} />}

						{['pdf', 'file'].includes(type) && (
							<Controller
								name="file"
								control={control}
								// rules={{
								// 	required: {
								// 		value: ['pdf', 'file'].includes(type),
								// 		message: t('userPanel.userFiles.file.fileRequired'),
								// 	},
								// }}
								render={({ field: { value, onChange }, fieldState: { error } }) => {
									const accept = (
										type === 'pdf'
											? ['application/pdf']
											: [
													'application/vnd.ms-excel',
													'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
													'application/vnd.ms-powerpoint',
													'application/vnd.openxmlformats-officedocument.presentationml.presentation',
													'application/msword',
													'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
													'application/vnd.oasis.opendocument.presentation',
													'application/vnd.oasis.opendocument.spreadsheet',
													'application/vnd.oasis.opendocument.text',
											  ]
									).join(',');
									return (
										<>
											{!value && (
												<>
													{fileData?.storageUrl && fileData?.originalFilename && (
														<div>
															{t('userPanel.userFiles.file.originalFilename')}:{' '}
															{fileData.originalFilename || fileData.storageUrl || '-'}
														</div>
													)}
													<FileUpload
														mode="basic"
														accept={accept}
														customUpload
														onSelect={({ originalEvent: event }) => {
															if (((event?.target as any)?.files?.length || 0) > 0) {
																onChange((event.target as any).files[0]);
															}
														}}
														chooseLabel={t('misc.choose')}
													/>
													{error && (
														<small className="p-error font-bold ml-3">
															{error.message}
														</small>
													)}
												</>
											)}
											{value && (
												<div className="flex gap-4 align-items-center justify-content-start">
													<span>{value.name}</span>
													<Button
														onClick={() => {
															onChange(null);
														}}
														icon="pi pi-times"
														className="p-button-danger p-button-xs"
														label={t('actions.remove')}
													/>
												</div>
											)}
										</>
									);
								}}
							/>
						)}

						{['pdf', 'file', 'video', 'html_wyswig'].includes(type) && (
							<Controller
								control={control}
								name="content"
								render={() => (
									<MarkdownEditor
										defaultValue={contentDefault}
										onChange={(value) => {
											setValue('content', String(value));
										}}
									/>
								)}
								rules={{
									required: {
										value: ['html_wyswig'].includes(type),
										message: t('misc.forms.isRequired'),
									},
								}}
							/>
						)}

						<div className="flex flex-row justify-content-end">
							<Button submit label={t('actions.save')} loading={isLoading} />
						</div>
					</form>
				</BoxSection>
			</Box>
		</>
	);
};
