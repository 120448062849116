import React, { Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';
import { Password as PasswordOriginal, PasswordProps as PasswordPropsOriginal } from 'primereact/password';

type PasswordProps = PasswordPropsOriginal & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	hasError?: any;
};

export const Password = forwardRef(({ hasError, className, ...props }: PasswordProps, ref: Ref<PasswordOriginal>) => {
	const finalClassName = classNames('w-full', className, {
		'p-invalid': Boolean(hasError),
	});
	return <PasswordOriginal ref={ref} className={finalClassName} {...props} />;
});
