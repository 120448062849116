import React from 'react';
import { MessageBox, MessageBoxProps } from 'components/_new/MessageBox';
import { useTranslation } from 'react-i18next';

type TipProps = Omit<MessageBoxProps, 'title' | 'variant'>;

export const Tip = (props: TipProps) => {
	const { t } = useTranslation();
	return <MessageBox variant="purple" title={t('misc.tip')} icon="info-circle" iconSet="pi" {...props} />;
};
