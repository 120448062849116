import { Goal } from './Goal';

export interface ContractType {
	finalSignDateMentor: Date | null;
	finalSignDateMentee: Date | null;
	initialSignDateMentee: Date | null;
	initialSignDateMentor: Date | null;
	lastSignedDate: Date;
	id: number;
	menteeMembershipId: number;
	mentorMembershipId: number;
	modules: ModuleType[];
	sessionTime: number;
	prefContactAndPlace: string | null;
	prefContact: string | null;
	goalSignDateMentee: Date | null;
	goalSignDateMentor: Date | null;
}

export interface ModuleType {
	contractId: number;
	id: number;
	setting: Setting;
	settingId: number;
	value: string | Goal[];
}

export interface Setting {
	header: string;
	id: number;
	moduleType: string;
	priority: number;
	programId: number;
	text?: string;
}

export enum ModuleTypes {
	text = 'text',
	editText = 'edit text',
	meetings = 'meetings',
	meetingSettings = 'meeting settings',
	goals = 'goals',
}

export interface EditedModule {
	moduleId: number;
	initialValue: string;
	newValue: string;
}
