import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useTranslation } from 'react-i18next';
import { statisticsApi } from 'api/statistics';
import { Spinner } from 'components/_new/Spinner';
import { Box, BoxSection } from 'components/_new/Box';

export const Mentor = () => {
	const { t } = useTranslation();

	const { data: program } = useProgramPanel();

	const { data: mentorData, isLoading: mentorIsLoading } = useQuery(
		['programStatistics', { type: 'mentor', programId: Number(program?.id) }],
		() => statisticsApi.getMentor(Number(program?.id)),
		{
			enabled: Boolean(program),
			cacheTime: 10000,
		}
	);

	if (mentorIsLoading || !mentorData) {
		return <Spinner />;
	}

	return (
		<Box title={t('programPanel.statistics.selfAssessmentCompetences')} variant="white-bordered" noPad>
			<BoxSection noPad>
				<table className="p-table stats-table">
					<thead>
						<tr>
							<td>&nbsp;</td>
							<th>{t('programPanel.statistics.constructiveFeedback')}</th>
							<th>{t('programPanel.statistics.communicationSkills')}</th>
							<th>{t('programPanel.statistics.activeListening')}</th>
							<th>{t('programPanel.statistics.askingQuestions')}</th>
							<th>{t('programPanel.statistics.useKnowledge')}</th>
							<th>{t('programPanel.statistics.knowledgeUsefulnessAssessment')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div className="text-lg">
									<b>{t('programPanel.statistics.afterSecondSession')}</b>
								</div>
								<div>
									{t('programPanel.statistics.filled')} {mentorData?.meta.second.filled}{' '}
									<span className="no-break">({mentorData?.meta.second.percent}%)</span>
								</div>
							</td>
							<td>
								{mentorData?.data?.second?.givingFeedback ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.second?.givingFeedback}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.second?.communicationSkils ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.second?.communicationSkils}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.second?.listeningSkills ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.second?.listeningSkills}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.second?.questioningSkills ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.second?.questioningSkills}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.second?.experienceUsage ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.second?.experienceUsage}
										<span className="stats-table-value-max">%</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.second?.newKnowledgeUsability ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.second?.newKnowledgeUsability}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
						</tr>
						<tr>
							<td>
								<div className="text-lg">
									<b>{t('programPanel.statistics.afterMiddleSession')}</b>
								</div>
								<div>
									{t('programPanel.statistics.filled')} {mentorData?.meta.middle.filled}{' '}
									<span className="no-break">({mentorData?.meta.middle.percent}%)</span>
								</div>
							</td>
							<td>
								{mentorData?.data?.middle?.givingFeedback ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.middle?.givingFeedback}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.middle?.communicationSkils ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.middle?.communicationSkils}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.middle?.listeningSkills ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.middle?.listeningSkills}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.middle?.questioningSkills ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.middle?.questioningSkills}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.middle?.experienceUsage ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.middle?.experienceUsage}
										<span className="stats-table-value-max">%</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.middle?.newKnowledgeUsability ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.middle?.newKnowledgeUsability}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
						</tr>
						<tr>
							<td>
								<div className="text-lg">
									<b>{t('programPanel.statistics.afterLastSession')}</b>
								</div>
								<div>
									{t('programPanel.statistics.filled')} {mentorData?.meta.last.filled}{' '}
									<span className="no-break">({mentorData?.meta.last.percent}%)</span>
								</div>
							</td>
							<td>
								{mentorData?.data?.last?.givingFeedback ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.last?.givingFeedback}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.last?.communicationSkils ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.last?.communicationSkils}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.last?.listeningSkills ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.last?.listeningSkills}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.last?.questioningSkills ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.last?.questioningSkills}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.last?.experienceUsage ? (
									<span className="stats-table-value-current text-purplish-blue">
										{mentorData?.data?.last?.experienceUsage}
										<span className="stats-table-value-max">%</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
							<td>
								{mentorData?.data?.last?.newKnowledgeUsability ? (
									<span className="stats-table-value-current text-rose">
										{mentorData?.data?.last?.newKnowledgeUsability}
										<span className="stats-table-value-max">/10</span>
									</span>
								) : (
									<span className="stats-table-bd">{t('misc.noData')}</span>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</BoxSection>
		</Box>
	);
};
