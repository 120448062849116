import React, { ReactNode } from 'react';

import { MenuItemExt, PanelMenu } from 'components/_common/PanelMenu';
import { border, colors } from 'theme';

type NavigationProps = {
	navigationOptions: MenuItemExt[];
	top?: ReactNode | (() => ReactNode);
	bottom?: ReactNode | (() => ReactNode);
};

const Navigation = (props: NavigationProps) => {
	const { navigationOptions, top, bottom } = props;

	const renderTop = (): ReactNode => {
		if (top) {
			if (typeof top === 'function') {
				return top();
			}
			return top;
		}
		return null;
	};

	const renderBottom = (): ReactNode => {
		if (bottom) {
			if (typeof bottom === 'function') {
				return bottom();
			}
			return bottom;
		}
		return null;
	};

	return (
		<div className="hidden md:flex flex-column w-2 pr-3" style={{ minWidth: 240 }}>
			<div className="flex flex-row justify-content-end">{renderTop()}</div>
			<div
				className="flex-1 flex flex-column border-round-right-xl py-3 overflow-y-hidden"
				style={{ background: colors.purplishBlue }}
			>
				<div className="flex-1 flex flex-column overflow-y-auto p-styled-scrollbar p-styled-scrollbar-lightblue">
					<PanelMenu model={navigationOptions} multiple />
					{bottom ? (
						<div className="p-panelmenu">
							<hr
								className="mx-3 mt-6"
								style={{ borderTop: 'none', borderBottom: border(colors.lightGray) }}
							/>
							{renderBottom()}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Navigation;
