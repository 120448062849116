import React from 'react';
import { Outlet } from 'react-router-dom';
import { useOrganizationPanel } from 'contexts/organizationPanelContext';

export const OrganizationGuard = () => {
	const {
		currentOrganization: { isSuccess, isError },
	} = useOrganizationPanel();

	return (
		<>
			{isSuccess && <Outlet />}
			{isError && <div className="text-center">Wystąpił błąd lub nie masz dostępu do tej organizacji</div>}
		</>
	);
};
