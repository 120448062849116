import parseOriginal, { HTMLReactParserOptions } from 'html-react-parser';

type ParseOptions = HTMLReactParserOptions & {
	nl2br?: boolean;
};

// eslint-disable-next-line no-useless-concat
const nl2br = (text: string) => text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');

export const parseHtml = (_html: string, _options?: ParseOptions) => {
	const { nl2br: useNl2Br, ...options } = { nl2br: true, trim: true, ..._options };
	// const html = useNl2Br ? nl2br(_html.replace(/[\r\n]\s*[\r\n]/g, '\n\n')) : _html;
	const html = useNl2Br ? nl2br(_html) : _html;
	return parseOriginal(html, options);
};
