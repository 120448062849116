import React, { useCallback, useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Button, ButtonProps } from '../Button';
import './ButtonGroup.scss';

type ButtonGroupOption = ButtonProps & {
	key: string | number;
	value: string | number | boolean;
};

export type ButtonGroupProps = {
	value?: string | number | boolean | null;
	defaultValue?: string | number | null;
	options: ButtonGroupOption[];
	unselectable?: boolean;
	onChange?: (event: any) => void;
	disabled?: boolean;
	title?: string;
	asBoolean?: boolean;
};

export const ButtonGroup = ({
	value,
	defaultValue,
	options,
	unselectable = false,
	onChange,
	disabled = false,
	title,
	asBoolean = false,
}: ButtonGroupProps) => {
	const [currentValue, setCurrentValue] = useState<string | number | boolean | null>(
		// eslint-disable-next-line no-nested-ternary
		typeof value !== 'undefined' ? value : typeof defaultValue === 'undefined' ? null : defaultValue
	);

	useEffect(() => {
		if (typeof value !== 'undefined' && value !== currentValue) {
			setCurrentValue(value);
		}
	}, [value, currentValue]);

	const handleClick = useCallback(
		(event: any, option: any) => {
			const { value } = option;
			if (unselectable && value === currentValue) {
				setCurrentValue(null);
				if (onChange) {
					onChange({ value: null });
				}
			} else {
				setCurrentValue(value);
				if (onChange) {
					onChange({ value: asBoolean ? Boolean(value) : value });
				}
			}
		},
		[unselectable, currentValue, onChange, asBoolean]
	);

	return (
		<div className={classNames('button-group-container', { 'button-group-container-disabled': disabled })}>
			{options.map(({ key, ...option }) => (
				<Button
					key={key}
					onClick={(event) => handleClick(event, option)}
					{...option}
					unactive={option.value !== currentValue}
					disabled={disabled}
					title={title}
				/>
			))}
		</div>
	);
};
