import React, { ReactNode, Ref, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { universalRenderer } from 'utils/universalRenderer';
import { DialogBase, DialogBaseExtendProps } from './DialogBase';
import { Button } from '../Button';

export type DialogProps = DialogBaseExtendProps & {
	title: string;
	headerRight?: ReactNode | (() => ReactNode);
	cancelAction?: boolean;
	maxHeight?: boolean;
};

export const Dialog = forwardRef(
	({ title, headerRight, onHide, ...restProps }: DialogProps, ref: Ref<HTMLDivElement>) => {
		const { t } = useTranslation();
		const handleCloseButtonClick = useCallback(() => {
			if (onHide) {
				onHide();
			}
		}, [onHide]);

		return (
			<DialogBase
				ref={ref}
				header={
					<div className="flex flex-row gap-4 justify-content-between align-items-center">
						<div>{title}</div>
						<div className="flex flex-row gap-2 align-items-center">
							{headerRight ? (
								<div className="flex flex-row flex-wrap gap-2 align-items-center justify-content-end">
									{universalRenderer(headerRight)}
								</div>
							) : null}
							<Button
								label={t('actions.close')}
								icon="times"
								iconOnly
								onClick={handleCloseButtonClick}
								variantSize="lg"
								variant="grey-text"
								rounded
							/>
						</div>
					</div>
				}
				onHide={onHide}
				{...restProps}
			/>
		);
	}
);
