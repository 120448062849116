import React, { HTMLAttributes, Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';

import './Tag.scss';

type TagVariant = 'grey' | 'blue';

export type TagProps = HTMLAttributes<HTMLSpanElement> & {
	variant?: TagVariant;
};

export const Tag = forwardRef(({ variant = 'blue', className, ...restProps }: TagProps, ref: Ref<HTMLSpanElement>) => {
	const finalClassName = classNames('tag', `tag-variant-${variant}`, className);
	return <span ref={ref} className={finalClassName} {...restProps} />;
});
