import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';
import { MessageBox } from 'components/_new/MessageBox';
import { DividerList } from 'components/_new/Divider';
import { ExpandablePanel } from 'components/_new/ExpandablePanel';
import { InputTextArea } from 'components/_new/InputTextArea';
import { Tag } from 'components/_new/Tag';
import { Icon } from 'components/_new/Icon';
import { useTranslation } from 'react-i18next';

type ReflectionType =
	| 'general'
	| 'thingsEasy'
	| 'techniques'
	| 'barriers'
	| 'thingsHard'
	| 'selfReflection'
	| 'happyFor'
	| 'sadFor'
	| 'nextSteps'
	| 'other';

type ReflectionDialogProps = DialogBaseExtendProps & {
	reflections: Array<{ key: string; title: string; value: string | null }>;
	onSave: (values: any) => void;
	loading?: boolean;
};

type ReflectionForm = {
	[key in ReflectionType]: string;
};

export const ReflectionDialog = ({ reflections, onSave, loading = false, ...restProps }: ReflectionDialogProps) => {
	const { t } = useTranslation();

	const defaultValues: ReflectionForm = useMemo(
		() => Object.fromEntries(reflections.map(({ key, value }) => [key, value || ''])) as ReflectionForm,
		[reflections]
	);

	const { handleSubmit, register, reset } = useForm<ReflectionForm>({ defaultValues });

	useEffect(() => {
		reset(defaultValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues]);

	const handleSubmitForm = handleSubmit((values) => {
		onSave(values);
	});

	return (
		<DialogAction
			title={t('userPanel.sessionsNew.completeReflections')}
			actions={[
				{
					key: 'save',
					label: t('actions.save'),
					loading,
					submit: true,
					form: 'reflection-form',
				},
			]}
			{...restProps}
		>
			<form id="reflection-form" className="flex flex-column gap-4" onSubmit={handleSubmitForm}>
				<MessageBox
					icon="eye"
					title={t('userPanel.sessionsNew.reflection.infoVisibleOnlyForYou')}
					variant="rose"
				/>
				<DividerList>
					{reflections.map(({ key, title, value }) => {
						const hasAnswer = String(value || '')?.length > 0;
						return (
							<ExpandablePanel
								key={key}
								header={
									<div className="flex flex-column justify-content-start md:flex-row md:align-items-center gap-2 flex-wrap">
										<div className="flex-1">
											{!hasAnswer && (
												<Tag
													variant="grey"
													className="inline-flex flex-row gap-2 align-items-center text-nowrap"
												>
													<Icon name="times" />
													<span>{t('userPanel.sessionsNew.reflection.noAnswer')}</span>
												</Tag>
											)}
											{hasAnswer && (
												<Tag
													variant="blue"
													className="inline-flex flex-row gap-2 align-items-center text-nowrap"
												>
													<Icon name="check" />
													<span>{t('userPanel.sessionsNew.reflection.hasAnser')}</span>
												</Tag>
											)}
										</div>
										<div className="flex-3 text-sm">{title}</div>
									</div>
								}
								expandedHeaderClassName="text-purple font-bold"
								expanded={hasAnswer}
							>
								<InputTextArea
									defaultValue={value || ''}
									{...register(key as ReflectionType)}
									placeholder={t('userPanel.sessionsNew.reflection.enterAnswer')}
								/>
							</ExpandablePanel>
						);
					})}
				</DividerList>
			</form>
		</DialogAction>
	);
};
