import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { authApi } from 'api';
import { RouterUrlParams } from 'App';
import { useGlobal } from 'contexts/globalContext';
import { Spinner } from 'components/_new/Spinner';

const Confirm: FC = () => {
	const { t } = useTranslation();
	const { token } = useParams() as RouterUrlParams;
	const { toastRef } = useGlobal();
	const navigate = useNavigate();

	const { mutate, isLoading } = useMutation((token: string) => authApi.confirmRegisterOrganization(token), {
		onSuccess: () => {
			navigate('/login');
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: 'Adres emial został potwierdzony. Możesz się zalogować na swoje konto.',
			});
		},
		onError: () => {
			navigate('/login');
			toastRef?.current?.show({
				severity: 'error',
				life: 3000,
				summary: t('misc.error'),
				detail: 'Link został już wykorzystany do potwierdzenia.',
			});
		},
	});

	useEffect(() => {
		if (token) {
			mutate(token);
		}
	}, [token, mutate]);

	return (
		<>
			<Helmet title={t('auth.confirmEmail')} />
			{isLoading && <Spinner />}
		</>
	);
};

export default Confirm;
