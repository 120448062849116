import React, { Fragment } from 'react';
import { Link } from 'components/_new/Link';

const removeProtocolFromLink = (url: string) => url.replace(/(^\w+:|^)\/\//, '');

type UrlMatch = {
	url: URL | null;
	text: string;
	start: number;
	end: number;
};

function urlDetect(text: string): UrlMatch[] {
	const urlRegex =
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

	return Array.from(text.matchAll(urlRegex)).map((match) => {
		const text = `https://${removeProtocolFromLink(match[0])}`;
		let url = null;
		try {
			url = new URL(text);
		} catch {
			//
		}
		return {
			url,
			text: removeProtocolFromLink(match[0]),
			start: match.index || 0,
			end: (match.index || 0) + match[0].length,
		};
	});
}

type StrPart = {
	key: string;
	url?: URL;
	text: string;
};

export const contentLinkParser = (
	content: string,
	options?: {
		linkClassName?: string;
		allowDomains?: string[];
	}
) => {
	const linkClassName = options?.linkClassName || 'p-link underline';
	const finalAllowDomains = (options?.allowDomains || []).reduce<string[]>((current, item) => {
		if (!item.startsWith('www.')) {
			current.push(`www.${item}`);
		}
		current.push(item);
		return current;
	}, []);

	const urls = urlDetect(content);

	if (urls.length > 0) {
		const parts = urls.reduce<StrPart[]>((current, part, index, source) => {
			const { start, end, text } = part;
			// add previous text
			if (start > 0) {
				const prevEnd = source[index - 1]?.end || 0;
				current.push({ key: `${index}-prev`, text: content.substring(prevEnd, start).trim() });
			}
			// add current link
			if (part.url && (finalAllowDomains?.length === 0 || finalAllowDomains.includes(part.url.hostname))) {
				current.push({ key: `${index}-current`, text, url: part.url });
			} else {
				current.push({ key: `${index}-current`, text });
			}

			// and last text if exists
			const hasNext = Boolean(source[index + 1]);
			if (!hasNext) {
				current.push({ key: `${index}-next`, text: content.substring(end).trim() });
			}
			return current;
		}, []);
		const result = parts.map((part) => {
			if (part.url) {
				return (
					<a key={part.key} href={part.url.href} target="_blank" className={linkClassName} rel="noreferrer">
						{`${part.text} `}
					</a>
				);
			}
			return <Fragment key={part.key}>{`${part.text} `}</Fragment>;
		});
		return <span>{result}</span>;
	}
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{content}</>;
};
