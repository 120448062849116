/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { programsApi } from 'api';
import { UpdateProgramSettingsParametersPayload } from 'types/Program';
import { Link } from 'components/_new/Link';
import { Icon } from 'components/_new/Icon';
import { Panel, PanelItem } from '../../partials/panel';

type ProgramSettingsForm = {
	numberOfSessions: number;
	mentibotEnabled: boolean;
	requireTwoFactorAuth: boolean;
	menteeLabelFileId: number | null;
	mentorLabelFileId: number | null;
	contact: string | null;
};

export const ProgramAdditionalSettings = () => {
	const { t } = useTranslation();

	const { data: programData, refetch: programRefetch } = useProgramPanel();
	const editModeState = useState(false);
	const form = useForm<ProgramSettingsForm>({
		defaultValues: {
			numberOfSessions: programData?.numberOfSessions,
			mentibotEnabled: programData?.mentibotEnabled,
			requireTwoFactorAuth: programData?.requireTwoFactorAuth,
			menteeLabelFileId: programData?.menteeLabelFileId,
			mentorLabelFileId: programData?.mentorLabelFileId,
			contact: programData?.contact || null,
		},
	});

	const { mutateAsync, isLoading } = useMutation(
		(data: UpdateProgramSettingsParametersPayload) =>
			programsApi.putProgramAdminSettingsParameters(Number(programData?.id), data),
		{
			onSuccess: () => {
				programRefetch?.();
			},
		}
	);

	const handleSave = (values: ProgramSettingsForm) => {
		mutateAsync(values).then(() => {
			editModeState[1](false);
		});
	};

	const { data: labelFiles } = useQuery(
		['program-admin-label-files', { programId: Number(programData?.id) }],
		() => programsApi.getProgramAdminLabelFiles({ programId: Number(programData?.id) }),
		{ enabled: true, initialData: [] }
	);

	return (
		<Panel
			title={t('programPanel.dashboard.additionalSettings.title')}
			form={form}
			editModeState={editModeState}
			isLoading={isLoading}
			onSave={handleSave}
		>
			<>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.contractInPair')}
					value={programData?.stats?.contractFieldCount}
					transformValue={({ value, editMode }) => (
						<>
							<span>
								{t('programPanel.dashboard.additionalSettings.contractElements', {
									count: value,
								})}
							</span>
							{editMode && (
								<Link to={`/program/${programData?.name}/contract`}>
									<Icon name="pencil" iconSet="fa" iconStyle="solid" />
								</Link>
							)}
						</>
					)}
					editable
					bold
				/>
			</>
			<>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.numberOfSessions')}
					value={programData?.numberOfSessions}
					type="number"
					controller={{ control: form.control, name: 'numberOfSessions' }}
					inputNumberProps={{ min: 3, max: 12 }}
					bold
				/>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.labelPlaceholderForMentors')}
					value={programData?.mentorLabelFileId}
					type="select"
					controller={{ control: form.control, name: 'mentorLabelFileId' }}
					inputDropdownProps={{
						options: labelFiles.map(({ id, name }) => ({
							value: id,
							label: name,
						})),
						noOptionsText: t('programPanel.dashboard.additionalSettings.labelPlaceholderNoFiles'),
					}}
					withEmptyText="default"
					bold
				/>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.labelPlaceholderForMentees')}
					value={programData?.menteeLabelFileId}
					type="select"
					controller={{ control: form.control, name: 'menteeLabelFileId' }}
					inputDropdownProps={{
						options: labelFiles.map(({ id, name }) => ({
							value: id,
							label: name,
						})),
						noOptionsText: t('programPanel.dashboard.additionalSettings.labelPlaceholderNoFiles'),
					}}
					withEmptyText="default"
					bold
				/>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.programContact')}
					value={programData?.contact && programData?.contact.length > 0}
					transformValue="yes/no"
					editable
				/>
				<PanelItem
					// title={t('programPanel.dashboard.additionalSettings.programContact')}
					type="string-editor"
					value={programData?.contact}
					controller={{ control: form.control, name: 'contact' }}
					visibleOnlyInEditMode
				/>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.mentibotEnabled')}
					value={programData?.mentibotEnabled}
					type="boolean"
					transformValue="active/unactive"
					controller={{ control: form.control, name: 'mentibotEnabled' }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.additionalSettings.requireTwoFactorAuth')}
					value={programData?.requireTwoFactorAuth}
					type="boolean"
					transformValue="yes/no"
					controller={{ control: form.control, name: 'requireTwoFactorAuth' }}
				/>
			</>
		</Panel>
	);
};
