import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Link } from 'react-router-dom';
import { Panel, PanelItem } from '../../partials/panel';

export const ProgramPairs = () => {
	const { t } = useTranslation();

	const { data: programData } = useProgramPanel();

	return (
		<Panel title={t('programPanel.dashboard.pairs.title')}>
			<PanelItem
				title={t('programPanel.dashboard.pairs.pairingEnabled')}
				value={programData?.pairingStatus}
				type="boolean"
				transformValue="on/off"
			/>
			<>
				<PanelItem
					title={t('programPanel.dashboard.pairs.acceptedPairs')}
					value={programData?.pairStats?.programPairAcceptedCount}
					transformValue={({ value }) => (
						<>
							{value}
							<Link
								to={{ pathname: `/program/${programData?.name}/pair/list`, search: '' }}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
					bold
					large
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.mentorsWithoutPair')}
					value={programData?.applicationStats?.mentorsWithoutPairCount}
					transformValue={({ value }) => (
						<>
							{Number(value) > 0 ? <span className="text-danger">{Number(value)}</span> : 0}
							<Link
								to={{
									pathname: `/program/${programData?.name}/application/list`,
									search: '?applicationRole=mentor&advanced=without-pair',
								}}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
					bold
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.menteesWithoutPair')}
					value={programData?.applicationStats?.menteesWithoutPairCount}
					transformValue={({ value }) => (
						<>
							{Number(value) > 0 ? <span className="text-danger">{Number(value)}</span> : 0}
							<Link
								to={{
									pathname: `/program/${programData?.name}/application/list`,
									search: '?applicationRole=mentee&advanced=without-pair',
								}}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
					bold
				/>
			</>
		</Panel>
	);
};
