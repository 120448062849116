import { httpClient } from 'api';
import { ApplicationWithContactInfo } from 'types/Application';
import { Paginated } from 'types/Paginated';
import { Pair } from 'types/Pair';

export const pairsApi = {
	// adminDownloadPairsXML: ({ programId }: { programId: number }) =>
	// 	axiosInstance.get<string>(`pairs/admin/${programId}/xml`),
	adminDownloadPairsXLS: ({ programId }: { programId: number }) =>
		httpClient.get<Blob>(`pairs/admin/${programId}/xls`, undefined, { responseType: 'blob' }),
	adminChangePairStatus: ({ programId, pairId, payload }: { programId: number; pairId: number; payload: string }) =>
		httpClient.put<any>(`pairs/admin/${programId}/pair/${pairId}`, { status: payload }),
	getApplicationAreas: ({ applicationRole, membershipId }: { applicationRole: string; membershipId: number }) =>
		httpClient.get<Array<{ title: string; options: string[] }>>(
			`pairs/browse/application/${applicationRole}/${membershipId}`
		),
	getBrowsePotentialPairs: ({
		applicationRole,
		membershipId,
		filter,
		page,
	}: {
		applicationRole: string;
		membershipId: number;
		filter: any;
		page: number;
	}) =>
		httpClient.get<Paginated<ApplicationWithContactInfo>>(`pairs/browse/list/${applicationRole}/${membershipId}`, {
			page,
			filter,
		}),
	makeContact: ({
		applicationRole,
		membershipId,
		applicationId,
	}: {
		applicationRole: string;
		membershipId: number;
		applicationId: number;
	}) => httpClient.put<Pair>(`pairs/browse/make-contact/${applicationRole}/${membershipId}/${applicationId}`),
};
