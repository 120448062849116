export const findScrollableElements = (element: HTMLElement | null) => {
	const scrollableElements = [];
	if (element) {
		let nextElement = element.parentElement;
		while (nextElement) {
			if (nextElement.scrollHeight > nextElement.clientHeight) {
				scrollableElements.push(nextElement);
			}
			nextElement = nextElement.parentElement;
		}
	}
	return scrollableElements;
};

export const scrollElementToById = (elementId: string, top = 0, offset = 0) => {
	const mainElement = document.getElementById(elementId);
	if (mainElement) {
		mainElement.scrollTo({ top: top + offset });
	}
};

export const scrollToElementById = (elementId: string, offset = 0) => {
	const mainElement = document.getElementById(elementId);
	if (mainElement) {
		const scrollableElements = findScrollableElements(mainElement);
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < scrollableElements.length; i++) {
			const prevElement = scrollableElements[i - 1] || mainElement;
			const scrollableElement = scrollableElements[i];
			scrollableElement.scrollTo({ top: prevElement.offsetTop + offset });
		}
	}
};
