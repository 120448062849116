export interface Goals {
	goals: Goal[];
	goalLastSignedDate: Date;
	goalSignDateMentee: Date;
	goalSignDateMentor: Date;
}
export interface Goal {
	goal: string;
	change: string;
	achievement: string;
	result: string;
	priorities: string;
	id: number;
}

export enum ActionGoalId {
	new = -1,
}

export type GoalFields = 'goal' | 'change' | 'achievement' | 'result' | 'priorities';
