import React, { ReactNode, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { BoxSection, BoxSectionProps } from './BoxSection';
import './Box.scss';

export type BoxSectionListVariant = 'standard' | 'additional-pads' | 'bigger';

export type BoxSectionListProps = Omit<BoxSectionProps, 'children'> & {
	variant?: BoxSectionListVariant;
	itemClassName?: string;
	children?: ReactNode | ReactNode[];
};

export const BoxSectionList = ({
	children,
	variant = 'standard',
	itemClassName,
	className,
	...restProps
}: BoxSectionListProps) => {
	const mappedNodes = useMemo(
		() => (Array.isArray(children) ? children : [children]).map((node, index) => ({ node, key: index })),
		[children]
	);
	return (
		<BoxSection noPad {...restProps}>
			<ul className={classNames('box-section-list', `box-section-list-${variant}`, className)}>
				{mappedNodes.map(({ node, key }) => (
					// eslint-disable-next-line react/self-closing-comp
					<li key={key} className={classNames('box-section-list-item', itemClassName)}>
						{node}
					</li>
				))}
			</ul>
		</BoxSection>
	);
};
