import React, { useState, useEffect } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
// import draftToMarkdown from 'draftjs-to-markdown';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './markdown-editor.scss';
import { useMutation } from '@tanstack/react-query';
import { postsApi } from 'api';
import { useGlobal } from 'contexts/globalContext';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

type MarkdownEditorProps = {
	defaultValue?: string;
	onChange?: (value: string | null) => void;
};

export const MarkdownEditor = ({ defaultValue, onChange }: MarkdownEditorProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();

	const [editorState, setEditorState] = useState<EditorState | undefined>(undefined);
	const handleEditorStateChange = (editorState: EditorState) => {
		if (onChange) {
			const value = editorState && draftToHtml(convertToRaw(editorState.getCurrentContent()));
			onChange(value || null);
		}
		setEditorState(editorState);
	};

	useEffect(() => {
		if (defaultValue !== undefined) {
			const blocksFromHtml = htmlToDraft(defaultValue);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			setEditorState(EditorState.createWithContent(contentState));
		} else {
			setEditorState(EditorState.createEmpty());
		}
	}, [defaultValue]);

	const { mutateAsync: uploadImageMutateAsync } = useMutation(postsApi.uploadImage);
	const uploadCallback = (file: File): Promise<object> => {
		const formData = new FormData();
		formData.append('file', file);

		const maxFileSize = 1024 * 1024 * 3; // 3 mb

		return new Promise((resolve, reject) => {
			if (file.size > maxFileSize) {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: t(`misc.error`),
					detail: t('misc.fileSizeExceeds', { count: 3 }),
				});
				reject(new Error('max file size'));
			}
			uploadImageMutateAsync(formData)
				.then((link) => resolve({ data: { link } }))
				.catch((err) => {
					if (err instanceof AxiosError) {
						const message = err.response?.data.message || err.message;
						switch (message) {
							case 'File too large':
								toastRef?.current?.show({
									severity: 'error',
									life: 3000,
									summary: t(`misc.error`),
									detail: t('misc.fileSizeExceeds', { count: 3 }),
								});
								break;
							case 'Invalid image format':
								toastRef?.current?.show({
									severity: 'error',
									life: 3000,
									summary: t(`misc.error`),
									detail: t('misc.fileInvalidFileType'),
								});
								break;
							default:
						}
					} else {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t(`misc.error`),
							detail: t('misc.fileUnexpectedError'),
						});
					}
					reject(new Error('unexpected upload error'));
				});
		});
	};

	return (
		<div className="p-inputtext p-component border-round-3xl p-0 xxx">
			<Editor
				toolbar={{
					embedded: {
						className: 'test',
						popupClassName: 'popup-upload-in-draft-editor',
					},
					emoji: {
						popupClassName: 'popup-upload-in-draft-editor',
					},
					colorPicker: {
						popupClassName: 'popup-upload-in-draft-editor',
					},
					url: {
						popupClassName: 'popup-upload-in-draft-editor',
					},
					image: {
						uploadCallback,
						previewImage: true,
						alt: { present: true, mandatory: false },
						inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
					},
					link: {
						popupClassName: 'popup-upload-in-draft-editor',
					},
				}}
				uploadCallback={uploadCallback}
				wrapperClassName="editor-wrapper p-full"
				editorClassName="editor px-3"
				toolbarClassName="border-none bg-transparent"
				onEditorStateChange={handleEditorStateChange}
				editorState={editorState}
				wrapperStyle={{ minHeight: '15rem' }}
				editorStyle={{ minHeight: '13rem' }}
				stripPastedStyles
			/>
		</div>
	);
};
