import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { Dialog, DialogBaseExtendProps } from 'components/_new/Dialog';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';
import { MessageBox } from 'components/_new/MessageBox';
import FileDisplayModule from './FileDisplayModule';

type FileDisplayModalProps = Omit<DialogBaseExtendProps, 'title' | 'size'> & {
	selectedFileId: number | null;
};
const FileDisplayModal: FC<FileDisplayModalProps> = ({ selectedFileId, ...dialogProps }) => {
	const { t } = useTranslation();

	const { currentApplicationData, currentProgramMembership } = usePanel();

	const { data: selectedFileData } = useQuery(
		['knowledge-file', { id: selectedFileId }],
		() =>
			userPanelApi.getFile({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: Number(selectedFileId),
			}),
		{
			enabled: Boolean(selectedFileId),
			refetchOnWindowFocus: false,
		}
	);

	return (
		<Dialog title={selectedFileData?.name || ''} size="lg" {...dialogProps}>
			{selectedFileData && (
				<>
					{selectedFileData.menteeAccess && selectedFileData.mentorAccess && (
						<Message
							severity="info"
							text={t('userPanel.files.accessBoth')}
							className="w-full justify-content-start pl-4 mb-4"
						/>
					)}
					{selectedFileData.menteeAccess && !selectedFileData.mentorAccess && (
						<Message
							severity="info"
							text={t('userPanel.files.accessMenteesOnly')}
							className="w-full justify-content-start pl-4 mb-4"
						/>
					)}
					{!selectedFileData.menteeAccess && selectedFileData.mentorAccess && (
						<MessageBox
							icon="info-circle"
							iconSet="pi"
							variant="purple"
							message={t('userPanel.files.accessMentorsOnly')}
						/>
					)}
					<FileDisplayModule selectedFile={selectedFileData} />
				</>
			)}
		</Dialog>
	);
};

export default FileDisplayModal;
