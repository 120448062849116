import React, { Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';
import { InputText as InputTextOriginal, InputTextProps as InputTextPropsOriginal } from 'primereact/inputtext';

type InputTextProps = InputTextPropsOriginal & {
	rounded?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	hasError?: any;
};

export const InputText = forwardRef(
	({ hasError, rounded, className, ...props }: InputTextProps, ref: Ref<InputTextOriginal>) => {
		const finalClassName = classNames('w-full', className, {
			'border-round-3xl px-3': rounded,
			'p-invalid': Boolean(hasError),
		});
		return <InputTextOriginal ref={ref} className={finalClassName} {...props} />;
	}
);
