import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Setting, ModuleTypes } from '../../../types/Contract';
import { parseHtml } from '../../../utils/htmlParser';
import { InputText } from '../../_new/InputText';
import { MarkdownEditor } from '../../_common/MarkdownEditor/MarkdownEditor';
import { Button } from '../../_common/Button';
import './ContractModuleItem.scss';

interface ContractCardProps extends Setting {
	onSwap: (currentIndex: number, swapWithIndex: number) => void;
	index: number;
	canMoveUp: boolean;
	canMoveDown: boolean;
	onDelete: () => void;
	contractModified: boolean;
	onEdit: (editedSetting: Partial<Setting>) => void;
}

const ContractCard: React.FC<ContractCardProps> = ({
	header,
	text,
	moduleType,
	onSwap,
	index,
	canMoveUp,
	canMoveDown,
	onDelete,
	onEdit,
	id,
	programId,
	priority,
	contractModified,
}) => {
	const [isEdited, setIsEdited] = useState(false);
	const [editedSetting, setEditedSetting] = useState<Partial<Setting>>({});
	const { t } = useTranslation();

	const { control, handleSubmit, reset } = useForm<Partial<Setting>>({
		defaultValues: {
			header,
			text,
		},
	});

	const renderCopy = () => {
		switch (moduleType) {
			case ModuleTypes.text:
				return <div> {parseHtml(text || '', { nl2br: false })}</div>;
			case ModuleTypes.editText:
				return (
					<div>
						{parseHtml(text || '', { nl2br: false })}
						<div className="font-italic">{t('programPanel.editContract.editText')}</div>
					</div>
				);
			case ModuleTypes.meetings:
				return <div className="font-italic">{t('programPanel.editContract.meetings')}</div>;
			case ModuleTypes.meetingSettings:
				return <div className="font-italic">{t('programPanel.editContract.meetingSetting')}</div>;
			case ModuleTypes.goals:
				return <div className="font-italic">{t('programPanel.editContract.goals')}</div>;
			default:
				return <div>Nieznany typ modułu</div>;
		}
	};

	const renderEdit = () => {
		switch (moduleType) {
			case ModuleTypes.editText:
			case ModuleTypes.text:
				return (
					<div className="flex-column flex">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="mb-3 mt-2">
								<Controller
									name="header"
									control={control}
									render={({ field }) => <InputText className="mb-2" {...field} />}
								/>
							</div>
							<div style={{ cursor: 'text' }}>
								<Controller
									name="text"
									control={control}
									render={({ field }) => <MarkdownEditor defaultValue={text} {...field} />}
								/>
							</div>
							<div className="flex justify-content-end mt-2">
								<Button
									label={t('actions.cancel')}
									className="p-button p-button-sm p-button-secondary mr-2"
									onClick={() => setIsEdited(false)}
								/>
								<Button label={t('actions.save')} className="p-button p-button-sm " type="submit" />
							</div>
						</form>
					</div>
				);
			case ModuleTypes.meetings:
			case ModuleTypes.meetingSettings:
			case ModuleTypes.goals:
				return (
					<div className="gap-2 flex-column flex mt-4 mb-2">
						<form onSubmit={handleSubmit(onSubmit)}>
							<Controller
								name="header"
								control={control}
								render={({ field }) => <InputText {...field} />}
							/>
							<div className="flex justify-content-end mt-2">
								<Button
									label={t('actions.cancel')}
									className="p-button p-button-sm p-button-secondary mr-2"
									onClick={() => setIsEdited(false)}
								/>
								<Button label={t('actions.save')} className="p-button p-button-sm " type="submit" />
							</div>
						</form>
					</div>
				);
			default:
				return <div>Unsupported module type for editing</div>;
		}
	};
	const onSubmit = (data: Partial<Setting>) => {
		onEdit({ ...data, id, moduleType, priority, programId });
		setIsEdited(false);
	};

	const toggleEditMode = () => {
		setIsEdited(!isEdited);
		reset({
			header,
			text,
		});
	};

	const tooltipText =
		moduleType !== ModuleTypes.editText && moduleType !== ModuleTypes.text
			? t('programPanel.editContract.baseModuleTooltip')
			: t('programPanel.editContract.onePairConfiguredTooltip');

	return (
		<div
			className={classNames(
				'p-module-item-container',
				'flex flex-column b-bottom pl-4 pr-4 pt-2 pb-4  mb-4 border-round-lg'
			)}
		>
			{!isEdited ? (
				<div className="contract-module-box">
					<div
						className="flex flex-row w-full justify-content-between align-items-center pb-2"
						style={{ borderBottom: '1px solid lightGray' }}
					>
						<h3>{header}</h3>
						<div>
							<Button
								className="p-button-text p-button-label text-black-alpha-80 p-button-sm"
								icon="pi pi-pencil text-purple-500"
								onClick={toggleEditMode}
								label={t('actions.edit')}
								size="sm"
							/>
							<Button
								icon="fa-solid fa-up-long"
								className={classNames('p-button-sm p-button-text w-1', {
									'p-button-disabled': !canMoveUp,
									'opacity-full': canMoveUp,
									'opacity-half': !canMoveUp,
								})}
								onClick={() => canMoveUp && onSwap(index, index - 1)}
							/>
							<Button
								icon="fa-solid fa-down-long"
								className={classNames('p-button-sm p-button-text', {
									'p-button-disabled': !canMoveDown,
									'opacity-full': canMoveDown,
									'opacity-half': !canMoveDown,
								})}
								onClick={() => canMoveDown && onSwap(index, index + 1)}
							/>
							<Button
								className="p-button-text p-button-label p-button-danger text-black-alpha-80 p-button-sm"
								icon="pi pi-trash text-pink-500 w-1"
								onClick={onDelete}
								label={t('actions.remove')}
								size="sm"
								disabled={
									contractModified ||
									(moduleType !== ModuleTypes.editText && moduleType !== ModuleTypes.text)
								}
								tooltip={
									contractModified ||
									(moduleType !== ModuleTypes.editText && moduleType !== ModuleTypes.text)
										? tooltipText
										: undefined
								}
								tooltipOptions={{ showOnDisabled: true }}
							/>
						</div>
					</div>
					<span>{renderCopy()}</span>
				</div>
			) : (
				<div>{renderEdit()}</div>
			)}
		</div>
	);
};

export default ContractCard;
