import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { postsApi, programsApi } from 'api';
import { v4 as UUIDv4 } from 'uuid';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import ValidateDropdown from 'components/_common/forms/ValidateDropdown';
import NotificationPost from 'components/dashboard/NotificationPost';
import { Format, PostAttachment, PostAttachmentFileType, PostAttachmentLocal, UserData } from 'types/Dashboard';
import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { useGlobal } from 'contexts/globalContext';
import { MarkdownEditor } from 'components/_common/MarkdownEditor/MarkdownEditor';
import { useAuth } from 'contexts/authContext';
import { Dialog, DialogConfirm } from 'components/_new/Dialog';
import { userDisplayName } from 'utils/userDisplayName';
import { Application } from 'types/Application';
import { Avatar } from 'components/_new/Avatar';
import { User } from 'types/User';
import CustomLabel from 'components/_common/forms/Label';
import ValidateTextField from 'components/_common/forms/TextField';
import { Box, BoxSection } from 'components/_new/Box';
import { MultiSelect } from 'components/_new/MultiSelect';
import { readPostAttachmentTypeFromFile } from 'utils/postAttachment';
import { AttachmentList } from 'components/_new/Attachment';
import { Button } from 'components/_new/Button';

type ApplicationOption = {
	user: User;
	label: string;
	value: number;
};

type EditPostForm = {
	subject: string;
	content: string;
	recipients: string;
	applications: number[];
};

export const ProgramPostEdit = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	const { toastRef } = useGlobal();
	const { currentUser } = useAuth();
	const postId = Number(id);
	const { data: program, programName, isSuccess } = useProgramPanel();

	const { control, handleSubmit, getValues, watch, reset, setValue } = useForm<EditPostForm>();

	const [attachments, setAttachments] = useState<PostAttachment[]>([]);
	const { data, refetch } = useQuery(['getPostToEdit', id], () => postsApi.getPostAdmin({ postId }), {
		onSuccess: (data) => {
			if (data?.attachments) {
				setAttachments(data.attachments);
			}
			const applicationIds = (data.applications || []).map(({ id }) => id);
			const prepareRecipients = () => {
				if (!data.menteeAccess && !data.mentorAccess && applicationIds.length > 0) {
					return 'selected';
				}
				if (data.menteeAccess && data.mentorAccess) {
					return 'all';
				}
				if (data.menteeAccess && !data.mentorAccess) {
					return 'mentees';
				}
				if (data.mentorAccess && !data.menteeAccess) {
					return 'mentors';
				}
				return '';
			};
			reset({
				subject: data.title,
				content: data.text,
				recipients: prepareRecipients(),
			});
		},
	});
	useEffect(() => {
		refetch();
	}, []);

	const { mutate: editPostMutate, isLoading: editPostLoading } = useMutation(
		['editProgramPost'],
		postsApi.editPostAdmin,
		{
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: 'Post został zaktualizowany',
				});
				refetch();
			},
		}
	);
	const handleFormSubmit = handleSubmit((values) => {
		const { ...data } = values;
		editPostMutate({ postId, data });
	});

	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const handlePreviewClick = useCallback(() => {
		// const values = getValues();
		setPreviewModalOpen(true);
	}, []);

	const subject = watch('subject');
	const content = watch('content');
	const recipients = watch('recipients');

	const hasSubject = subject && subject.length > 0;
	const hasContent = content && content.length > 0;

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	// get applications for options
	const {
		data: applicationMenteesData,
		isLoading: applicationMenteesLoading,
		isFetched: applicationMentessSuccess,
	} = useQuery(
		['getProgramApplications', { programName, applicationRole: 'mentee', acceptedOnly: true }],
		() =>
			programsApi.getProgramAdminApplicationsAll({
				programId: Number(program?.id),
				applicationRole: 'mentee',
			}),
		{
			enabled: isSuccess && recipients === 'selected',
		}
	);
	const {
		data: applicationMentorsData,
		isLoading: applicationMentorsLoading,
		isSuccess: applicationMentorsSuccess,
	} = useQuery(
		['getProgramApplications', { programName, applicationRole: 'mentor', acceptedOnly: true }],
		() =>
			programsApi.getProgramAdminApplicationsAll({
				programId: Number(program?.id),
				applicationRole: 'mentor',
			}),
		{
			enabled: isSuccess,
		}
	);
	const applicationsMerged = [...(applicationMenteesData || []), ...(applicationMentorsData || [])].filter(
		(application) => application.approved === 'accepted'
	);
	useEffect(() => {
		if (data && applicationMentessSuccess && applicationMentorsSuccess) {
			const applicationIds = (data.applications || []).map(({ id }) => id);
			reset({
				...getValues(),
				applications: applicationIds,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, applicationMentessSuccess, applicationMentorsSuccess]);
	const applicationDataLoading = applicationMenteesLoading || applicationMentorsLoading;

	const mapApplicationOption = ({ id, programMembership: { user } }: Application): ApplicationOption => ({
		user,
		label: userDisplayName(user),
		value: id,
	});
	const applicationOptionTemplate = (option: ApplicationOption) => (
		<div className="inline-flex flex-row gap-2 align-items-center mr-2">
			<Avatar src={option.user.avatar} size="sm" name={userDisplayName(option.user, null)} />
			<span>{option.label}</span>
		</div>
	);
	const applicationSelectedOptionTemplate = (value: number) => {
		const application = applicationsMerged.find(({ id }) => id === value);
		if (!application) {
			return null;
		}
		const option = mapApplicationOption(application);
		return applicationOptionTemplate(option);
	};
	const applicationOptions = [
		{
			label: t('misc.mentees'),
			items: (applicationMenteesData || [])
				.filter((application) => application.approved === 'accepted')
				.map(mapApplicationOption),
		},
		{
			label: t('misc.mentors'),
			items: (applicationMentorsData || [])
				.filter((application) => application.approved === 'accepted')
				.map(mapApplicationOption),
		},
	];

	// upload post section
	const { mutateAsync: addPostAttachmentMutateAsync, isLoading: addPostAttachmentLoading } = useMutation(
		(data: FormData) => postsApi.addProgramPostAttachmentAdmin({ postId, data }),
		{
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('programPanel.post.attachmentUploadSuccessfully'),
				});
			},
		}
	);

	const { mutateAsync: removePostAttachmentMutateAsync } = useMutation(postsApi.removeProgramPostAttachmentAdmin, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('programPanel.post.attachmentRemoveSuccessfully'),
			});
		},
	});

	const [newAttachments, setNewAttachments] = useState<PostAttachment[]>([]);
	const [uploadedAttachments, setUploadedAttachments] = useState<PostAttachmentLocal[]>([]);
	const handleAttachmentUpload = useCallback((file: File) => {
		const formData = new FormData();
		formData.append('file', file);

		const localUuid = UUIDv4();
		const { name: originalFileName, size: originalFileSize, type } = file;
		const fileType = readPostAttachmentTypeFromFile(type);

		const maxFileSize = 1024 * 1024 * 3;
		if (originalFileSize > maxFileSize) {
			toastRef?.current?.show({
				severity: 'error',
				life: 3000,
				summary: 'error',
				detail: t('misc.fileSizeExceeds', { count: 3 }),
			});
			return;
		}
		if (fileType === PostAttachmentFileType.UNKNOWN) {
			toastRef?.current?.show({
				severity: 'error',
				life: 3000,
				summary: 'error',
				detail: t('misc.fileInvalidFileType'),
			});
			return;
		}

		const postAttachmentLocal: PostAttachmentLocal = {
			postUuidLink: '',
			localUuid,
			originalFileName,
			originalFileSize,
			fileType,
			createdAt: new Date(),
		};
		setUploadedAttachments((prev) => [...prev, postAttachmentLocal]);
		addPostAttachmentMutateAsync(formData)
			.then((result) => {
				setUploadedAttachments((prev) => prev.filter((attachment) => attachment.localUuid !== localUuid));
				setNewAttachments((prev) => [...prev, result]);
				return result;
			})
			.catch(() => {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: t('misc.error'),
					detail: t('misc.fileUnexpectedError'),
				});
			})
			.finally(() => {
				setTimeout(() => {
					setUploadedAttachments((prev) => prev.filter((attachment) => attachment.localUuid !== localUuid));
				}, 1000);
			});
	}, []);

	const handleAttachmentRemove = useCallback(
		(attachment: PostAttachment) =>
			removePostAttachmentMutateAsync(attachment.id)
				.then((result) => {
					setAttachments((prev) => prev.filter(({ id }) => id !== attachment.id));
					setNewAttachments((prev) => prev.filter(({ id }) => id !== attachment.id));
					return result;
				})
				.catch(() => {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.success'),
						detail: t('misc.fileUnexpectedError'),
					});
				}),
		[]
	);

	return (
		<>
			<Helmet title={t('organizationPanel.programCommunicationEditPost')} />
			<SubPageTitle
				title={t('organizationPanel.programCommunicationEditPost')}
				additional={program?.displayName}
				right={
					<Link
						to={`/program/${program?.name}/communication/list`}
						className="p-button p-button-text p-button-sm p-0"
					>
						{t('programPanel.backToList')}
					</Link>
				}
			/>

			<DialogConfirm
				visible={confirmDialogOpen}
				onHide={() => setConfirmDialogOpen(false)}
				title={t('programPanel.post.saveConfirm')}
				confirmAction={{ submit: true, form: 'edit-post-form' }}
			/>
			<Dialog
				title={t('programPanel.post.postPreview')}
				visible={previewModalOpen}
				onHide={() => setPreviewModalOpen(false)}
				size="lg"
			>
				{program && (
					<NotificationPost
						postData={{
							id: 0,
							language: 'pl',
							menteeAccess: true,
							mentorAccess: true,
							postedBy: 'user',
							format: Format.HTML,
							programId: program?.id,
							text: getValues('content'),
							title: getValues('subject'),
							user: currentUser as UserData,
							userId: (currentUser as UserData)?.id,
							updatedAt: new Date(),
							programType: 'test',
							applications: [],
							attachments: [...attachments, ...newAttachments],
						}}
					/>
				)}
			</Dialog>

			<form id="edit-post-form" onSubmit={handleFormSubmit}>
				<Box variant="white-bordered" className="gap-4">
					<BoxSection header={t('programPanel.post.contentSectionTitle')}>
						<ValidateTextField
							label={t('programPanel.post.subject')}
							name="subject"
							control={control}
							required
						/>
						<MarkdownEditor
							defaultValue={data?.text}
							onChange={(value) => {
								setValue('content', String(value));
							}}
						/>
						<AttachmentList
							attachments={attachments}
							newAttachments={newAttachments}
							uploadedAttachments={uploadedAttachments}
							onUpload={handleAttachmentUpload}
							onRemove={handleAttachmentRemove}
							isAdding={addPostAttachmentLoading}
							options={{
								addAttachmentLabel: t('programPanel.post.addAttachment'),
								removeAttachmentLabel: t('programPanel.post.addAttachment'),
								uploadDesc: t('programPanel.post.attachmentUploadDescription', { count: 3 }),
							}}
						/>
					</BoxSection>
					<BoxSection header={t('programPanel.post.settingsSectionTitle')}>
						<ValidateDropdown
							name="recipients"
							label={t('programPanel.recipients')}
							control={control}
							options={[
								{
									label: t('programPanel.recipientOptions.all'),
									value: 'all',
								},
								{
									label: t('programPanel.recipientOptions.mentorsOnly'),
									value: 'mentors',
								},
								{
									label: t('programPanel.recipientOptions.menteesOnly'),
									value: 'mentees',
								},
								{
									label: t('programPanel.recipientOptions.selected'),
									value: 'selected',
								},
							]}
							required
						/>

						{recipients === 'selected' && (
							<Controller
								control={control}
								name="applications"
								rules={{ required: t('programPanel.selectedPersons.required') }}
								render={({ field, fieldState: { error } }) => (
									<div>
										<CustomLabel
											name="applications"
											label={t('programPanel.selectedPersons.label')}
											required
										/>
										<MultiSelect
											{...field}
											options={applicationOptions}
											placeholder={t('programPanel.recipientOptions.selected')}
											className="w-full"
											itemTemplate={applicationOptionTemplate}
											selectedItemTemplate={applicationSelectedOptionTemplate}
											panelClassName="px-3"
											loading={applicationDataLoading}
										/>
										{error && <small className="p-error font-bold ml-3">{error.message}</small>}
									</div>
								)}
							/>
						)}
					</BoxSection>
					<BoxSection horizontal contentClassName="justify-content-end">
						<Button
							onClick={handlePreviewClick}
							label={t('programPanel.post.preview')}
							variant="primary-outlined"
							loading={editPostLoading}
							disabled={!hasSubject || !hasContent}
						/>
						<Button
							label={t('actions.save')}
							variant="primary"
							onClick={() => setConfirmDialogOpen(true)}
							disabled={!hasSubject || !hasContent}
							loading={editPostLoading}
						/>
					</BoxSection>
				</Box>
			</form>
		</>
	);
};
