import React from 'react';

interface IconWrapperProps {
	imgSrc: string;
	color?: string;
	text: string;
	alt: string;
}

export const IconWrapper = ({ imgSrc, color, text, alt }: IconWrapperProps) => (
	<div className="flex align-items-center text-sm">
		<img className="mr-3" src={imgSrc} alt={alt} />
		<span className="text-xs" color={color || 'white'}>
			{text}
		</span>
	</div>
);
