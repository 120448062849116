import React, { useMemo } from 'react';
import { Outlet, Navigate, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useAuth } from 'contexts/authContext';
import { useTranslation } from 'react-i18next';
import { PageLoading } from './PageLoading';

interface RequireAuthProps {
	strict: boolean;
}
const RequireAuth = ({ strict }: RequireAuthProps) => {
	const { isLogged, ready, currentUser } = useAuth();
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();
	const scopePath = useMemo(() => {
		if (organizationName && String(organizationName).length > 0 && programName && String(programName).length > 0) {
			return `${organizationName}/${programName}`;
		}
		return undefined;
	}, [organizationName, programName]);
	const location = useLocation();
	const redirectUrl =
		String(location.pathname).length > 0 && String(location.pathname) !== '/' ? location.pathname : scopePath;

	if (!ready) {
		return <PageLoading text={t('auth.authorizationMessage')} />;
	}

	if (!isLogged) {
		return (
			<Navigate
				to={{
					pathname: '/login',
					search: redirectUrl ? `?redirectUrl=${encodeURI(redirectUrl)}` : undefined,
				}}
				state={location}
			/>
		);
	}

	// force require terms page
	if (
		!['/require-terms', '/login'].includes(location.pathname) &&
		ready &&
		isLogged &&
		currentUser &&
		!currentUser.terms
	) {
		return <Navigate to="/require-terms" />;
	}

	return <Outlet />;

	// const [isLoading, setIsLoading] = useState(true);
	// const [isAuthRequired, setIsAuthRequired] = useState(strict);
	// const { refreshAccessToken } = useRefreshToken();
	// const { organization, program } = useParams() as RouterUrlParams;
	// useEffect(() => {
	// 	// temp disable redirect by "last visit url" store sin localStorage
	// 	// 	if (location.pathname.length > 0 && location.pathname !== '/') {
	// 	// 		window.localStorage.setItem(StoredKeys.LastVisitedUrl, `${location.pathname}`);
	// 	// 	}
	// 	// used for register to proper program
	// 	if (organization) {
	// 		window.localStorage.setItem(StoredKeys.ProgramName, `${organization}/${program}`);
	// 	}
	// }, [location.pathname, organization, program]);
	// useEffect(() => {
	// 	setIsAuthRequired(strict);
	// 	const verifyRefreshToken = async () => {
	// 		try {
	// 			await refreshAccessToken();
	// 		} catch (error) {
	// 			setAuth({
	// 				loading: false,
	// 			});
	// 		} finally {
	// 			setIsLoading(false);
	// 		}
	// 	};
	// 	// Avoid unwanted calls if accessToken already present
	// 	if (!auth?.accessToken) {
	// 		verifyRefreshToken();
	// 	} else {
	// 		setIsLoading(false);
	// 	}
	// }, [auth?.accessToken, strict, setAuth, refreshAccessToken]);
	// if (isLoading) return <Loader />;
	// return (auth?.accessToken && auth?.currentUser) || !isAuthRequired ? (
	// 	// eslint-disable-next-line react/jsx-no-useless-fragment
	// 	<>
	// 		{auth?.currentUser?.terms === false && location.pathname !== '/require-terms' ? (
	// 			<Navigate to="/require-terms" />
	// 		) : (
	// 			<Outlet context={{ setIsAuthRequired }} />
	// 		)}
	// 	</>
	// ) : (
	// 	// eslint-disable-next-line react/jsx-no-useless-fragment
	// 	<>
	// 		{strict ? (
	// <Navigate
	// 	to={{
	// 		pathname: '/login',
	// 		search: redirectUrl ? `?redirectUrl=${encodeURI(location.pathname)}` : undefined,
	// 	}}
	// 	state={location}
	// />
	// 		) : (
	// 			<Outlet context={{ setIsAuthRequired }} />
	// 		)}
	// 	</>
	// );
};

export default RequireAuth;

interface OutletContext {
	setIsAuthRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useIsAuthRequired() {
	return useOutletContext<OutletContext>();
}
