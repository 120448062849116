import { httpClient } from 'api';
import { Pair } from '../types/Pair';

export type StepsData = {
	activeStep: number;
	steps: any[];
	currentPairs?: Pair[];
	pairId?: number;
};

export const stepsApi = {
	getSteps: ({ pairId, applicationId }: { applicationId: number; pairId?: number }) =>
		httpClient.get<StepsData>(`steps/${applicationId}`, { pairId }),
};
