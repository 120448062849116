import React from 'react';

const GreenCheckmarkIcon = () => (
	<i
		className="pi pi-check text-lg font-bold text-white bg-green-500 p-2 align-self-end"
		style={{
			borderRadius: '50%',
		}}
	/>
);

export default GreenCheckmarkIcon;
