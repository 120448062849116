import React, { Ref, forwardRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import './Divider.scss';

export type DividerVariant = 'grey';

export type DividerVariantSize = 'sm';

export type DividerProps = {
	margin?: number;
	variant?: DividerVariant;
	variantSize?: DividerVariantSize;
	className?: string;
};

export const Divider = forwardRef(
	(
		{ margin = 1, variant = 'grey', variantSize = 'sm', className, ...restProps }: DividerProps,
		ref: Ref<HTMLDivElement>
	) => {
		const finalClassName = useMemo(
			() =>
				classNames(
					'divider',
					`divider-variant-${variant}`,
					`divider-size-${variantSize}`,
					`my-${margin}`,
					className
				),
			[variant, variantSize, margin, className]
		);
		return <div ref={ref} className={finalClassName} {...restProps} />;
	}
);
