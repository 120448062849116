import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { userFilesApi } from 'api';
import { FileType, SessionFile } from 'types/SessionFile';
import { Button } from 'components/_common/Button';
import { Message } from 'primereact/message';
import { BreadCrumb } from 'primereact/breadcrumb';
import { RouterUrlParams } from 'App';
import { Box, BoxSection } from 'components/_new/Box';
import { slugify } from 'utils/helpers';
import { Spinner } from 'components/_new/Spinner';
import { DispalyModule } from './DisplayModule';

export const DispalyPage = () => {
	const { t } = useTranslation();
	const params = useParams();
	const userFileId = Number(params.userFileId);
	const { organizationName, programName, type, pairId } = useParams() as RouterUrlParams;

	const {
		data: getUserfileData,
		isSuccess: getUserfileSuccess,
		isFetched: getUserfileFetched,
	} = useQuery(['userfile', { userFileId }], () => userFilesApi.getUserfile(Number(userFileId)), {
		enabled: Boolean(userFileId),
	});

	const { data: userFileDataStorageUrl } = useQuery(
		['userfile-file', { userFileId }],
		() => userFilesApi.getUserfileBlob(Number(userFileId)),
		{
			enabled: Boolean(getUserfileSuccess) && getUserfileData && getUserfileData.type === 'pdf',
		}
	);

	const handleHtmlCopy = (event: ClipboardEvent) => {
		const selection = document.getSelection() as Selection;
		if (event) {
			event.preventDefault();
			(event as any).clipboardData.setData(
				'text',
				`${selection.toString()} - ${t('userPanel.files.contentCopyMessage')}`
			);
		}
	};

	const getParsetContent = (userFile: SessionFile) => {
		// eslint-disable-next-line no-case-declarations
		const parseFileData = (content: string) => {
			try {
				return parse(content);
			} catch {
				return 'Wystąpił błąd podczas wyświetlania zawartości.';
			}
		};
		return <div onCopy={(event) => handleHtmlCopy(event as any)}>{parseFileData(String(userFile.content))}</div>;
	};

	const renderFileContent = (userFile: SessionFile) => {
		if (getUserfileSuccess && getUserfileData && getUserfileFetched) {
			switch (userFile.type) {
				case 'html':
					return getParsetContent(userFile);
				case 'file':
					return (
						<>
							{getParsetContent(userFile)}
							<a
								href={String(userFile?.storageUrl)}
								download={
									userFile?.originalFilename ||
									`${slugify(userFile.name)}${
										[FileType.PDF, FileType.FILE]
											.map((s) => s.toLowerCase())
											.includes(String(userFile?.type).toLowerCase())
											? '.pdf'
											: ''
									}`
								}
								rel="noreferrer"
								target="_blank"
								className="text-white no-underline"
							>
								<Button>{t('misc.downloadFile')}</Button>
							</a>
						</>
					);
				case 'video':
					// eslint-disable-next-line no-case-declarations
					let src = null;
					try {
						src = `https://www.youtube.com/embed/${(userFile.url as string).split('=')[1]}`;
					} catch {
						return 'Wystąpił błąd podczas ładwania filmu';
					}
					return (
						<>
							{getParsetContent(userFile)}
							<iframe width="100%" height="500px" src={src} title={userFile.name} allowFullScreen />
						</>
					);
				case 'pdf':
					if (userFile.storageUrl && userFile.originalFilename && userFileDataStorageUrl) {
						return (
							<>
								{getParsetContent(userFile)}
								<iframe
									className="iframe-pdf"
									src={userFileDataStorageUrl}
									title={userFile.originalFilename}
									allowFullScreen
								/>
							</>
						);
					}

					return (
						<>
							{getParsetContent(userFile)}
							{/* <Loader text={{ firstLine: 'Download file...' }} /> */}
							{/* TODO: add text */}
							<Spinner />
						</>
					);
				case 'link':
					try {
						if (userFile.url === null) {
							throw new Error('invalid url');
						} else {
							const url = new URL(userFile.url);
							window.location.href = url.href;
							// TODO: add text
							// <CustomLoader text={{ firstLine: 'Opening link...', secondLine: 'Redirecting...' }} />
							return <Spinner />;
						}
					} catch {
						return t('userPanel.userFiles.file.invalidUrl');
					}
				default:
					return (
						<Message
							severity="error"
							text={t('misc.genericError')}
							className="w-full justify-content-start pl-4"
						/>
					);
			}
		}
		return null;
	};

	const navigate = useNavigate();
	const homeBreadcrumb = {
		icon: 'pi pi-book',
		command: () => {
			navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/userfiles`);
		},
	};

	return (
		<>
			<Helmet title={t('userPanel.userFiles.title')} />
			<SubPageTitle title={t('userPanel.userFiles.title')} />
			{getUserfileSuccess && (
				<>
					<BreadCrumb
						model={[
							{
								label: t('userPanel.userFiles.title'),
								command: () => {
									navigate(
										`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/userfiles`
									);
								},
							},
							{
								label: `${t('userPanel.userFiles.file.session')} ${
									getUserfileData?.sessionNumber || '-'
								}`,
								command: () => {
									navigate(
										`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session/${getUserfileData.sessionId}`
									);
								},
							},
							{
								label: getUserfileData.name,
							},
						]}
						home={homeBreadcrumb}
						className="mb-3"
					/>
					<Box variant="white-bordered">
						<BoxSection>
							<DispalyModule userFileId={userFileId} />
						</BoxSection>
					</Box>
				</>
			)}
		</>
	);
};
