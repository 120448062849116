import { httpClient } from 'api';
import { CalendarEvent, CalendarSource, CalendarSyncInfo } from 'types/Calendar';

export const calendarApi = {
	getCalendarEvents: () => httpClient.get<CalendarEvent[]>('/calendar/events'),
	getCalendarEventsPair: (pairId: number) => httpClient.get<CalendarEvent[]>(`/calendar/events/${pairId}`),
	getCalendars: () => httpClient.get<CalendarSource[]>('/calendar'),
	addCalendar: (data: { type: string; url: string }) => httpClient.post('/calendar', data),
	removeCalendar: (id: number) => httpClient.delete(`/calendar/${id}`),
	syncAllCalendars: () => httpClient.put(`/calendar/sync`),
	getCalendarSyncInfo: () => httpClient.get<CalendarSyncInfo>(`/calendar/sync`),
	syncCalendar: (id: number) => httpClient.put(`/calendar/${id}`),
};
