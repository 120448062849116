/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ClipboardEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { File, FileType } from 'types/File';
import { Message } from 'primereact/message';
import ValidateTextArea from 'components/_common/forms/TextArea';
import { Button } from 'components/_new/Button';
import PageMissing from 'components/PageMissing';
import { PageLoading } from 'components/PageLoading';
import { slugify } from 'utils/helpers';
import { Spinner } from 'components/_new/Spinner';
import { Dialog } from 'components/_new/Dialog';

interface FileDisplayModuleProps {
	selectedFile: File;
	disableRating?: boolean;
}

const FileDisplayModule: FC<FileDisplayModuleProps> = ({ selectedFile, disableRating = false }) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();

	const { currentApplicationData, currentProgramMembership } = usePanel();

	const responseAsBlob = Boolean(selectedFile.type === 'file' || selectedFile.type === 'pdf');
	const {
		data: fileData,
		isLoading,
		isError,
		isRefetching,
		isFetched,
	} = useQuery(
		['knowledge-file-data', { id: Number(selectedFile?.id), responseAsBlob }],
		() =>
			userPanelApi.getFileDataByApplicationAndMembership({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: selectedFile.id,
				responseAsBlob,
			}),
		{
			enabled: Boolean(selectedFile),
			refetchOnWindowFocus: false,
		}
	);

	const handleHtmlCopy = (event: ClipboardEvent) => {
		const selection = document.getSelection() as Selection;
		event.preventDefault();
		event.clipboardData.setData('text', `${selection.toString()} - ${t('userPanel.files.contentCopyMessage')}`);
	};

	// ratings
	const { control, handleSubmit, reset } = useForm<{ comment: string }>({
		defaultValues: {
			comment: '',
		},
	});
	const {
		mutate: addFileRating,
		mutateAsync: addFileRatingAsync,
		isLoading: addFileRatingLoading,
		isSuccess: addFileRatingSuccess,
	} = useMutation(
		({ rating, comment }: { rating?: number; comment?: string }) =>
			userPanelApi.addFileRating({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: selectedFile.id,
				rating,
				comment,
			}),
		{
			onSuccess: () => {
				fileRatingRefetch();
			},
		}
	);
	const {
		data: fileRatingData,
		refetch: fileRatingRefetch,
		isLoading: fileRatingLoading,
	} = useQuery(
		['knowledge-rating', { id: selectedFile.id }],
		() =>
			userPanelApi.getFileRating({
				applicationId: currentApplicationData.id,
				membershipId: currentProgramMembership.id,
				fileId: selectedFile.id,
			}),
		{
			enabled: Boolean(selectedFile),
			onSuccess: (data) => {
				if (data && String(data?.comment).length > 0) {
					reset({ comment: data.comment });
				}
			},
		}
	);

	const handleRateUpClick = () => {
		const isRatingUp = fileRatingData && fileRatingData.rating === 1;
		addFileRatingAsync({ rating: isRatingUp ? 0 : 1 }).then(({ rating }) => {
			if (rating !== 0) {
				setAfterFeadbackModalOpen(true);
			}
		});
	};

	const handleRateDownClick = () => {
		const isRatingUp = fileRatingData && fileRatingData.rating === -1;
		addFileRatingAsync({ rating: isRatingUp ? 0 : -1 }).then(({ rating }) => {
			if (rating !== 0) {
				setAfterFeadbackModalOpen(true);
			}
		});
	};

	const handleSubmitForm = handleSubmit(({ comment }) => {
		if (fileRatingData && fileRatingData.rating !== 0) {
			addFileRatingAsync({ comment }).then(() => {
				setAfterFeadbackModalOpen(false);
			});
		}
	});

	const [afterFeadbackModalOpen, setAfterFeadbackModalOpen] = useState(false);

	if (isError) return <PageMissing />;

	if (isLoading || isRefetching) return <PageLoading />;

	const renderFileContent = (fileType: FileType) => {
		switch (fileType) {
			case 'html_wyswig':
				// eslint-disable-next-line consistent-return, no-case-declarations
				const parseFileData2 = () => {
					// eslint-disable-next-line no-case-declarations
					if (isFetched) {
						const preParser = (_data: string) => {
							let data = _data;
							if (organizationName) {
								data = data.replaceAll('{{organization}}', organizationName);
							}
							if (programName) {
								data = data.replaceAll('{{program}}', programName);
							}
							if (type) {
								data = data.replaceAll('{{usertype}}', type);
							}
							return data;
						};
						try {
							return parse(preParser(String(selectedFile.description || '')));
						} catch {
							return 'Wystąpił błąd podczas wyświetlania zawartości.';
						}
					}
				};
				return <div onCopy={(event) => handleHtmlCopy(event)}>{parseFileData2()}</div>;
			case 'html':
				// eslint-disable-next-line consistent-return, no-case-declarations
				const parseFileData = () => {
					if (isFetched) {
						// eslint-disable-next-line no-case-declarations
						const preParser = (_data: string) => {
							let data = _data;
							if (organizationName) {
								data = data.replaceAll('{{organization}}', organizationName);
							}
							if (programName) {
								data = data.replaceAll('{{program}}', programName);
							}
							if (type) {
								data = data.replaceAll('{{usertype}}', type);
							}
							return data;
						};
						try {
							return parse(preParser(fileData));
						} catch {
							return 'Wystąpił błąd podczas wyświetlania zawartości.';
						}
					}
				};
				return <div onCopy={(event) => handleHtmlCopy(event)}>{parseFileData()}</div>;
			case 'file':
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const prepareUrl1 = (file: any) => {
					const slugName = slugify(selectedFile.name);
					const extension = ['file', 'pdf'].includes(selectedFile.type) ? `.pdf` : '';
					return selectedFile.originalFilename || `${slugName}${extension}`;
				};
				const fileName1 = prepareUrl1(selectedFile);
				return (
					<Button
						variant="primary"
						label={t('misc.downloadFile')}
						onClick={() => {
							const downloadLink = document.createElement('a');
							downloadLink.target = '_blank';
							downloadLink.download = fileName1;
							const downloadUrl = URL.createObjectURL(fileData);
							downloadLink.href = downloadUrl;
							document.body.append(downloadLink);
							downloadLink.click();
						}}
					/>
				);
			case 'pdf':
				return (
					<object
						data={URL.createObjectURL(new Blob([fileData], { type: 'application/pdf' }))}
						type="application/pdf"
						name={selectedFile.name}
						width="100%"
						height="500px"
						aria-label="pdf"
					/>
				);
			case 'video':
				// eslint-disable-next-line no-case-declarations
				let src = null;
				try {
					src = `https://www.youtube.com/embed/${(selectedFile.url as string).split('=')[1]}`;
				} catch {
					return 'Wystąpił błąd podczas ładwania filmu';
				}
				return (
					<iframe
						className="iframe-pdf"
						src={src}
						frameBorder="0"
						title={selectedFile.name}
						allowFullScreen
					/>
				);
			case 'link':
				try {
					if (selectedFile.url === null) {
						throw new Error('invalid url');
					} else {
						const url = new URL(selectedFile.url);
						window.location.href = url.href;
						return <Spinner />;
						// return <Loader text={{ firstLine: 'Opening link...', secondLine: 'Redirecting...' }} />;
					}
				} catch {
					return t('userPanel.userFiles.file.invalidUrl');
				}
			default:
				return (
					<Message
						severity="error"
						text={t('misc.genericError')}
						className="w-full justify-content-start pl-4"
					/>
				);
		}
	};

	return (
		<>
			<div className="p-knowledge-base-article">{renderFileContent(selectedFile.type)}</div>

			{!disableRating && (
				<>
					<Dialog
						title={t('userPanel.files.rating.thanks1')}
						visible={afterFeadbackModalOpen}
						onHide={() => {
							setAfterFeadbackModalOpen(false);
						}}
					>
						<p>{t('userPanel.files.rating.thanks2')}</p>
						<form onSubmit={handleSubmitForm} className="w-full flex flex-column gap-1 mt-2">
							<ValidateTextArea
								control={control}
								name="comment"
								containerClassName="w-full"
								placeholder={t('userPanel.files.rating.commentPlaceholder')}
							/>
							<div className="flex flex-row justify-content-end">
								<Button submit label={t('actions.save')} loading={addFileRatingLoading} />
							</div>
						</form>
					</Dialog>
					<div className="flex flex-column gap-1 align-items-center">
						<span>{t('userPanel.files.rating.question')}</span>
						<span className="inline-flex gap-2">
							<Button
								label={t('misc.yes')}
								onClick={handleRateUpClick}
								title="Rate up"
								disabled={addFileRatingLoading || fileRatingLoading}
								variant={!(fileRatingData?.rating === 1) ? 'grey-text' : 'primary-text'}
								variantSize="lg"
								icon="thumbs-up"
								iconSet="fa"
								iconStyle="regular"
								iconClassName="text-3xl"
								iconPosition="right"
								className="p-1"
							/>
							<Button
								label={t('misc.no')}
								onClick={handleRateDownClick}
								title="Rate down"
								disabled={addFileRatingLoading || fileRatingLoading}
								variant={!(fileRatingData?.rating === -1) ? 'grey-text' : 'primary-text'}
								variantSize="lg"
								icon="thumbs-down"
								iconSet="fa"
								iconStyle="regular"
								iconClassName="text-3xl"
								className="p-1"
							/>
						</span>
					</div>
				</>
			)}
		</>
	);
};

export default FileDisplayModule;
