import React, {
	useMemo,
	useState,
	useRef,
	HTMLAttributes,
	Ref,
	forwardRef,
	useImperativeHandle,
	PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring';
import composeRefs from '@seznam/compose-react-refs';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { classNames } from 'primereact/utils';
import { useEventListener, useUpdateEffect } from 'primereact/hooks';
import './Overlay.scss';
import { baseIndex, decrementIndex, incrementIndex } from '../UIStorage/UIStorage';

export type OverlayExtendProps = {
	placement?: Placement;
	container?: HTMLElement;
	onOpen?: () => void;
	onClose?: (element: HTMLElement) => void;
};

export type OverlayProps = PropsWithChildren<HTMLAttributes<HTMLDivElement> & OverlayExtendProps>;

export type OverlayElement = {
	open: (element: HTMLElement) => void;
	toggle: (element: HTMLElement) => void;
	close: () => void;
};

export const Overlay = forwardRef(
	(
		{ placement, container = document.body, onOpen, onClose, className, children }: OverlayProps,
		ref: Ref<OverlayElement>
	) => {
		const [zIndex, setZIndex] = useState(baseIndex);

		const containerRef = useRef<HTMLDivElement>(null);
		const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
		const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
		const { styles, attributes } = usePopper(referenceElement, popperElement, {
			strategy: 'fixed',
			placement,
		});

		const [displayOverlay, setDisplayOverlay] = useState(false);

		const [isOpen, setIsOpen] = useState(false);
		useUpdateEffect(() => {
			if (isOpen) {
				setZIndex(incrementIndex());
				bindDocumentClickListener();
				setDisplayOverlay(true);
			} else {
				setZIndex(decrementIndex());
				unbindDocumentClickListener();
			}
		}, [isOpen]);

		const onClick = ({ target }: PointerEvent) => {
			// on outside click
			const isReferencedElement = Boolean(
				target &&
					referenceElement &&
					(referenceElement === target || referenceElement?.contains(target as HTMLElement))
			);
			const isContainerElement = Boolean(
				(target && containerRef?.current && containerRef.current === target) ||
					containerRef.current?.contains(target as HTMLElement)
			);
			if (!isReferencedElement && !isContainerElement) {
				setIsOpen(false);
			}
		};
		const [bindDocumentClickListener, unbindDocumentClickListener] = useEventListener({
			type: 'click',
			listener: (event: PointerEvent) => onClick(event),
		});

		const open = (element: HTMLElement) => {
			setReferenceElement(element);
			setIsOpen(true);
			if (onOpen) {
				onOpen();
			}
		};
		const close = () => {
			setIsOpen(false);
			if (onClose && referenceElement) {
				onClose(referenceElement);
			}
		};
		useImperativeHandle(ref, () => ({
			open,
			toggle: (element: HTMLElement) => {
				if (isOpen) {
					close();
				} else {
					open(element);
				}
			},
			close,
		}));

		const finalContainerClassName = useMemo(
			() => classNames('overlay-container', { 'overlay-container-open': isOpen }, className),
			[className, isOpen]
		);

		const containerStyle = useSpring({
			opacity: isOpen ? 1 : 0,
			transform: isOpen ? 'translateY(0) scaleY(1)' : 'translateY(-15%) scaleY(0.9)',
			config: { duration: 250 },
			onResolve: ({ finished }) => {
				if (finished && !isOpen) {
					setDisplayOverlay(false);
					setReferenceElement(null);
				}
			},
		});

		return createPortal(
			displayOverlay ? (
				<animated.div
					ref={composeRefs(containerRef, setPopperElement as unknown as React.RefObject<HTMLDivElement>)}
					className={finalContainerClassName}
					style={{ ...containerStyle, zIndex, ...styles.popper }}
					{...attributes.popper}
				>
					{children}
				</animated.div>
			) : null,
			container
		);
	}
);
