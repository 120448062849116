import React, { Ref, HTMLAttributes, forwardRef, useMemo, ReactNode, Fragment } from 'react';
import { classNames } from 'primereact/utils';
import { universalRenderer } from 'utils/universalRenderer';
import { Icon, IconSet, IconStyle } from '../Icon';

import './MessageBox.scss';

type MessageBoxVariant = 'success' | 'rose' | 'purple' | 'lightpurple' | 'warn';

export type MessageBoxProps = Pick<HTMLAttributes<HTMLDivElement>, 'className'> & {
	icon?: ReactNode | (() => ReactNode);
	iconStyle?: IconStyle;
	iconSet?: IconSet;
	title?: ReactNode;
	message?: ReactNode | ReactNode[];
	variant?: MessageBoxVariant;
	highlight?: boolean;
};

export const MessageBox = forwardRef(
	(
		{
			icon,
			iconStyle,
			iconSet,
			title,
			message,
			variant = 'rose',
			className,
			highlight = false,
			...restProps
		}: MessageBoxProps,
		ref: Ref<HTMLDivElement>
	) => {
		const finalClassName = useMemo(
			() =>
				classNames('message-box', `message-box-${variant}`, { 'message-box-highlight': highlight }, className),
			[highlight, variant, className]
		);

		const renderIcon = () => {
			if (icon) {
				if (typeof icon === 'string') {
					return <Icon name={icon} iconStyle={iconStyle} iconSet={iconSet} className="message-box-icon" />;
				}
				return <span className="message-box-icon">{universalRenderer(icon)}</span>;
			}
			return null;
		};

		return title || message ? (
			<div ref={ref} className={finalClassName} {...restProps}>
				<div className="message-box-inside">
					{!title && renderIcon()}
					<div className="message-box-content">
						{title && (
							<div className="message-box-title">
								{renderIcon()}
								<span>{title}</span>
							</div>
						)}
						{Array.isArray(message) ? (
							message.map((text, i) => (
								// eslint-disable-next-line react/no-array-index-key
								<div className="message-box-text" key={i}>
									{text}
								</div>
							))
						) : (
							<div className="message-box-text">{message}</div>
						)}
					</div>
				</div>
			</div>
		) : null;
	}
);
