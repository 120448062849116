import { format } from 'date-fns';
import { enUS, pl } from 'date-fns/locale';
import i18n from 'locale';

type DateFormatType = 'full' | 'hours' | 'dayAndMonth' | 'namedMonth' | 'date' | 'time' | 'dayAndMonthPlusYear';

export const dateFormat = (_date: Date | string, type: DateFormatType) => {
	try {
		const getLocaleSet = () => {
			switch (i18n.language) {
				case 'en':
					return enUS;
				case 'pl':
				default:
					return pl;
			}
		};
		const getLocale = () => {
			switch (i18n.language) {
				case 'en':
					return 'en-US';
				case 'pl':
				default:
					return 'pl-PL';
			}
		};

		const date = _date instanceof Date ? _date : new Date(_date);

		switch (type) {
			case 'full':
				return format(date, 'Pp', { locale: getLocaleSet() });
			case 'hours':
				return format(date, 'HH:mm', { locale: getLocaleSet() });
			case 'dayAndMonth':
				return format(date, 'd MMMM', { locale: getLocaleSet() });
			case 'dayAndMonthPlusYear':
				return format(date, 'd MMMM yyyy', { locale: getLocaleSet() });
			case 'namedMonth':
				return format(date, 'd, MMMM yyyy HH:mm', { locale: getLocaleSet() });
			case 'date':
				return date.toLocaleDateString(getLocale());
			case 'time':
				return date.toLocaleTimeString(getLocale());
			default:
				return date.toLocaleString(getLocale());
		}
	} catch {
		console.error('invalid date', _date);
		return undefined;
	}
};
