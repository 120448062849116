import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { organizationsApi } from 'api';
import { Helmet } from 'react-helmet';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useOrganizationPanel } from 'contexts/organizationPanelContext';
import { Program } from 'types/Program';
import { classNames } from 'primereact/utils';
import { Box, BoxSection } from 'components/_new/Box';

const OrganizationProgramList = () => {
	const { t } = useTranslation();
	const {
		currentOrganization: { organizationName },
	} = useOrganizationPanel();

	const { data: ogranizationPrograms, isSuccess } = useQuery(
		['getOrganizationPrograms', organizationName],
		() => organizationsApi.getOrganizationPrograms({ organizationName: String(organizationName) }),
		{
			enabled: Boolean(organizationName),
		}
	);

	return (
		<>
			<Helmet title={t('organizationPanel.organizationPrograms')} />
			<SubPageTitle
				title={t('organizationPanel.organizationPrograms')}
				// right={
				// 	<Link
				// 		to={`/organization/${organizationName}/add-program`}
				// 		className="p-button p-button-text p-button-sm"
				// 	>
				// 		{t('organizationPanel.addNewProgram')}
				// 	</Link>
				// }
			/>
			<div>
				<ul className="p-list-unstylled flex flex-column gap-2">
					{isSuccess && (ogranizationPrograms || []).length === 0 && (
						<p>{t('organizationPanel.program.list.noPrograms')}</p>
					)}
					{isSuccess &&
						(ogranizationPrograms || []).map((program: Program) => (
							<li key={program.id}>
								<Box variant="white-bordered">
									<BoxSection>
										<h2 className={classNames({ 'text-gray': program.finished })}>
											{program.displayName}{' '}
											{program.finished ? `[${t('organizationPanel.programIsFinished')}]` : ''}
										</h2>
										<div className="flex gap-2 justify-content-end">
											{!program.finished && (
												<Link to={`/${program.name}`} className="p-button p-button-text">
													{t('organizationPanel.programHomepage')}
												</Link>
											)}
											<Link
												to={`/program/${program.name}`}
												className={classNames('p-button ', {
													'p-button-primary': !program.finished,
													'p-button-outlined': program.finished,
												})}
											>
												{t('organizationPanel.programDashboard')}
											</Link>
										</div>
									</BoxSection>
								</Box>
							</li>
						))}
				</ul>
			</div>
		</>
	);
};

export default OrganizationProgramList;
