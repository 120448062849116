import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { emailChangesApi } from 'api';
import { Messages } from 'primereact/messages';
import { InputText } from 'components/_common/forms/InputText';
import ValidatePassword from 'components/_common/forms/ValidatePassword';
import CustomLabel from 'components/_common/forms/Label';
import { dateFormat } from 'utils/dateFormat';
import { Button } from 'components/_new/Button';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';

type ChangeEmailForm = {
	password: string;
	newEmail: string;
};

type ChangeEmailDialogProps = DialogBaseExtendProps;

export const ChangeEmailDialog = ({ onHide, visible, ...props }: ChangeEmailDialogProps) => {
	const { t } = useTranslation(['common']);
	const queryClient = useQueryClient();
	const messagesRef = useRef<Messages>(null);

	const { data: myLastEmailChange, error: myLastEmailChangeError } = useQuery(
		['getMyLastEmailChange'],
		emailChangesApi.getMyLast,
		{
			enabled: visible,
			onError: () => {},
		}
	);

	const { mutate, isLoading } = useMutation(['sendEmailChange'], emailChangesApi.sendEmailChange, {
		onSuccess: () => {
			queryClient.refetchQueries(['getMyLastEmailChange']);
			if (messagesRef?.current) {
				messagesRef.current.show({
					severity: 'success',
					detail: t('userPanel.myAccount.afterEmailChangeSentMessage'),
				});
			}
		},
	});

	const { mutate: cancelEmailChangeMutate, isLoading: cancelEmailChangeLoading } = useMutation(
		emailChangesApi.cancelEmailChange,
		{
			onSuccess: () => {
				queryClient.refetchQueries(['getMyLastEmailChange']);
				if (messagesRef?.current) {
					messagesRef.current.show({
						severity: 'success',
						detail: t('userPanel.myAccount.afterEmailChangeCancelMessage'),
					});
				}
			},
		}
	);

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ChangeEmailForm>({
		defaultValues: { newEmail: '' },
	});
	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});

	const hasEmailChange = Boolean(myLastEmailChange && !myLastEmailChangeError);

	return (
		<DialogAction
			title={t('userPanel.myAccount.changeEmail')}
			size="sm"
			actions={[
				{
					key: 'save',
					submit: true,
					form: 'changeEmailForm',
					label: t('actions.save'),
					loading: isLoading,
				},
			]}
			visible={visible}
			onHide={onHide}
			{...props}
		>
			<>
				<Messages ref={messagesRef} />
				{hasEmailChange ? (
					<div className="flex flex-column align-items-center">
						<p>{t('userPanel.myAccount.changeEmailInProcess')}</p>
						<p>
							<span>
								{t('userPanel.myAccount.newEmailAddress')}
								{':\u0020'}
							</span>
							<span className="font-bold">{myLastEmailChange?.newEmail}</span>
						</p>
						<p className="text-center" style={{ maxWidth: '75%' }}>
							{t('userPanel.myAccount.emailChangeExpiredAt', {
								date: dateFormat(String(myLastEmailChange?.expiredAt), 'full'),
							})}
						</p>
						<Button
							onClick={() => {
								cancelEmailChangeMutate();
							}}
							label={t('userPanel.myAccount.cancelEmailChange')}
							className="p-button-danger mt-2"
							loading={cancelEmailChangeLoading}
							variantSize="sm"
						/>
					</div>
				) : (
					<form id="changeEmailForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
						<div className="flex flex-column gap-1">
							<CustomLabel
								name="repeatPassword"
								label={t('userPanel.myAccount.currentPassword')}
								required
							/>
							<ValidatePassword
								name="password"
								control={control}
								placeholder={t('userPanel.myAccount.passwordRequired')}
							/>
							{errors?.password && <small className="p-error">{errors?.password?.message}</small>}
						</div>
						<div className="flex flex-column gap-1">
							<CustomLabel name="newEmail" label={t('userPanel.myAccount.newEmailAddress')} required />
							<InputText
								type="email"
								{...register('newEmail', {
									required: t('userPanel.myAccount.emailRequired'),
								})}
								hasError={errors?.newEmail}
								rounded
							/>
							{errors?.newEmail && <small className="p-error">{errors?.newEmail?.message}</small>}
						</div>
					</form>
				)}
			</>
		</DialogAction>
	);
};
