type SortOrder = 'asc' | 'desc';

function compareNulls(a: any, b: any): number {
	if (a === null && b === null) return 0;
	if (a === null) return -1;
	if (b === null) return 1;
	return 0;
}

function getProperty<T>(obj: T, path: string): any | undefined {
	const keys = path.split('.');

	let nestedValue: any = obj;

	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys) {
		nestedValue = nestedValue[key];
		if (nestedValue === undefined) {
			return undefined; // Jeśli którykolwiek klucz jest undefined, zwróć undefined
		}
	}

	return nestedValue;
}

export function sortByProp<T>(arr: T[], property: string, order: SortOrder = 'asc'): T[] {
	const sortedArray = [...arr];

	sortedArray.sort((a, b) => {
		const valueA: any = getProperty<T>(a, property);
		const valueB: any = getProperty<T>(b, property);

		if (valueA === null || valueB === null) {
			return order === 'asc' ? compareNulls(valueA, valueB) : compareNulls(valueB, valueA);
		}

		if (valueA instanceof Date && valueB instanceof Date) {
			return order === 'asc' ? valueA.getTime() - valueB.getTime() : valueB.getTime() - valueA.getTime();
		}

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			return order === 'asc' ? String(valueA).localeCompare(valueB) : String(valueB).localeCompare(valueA);
		}

		// Obsługa porównywania dla innych typów, np. number
		return order === 'asc' ? (valueA as any) - (valueB as any) : (valueB as any) - (valueA as any);
	});

	return sortedArray;
}
