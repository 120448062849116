import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/_new/Button';
import { IconStyle } from 'components/_new/Icon';
import { RouterUrlParams } from 'App';
import { useParams } from 'react-router-dom';
import { MaterialCategory, SessionFile } from 'types/SessionFile';
import slugify from 'slugify';
import FileDisplayModal from 'components/knowledgeDatabase/FileDisplayModal';
import { DividerList } from 'components/_new/Divider';
import { Link } from 'components/_new/Link';
import { getMaterialIconPropsForLink } from 'utils/materialIconProps';

type MaterialsSubpageProps = {
	files: SessionFile[];
};

export const MaterialsSubpage = ({ files }: MaterialsSubpageProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type: panelType } = useParams() as RouterUrlParams;

	const sessionDisplayableFiles = files;

	const [selectedFile, setSelectedFile] = useState<number | null>(null);

	if (!sessionDisplayableFiles.length) return <div />;
	const list = sessionDisplayableFiles.map(({ id, materialCategory, name, directory }) => {
		const to = `/panel/${organizationName}/${programName}/${panelType}/files/${slugify(directory)}/${slugify(
			name
		)}`.toLowerCase();
		return (
			<div key={id} className="flex flex-row gap-2 justify-content-between hover-focus-area">
				<Link
					to={to}
					onClick={(event) => {
						event.preventDefault();
						setSelectedFile(id);
					}}
					{...getMaterialIconPropsForLink(materialCategory)}
					iconClassName="text-lg text-purple"
					noUnderline
					variant="grey"
					className="font-bold"
				>
					{name}
				</Link>
				<div className="flex flex-row gap-2 hover-focus-view">
					<Button
						label="View"
						icon="eye"
						iconOnly
						variant="primary-text"
						variantSize="sm"
						onClick={(event) => {
							event.preventDefault();
							setSelectedFile(id);
						}}
					/>
					<Button
						label="Open in new tab"
						icon="arrow-up-right-from-square"
						iconOnly
						variant="primary-text"
						variantSize="sm"
						onClick={() => {
							const a = document.createElement('a');
							a.target = '_blank';
							a.href = to;
							a.click();
						}}
					/>
				</div>
			</div>
		);
	});

	return (
		<>
			<FileDisplayModal
				selectedFileId={selectedFile}
				visible={Boolean(selectedFile)}
				onHide={() => {
					setSelectedFile(null);
				}}
			/>
			<div className="flex flex-column gap-2">
				<h2 className="my-5">{t('userPanel.sessionsNew.materials.heading')}</h2>
				<div className="flex flex-column md:flex-row gap-6">
					{list.length === 0 ? (
						<span className="text-muted">{t('userPanel.sessionsNew.materials.noMaterials')}</span>
					) : (
						<>
							<DividerList className="md:flex-1">{list.filter((_, i) => !(i % 2))}</DividerList>
							<DividerList className="md:flex-1">{list.filter((_, i) => i % 2)}</DividerList>
						</>
					)}
				</div>
			</div>
		</>
	);
};
