import React from 'react';
import { Box, BoxProps } from 'components/_new/Box';

type ExtendedBoxProps = BoxProps & {
	flagcolor: string;
};

export enum SessionBadgeColor {
	Green = '#c8f392',
	Blue = '#b0b1ed',
}

const ExtendedBox = ({ flagcolor, className, children, ...restProps }: ExtendedBoxProps) => {
	return (
		<Box vertical variant="white-bordered" noPad overflowHidden {...restProps}>
			<div
				style={{
					width: '0.6rem',
					minWidth: '0.6rem',
					backgroundColor:
						flagcolor === SessionBadgeColor.Green ? SessionBadgeColor.Green : SessionBadgeColor.Blue,
				}}
			/>
			{children}
		</Box>
	);
};

export default ExtendedBox;
