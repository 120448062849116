import { PostAttachmentFileType } from 'types/Dashboard';

export const readPostAttachmentTypeFromFile = (mimetype: string): PostAttachmentFileType => {
	switch (mimetype) {
		case 'application/pdf':
			return PostAttachmentFileType.PDF;
		case 'image/gif':
		case 'image/png':
		case 'image/jpg':
		case 'image/jpeg':
			return PostAttachmentFileType.IMAGE;
		default:
			return PostAttachmentFileType.UNKNOWN;
	}
};
