import React, { useMemo } from 'react';
import { Avatar } from 'components/_new/Avatar';
import { Icon } from 'components/_new/Icon';
import { PostedBy } from 'types/Dashboard';
import { User } from 'types/User';
import './NotificationAvatar.scss';

type NotificationAvatarProps = {
	name: string;
	user?: User;
	postedBy: PostedBy;
};

export const NotificationAvatar = (props: NotificationAvatarProps) => {
	const { name, user, postedBy } = props;

	const avatarName = useMemo(() => {
		if (postedBy === 'mentibot' || postedBy === 'application') {
			return null;
		}
		return name;
	}, [postedBy, name]);

	if (postedBy === 'mentibot') {
		return (
			<div className="avatar avatar-mentibot">
				<Icon iconSet="fa" name="robot" iconStyle="solid" />
			</div>
		);
	}

	if (postedBy === 'application') {
		return (
			<div className="avatar avatar-application">
				<Icon iconSet="fa" name="info" iconStyle="solid" />
			</div>
		);
	}

	if (user) {
		return <Avatar src={user?.avatar} name={avatarName} size="lg" />;
	}

	return null;
};
