/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { authApi } from 'api';
import { useGlobal } from 'contexts/globalContext';
import { StoredKeys } from 'types/Auth';
import { Button } from 'primereact/button';
import TextField from 'components/_common/forms/TextField';
import Checkbox from 'components/_common/forms/Checkbox';
import { colors } from 'theme';
import { resolveSiteUrl, resolveStaticsUrl } from 'utils/urlBuilder';
import ValidatePassword from 'components/_common/forms/ValidatePassword';
import { classNames } from 'primereact/utils';
import { translatedStaticResourceResolver } from 'utils/translatedStaticResourceResolver';
import { AxiosError } from 'axios';

const formFieldStepMap = [
	['email', 'password', 'confirmPassword', 'firstName', 'lastName', 'phoneNumber'],
	[
		'billingCompanyName',
		'billingTin',
		'billingAddressStreetAndNumber',
		'billingPostCode',
		'billingCity',
		'billingEmail',
		'organizationName',
	],
];

interface RegisterOrganizationFormObject {
	email: string;
	password: string;
	confirmPassword: string;

	firstName: string;
	lastName: string;
	phoneNumber: string;

	billingCompanyName: string;
	billingTin: string;
	billingAddressStreetAndNumber: string;
	billingPostCode: string;
	billingCity: string;
	billingEmail: string;
	organizationName: string;

	language: string;
	terms: string;
}

const RegisterOrganization: FC = () => {
	const { i18n, t } = useTranslation();
	const { toastRef } = useGlobal();

	const [step, setStep] = useState(1);

	const {
		trigger,
		control,
		handleSubmit,
		watch,
		setError,
		formState: { errors },
	} = useForm<RegisterOrganizationFormObject>({
		defaultValues: {
			language: i18n.resolvedLanguage,
		},
	});

	const [afterSend, setAfterSend] = useState(false);

	const {
		mutate,
		isSuccess,
		isLoading: isSubmitting,
	} = useMutation(authApi.registerOrganization, {
		onError: (error) => {
			if (error instanceof AxiosError) {
				const data = error.response?.data;
				const message = data?.message;
				if (message) {
					if (message === 'userEmailExists') {
						const translated = t(`auth.messages.${message}`);
						setError('email', {
							type: 'custom',
							message: translated,
						});
					} else {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t('misc.error'),
							detail: data.message,
						});
					}
				} else {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.error'),
						detail: error.message,
					});
				}
			}
		},
		onSuccess: () => {
			setAfterSend(true);
		},
	});

	if (isSuccess) {
		return (
			<>
				<div>{t('auth.thankYouMessage')}</div>
				<p>{t('auth.closeTabAndCheckInbox')}</p>
			</>
		);
	}

	const handleClickNext = () => {
		if (step === 1) {
			trigger(['email', 'password', 'confirmPassword', 'firstName', 'lastName', 'phoneNumber']).then(
				(valid: any) => {
					if (valid) {
						setStep(2);
					}
				}
			);
		}
	};

	const handleSubmitForm = handleSubmit(({ terms, confirmPassword, ...values }) => {
		mutate(values);
	});

	const password = watch('password');

	return (
		<>
			<Helmet title={t('auth.register')} />
			{afterSend ? (
				<>
					<div>{t('auth.thankYouMessage')}</div>
					<p>{t('auth.closeTabAndCheckInbox')}</p>
				</>
			) : (
				<form onSubmit={handleSubmitForm} className="flex flex-column row-gap-4">
					<div className="text-sm">
						<p>{t('auth.registerTooltip1')}</p>
					</div>
					<div
						className={classNames('flex-column row-gap-4', {
							flex: step === 1,
							hidden: step !== 1,
						})}
					>
						<TextField name="email" placeholder={t('auth.inputs.email')} control={control} required />

						<ValidatePassword
							name="password"
							placeholder={t('auth.inputs.password')}
							control={control}
							required
						/>

						<ValidatePassword
							name="confirmPassword"
							placeholder={t('auth.inputs.confirmPassword')}
							control={control}
							required
							rules={{
								validate: {
									matching: (v) => v === password || t('misc.forms.notMatchingPassword'),
								},
							}}
						/>

						<TextField
							name="firstName"
							placeholder={t('auth.inputs.firstName')}
							control={control}
							required
						/>

						<TextField name="lastName" placeholder={t('auth.inputs.lastName')} control={control} required />

						<TextField
							name="phoneNumber"
							placeholder={t('auth.inputs.phoneNumber')}
							control={control}
							required
						/>

						<div className="p-mt-2">
							<Button
								type="button"
								style={{ display: 'unset' }}
								label={t('auth.buttons.next')}
								onClick={handleClickNext}
								className="p-button-rounded w-full"
							/>
						</div>
					</div>

					<div
						className={classNames('flex-column row-gap-4', {
							flex: step === 2,
							hidden: step !== 2,
						})}
					>
						<div className="p-mb-3">
							<Button
								type="button"
								onClick={() => {
									setStep(1);
								}}
								label={t('auth.buttons.prev')}
								className="p-button-rounded w-full"
							/>
						</div>

						<TextField
							name="billingCompanyName"
							placeholder={t('auth.inputs.billingCompanyName')}
							control={control}
							required
						/>

						<TextField
							name="billingTin"
							placeholder={t('auth.inputs.billingTin')}
							control={control}
							required
						/>

						<TextField
							name="billingAddressStreetAndNumber"
							placeholder={t('auth.inputs.billingAddressStreetAndNumber')}
							control={control}
							required
						/>

						<TextField
							name="billingPostCode"
							placeholder={t('auth.inputs.billingPostCode')}
							control={control}
							required
						/>

						<TextField
							name="billingCity"
							placeholder={t('auth.inputs.billingCity')}
							control={control}
							required
						/>

						<TextField
							name="billingEmail"
							placeholder={t('auth.inputs.billingEmail')}
							control={control}
							required
						/>

						<TextField
							name="organizationName"
							placeholder={t('auth.inputs.organizationName')}
							control={control}
							required
						/>

						<div className="mt-3">
							<Button
								type="submit"
								style={{ display: 'unset' }}
								loading={isSubmitting}
								label={t('auth.buttons.registerAndCreateOrganization')}
								className="p-button-rounded w-full"
							/>
						</div>
					</div>

					<Checkbox
						name="terms"
						control={control}
						text={
							<label htmlFor="termsOfService" className="text-sm">
								{t('auth.accept')}
								<a
									href={translatedStaticResourceResolver('terms')}
									target="_blank"
									style={{
										color: errors.terms ? 'inherit' : colors.purplishBlue,
									}}
									rel="noreferrer"
								>
									{t('auth.termsOfService')}
								</a>
							</label>
						}
						required
					/>
					<div className="text-sm">
						<p>{t('auth.registerTooltip2')} </p>
					</div>
				</form>
			)}
		</>
	);
};

export default RegisterOrganization;
