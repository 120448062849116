import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Button } from 'components/_new/Button';
import { chatApi } from 'api/chat';
import { useGlobal } from 'contexts/globalContext';
import { useScope } from 'contexts/scopeContext';
import { useLocation } from 'react-router-dom';
// import { useWebSocket } from 'components/ws/use-websocket';
import { useChat } from './useChat';
import { ChatDialog } from './ChatDialog';
import { useChatThreadSubscriber } from './useChatSubscrber';

export const ChatButton = () => {
	const { t } = useTranslation();

	const {
		access,
		openDialog,
		dialogProps: { visible },
		queryUniqueParam,
	} = useChat();
	const { currentProgramMembership } = useScope();
	const { newChats, setNewChats } = useGlobal();

	const { refetch: chatApiStatusRefetch, isLoading: statusLoading } = useQuery(
		['chat-status', queryUniqueParam],
		() => chatApi.getStatus(Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(currentProgramMembership) && access.hasAccess,
			initialData: [],
			refetchOnWindowFocus: true,
			cacheTime: 60000,
			onSuccess: (data) => {
				setNewChats(data);
			},
		}
	);

	const statusRefetchDelayed = () => {
		// if dialog is open, delay refetch for prevent fetch "open & now read" threads
		if (visible) {
			setTimeout(() => {
				if (!statusLoading) {
					chatApiStatusRefetch();
				}
			}, 1000);
		}

		// otherwise refetch now
		else if (!statusLoading) {
			chatApiStatusRefetch();
		}
	};

	const chatThreadHandlerEvent = useCallback(() => {
		statusRefetchDelayed();
	}, []);
	useChatThreadSubscriber({
		onChatThread: chatThreadHandlerEvent,
		onChatThreadSelf: chatThreadHandlerEvent,
	});

	// useEffect(() => {
	// 	const handleMessage = (rawEvent: MessageEvent<any>) => {
	// 		const { event, data } = parseMessage(rawEvent);
	// 		if (event === 'chat-thread') {
	// 			statusRefetchDelayed();
	// 		}
	// 	};
	// 	return () => {
	// 		connection?.socket.removeEventListener('message', handleMessage);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [connection]);

	const location = useLocation();
	useEffect(() => {
		const currentTitle = document.title.replace(/^\([0-9]+\)\s/g, '');
		if (newChats.length > 0) {
			document.title = `(${newChats.length}) ${currentTitle}`;
		} else {
			document.title = currentTitle;
		}
	}, [location.pathname, newChats.length]);

	return (
		<>
			<ChatDialog />
			<Button
				onClick={() => openDialog()}
				rounded
				variantSize="lg"
				variant="primary-outlined"
				icon="comments"
				iconStyle="regular"
				iconOnly
				label={t('chat.title')}
				badge={newChats.length}
				loading={access.loading}
				disabled={!access.hasAccess}
			/>
		</>
	);
};
