import React, { Ref, forwardRef, HTMLProps } from 'react';
import { classNames } from 'primereact/utils';
import './Field.scss';

type FieldProps = HTMLProps<HTMLLabelElement> & {
	label: string;
	required?: boolean;
	containerClassName?: string;
};

export const Field = forwardRef(
	(
		{ label, required, className, containerClassName, children, ...props }: FieldProps,
		ref: Ref<HTMLLabelElement>
	) => {
		return (
			<div className={classNames('field', containerClassName)}>
				<label ref={ref} className={classNames('field-label', className)} {...props}>
					{label}
				</label>
				{children}
			</div>
		);
	}
);
