import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { programsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { userDisplayName } from 'utils/userDisplayName';
import { Spinner } from 'components/_new/Spinner';
import { Box, BoxSection } from 'components/_new/Box';

const ProgramMembership = () => {
	const { t } = useTranslation();

	const { membershipId } = useParams();
	const { data: program, isLoading, isSuccess } = useProgramPanel();

	const {
		data: programMembership,
		isSuccess: getProgramMembershipSuccess,
		isLoading: getProgramMembershipLoading,
	} = useQuery(
		['getProgramMembership', program?.name],
		() => programsApi.getProgramMembership({ programId: Number(program?.id), membershipId: Number(membershipId) }),
		{
			enabled: isSuccess,
		}
	);

	return (
		<>
			<Helmet title={t('organizationPanel.programMembership')} />
			{(isLoading || getProgramMembershipLoading) && <Spinner />}
			{isSuccess && getProgramMembershipSuccess && (
				<>
					<SubPageTitle
						additional={`${t('organizationPanel.programMembership')} ${program?.displayName}`}
						title={userDisplayName(programMembership.user)}
					/>
					{getProgramMembershipSuccess && !programMembership && <p>{t('programPanel.membershipNotFound')}</p>}
					{getProgramMembershipSuccess && programMembership && (
						<Box variant="white-bordered">
							<BoxSection>
								<h2>{userDisplayName(programMembership.user)}</h2>
								<p>
									{t('programPanel.role')}: {programMembership.role}
								</p>
							</BoxSection>
						</Box>
					)}
				</>
			)}
		</>
	);
};

export default ProgramMembership;
