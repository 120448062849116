import React, { useState } from 'react';
import { Button } from 'components/_common/Button';
import { useForm } from 'react-hook-form';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { userPanelApi, GetPostCommentsResponse } from 'api';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'contexts/globalContext';
import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { userDisplayName } from 'utils/userDisplayName';
import { dateFormat } from 'utils/dateFormat';
import { parseHtml } from 'utils/htmlParser';
import { classNames } from 'primereact/utils';
import ValidateTextArea from 'components/_common/forms/TextArea';
import { Avatar } from 'components/_new/Avatar';
import { useAuth } from 'contexts/authContext';

type NotificationPostCommentsProps = {
	postId: number;
	getPostCommentsRequest?: (params: any) => void;
	addPostCommentRequest?: (data: { postId: number; payload: any }) => void;
	adminMode: boolean;
};

const NotificationPostComments = ({
	postId,
	getPostCommentsRequest,
	addPostCommentRequest,
	adminMode,
}: NotificationPostCommentsProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();
	const { currentUser } = useAuth();

	const { control, handleSubmit, reset, clearErrors } = useForm<{ text: string }>({ defaultValues: { text: '' } });

	const { data, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } = useInfiniteQuery<GetPostCommentsResponse>(
		['postComments', postId],
		({ pageParam }) => getPostCommentsRequest?.({ postId, pageParam }) as unknown as GetPostCommentsResponse,
		{
			enabled: true,
			keepPreviousData: true,
			getNextPageParam: (response) => (response?.meta?.nextPage ? [response?.meta?.nextPage] : null),
			initialData: { pageParams: [1], pages: [{ data: [], meta: { page: 1, nextPage: null, left: 0 } }] },
		}
	);

	const { mutate: addCommentMutate, isLoading: addCommentLoading } = useMutation(
		// (text: string) => userPanel.addPostComment(postId, { text }),
		(text: string) => addPostCommentRequest?.({ postId, payload: { text } }) as unknown as any,
		{
			onSuccess: () => {
				reset();
				refetch();
			},
		}
	);

	const lastPageData = data ? [...(data?.pages || [])].pop() : null;

	const comments = (data?.pages || [])
		.map(({ data }) => data)
		.reduce((current, value) => [...current, ...value], [])
		.sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? 1 : -1));

	const handleSubmitForm = handleSubmit((values) => {
		if (updateCommentMode) {
			updateCommentMutate({ commentId: updateCommentMode, text: values.text });
		} else {
			addCommentMutate(values.text);
		}
	});

	const [updateCommentMode, setUpdateCommentMode] = useState<number | null>(null);

	const {
		mutate: updateCommentMutate,
		isLoading: updateCommentLoading,
		variables: updateCommentVariables,
	} = useMutation(
		(variables: { commentId: number; text: string }) =>
			userPanelApi.updatePostComment(postId, variables.commentId, { text: variables.text }),
		{
			onSuccess: () => {
				refetch();
				setUpdateCommentMode(null);
				reset({ text: '' });
				clearErrors();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.dashboard.comment.updateSuccess'),
				});
			},
		}
	);

	const handleCancelUpdate = () => {
		setUpdateCommentMode(null);
		reset({ text: '' });
		clearErrors();
	};

	const [selectedCommentToRemove, setSelectedCommentToRemove] = useState<number | null>(null);
	const [removeCommentConfirmationOpen, setRemoveCommentConfirmationOpen] = useState(false);
	const {
		mutate: removeCommentMutate,
		isLoading: removeCommentLoading,
		variables: removeCommentVariables,
	} = useMutation((commentId: number) => userPanelApi.removePostComment(postId, commentId), {
		onSuccess: () => {
			refetch();
			setSelectedCommentToRemove(null);
			reset({ text: '' });
			clearErrors();
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.dashboard.comment.removeSuccess'),
			});
		},
	});
	const handleAcceptRemove = () => {
		if (selectedCommentToRemove) {
			removeCommentMutate(selectedCommentToRemove);
		}
	};

	return (
		<>
			<CustomConfirmDialog
				confirmationOpen={removeCommentConfirmationOpen}
				setConfirmationOpen={setRemoveCommentConfirmationOpen}
				handleAccept={handleAcceptRemove}
				message={t('userPanel.dashboard.comment.confirmRemove')}
			/>
			<div className="">
				<div className="w-full my-2" style={{ borderTop: '1px solid lightgray' }} />
				<div className="w-full flex flex-row justify-content-center">
					{hasNextPage ? (
						<Button
							type="button"
							label={t('userPanel.dashboard.comment.seePrevComments', {
								count: lastPageData?.meta?.left || 0,
							})}
							size="xs"
							className="p-button-outlined p-button-rounded"
							onClick={() => fetchNextPage()}
							disabled={!hasNextPage || isFetchingNextPage}
							loading={isFetchingNextPage}
						/>
					) : (
						<p className="text-gray text-xs">{t('userPanel.dashboard.comment.noPrevComments')}</p>
					)}
				</div>
				{comments.length > 0 && (
					<ul className="list-none p-0 mt-1 mb-1">
						{comments.map((comment) => {
							const { user } = comment;
							const displayName = userDisplayName(user);
							const isYour = comment.userId === currentUser?.id;
							const canEdit = isYour;
							const canDelete = isYour || adminMode;

							return (
								<li key={comment.id} className="p-0">
									<div className="flex flex-col gap-2 py-1">
										<Avatar
											src={user.avatarActive ? user.avatar : undefined}
											name={userDisplayName(user, null)}
											size="md"
										/>
										<div className="flex flex-column gap-0">
											<div>
												<span className="bold mr-2">{displayName}</span>
												<span>{dateFormat(comment.createdAt, 'full')}</span>
												{(canEdit || canDelete) && (
													<span className="inline-flex gap-1">
														{canEdit && (
															<button
																type="button"
																className={classNames(
																	'p-button p-button-link p-button-sm p-1',
																	{
																		'text-gray': !(
																			updateCommentMode === comment.id
																		),
																	}
																)}
																onClick={() => {
																	setUpdateCommentMode(comment.id);
																	reset({ text: comment.text });
																}}
																aria-label={t('actions.edit')}
																disabled={
																	updateCommentLoading &&
																	updateCommentVariables?.commentId === comment.id
																}
															>
																<i className="fa-solid fa-pen" />
															</button>
														)}
														{canDelete && (
															<button
																type="button"
																className="p-button p-button-link p-button-sm p-1 text-gray"
																onClick={() => {
																	setSelectedCommentToRemove(comment.id);
																	setRemoveCommentConfirmationOpen(true);
																}}
																aria-label={t('actions.remove')}
																disabled={
																	removeCommentLoading &&
																	removeCommentVariables === comment.id
																}
															>
																<i className="fa-solid fa-trash" />
															</button>
														)}
													</span>
												)}
											</div>
											<div>
												{parseHtml(comment.text, {
													nl2br: false,
												})}
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				)}
				<form onSubmit={handleSubmitForm} className="flex flex-col gap-2">
					<Avatar src={currentUser?.avatar} name={userDisplayName(currentUser!, null)} size="md" />
					<div className="flex-1">
						<ValidateTextArea
							placeholder={t('userPanel.dashboard.comment.addComment')}
							name="text"
							control={control}
							required
							disabled={addCommentLoading || updateCommentLoading || removeCommentLoading}
							rows={1}
						/>
					</div>
					<div className="flex flex-col gap-1 align-items-start">
						{updateCommentMode ? (
							<>
								<Button
									type="button"
									label={t('actions.cancel')}
									className="p-button-sm p-button-rounded p-button-outlined"
									loading={
										updateCommentLoading && updateCommentVariables?.commentId === updateCommentMode
									}
									onClick={handleCancelUpdate}
								/>

								<Button
									type="submit"
									label={t('actions.save')}
									className="p-button-sm p-button-rounded"
									loading={
										updateCommentLoading && updateCommentVariables?.commentId === updateCommentMode
									}
								/>
							</>
						) : (
							<Button
								type="submit"
								label={t('actions.add')}
								className="p-button-sm p-button-rounded"
								loading={addCommentLoading}
							/>
						)}
					</div>
				</form>
			</div>
		</>
	);
};

export default NotificationPostComments;
