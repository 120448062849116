import React, { Fragment } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useTranslation } from 'react-i18next';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { statisticsApi } from 'api/statistics';
import { Spinner } from 'components/_new/Spinner';
import { Box, BoxSection } from 'components/_new/Box';
import { Tooltip } from 'primereact/tooltip';
import { StatsItem, StatsItemProgress } from '../components/StatsItem';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const Summary = () => {
	const { t } = useTranslation();
	const { data: program } = useProgramPanel();

	const { data: summaryData, isLoading: summaryIsLoading } = useQuery(
		['programStatistics', { type: 'summary', programId: Number(program?.id) }],
		() => statisticsApi.getSummary(Number(program?.id)),
		{
			enabled: Boolean(program),
			cacheTime: 10000,
		}
	);

	const { data: progressData, isLoading: progressIsLoading } = useQuery(
		['programStatistics', { type: 'progress', programId: Number(program?.id) }],
		() => statisticsApi.getProgress(Number(program?.id)),
		{
			enabled: Boolean(program),
			cacheTime: 10000,
		}
	);

	const chartProgressData = [
		{
			key: 'sessionNo1',
			label: t('programPanel.statistics.sessionNo', { no: 1 }),
			value: progressData?.sessionParticipations?.[0].perc || 0,
		},
		{
			key: 'sessionNo2',
			label: t('programPanel.statistics.sessionNo', { no: 2 }),
			value: progressData?.sessionParticipations?.[1].perc || 0,
		},
		{
			key: 'sessionNo3',
			label: t('programPanel.statistics.sessionNo', { no: 3 }),
			value: progressData?.sessionParticipations?.[2].perc || 0,
		},
		{
			key: 'sessionNo4',
			label: t('programPanel.statistics.sessionNo', { no: 4 }),
			value: progressData?.sessionParticipations?.[3].perc || 0,
		},
		{
			key: 'sessionNo5',
			label: t('programPanel.statistics.sessionNo', { no: 5 }),
			value: progressData?.sessionParticipations?.[4].perc || 0,
		},
		{
			key: 'sessionNo6',
			label: t('programPanel.statistics.sessionNo', { no: 6 }),
			value: progressData?.sessionParticipations?.[5].perc || 0,
		},
		{
			key: 'sessionPenultimate',
			label: t('programPanel.statistics.penultimate'),
			value: progressData?.sessionParticipations?.[6].perc || 0,
		},
		{
			key: 'sessionLast',
			label: t('programPanel.statistics.last'),
			value: progressData?.sessionParticipations?.[7].perc || 0,
		},
	];

	const chartContractData = [
		{
			key: 'contractSigned',
			label: t('programPanel.statistics.contractSigned'),
			value: progressData?.contract?.[0]?.perc || 0,
		},
		{
			key: 'goalsSigned',
			label: t('programPanel.statistics.goalsSigned'),
			value: progressData?.contract?.[1].perc || 0,
		},
	];

	return (
		<>
			{summaryIsLoading || !summaryData ? (
				<Spinner />
			) : (
				<div className="grid">
					<div className="col-12">
						<div className=" flex flex-row flex-wrap">
							<div className="col-12 sm:col-6 lg:col-4">
								<StatsItem
									name={t('programPanel.statistics.menteeAcceptedAplpications')}
									value={summaryData?.applications?.mentees}
									variant="purplish"
								/>
							</div>
							<div className="col-12 sm:col-6 lg:col-4">
								<StatsItem
									name={t('programPanel.statistics.mentorAcceptedAplpications')}
									value={summaryData?.applications?.mentors}
									variant="purplish"
								/>
							</div>
							<div className="col-12 sm:col-6 lg:col-4">
								<StatsItem
									name={t('programPanel.statistics.createdPairs')}
									value={summaryData?.pairs.created}
									variant="lpurplish"
								/>
							</div>
							<div className="col-12 sm:col-6 lg:col-4">
								<StatsItemProgress
									name={t('programPanel.statistics.menteeMatchedPairParticipation')}
									value={`${summaryData?.pairs.participation?.mentees}%`}
									progressValue={summaryData?.pairs.participation?.mentees}
									variant="rose"
								/>
							</div>
							<div className="col-12 sm:col-6 lg:col-4">
								<StatsItemProgress
									name={t('programPanel.statistics.mentorMatchedPairParticipation')}
									value={`${summaryData?.pairs.participation?.mentors || 0}%`}
									progressValue={summaryData?.pairs.participation?.mentors}
									variant="rose"
								/>
							</div>
							<div className="col-12 sm:col-6 lg:col-4">
								<StatsItemProgress
									name={t('programPanel.statistics.activePairs')}
									value={summaryData?.pairs.accepted}
									progressValue={
										summaryData?.pairs.created > 0 && summaryData?.pairs.accepted > 0
											? (Number(summaryData?.pairs.accepted) /
													Number(summaryData?.pairs.created)) *
											  100
											: 0
									}
									variant="lpurplish"
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			<hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />

			{progressIsLoading || !progressData ? (
				<Spinner />
			) : (
				<div className="flex flex-column align-items-between program-card">
					<div className="text-xl my-3 text-darkgray font-bold">
						{t('programPanel.statistics.programProgress')}
					</div>
					<div className="grid">
						<div className="col-12 md:col-8 flex flex-column gap-3">
							<Box variant="white-bordered">
								<BoxSection>
									<h4 className="text-purplish-blue">
										{t('programPanel.statistics.pairsParticipationByCurrentStage')}
									</h4>

									<div className="chart" role="img">
										<div className="chart-data">
											{chartProgressData.map(({ key, label, value }) => (
												<Fragment key={key}>
													<Tooltip target={`#chart-bar-${key}`} position="bottom">
														{t(
															'programPanel.statistics.pairsParticipationByCurrentStagePerc',
															{ perc: Number(value) }
														)}
													</Tooltip>
													<div className="chart-data-label">{label}</div>
													<div className="chart-data-bar-container">
														<div className="chart-data-bar-grid">
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
														</div>
														<div
															id={`chart-bar-${key}`}
															className="chart-data-bar"
															style={{ width: `${value}%` }}
														/>
													</div>
												</Fragment>
											))}
										</div>
										<div className="chart-data">
											<div className="chat-bottom-labels zero">
												<span>0%</span>
											</div>
											<div className="chat-bottom-labels">
												<span>10%</span>
												<span>20%</span>
												<span>30%</span>
												<span>40%</span>
												<span>50%</span>
												<span>60%</span>
												<span>70%</span>
												<span>80%</span>
												<span>90%</span>
												<span>100%</span>
											</div>
										</div>
									</div>
								</BoxSection>
							</Box>
							<Box variant="white-bordered">
								<BoxSection>
									<h4 className="text-purplish-blue">
										{t('programPanel.statistics.contractSignedParticipation')}
									</h4>

									<div className="chart" role="img">
										<div className="chart-data">
											{chartContractData.map(({ key, label, value }) => (
												<Fragment key={key}>
													<Tooltip target={`#chart-bar-${key}`} position="bottom">
														{t('programPanel.statistics.contractSignedParticipationPerc', {
															perc: Number(value),
														})}
													</Tooltip>
													<div className="chart-data-label">{label}</div>
													<div className="chart-data-bar-container">
														<div className="chart-data-bar-grid">
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
															<span />
														</div>
														<div
															id={`chart-bar-${key}`}
															className="chart-data-bar"
															style={{ width: `${value}%` }}
														/>
													</div>
												</Fragment>
											))}
										</div>
										<div className="chart-data">
											<div className="chat-bottom-labels zero">
												<span>0%</span>
											</div>
											<div className="chat-bottom-labels">
												<span>10%</span>
												<span>20%</span>
												<span>30%</span>
												<span>40%</span>
												<span>50%</span>
												<span>60%</span>
												<span>70%</span>
												<span>80%</span>
												<span>90%</span>
												<span>100%</span>
											</div>
										</div>
									</div>
								</BoxSection>
								{/* <BoxSection>
									<div className="w-full" style={{ width: 'auto', maxHeight: 490 }}>
										<h4 className="text-purplish-blue">
											{t('programPanel.statistics.pairsParticipationByCurrentStage')}
										</h4>

										<Bar
											style={{ minHeight: 300, maxHeight: 440 }}
											options={{
												maintainAspectRatio: false,
												responsive: true,
												indexAxis: 'y',
												scales: {
													y: {
														ticks: {
															autoSkip: false,
															align: 'end',
															padding: 5,
														},
													},
													x: {
														min: 0,
														max: 100,
														ticks: {
															stepSize: 10,
															callback(value, index, values) {
																return `${value}%`;
															},
														},
													},
												},
												plugins: {
													legend: {
														display: false,
													},
													tooltip: {
														callbacks: {
															label: (item) =>
																String(
																	t(
																		'programPanel.statistics.pairsParticipationByCurrentStagePerc',
																		{ perc: Number(item.formattedValue) }
																	)
																),
														},
													},
												},
											}}
											datasetIdKey="sessions"
											data={{
												labels: [
													t('programPanel.statistics.contractSigned'),
													t('programPanel.statistics.goalsSigned'),
													t('programPanel.statistics.sessionNo', { no: 1 }),
													t('programPanel.statistics.sessionNo', { no: 2 }),
													t('programPanel.statistics.sessionNo', { no: 3 }),
													t('programPanel.statistics.sessionNo', { no: 4 }),
													t('programPanel.statistics.sessionNo', { no: 5 }),
													t('programPanel.statistics.sessionNo', { no: 6 }),
													t('programPanel.statistics.penultimate'),
													t('programPanel.statistics.last'),
												].map(String),
												datasets: [
													{
														data: [
															...(progressData?.contract || []),
															...(progressData?.sessionParticipations || []),
														].map((item: any) => item.perc),
														backgroundColor: '#cec5ff',
													},
												],
											}}
										/>
									</div>
								</BoxSection> */}
							</Box>

							{/* <Box>
								<BoxSection>
									<div className="w-full" style={{ width: 'auto', maxHeight: 350 }}>
										<h4 className="text-purplish-blue">
											{t('programPanel.statistics.contractSignedParticipation')}
										</h4>
										<Bar
											style={{ maxHeight: 150 }}
											options={{
												responsive: true,
												animation: false,
												indexAxis: 'y',
												plugins: {
													legend: {
														display: false,
													},
													tooltip: {
														callbacks: {
															label: (item) =>
																String(
																	t(
																		'programPanel.statistics.contractSignedParticipationPerc',
																		{
																			perc: Number(item.formattedValue),
																		}
																	)
																),
														},
													},
												},
												scales: {
													x: {
														min: 0,
														max: 100,
														ticks: {
															stepSize: 10,
															callback(value, index, values) {
																return `${value}%`;
															},
														},
													},
												},
											}}
											datasetIdKey="signes"
											data={{
												labels: [
													t('programPanel.statistics.contractSigned'),
													t('programPanel.statistics.goalsSigned'),
												],
												datasets: [
													{
														data: (progressData?.contract || []).map(
															(item: any) => item.perc
														),
														backgroundColor: '#cec5ff',
													},
												],
											}}
										/>
									</div>
								</BoxSection>
							</Box> */}
						</div>
						<div className="col-12 md:col-4 flex flex-column gap-3">
							<StatsItemProgress
								name={t('programPanel.statistics.pairsWithMeetingLast30daysParticipation')}
								value={`${progressData?.pairsWithLastMonthMeeting.perc}%`}
								progressValue={progressData?.pairsWithLastMonthMeeting.perc}
								variant="rose"
							/>
							<StatsItemProgress
								name={t('programPanel.statistics.usersWithLoginInLastMonth')}
								value={`${progressData?.usersWithLoginInLastMonth.perc}%`}
								progressValue={progressData?.usersWithLoginInLastMonth.perc}
								variant="rose"
							/>
							<StatsItemProgress
								name={t('programPanel.statistics.avgMentorSessionSatisfaction')}
								value={`${progressData?.rating.mentors}/5`}
								progressValue={
									progressData?.rating.mentors && progressData?.rating.mentors > 0
										? (Number(progressData?.rating.mentors) / 5) * 100
										: 0
								}
								variant="purplish"
							/>
							<StatsItemProgress
								name={t('programPanel.statistics.avgMenteeSessionSatisfaction')}
								value={`${progressData?.rating.mentees}/5`}
								progressValue={
									progressData?.rating.mentees && progressData?.rating.mentees > 0
										? (Number(progressData?.rating.mentees) / 5) * 100
										: 0
								}
								variant="purplish"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
