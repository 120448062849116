import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	MultiSelect as MultiSelectOriginal,
	MultiSelectProps as MultiSelectPropsOriginal,
} from 'primereact/multiselect';
import { Spinner } from '../Spinner';

export type MultiSelectProps = MultiSelectPropsOriginal & {
	loading?: boolean;
};

export const MultiSelect = ({
	loading,
	display,
	disabled,
	placeholder,
	optionGroupLabel,
	optionGroupChildren,
	...restProps
}: MultiSelectProps) => {
	const { t } = useTranslation();
	return (
		<MultiSelectOriginal
			{...restProps}
			display={display || 'chip'}
			placeholder={loading ? t('misc.loading') : placeholder}
			optionGroupLabel={optionGroupLabel || 'label'}
			optionGroupChildren={optionGroupChildren || 'items'}
			disabled={disabled || loading}
			dropdownIcon={loading ? <Spinner size="xs" /> : undefined}
		/>
	);
};
