import { httpClient } from 'api';
import { EmailChange } from 'types/EmailChange';
import { ConfirmEmailChangePayload } from 'types/payloads/ConfirmEmailChangePayload';
import { SendEmailChangePayload } from 'types/payloads/SendEmailChangePayload';
import { ValidateEmailChangePayload } from 'types/payloads/ValidateEmailChangePayload';

export const emailChangesApi = {
	getMyLast: () => httpClient.get<EmailChange>('/email-change'),
	sendEmailChange: (payload: SendEmailChangePayload) => httpClient.post('/email-change', payload),
	cancelEmailChange: () => httpClient.delete<boolean>('/email-change'),
	validateEmailChange: ({ token }: ValidateEmailChangePayload) =>
		httpClient.get<boolean>(`/email-change/${token}/validate`),
	confirmEmailChange: ({ token }: ConfirmEmailChangePayload) =>
		httpClient.post<boolean>(`/email-change/${token}/confirm`),
};
