import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { CreateGoalPayload, UpdateGoalPayload } from 'types/payloads/GoalPayload';

const useGoals = (pairId: number) => {
	const { currentProgramMembership } = usePanel();
	const queryClient = useQueryClient();
	const {
		refetch: getGoals,
		data: fetchedGoals,
		isLoading: goalLoading,
		isError: goalError,
	} = useQuery(['goals', pairId], () => userPanelApi.getGoals(+pairId, currentProgramMembership.id));

	const { mutate: updateGoal } = useMutation(
		(goalPayload: UpdateGoalPayload) => userPanelApi.updateGoal(+pairId, currentProgramMembership.id, goalPayload),
		{
			onSuccess: () => getGoals(),
		}
	);

	const { mutate: deleteGoal } = useMutation(
		(goalId: number) => userPanelApi.deleteGoal(+pairId, currentProgramMembership.id, goalId),
		{
			onSuccess: () => getGoals(),
		}
	);

	const { mutate: saveNewGoal } = useMutation(
		(goalPayload: CreateGoalPayload) => userPanelApi.postGoal(+pairId, currentProgramMembership.id, goalPayload),
		{
			onSuccess: () => getGoals(),
		}
	);
	const { mutate: signGoals } = useMutation(() => userPanelApi.signGoal(+pairId, currentProgramMembership.id), {
		onSuccess: () => {
			getGoals();
			queryClient.refetchQueries({ queryKey: ['sessions', { pairId: Number(pairId) }] }).then();
		},
	});

	useEffect(() => {
		getGoals();
	}, [getGoals]);

	return {
		getGoals,
		updateGoal,
		deleteGoal,
		saveNewGoal,
		signGoals,
		fetchedGoals,
		goalLoading,
		goalError,
	};
};

export default useGoals;
