import React, { useState, useMemo, ReactNode, CSSProperties, useEffect } from 'react';
import { sortByProp } from 'utils/sort';
import { classNames } from 'primereact/utils';
import { getNestedValue } from 'utils/object';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import { Icon } from '../Icon';

export type TableColumn<T = any> = {
	label: string;
	longLabel?: string;
	name: string;
	select?: string;
	body?: (data: T) => ReactNode;
	sortable?: boolean;
	bodyStyle?: CSSProperties;
	bodyClassName?: string;
	isDate?: boolean;
};

type TableProps<T> = {
	keyOptionProp?: string;
	data: T[];
	columns: TableColumn<T>[];
	defaultSort?: {
		field: keyof T | string;
		order: 'asc' | 'desc';
	};
	onSort?: (field: keyof T | string, order: 'desc' | 'asc' | null) => void;
	pagination?: {
		itemsPerPage: number;
		total: number;
		current: number;
		onPageChange: (page: number) => void;
	};
	isRefetching?: boolean;
};

export const Table = <T = any,>(props: TableProps<T>) => {
	const { keyOptionProp, data, columns, defaultSort, onSort, pagination, isRefetching } = props;
	const finalKeyOptionProp = keyOptionProp || 'id';

	const [currentSort, setCurrentSort] = useState<{ field: keyof T | string; order: 'desc' | 'asc' | null }>(
		defaultSort || {
			field: columns[0].name as keyof T,
			order: 'asc',
		}
	);

	const handleSort = (column: keyof T | string, order: 'desc' | 'asc' | null) => {
		if (onSort) {
			onSort(column, order);
		} else {
			setCurrentSort({ field: column, order });
		}
	};

	useEffect(() => {
		if (onSort && defaultSort) {
			setCurrentSort(defaultSort);
		}
	}, [defaultSort, onSort]);

	const sortedData = useMemo(() => {
		if (onSort) {
			return data;
		}
		return currentSort?.field && currentSort?.order
			? sortByProp(data, currentSort.field as any, currentSort.order || 'asc')
			: data;
	}, [data, currentSort, onSort]);

	const handlePageChange = ({ page }: PaginatorPageState) => {
		if (pagination?.onPageChange) {
			pagination.onPageChange(page + 1);
		}
	};

	return (
		<div>
			<div className="w-full overflow-x-auto">
				<table
					className={classNames('p-datatable w-full', {
						'opacity-70 pointer-events-none': isRefetching,
					})}
				>
					<thead className="p-datatable-thead">
						<tr>
							{columns.map(({ name, label, longLabel, sortable }) => {
								const currentSorted = currentSort?.field === name;
								return (
									<th
										key={name}
										className={classNames('p-0', { 'p-sortable-column': sortable })}
										onClick={(event) => {
											if (!sortable) {
												event.preventDefault();
											} else {
												const ascing = currentSort?.order === 'asc' ? 'desc' : 'asc';
												handleSort(name as keyof T, ascing);
											}
										}}
									>
										{longLabel ? (
											<Tooltip content={longLabel} target={`#header-${name}`} position="top" />
										) : null}

										<div
											id={`header-${name}`}
											className="flex flex-row gap-2 align-items-center p-2"
										>
											<span>{label}</span>
											{sortable ? (
												<Icon
													iconSet="fa"
													iconStyle="solid"
													name={currentSort?.order === 'asc' ? 'caret-up' : 'caret-down'}
													className={currentSorted ? 'text-primary' : undefined}
												/>
											) : (
												<span />
											)}
										</div>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody className="p-datatable-tbody">
						{sortedData.map((data) => {
							const trKey = String((data as any)?.[finalKeyOptionProp]);
							return (
								<tr key={trKey} className="p-1">
									{columns.map(({ name, select, body, bodyStyle, bodyClassName, isDate = false }) => {
										const tdKey = `${trKey}-${name}`;
										const rawVal = getNestedValue(data, (select || name) as any);
										let val;
										if (body) {
											val = body(data);
										} else {
											val = rawVal;
											const isEmpty =
												typeof val === 'undefined' ||
												(typeof val === 'string' && val.trim() === '');
											if (isEmpty) {
												val = '-';
											}

											// for dates
											else if (val instanceof Date) {
												val.toLocaleString();
											}

											// if is date
											else if (isDate) {
												val =
													rawVal && new Date(val).toString() !== 'Invalid Date'
														? new Date(val).toLocaleString()
														: 'Invalid Date';
											}
										}

										return (
											<td key={tdKey} style={bodyStyle} className={bodyClassName}>
												{val}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{pagination && (
				<Paginator
					first={pagination.current * pagination.itemsPerPage - pagination.itemsPerPage + 1}
					rows={pagination.itemsPerPage}
					totalRecords={pagination.total}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};
