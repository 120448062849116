import React, { useContext, createContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { usePanel } from 'contexts/panelContext';
import { Pair } from 'types/Pair';
import PageMissing from 'components/PageMissing';
import { PageLoading } from 'components/PageLoading';
import { Goals } from 'types/Goal';
import useGoals from 'hooks/useGoals';
import { Application } from 'types/Application';
import { userPanelApi } from 'api';
import { useQuery } from '@tanstack/react-query';
import { ContractType } from 'types/Contract';
import { useTranslation } from 'react-i18next';

type PairContextType = {
	pair: Pair;
	application: Application;
	goals?: Goals;
	contract: ContractType;
};

export const PairContext = createContext<PairContextType>({} as PairContextType);

export const PairProvider = () => {
	const { pairId } = useParams();

	const { userPairs, userPairsLoading, userPairsFetched, currentApplicationData, currentProgramMembership } =
		usePanel();
	const pair = (userPairs || []).find(({ id }) => id === Number(pairId));
	const { t } = useTranslation();
	const { data: contractData, isLoading: contractLoading } = useQuery(
		['contract', pairId],
		() => userPanelApi.getContract(Number(pair?.id), currentProgramMembership.id),
		{
			enabled: Boolean(pair?.id),
		}
	);

	const { fetchedGoals } = useGoals(Number(pairId));

	if (!userPairsFetched || userPairsLoading || contractLoading) {
		return <PageLoading text={t('userPanel.pairing.loadingPairMessage')} />;
	}

	if (userPairsFetched && !pair) {
		return <PageMissing />;
	}

	if (!pair || !contractData) {
		return null;
	}

	return (
		<PairContext.Provider
			value={{
				pair,
				application:
					currentApplicationData.applicationRole === 'mentee'
						? pair.mentorApplication
						: pair.menteeApplication,
				goals: fetchedGoals,
				contract: contractData,
			}}
		>
			<Outlet />
		</PairContext.Provider>
	);
};

export const usePair = () => useContext(PairContext);
