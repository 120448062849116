/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { authApi, httpClient, apiUrl } from 'api';
import { useGlobal } from 'contexts/globalContext';
import { RegisterPayload } from 'types/payloads/RegisterPayload';
import { StoredKeys } from 'types/Auth';
import { Button } from 'primereact/button';
import TextField from 'components/_common/forms/TextField';
import Checkbox from 'components/_common/forms/Checkbox';
import { colors } from 'theme';
import { resolveSiteUrl, resolveStaticsUrl } from 'utils/urlBuilder';
import GoogleIcon from 'assets/icons/google-logo.svg';
import OfficeIcon from 'assets/icons/office-logo.svg';
import { translatedStaticResourceResolver } from 'utils/translatedStaticResourceResolver';
import { AxiosError } from 'axios';

interface RegisterFormObject extends RegisterPayload {
	terms: boolean;
}

const Register: FC = () => {
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const { toastRef } = useGlobal();

	const {
		control,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<RegisterFormObject>({
		defaultValues: {
			email: '',
			language: i18n.resolvedLanguage,
			terms: false,
		},
	});

	const lastVisitedProgramName = window.localStorage.getItem(StoredKeys.ProgramName) as string;

	useEffect(() => {
		if (!lastVisitedProgramName) navigate('/login');
	});

	const {
		mutate,
		isSuccess,
		isLoading: isSubmitting,
	} = useMutation((data: RegisterPayload) => authApi.registerUser(data), {
		onError: (error) => {
			if (error instanceof AxiosError) {
				const data = error.response?.data;
				const message = data?.message;
				if (message) {
					if (message === 'userEmailExists') {
						const translated = t(`auth.messages.${message}`);
						setError('email', {
							type: 'custom',
							message: translated,
						});
					} else {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t('misc.error'),
							detail: data.message,
						});
					}
				} else {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.error'),
						detail: error.message,
					});
				}
			}
		},
	});

	const { mutate: googleLogin } = useMutation(
		['googleLogin'],
		({ type, ...data }: any) => httpClient.post<string>(`auth/${type}`, data),
		{
			onSuccess: (token: string, { type }: any) => {
				window.localStorage.setItem(`${type}-auth-data`, token);
				window.location.href = `${apiUrl}/auth/${type}/redirect`;
			},
		}
	);

	const handleExternalLoginClick = (type: string) => {
		const data = { provider: type, programName: window.localStorage.getItem('programName') };
		googleLogin({ type, ...data });
	};

	if (isSuccess) {
		return (
			<>
				<div>{t('auth.thankYouMessage')}</div>
				<p>{t('auth.closeTabAndCheckInbox')}</p>
			</>
		);
	}

	return (
		<>
			<Helmet title={t('auth.register')} />
			<form
				onSubmit={handleSubmit(({ email, language }) =>
					mutate({ email, language, programName: lastVisitedProgramName })
				)}
				className="flex flex-column row-gap-4"
			>
				<div className="text-sm">
					<p>{t('auth.registerTooltip1')}</p>
					<p>{t('auth.registerTooltip2')} </p>
				</div>

				<TextField name="email" placeholder={t('auth.inputs.email')} control={control} required />

				<Checkbox
					name="terms"
					control={control}
					text={
						<label htmlFor="termsOfService" className="text-sm">
							{t('auth.accept')}
							{/* eslint-disable-next-line */
							/*  TODO remove when correct file link is implemented */}
							<a
								href={translatedStaticResourceResolver('terms')}
								target="_blank"
								style={{
									color: errors.terms ? 'inherit' : colors.purplishBlue,
								}}
								rel="noreferrer"
							>
								{t('auth.termsOfService')}
							</a>
						</label>
					}
					required
				/>

				<Button
					type="submit"
					style={{ display: 'unset' }}
					loading={isSubmitting}
					label={t('auth.buttons.sendLink')}
				/>

				<span className="text-center">{t('misc.or')}</span>

				<div className="flex justify-content-between">
					<Button
						type="button"
						onClick={() => handleExternalLoginClick('google')}
						className="flex column-gap-3 w-full p-button-raised p-button-text p-button-secondary border-round-3xl text-xs px-3 py-2 align-self-center"
					>
						<img src={GoogleIcon} alt="icon" />
						{t('auth.buttons.registerWithGoogle')}
					</Button>
					<Button
						type="button"
						onClick={() => handleExternalLoginClick('microsoft')}
						className="flex column-gap-3 w-full p-button-raised p-button-text p-button-secondary border-round-3xl text-xs px-3 py-2 align-self-center"
					>
						<img src={OfficeIcon} alt="icon" />
						{t('auth.buttons.registerWithOffice')}
					</Button>
				</div>
			</form>
		</>
	);
};

export default Register;
