import React, { forwardRef, Ref } from 'react';
import { DialogConfirm, DialogConfirmExtendProps } from 'components/_new/Dialog';
import { useTranslation } from 'react-i18next';

export const RemoveSessionDialogConfirm = forwardRef((props: DialogConfirmExtendProps, ref: Ref<HTMLDivElement>) => {
	const { t } = useTranslation();
	return (
		<DialogConfirm ref={ref} title="Potiwerdź usunięcie" {...props}>
			{t('userPanel.sessionsNew.confirmDeleteSession')}
		</DialogConfirm>
	);
});
