import React from 'react';
import { usePair } from 'contexts/pairContext';
import PersonalDetails from './PersonalDetails';

export const PairDetails = () => {
	const {
		pair: { application },
	} = usePair();
	return <PersonalDetails applicationData={application} />;
};
