import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { postsApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Button } from 'components/_common/Button';
import { Post } from 'types/Dashboard';
import { Spinner } from 'components/_new/Spinner';
import NotificationPost from './NotificationPost';
import ProgressSteps from '../_common/ProgressSteps/ProgressSteps';

const Dashboard = () => {
	const { currentProgramMembership, currentApplicationData } = usePanel();
	const { t } = useTranslation();
	const postsPerLoad = 10;

	const { data, fetchNextPage, isLoading, isFetchingNextPage, isSuccess, hasNextPage } = useInfiniteQuery(
		[
			'posts',
			{
				membershipId: currentProgramMembership?.id,
				applicationId: currentApplicationData.id,
			},
		],
		({ pageParam }) =>
			postsApi.getPosts({
				membershipId: currentProgramMembership?.id,
				applicationId: currentApplicationData.id,
				params: {
					page: pageParam || 1,
					pageSize: postsPerLoad,
				},
			}),
		{
			onSuccess: ({ pages }) => pages,
			enabled: true,
			keepPreviousData: true,
			getNextPageParam: ({ pagination }) => {
				const nextPage = pagination.currentPage + 1;
				if (nextPage > pagination.itemsPerPage) {
					return false;
				}
				return nextPage;
			},
		}
	);

	const posts = (data?.pages || []).reduce<Post[]>((current, item) => {
		return [...current, ...item.data];
	}, []);

	return (
		<div className="h-full">
			<ProgressSteps />
			<SubPageTitle title={t('userPanel.dashboard.notifications')} />

			{isLoading && <Spinner />}
			{!isLoading && isSuccess && posts && posts.length === 0 && <p> {t('programPanel.post.noPosts')}</p>}
			{isSuccess &&
				posts &&
				posts.length > 0 &&
				posts.map((post) => (
					<NotificationPost
						key={post.id}
						postData={post}
						withComments
						getPostCommentsRequest={({ postId, pageParam }) => {
							return postsApi.getPostComments({
								postId,
								params: {
									page: pageParam[0] || 1,
									limit: 3,
								},
							});
						}}
						addPostCommentRequest={({ postId, payload }) => {
							return postsApi.addPostComment({ postId, payload });
						}}
					/>
				))}
			{hasNextPage && (
				<div className="flex justify-content-center">
					<Button label={t('misc.loadMore')} onClick={() => fetchNextPage()} loading={isFetchingNextPage} />
				</div>
			)}
		</div>
	);
};

export default Dashboard;
