type NestedKey<T> = T extends object ? `${string & keyof T}` | `${string & keyof T}.${NestedKey<T[keyof T]>}` : never;

export function getNestedValue<T>(obj: T, path: NestedKey<T>): any {
	const keys = path.split('.') as Array<keyof T>;

	let nestedValue: any = obj;

	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys) {
		nestedValue = nestedValue[key];
		if (nestedValue === undefined) {
			return undefined; // Jeśli którykolwiek klucz jest undefined, zwróć undefined
		}
	}

	return nestedValue;
}
