import React from 'react';

interface MatchingOptionsProps {
	option: {
		area: string;
		match: boolean;
	};
}

const OptionItem = ({ option }: MatchingOptionsProps) => (
	<div className="flex pr-2 mb-2 align-items-center w-full md:w-2" style={{ minWidth: '24%' }}>
		{option.match ? (
			<i className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl" />
		) : (
			<i className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl" />
		)}
		<div className="align-self-center">{option.area}</div>
	</div>
);

export default OptionItem;
