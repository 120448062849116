// eslint-disable-next-line import/no-mutable-exports
let baseIndex = 901;

export { baseIndex };

export function incrementIndex() {
	// eslint-disable-next-line no-plusplus
	baseIndex++;
	return baseIndex;
}

export function decrementIndex() {
	// eslint-disable-next-line no-plusplus
	baseIndex--;
	return baseIndex;
}
