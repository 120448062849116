import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogBaseExtendProps } from 'components/_new/Dialog';
import { MyAccount } from './MyAccount';

type MyAccountDialogProps = DialogBaseExtendProps;

export const MyAccountDialog = (props: MyAccountDialogProps) => {
	const { t } = useTranslation();
	return (
		<Dialog title={t('userPanel.myAccount.header')} size="sm" {...props}>
			<MyAccount />
		</Dialog>
	);
};
