import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Program } from 'types/Program';
import { ApplicationType } from 'types/Application';
import LandingPageForm from 'components/landingPages/LandingPageForm';
import { useScope } from 'contexts/scopeContext';
import { Spinner } from 'components/_new/Spinner';

interface LandingPageFormAccessProps {
	type: 'mentor' | 'mentee';
	programData: Program;
	applicationType: ApplicationType;
	recruitmentEnabled: boolean;
}

const LandingPageFormAccess: FC<LandingPageFormAccessProps> = (props) => {
	const { type, programData, applicationType, recruitmentEnabled } = props;
	const { t } = useTranslation();

	const { currentProgramMembership } = useScope();

	if (!currentProgramMembership) return <Spinner />;

	return (
		<>
			<Helmet title={t(`misc.pageTitles.apply-${type}`)} />
			<LandingPageForm
				type={type}
				requiresApplicationApproval={programData.requiresApplicationApproval}
				programId={programData.id}
				programLanguage={programData.language}
				applicationType={applicationType}
				currentProgramMembershipId={currentProgramMembership.id}
				recruitmentEnabled={recruitmentEnabled}
			/>
		</>
	);
};

export default LandingPageFormAccess;
