import React, { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Organization } from 'types/Organization';
import { organizationsApi } from 'api';

type OrganizationPanelContextValue = {
	myOrganizations: Pick<UseQueryResult<Organization[]>, 'data' | 'isLoading' | 'isSuccess'>;
	currentOrganization: { organizationName?: string } & Partial<
		Pick<UseQueryResult<Organization>, 'data' | 'isLoading' | 'isSuccess' | 'isError'>
	>;
};

export const OrganizationPanelContext = createContext<OrganizationPanelContextValue>({
	myOrganizations: {
		data: [],
		isLoading: false,
		isSuccess: false,
	},
	currentOrganization: {},
});

// eslint-disable-next-line @typescript-eslint/ban-types
type OrganizationPanelContextProviderProps = PropsWithChildren<{}>;

const OrganizationPanelContextProvider = ({ children }: OrganizationPanelContextProviderProps) => {
	const { organizationName } = useParams();

	const myOrganizations = useQuery(['myOrganizations'], organizationsApi.getMyOrganizations, {
		enabled: true,
		initialData: [],
	});

	const { data, isSuccess, isLoading, isError } = useQuery<Organization>(
		['currentOrganizationPanel', organizationName],
		() => organizationsApi.getOrganizationDetails({ organizationName: String(organizationName) }),
		{ enabled: Boolean(organizationName) }
	);

	return (
		<OrganizationPanelContext.Provider
			value={{ myOrganizations, currentOrganization: { organizationName, data, isSuccess, isLoading, isError } }}
		>
			{children}
		</OrganizationPanelContext.Provider>
	);
};

export const useOrganizationPanel = () => useContext(OrganizationPanelContext);

export default OrganizationPanelContextProvider;
