import React from 'react';
import CustomConfirmDialog, { CustomConfirmDialogProps } from 'components/_common/ConfirmDialog';
import { useTranslation } from 'react-i18next';

type MakeContactConfirmModalProps = Omit<CustomConfirmDialogProps, 'header' | 'message'>;

export const MakeContactConfirmModal = (props: MakeContactConfirmModalProps) => {
	const { t } = useTranslation();
	return <CustomConfirmDialog {...props} message={t('userPanel.potentialMembers.makeContactConfirmMessage')} />;
};
