import React, { Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';
import {
	InputTextarea as InputTextareaOriginal,
	InputTextareaProps as InputTextareaPropsOriginal,
} from 'primereact/inputtextarea';

type InputTextareaProps = InputTextareaPropsOriginal & {
	rounded?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	hasError?: any;
};

export const InputTextarea = forwardRef(
	({ hasError, rounded, className, ...props }: InputTextareaProps, ref: Ref<InputTextareaOriginal>) => {
		const finalClassName = classNames('w-full', className, {
			'border-round-3xl px-3': rounded,
			'p-invalid': Boolean(hasError),
		});
		return <InputTextareaOriginal ref={ref} className={finalClassName} {...props} />;
	}
);
