import React, { ReactNode } from 'react';

import { classNames } from 'primereact/utils';

interface CustomLabelProps {
	name: string;
	label?: string | ReactNode;
	children?: ReactNode;
	required?: boolean;
	inline?: boolean;
}

const CustomLabel = ({ name, label, children, required, inline }: CustomLabelProps) => (
	<label htmlFor={name} className={classNames({ 'block px-3': !inline })}>
		{label || children}
		{required && <span className="text-red-500">*</span>}
	</label>
);

export default CustomLabel;
