import React from 'react';
import { LinkExtendProps, Link } from 'components/_new/Link';
import { classNames } from 'primereact/utils';

export const LinkDoc = ({ className, ...props }: LinkExtendProps) => {
	return (
		<Link
			icon="file-lines"
			iconStyle="regular"
			variant="purple"
			className={classNames('link-doc', 'text-nowrap')}
			{...props}
		/>
	);
};
