import React, { FC, useCallback, useMemo, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';

interface Props {
	severity?: string;
	summary?: string;
	content: Array<{ text: ReactNode }>;
	className?: string;
}
const MessageBoxFull: FC<Props> = (props) => {
	const { t } = useTranslation();
	const { severity, summary, content, className } = props;

	const renderIcon = useCallback(() => {
		switch (severity) {
			case 'success':
				return <span className="p-message-icon pi pi-check-circle" />;
			case 'info':
				return <span className="p-message-icon pi pi-info-circle" />;
			case 'warn':
				return <span className="p-message-icon pi pi-exclamation-triangle" />;
			default:
				return null;
		}
	}, [severity]);

	const messageClassName = useMemo(() => {
		switch (severity) {
			case 'success':
				return 'p-message-success';
			case 'info':
				return 'p-message-info';
			case 'warn':
				return 'p-message-warn';
			default:
				return null;
		}
	}, [severity]);

	return (
		<div className="p-messages-no-icon">
			<div className={classNames('p-message p-component', messageClassName, className)}>
				<div className="p-message-wrapper">
					{renderIcon()}
					{summary && <span className="p-message-summary">{summary}</span>}
					<div className="flex flex-column">
						{content.map(({ text }, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<p key={i} className="p-message-detail">
								{text}
							</p>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MessageBoxFull;
