import React from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dropdown, DropdownProps } from './Dropdown';
import CustomLabel from './Label';

type ValidateDropdownProps<T extends FieldValues> = DropdownProps &
	UseControllerProps<T> & {
		options?: object[] | string[];
		label?: string;
		placeholder?: string;
		required?: boolean;
		value?: string | boolean;
		trueFalseSelector?: boolean;
	};

const ValidateDropdown = <T extends FieldValues>(props: ValidateDropdownProps<T>) => {
	const { name, value, label, placeholder, options, control, trueFalseSelector, required = false, rules } = props;

	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: {
			required: required ? `${placeholder} ${t('misc.forms.isRequired')}` : false,
			validate: (answer) => (answer ?? value) !== undefined, // with simple required rule trueFalseSelector won't work, because false as a value is regarded as no value
			...rules,
		},
	});

	return (
		<div>
			{label && <CustomLabel name={name} label={label} required={required} />}

			<Dropdown
				// {...field.value}
				inputRef={field.ref}
				value={field.value ?? value}
				options={
					trueFalseSelector
						? [
								{ label: t('misc.yes'), value: true },
								{ label: t('misc.no'), value: false },
						  ]
						: options
				}
				placeholder={t('misc.choose')}
				onChange={(e) => field.onChange(e.value)}
				hasError={error}
				className="mt-1"
				rounded
			/>

			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidateDropdown;
