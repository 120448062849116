import { Folder, File } from 'types/File';
import { slugify } from 'utils/helpers';

export const matchFiles = (passedData: Array<File | Folder>, filePath: string): File | Folder | null =>
	passedData.reduce((prev: File | Folder | null, file: File | Folder) => {
		if (prev) return prev;

		const fileNameFromUrl = filePath.split('/').slice(-1)[0];

		const checkNameMatch = slugify(file.name) === fileNameFromUrl;

		if (!checkNameMatch && Object.keys(file).includes('files'))
			return matchFiles((file as Folder).files as File[], filePath);
		if (checkNameMatch) return file;

		return prev;
	}, null);
