/* eslint-disable no-param-reassign */
export const clearInput = (inputEl: HTMLInputElement) => {
	try {
		inputEl.value = '';
		if (inputEl.value) {
			inputEl.type = 'text';
			inputEl.type = 'file';
		}
	} catch (err) {
		//
	}
};
