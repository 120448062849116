import React from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import CustomLabel from './Label';
import { InputText } from './InputText';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	disabled?: boolean;
	placeholder?: string;
	label?: string;
	underLabel?: string;
	required?: boolean;
	className?: string;
	inputClassName?: string;
	links?: string[] | null;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	maxLength?: number;
}
const ValidateTextField = <T extends FieldValues>(props: Props<T>) => {
	const {
		name,
		label,
		underLabel,
		placeholder,
		control,
		required = false,
		disabled = false,
		rules,
		className,
		inputClassName,
		links,
		onChange,
		maxLength,
	} = props;
	const finalClassName = classNames(className);
	const finalInputClassName = classNames(inputClassName);

	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: required ? `${label ?? placeholder} ${t('misc.forms.isRequired')}` : false, ...rules },
	});

	return (
		<div className={finalClassName}>
			{label && (
				<CustomLabel name={name} required={required}>
					<Trans
						components={
							links
								? links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{label}
					</Trans>
				</CustomLabel>
			)}
			{underLabel && <p className="mt-0 mb-2 px-3 text-sm text-muted">{underLabel}</p>}
			<InputText
				{...field}
				onChange={(event: any) => {
					if (onChange) {
						onChange(event);
					}
					if (field?.onChange) {
						field.onChange(event);
					}
				}}
				value={field.value && field.value !== 'undefined' ? field.value : ''}
				id={field.name}
				hasError={error}
				rounded
				placeholder={placeholder}
				className={finalInputClassName}
				disabled={disabled}
				maxLength={maxLength}
			/>
			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidateTextField;
