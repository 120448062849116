/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { HTMLAttributes, ReactNode, forwardRef, useMemo, useRef } from 'react';
import './Slider.scss';
import { classNames } from 'primereact/utils';
import composeRefs from '@seznam/compose-react-refs';
import { universalRenderer } from 'utils/universalRenderer';

export type SliderCommonProps = {
	min: number;
	max: number;
	step?: number;
	onBarClick?: (event: any) => void;
	track?: ReactNode | (() => ReactNode);
	disabled?: boolean;
	title?: string;
};

type SliderBaseProps = SliderCommonProps &
	HTMLAttributes<HTMLDivElement> & {
		whileDrag?: boolean;
		children: ReactNode;
	};

export const prepareSliderData = (min: number, max: number, step?: number) => {
	const fixedMin = min >= max ? max : min;
	const fixedMax = max <= min ? min : max;
	const range = fixedMax - fixedMin;
	const fixedStep = step || 1;
	const stepsCount = Math.floor(range / fixedStep);
	const stepsArr = Array(stepsCount)
		.fill(null)
		.map((_, i) => i);

	const stepped = stepsCount <= 20;
	return { fixedMin, fixedMax, step: fixedStep, range, stepsCount, stepsArr, stepped };
};

export const SliderBase = forwardRef(
	(
		{
			min,
			max,
			step = 1,
			whileDrag,
			children,
			onBarClick,
			track,
			title,
			disabled = false,
			...props
		}: SliderBaseProps,
		ref: any
	) => {
		const containerRef = useRef<HTMLDivElement>(null);

		const { fixedMin, fixedMax, stepsArr, stepped } = useMemo(
			() => prepareSliderData(min, max, step),
			[min, max, step]
		);

		const renderTrack = () => (track ? universalRenderer(track) : null);

		return (
			<div className="slider-container" title={title}>
				<div className="slider-container-label slider-container-label-min">{fixedMin}</div>
				<div
					ref={composeRefs(containerRef, ref)}
					className={classNames('slider', { 'slider-disabled': disabled })}
					{...props}
					onClick={onBarClick}
				>
					<div className="slider-track">
						{stepped ? (
							stepsArr.map((num) => <div key={num} className="slider-track-step" />)
						) : (
							<div className="slider-track-step" />
						)}
						{renderTrack()}
					</div>
					<div className={classNames('slider-range')}>{children}</div>
				</div>
				<div className="slider-container-label slider-container-label-max">{fixedMax}</div>
			</div>
		);
	}
);
