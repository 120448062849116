import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationType } from 'types/Application';
import { Link, useParams } from 'react-router-dom';
import { colors } from 'theme';
import { Box, BoxSection } from 'components/_new/Box';

interface FormSubmittedProps {
	requiresApplicationApproval: boolean;
	applicationType: ApplicationType;
}

const FormSubmitted = ({ requiresApplicationApproval, applicationType }: FormSubmittedProps) => {
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();

	const notifications = () => {
		if (applicationType === 'entry') {
			return <p>{t('misc.forms.formSendEntry')}</p>;
		}
		if (requiresApplicationApproval) {
			return <p>{t('misc.forms.formSendApprove')}</p>;
		}
		return (
			<>
				<p>{t('misc.forms.formSendNoApprove')}</p>
				<p>{t('misc.forms.formSendNoApprove2')}</p>
				<Link
					className="underline"
					style={{ color: colors.purplishBlue }}
					to={`/panel/${organizationName}/${programName}`}
				>
					{t('misc.buttons.programPanel')}
				</Link>
				<p>{t('misc.or')}</p>
			</>
		);
	};

	return (
		<div className="flex flex-column align-items-center justify-content-center row-gap-6 pt-8 pb-7">
			<Box variant="white" dropShadow>
				<BoxSection className="px-6 py-4 text-center" contentClassName="w-360-full">
					{notifications()}
					<Link
						className="underline"
						style={{ color: colors.purplishBlue }}
						to={`/${organizationName}/${programName}`}
					>
						{t('misc.buttons.backToMainPage')}
					</Link>
				</BoxSection>
			</Box>
		</div>
	);
};

export default FormSubmitted;
