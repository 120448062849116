import React from 'react';
import { useParams } from 'react-router-dom';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import parse, { domToReact } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Session } from 'types/Session';
import { ArrowList, OrderList } from 'components/_new/List';
import { SessionAgenda, SessionAgendaContentFormat } from 'types/SessionAgenda';
import { scrollElementToById } from 'utils/scroll';
import { usePanel } from 'contexts/panelContext';
import { Pair } from 'types/Pair';
import { RouterUrlParams } from 'App';
import { LinkDoc } from '../components/LinkDoc';
import { Tip } from '../components/Tip';

type AgendaSubpageProps = {
	session: Session;
	pair: Pair;
	sessionAgenda?: SessionAgenda;
	prevSession?: Session;
	nextSession?: Session;
	handlers?: Record<string, () => void>;
};

export const AgendaSubpage = ({
	session,
	pair,
	sessionAgenda,
	prevSession,
	nextSession,
	handlers,
}: AgendaSubpageProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type, pairId } = useParams() as RouterUrlParams;

	const { menteeApplicationId, mentorApplicationId, menteeMembershipId, mentorMembershipId } = pair;
	const { currentApplicationData, currentProgramMembership } = usePanel();
	const secondApplicationId =
		currentApplicationData?.id === menteeApplicationId ? mentorApplicationId : menteeApplicationId;
	const secondMembershipId =
		currentProgramMembership?.id === menteeMembershipId ? mentorMembershipId : menteeMembershipId;

	const parserOptions = {
		replace: (domNode: any) => {
			const domNodeName = String(domNode.name).toLowerCase();
			if (domNodeName === 'div' && domNode.attribs?.tip === '') {
				return <Tip message={domToReact(domNode.children, parserOptions)} />;
			}
			if (domNodeName === 'ul') {
				return <ArrowList>{domToReact(domNode.children, parserOptions)}</ArrowList>;
			}
			if (domNodeName === 'ol') {
				return <OrderList>{domToReact(domNode.children, parserOptions)}</OrderList>;
			}
			if (domNodeName === 'a') {
				const to = String(domNode.attribs.href || '')
					.replace('{{organization}}', organizationName)
					.replace('{{program}}', programName)
					.replace('{{userRole}}', type)
					.replace('{{pairId}}', pairId)
					.replace('{{sessionId}}', String(session?.id || 0))
					.replace('{{nextSessionId}}', String(nextSession?.id || 0))
					.replace('{{prevSessionId}}', String(prevSession?.id || 0))
					.replace('{{applicationId}}', String(currentApplicationData?.id || 0))
					.replace('{{secondApplicationId}}', String(secondApplicationId || 0))
					.replace('{{mentorApplicationId}}', String(mentorApplicationId || 0))
					.replace('{{menteeApplicationId}}', String(menteeApplicationId || 0))
					.replace('{{programMembershipId}}', String(currentProgramMembership?.id || 0))
					.replace('{{secondMembershipId}}', String(secondMembershipId || 0))
					.replace('{{mentorMembershipId}}', String(mentorMembershipId || 0))
					.replace('{{menteeMembershipId}}', String(menteeMembershipId || 0));
				const linkProps = {
					to,
					onClick: () => {
						if (domNode.attribs?.openautoreflection === '') {
							scrollElementToById('scrollWrapperRef');
							handlers?.openAutoreflectionDialog?.();
						}
						if (domNode.attribs?.scrolltotop === '') {
							scrollElementToById('scrollWrapperRef');
						}
					},
				};
				return (
					<LinkDoc target={domNode.attribs?.target} {...linkProps}>
						{domToReact(domNode.children, parserOptions)}
					</LinkDoc>
				);
			}
			return domNode;
		},
	};

	const renderContent = () => {
		if (sessionAgenda && sessionAgenda.content) {
			switch (sessionAgenda.format) {
				case SessionAgendaContentFormat.MD:
					return <ReactMarkdown>{sessionAgenda.content}</ReactMarkdown>;
				case SessionAgendaContentFormat.HTML:
				default:
					return parse(sessionAgenda.content, parserOptions);
			}
		}
		return <span>brak</span>;
	};

	return (
		<div className="flex flex-column gap-2">
			<h2 className="my-5">{t('userPanel.sessionsNew.proposedAgenda')}</h2>
			<div className="agenda-content w-full">{renderContent()}</div>
		</div>
	);
};
