import React, { Ref, forwardRef, InputHTMLAttributes } from 'react';
import { classNames } from 'primereact/utils';
import { getInputBaseClassName } from '../InputBase';

export type InputTextProps = InputHTMLAttributes<HTMLInputElement> & {
	variant?: 'white';
	inline?: boolean;
};

export const InputText = forwardRef(
	({ variant, className, inline, ...restProps }: InputTextProps, ref: Ref<HTMLInputElement>) => {
		return (
			<input ref={ref} className={classNames(getInputBaseClassName({ inline }), 'input-text')} {...restProps} />
		);
	}
);
