import React, { useRef, ReactNode, useMemo, forwardRef, Ref, ButtonHTMLAttributes, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { useEventListener } from 'primereact/hooks';
import composeRefs from '@seznam/compose-react-refs';
import { findFocusableElements } from 'utils/findFocusableElements';
import { Overlay, OverlayElement, OverlayExtendProps } from '../Overlay';
import { Box, BoxSection, BoxSectionHeader, BoxSectionList } from '../Box';
import { LinkProps } from '../Link';
import { Icon, IconStyle } from '../Icon';
import './Menu.scss';

type MenuItemBase = {
	key: string;
	icon?: string;
	iconStyle?: IconStyle;
	label: string;
	disabled?: boolean;
};

export type MenuItemButton = { type: 'button' } & ButtonHTMLAttributes<HTMLButtonElement> & MenuItemBase;
export type MenuItemLink = { type: 'link' } & LinkProps & MenuItemBase;

export type MenuItem = MenuItemButton | MenuItemLink;

export type MenuVariant = 'standard' | 'additional-pads' | 'bigger';

export type MenuProps = OverlayExtendProps & {
	header?: BoxSectionHeader;
	items?: MenuItem[];
	variant?: MenuVariant;
	boxClassName?: string;
};

export const Menu = forwardRef(
	({ header, items, variant = 'standard', boxClassName, ...restProps }: MenuProps, ref: Ref<OverlayElement>) => {
		const overlayRef = useRef<OverlayElement>(null);

		const renderContent = ({ icon, iconStyle, label }: MenuItem) => (
			<>
				{icon && <Icon name={icon} iconStyle={iconStyle} className="mr-1" />}
				<span>{label}</span>
			</>
		);

		const finalItems = useMemo(
			() =>
				(items || [])
					.map((item) => {
						const { key, title, disabled } = item;
						if (item.type === 'link') {
							const { to, onClick } = item;
							const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
								if (disabled) {
									event.preventDefault();
								} else {
									if (onClick) {
										onClick(event);
									}
									if (overlayRef?.current) {
										overlayRef?.current.close();
									}
								}
							};
							return (
								<Link
									key={key}
									to={to}
									className={classNames(
										'menu-item',
										{ 'menu-item-disabled': disabled },
										item.className
									)}
									onClick={handleClick}
									title={title}
								>
									{renderContent(item)}
								</Link>
							);
						}
						if (item.type === 'button') {
							const { onClick } = item;
							const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
								if (disabled) {
									event.preventDefault();
								} else {
									if (onClick) {
										onClick(event);
									}
									if (overlayRef?.current) {
										overlayRef?.current.close();
									}
								}
							};
							return (
								<button
									key={key}
									type="button"
									onClick={handleClick}
									className={classNames(
										'menu-item',
										{ 'menu-item-disabled': disabled },
										item.className
									)}
									title={title}
								>
									{renderContent(item)}
								</button>
							);
						}
						return null;
					})
					.filter(Boolean) as ReactNode[],
			[items]
		);

		const handleDocumentKeyDown = (event: KeyboardEvent) => {
			// close on esc
			if (event.key === 'Escape') {
				event.preventDefault();
				overlayRef?.current?.close();
			}

			// prevent tab outside menu
			if (event.key === 'Tab') {
				const focusable = findFocusableElements(boxRef.current);
				const lastFocusable = focusable[focusable.length - 1];

				// tab next
				if (!event.shiftKey && event.target === lastFocusable) {
					event.preventDefault();
					focusable[0].focus();
				}

				// tab prev
				if (event.shiftKey && event.target === focusable[0]) {
					event.preventDefault();
					lastFocusable.focus();
				}
			}
		};

		const [bindDocumentKeyDownListener, unbindDocumentKeyDownListener] = useEventListener({
			type: 'keydown',
			listener: handleDocumentKeyDown,
		});

		const boxRef = useRef<HTMLDivElement>(null);
		const handleOpen = () => {
			if (boxRef?.current) {
				const focusable = findFocusableElements(boxRef.current);
				if (focusable.length > 0) {
					focusable[0].focus();
				}
			}
			bindDocumentKeyDownListener();
		};
		const handleClose = (referenceElement: HTMLElement) => {
			referenceElement.focus();
			unbindDocumentKeyDownListener();
		};

		return (
			<Overlay ref={composeRefs(ref, overlayRef)} onOpen={handleOpen} onClose={handleClose} {...restProps}>
				<Box
					ref={boxRef}
					variant="white-bordered"
					className={classNames('menu', `menu-${variant}`)}
					style={{ minWidth: 100 }}
				>
					{!items?.length ? (
						<BoxSection header={header} className={boxClassName}>
							None
						</BoxSection>
					) : (
						<BoxSectionList header={header} variant={variant} className={boxClassName}>
							{finalItems}
						</BoxSectionList>
					)}
				</Box>
			</Overlay>
		);
	}
);
