import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from 'primereact/confirmdialog';

export interface CustomConfirmDialogProps {
	header?: string;
	confirmationOpen: boolean;
	setConfirmationOpen: (value: boolean) => void;
	handleAccept: () => void;
	handleReject?: () => void;
	message?: string;
	onHide?: () => void;
}

const CustomConfirmDialog: FC<CustomConfirmDialogProps> = (props) => {
	const { header, confirmationOpen, setConfirmationOpen, handleAccept, handleReject, message, onHide } = props;

	const { t } = useTranslation();

	return (
		<ConfirmDialog
			breakpoints={{ '960px': '75vw', '640px': '100vw' }}
			style={{ width: '50vw' }}
			visible={confirmationOpen}
			onHide={() => {
				onHide?.();
				setConfirmationOpen(false);
			}}
			message={message || t('misc.confirmationDialog.message')}
			header={header || t('misc.confirmationDialog.header')}
			acceptLabel={t('misc.confirmationDialog.accept')}
			rejectLabel={t('misc.confirmationDialog.reject')}
			icon="pi pi-exclamation-triangle"
			accept={handleAccept}
			reject={handleReject}
		/>
	);
};

export default CustomConfirmDialog;
