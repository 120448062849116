import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { UpdateProgramPairsParametersPayload } from 'types/Program';
import { programsApi } from 'api';
import { Link } from 'react-router-dom';
import { Panel, PanelItem } from '../../partials/panel';

type ProgramPairsForm = {
	pairingStatus: boolean;
	browseMembersByMentee: boolean;
	browseMembersByMentor: boolean;
	pairsToCreate: number;
	maxMentorDistance: number;
	minCommonSkills: number;
	skillMultiplier: number;
	sameDepartmentPenalty: number;
	matchingBonus: number;
};

export const ProgramPairs = () => {
	const { t } = useTranslation();

	const { data: programData, refetch: programRefetch } = useProgramPanel();
	const editModeState = useState(false);
	const form = useForm<ProgramPairsForm>({
		defaultValues: {
			pairingStatus: programData?.pairingStatus || false,
			browseMembersByMentee: programData?.browseMembersByMentee || false,
			browseMembersByMentor: programData?.browseMembersByMentor || false,
			pairsToCreate: programData?.algorithm?.pairsToCreate || 0,
			maxMentorDistance: programData?.algorithm?.maxMentorDistance || 0,
			minCommonSkills: programData?.algorithm?.minCommonSkills || 0,
			skillMultiplier: programData?.algorithm?.skillMultiplier || 0,
			sameDepartmentPenalty: programData?.algorithm?.sameDepartmentPenalty || 0,
			matchingBonus: programData?.algorithm?.matchingBonus || 0,
		},
	});

	const { mutateAsync, isLoading } = useMutation(
		(data: UpdateProgramPairsParametersPayload) =>
			programsApi.putProgramAdminPairsParameters(Number(programData?.id), data),
		{
			onSuccess: () => {
				programRefetch?.();
			},
		}
	);

	const handleSave = (values: UpdateProgramPairsParametersPayload) => {
		mutateAsync(values).then(() => {
			editModeState[1](false);
		});
	};

	return (
		<Panel
			title={t('programPanel.dashboard.pairs.title')}
			form={form}
			editModeState={editModeState}
			isLoading={isLoading}
			onSave={handleSave}
		>
			<>
				<PanelItem
					title={t('programPanel.dashboard.pairs.pairingEnabled')}
					value={programData?.pairingStatus}
					type="boolean"
					controller={{ control: form.control, name: 'pairingStatus' }}
					transformValue="on/off"
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.pairingAlghorithm')}
					value={Boolean(programData?.algorithm)}
					transformValue="active/unactive"
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.maxMentorDistance')}
					value={programData?.algorithm?.maxMentorDistance}
					type="number"
					controller={{ control: form.control, name: 'maxMentorDistance' }}
					inputNumberProps={{ min: 0, max: 5 }}
					visibleOnlyInEditMode
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.minCommonSkills')}
					value={programData?.algorithm?.minCommonSkills}
					type="number"
					controller={{ control: form.control, name: 'minCommonSkills' }}
					inputNumberProps={{ min: 0, max: 5 }}
					visibleOnlyInEditMode
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.skillMultiplier')}
					value={programData?.algorithm?.skillMultiplier}
					controller={{ control: form.control, name: 'skillMultiplier' }}
					inputNumberProps={{ min: -10, max: 10 }}
					type="number"
					visibleOnlyInEditMode
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.sameDepartmentPenalty')}
					value={programData?.algorithm?.sameDepartmentPenalty}
					type="number"
					controller={{ control: form.control, name: 'sameDepartmentPenalty' }}
					inputNumberProps={{ min: -10, max: 10 }}
					visibleOnlyInEditMode
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.matchingBonus')}
					value={programData?.algorithm?.matchingBonus}
					type="number"
					controller={{ control: form.control, name: 'matchingBonus' }}
					inputNumberProps={{ min: 0, max: 10 }}
					visibleOnlyInEditMode
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.pairsToCreate')}
					type="number"
					value={programData?.algorithm?.pairsToCreate}
					controller={{ control: form.control, name: 'pairsToCreate' }}
					inputNumberProps={{ min: 0, max: 5 }}
					bold
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.browseApplicationsByMentees')}
					value={programData?.browseMembersByMentee}
					type="boolean"
					controller={{ control: form.control, name: 'browseMembersByMentee' }}
					transformValue="yes/no"
				/>
				<PanelItem
					title={t('programPanel.dashboard.pairs.browseApplicationsByMentors')}
					value={programData?.browseMembersByMentor}
					type="boolean"
					controller={{ control: form.control, name: 'browseMembersByMentor' }}
					transformValue="yes/no"
				/>
			</>
		</Panel>
	);
};
