import i18n, { StringMap } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import pl from './translations/pl.json';
import { dayjs } from './utils/dayjs';

const resources = {
	en,
	pl,
} as const;

export const availableLanguages = Object.keys(resources);

const changeLanguage = (locale: string) => {
	// change date js locale
	dayjs.locale(locale);

	// change html lang tag value
	document.documentElement.setAttribute('lang', locale);
};

export const fixedT = (lng: string, translation: string, options?: StringMap) =>
	i18n.getFixedT(lng)(translation, options);

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		defaultNS: 'common',
		fallbackLng: 'en', // TODO switch to en when translations are completely ready
		supportedLngs: ['pl', 'en'],
		interpolation: {
			escapeValue: false,
		},
		detection: { order: ['localStorage', 'navigator'] },
		react: {
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
		},
	})
	.then(() => {
		changeLanguage(i18n.language);
	});

i18n.on('languageChanged', changeLanguage);

export default i18n;
