export const resolveSiteUrl = (path: string): string => {
	try {
		const fullUrl = `${process.env.REACT_APP_SITE_URL}/${path}`.replace(/(\/\/+)/g, '/');
		const url = new URL(fullUrl);
		return url.href;
	} catch {
		console.error('invalid site url');
		return '';
	}
};

export const resolveStaticsUrl = (path: string): string => {
	try {
		const fullUrl = `${process.env.REACT_APP_STATIC_URL}/${path}`.replace(/(\/\/+)/g, '/');
		const url = new URL(fullUrl);
		return url.href;
	} catch {
		console.error('invalid storage url');
		return '';
	}
};
