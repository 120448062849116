import { classNames } from 'primereact/utils';
import './InputBase.scss';

export const getInputBaseClassName = ({
	inline = false,
	disabled = false,
}: { inline?: boolean; disabled?: boolean } = {}) =>
	classNames('input-base', {
		'input-base-inline': inline,
		disabled,
	});
