// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import i18n from '../locale';

interface NavBlockerControl {
	confirm: () => void;
	cancel: () => void;
}

interface NavBlocker {
	onBlock: (control: NavBlockerControl) => void;
	enabled?: boolean;
}

export const useBlockNavigation = (enabled: boolean, message: string | undefined = undefined) => {
	const finalMessage = message || i18n.t(`misc.confirmReject`);
	const prompt = (message?: string) =>
		new Promise<boolean>((resolve) => {
			const result = window.confirm(message);
			resolve(result);
		});
	useNavBlocker({
		enabled,
		onBlock: async (navigation) =>
			prompt(finalMessage).then((result) => {
				if (result === true) {
					navigation.confirm();
				}
				navigation.cancel();
			}),
	});
};

export const useNavBlocker = ({ onBlock, enabled }: NavBlocker) => {
	const { block } = useContext(UNSAFE_NavigationContext).navigator as History;

	// Latest ref pattern
	// Latest version of the function stored to the onBlockRef
	const onBlockRef = useRef(onBlock);
	useLayoutEffect(() => {
		onBlockRef.current = onBlock;
	});

	useEffect(() => {
		if (!enabled) {
			return;
		}

		let isActive = false;

		// eslint-disable-next-line consistent-return
		const unblock = block(({ retry }) => {
			if (isActive) {
				unblock();
				// Retry method handles navigation for us 🎉
				// Allows to simplify code even more.
				return retry();
			}

			// This doesn't need to be included in dependencies
			// and won't trigger useEffect
			onBlockRef.current({
				confirm: retry,
				cancel: () => {
					isActive = false;
				},
			});

			isActive = true;
		});

		// eslint-disable-next-line consistent-return
		return unblock;
	}, [block, enabled]);
};
