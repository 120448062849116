import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOrganizationPanel } from 'contexts/organizationPanelContext';
import { Box, BoxSection } from 'components/_new/Box';

export const OrganizationList = () => {
	const { t } = useTranslation();

	const {
		myOrganizations: { data, isSuccess },
	} = useOrganizationPanel();

	return (
		<div>
			<ul className="p-list-unstylled flex flex-column gap-2">
				{isSuccess && (data || []).length === 0 && <p>{t('organizationPanel')}</p>}
				{isSuccess &&
					(data || []).map((organization, i) => (
						<li key={organization.id}>
							<Box variant="white-bordered">
								<BoxSection>
									<h2>{organization.displayName}</h2>
									<div className="flex gap-2 justify-content-end">
										<Link
											to={`/organization/${organization.name}`}
											className="p-button p-button-primary"
										>
											{t('organizationPanel.goToOrganization')}
										</Link>
										<Link
											to={`/organization/${organization.name}/memberships`}
											className="p-button p-button-text"
										>
											{t('organizationPanel.organizationMemberships')}
										</Link>
										<Link
											to={`/organization/${organization.name}/edit`}
											className="p-button p-button-text"
										>
											{t('actions.edit')}
										</Link>
									</div>
								</BoxSection>
							</Box>
						</li>
					))}
			</ul>
		</div>
	);
};
