import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18n from 'locale';
import { useMutation } from '@tanstack/react-query';
import { usersApi } from 'api';
import { Dropdown } from 'components/_common/forms/Dropdown';
import CustomLabel from 'components/_common/forms/Label';
import { useGlobal } from 'contexts/globalContext';
import { useAuth } from 'contexts/authContext';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';

type ChangeLanguageForm = {
	language: string;
};

type ChangeLanguageDialogProps = DialogBaseExtendProps;

const languageOptions = [
	{
		label: 'Polski',
		value: 'pl',
	},
	{
		label: 'English',
		value: 'en',
	},
];

export const ChangeLanguageDialog = ({ onHide, ...props }: ChangeLanguageDialogProps) => {
	const { t } = useTranslation(['common']);
	const { toastRef } = useGlobal();
	const { currentUser, currentUserRefetch } = useAuth();

	const { control, handleSubmit, setValue } = useForm<ChangeLanguageForm>({
		defaultValues: { language: currentUser?.language },
	});
	const { mutate, isLoading } = useMutation(usersApi.editMyData, {
		onSuccess: (response) => {
			i18n.changeLanguage(response?.language);
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('userPanel.myAccount.dataUpdatedSuccessfully'),
			});
			currentUserRefetch();
			onHide?.();
		},
	});
	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});

	return (
		<DialogAction
			title={t('userPanel.myAccount.changeLanguage')}
			size="sm"
			actions={[
				{
					key: 'save',
					submit: true,
					form: 'changeLanguageForm',
					label: t('actions.save'),
					loading: isLoading,
				},
			]}
			onHide={onHide}
			{...props}
		>
			<form id="changeLanguageForm" onSubmit={handleSubmitForm} className="flex flex-column gap-2">
				<div className="flex flex-column gap-1">
					<CustomLabel name="language" label={t('userPanel.myAccount.defaultLanguage')} required />
					<Controller
						name="language"
						control={control}
						render={({ field: { name, value }, fieldState: { error } }) => (
							<>
								<Dropdown
									name={name}
									value={value}
									options={languageOptions}
									placeholder={t('userPanel.myAccount.selectLanguage')}
									onChange={({ value }) => {
										setValue('language', String(value));
									}}
									className="w-full text-left"
									hasError={error?.message}
									rounded
								/>

								{error && <small className="p-error font-bold ml-3">{error.message}</small>}
							</>
						)}
						rules={{ required: t('userPanel.myAccount.selectLanguage') }}
					/>
				</div>
			</form>
		</DialogAction>
	);
};
