import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { useGlobal } from 'contexts/globalContext';
import { SessionType } from 'types/Session';
import { QuestionnaireAnswer, SessionQuestionnaire } from 'types/SessionQuestionnaire';
import {
	CreateSessionQuestionnairePayload,
	UpdateSessionQuestionnairePayload,
} from 'types/payloads/SessionQuestionnairePayload';
import { Button } from 'primereact/button';
import { Box, BoxSection } from 'components/_new/Box';
import { Line } from 'components/_new/Line';
import { StepSlider } from 'components/_new/Slider';
import { ButtonGroupYesNo } from 'components/_new/ButtonGroup';
import { Icon } from 'components/_new/Icon';
import { useSessionsData } from '../Sessions';

export interface MentorEvaluationFormObject {
	goalsSetting: number;
	fields: {
		[key: string]: number;
	};
	givingFeedback: number;
	communicationSkils: number;
	listeningSkills: number;
	questioningSkills: number;
	matchRate?: number;
	experienceUsage?: boolean;
	newKnowledge?: boolean;
	newKnowledgeUsability?: number;
	value?: number;
}

interface MentorEvaluationModuleProps {
	pairId: number;
	sessionType: SessionType;
	questionnairesData?: SessionQuestionnaire[];
	refetchQuestionnaires?: any;
}

const MentorEvaluationModule: FC<MentorEvaluationModuleProps> = ({
	pairId,
	sessionType,
	questionnairesData,
	refetchQuestionnaires,
}) => {
	const { t } = useTranslation();
	const { currentProgramMembership, currentApplicationData } = usePanel();
	const { toastRef } = useGlobal();
	const { sessiosRefetch } = useSessionsData();
	const currentQuestionnaire = questionnairesData?.find((questionnaire) => questionnaire.forMentorOnly === true);
	const questionnaireAnswers = currentQuestionnaire?.questionnaireAnswers;

	const baseDefaultFormValues = {
		goalsSetting: (questionnaireAnswers?.goalsSetting as number) ?? 0,
		fields: {
			...currentApplicationData.systemAnswers.areas
				.reduce((areasArray: string[], currGroup) => [...areasArray, ...(currGroup?.options ?? [])], [])
				.reduce((areasObject, area) => {
					const questionaireFieldsAnswers = questionnaireAnswers?.fields as QuestionnaireAnswer;

					return {
						...areasObject,
						[area]: (questionaireFieldsAnswers && questionaireFieldsAnswers[area]) ?? 0,
					};
				}, {}),
		},
		givingFeedback: (questionnaireAnswers?.givingFeedback as number) ?? 0,
		communicationSkils: (questionnaireAnswers?.communicationSkils as number) ?? 0,
		listeningSkills: (questionnaireAnswers?.listeningSkills as number) ?? 0,
		questioningSkills: (questionnaireAnswers?.questioningSkills as number) ?? 0,
	};

	const defaultFormValues: Record<string, any> = Object.assign(
		baseDefaultFormValues,
		sessionType === 'first' && { matchRate: (questionnaireAnswers?.matchRate as number) ?? 0 },
		sessionType === 'middle' && { experienceUsage: questionnaireAnswers?.experienceUsage as boolean },
		sessionType === 'last' && {
			newKnowledge: questionnaireAnswers?.newKnowledge as boolean,
			newKnowledgeUsability: (questionnaireAnswers?.newKnowledgeUsability as number) ?? 0,
			experienceUsage: questionnaireAnswers?.experienceUsage as boolean,
			value: (questionnaireAnswers?.value as number) ?? 0,
		}
	);

	const { control, handleSubmit } = useForm({
		defaultValues: defaultFormValues,
	});

	const { mutate: createQuestionnaire, isLoading: isCreateSubmitting } = useMutation(
		(data: CreateSessionQuestionnairePayload) =>
			userPanelApi.createQuestionnaire(+pairId, currentProgramMembership.id, data),
		{
			onSuccess: () => {
				refetchQuestionnaires();
				sessiosRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.successfullSessionEditTooltip'),
				});
			},
		}
	);

	const { mutate: updateQuestionnaire, isLoading: isUpdateSubmitting } = useMutation(
		(data: UpdateSessionQuestionnairePayload) =>
			userPanelApi.updateQuestionnaire(+pairId, currentProgramMembership.id, sessionType, data),
		{
			onSuccess: () => {
				refetchQuestionnaires();
				sessiosRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.successfullSessionEditTooltip'),
				});
			},
		}
	);

	const onSubmit = handleSubmit((data) => {
		if (!questionnairesData?.length || !currentQuestionnaire) {
			createQuestionnaire({
				sessionType,
				forMentorOnly: true,
				questionnaireAnswers: { ...data },
			});
		} else {
			updateQuestionnaire({
				id: currentQuestionnaire.id,
				questionnaireAnswers: { ...data },
			});
		}
	});

	return (
		<form className="flex flex-column row-gap-4" onSubmit={onSubmit}>
			{Object.entries(defaultFormValues).map(([k, v]) => {
				const title = t(`userPanel.sessions.mentorEvaluation.questionnaire.${k}`);
				switch (k) {
					case 'goalsSetting':
					case 'givingFeedback':
					case 'communicationSkils':
					case 'listeningSkills':
					case 'questioningSkills':
					case 'newKnowledgeUsability':
					case 'value':
					case 'matchRate':
						return (
							<Box key={k} variant="white-bordered">
								<Controller
									name={k}
									control={control}
									render={({ field: { ref, onChange, value } }) => (
										<BoxSection
											header={() => (
												<div className="flex flex-row gap-2 justify-content-start align-items-center">
													{title}
													{(!v || !value) && (
														<Icon name="circle" className="text-xs text-rose" />
													)}
												</div>
											)}
											headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
											contentClassName="pb-2"
										>
											<StepSlider
												ref={ref}
												min={0}
												max={10}
												onChange={onChange}
												defaultValue={Number(v || 0)}
											/>
										</BoxSection>
									)}
								/>
							</Box>
						);
					case 'experienceUsage':
					case 'newKnowledge':
						return (
							<Controller
								name={k}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div key={k} className="flex flex-row gap-2 align-items-center">
										<div className="">
											<h3 className="font-semibold heading-16-24 text-on-blue-grey">{title}</h3>
										</div>
										{(v === undefined || value === undefined) && (
											<Icon name="circle" className="text-xs text-rose" />
										)}
										<Line className="flex-1" minWidth={20} />
										<ButtonGroupYesNo
											value={typeof value !== 'undefined' && value !== null ? value : null}
											defaultValue={v}
											onChange={({ value }) => onChange(value)}
										/>
									</div>
								)}
							/>
						);
					default:
						return null;
				}
			})}

			<Button
				type="submit"
				label={t('misc.forms.save')}
				className="align-self-end"
				loading={isCreateSubmitting || isUpdateSubmitting}
			/>
		</form>
	);
};

export default MentorEvaluationModule;
