import { CalendarEventBase } from 'components/_new/Calendar/Calendar';
import { CalendarEvent } from 'types/Calendar';

export const mapEvent = (
	event: CalendarEvent,
	colorFactory: (event: CalendarEvent) => { bg: string; text: string }
): CalendarEventBase => {
	const { id, summary, startTime, endTime, source, dateOnly, meta } = event;
	return {
		key: id,
		id,
		summary,
		startTime: new Date(startTime),
		endTime: new Date(endTime),
		dateOnly,
		color: colorFactory(event),
		source,
		meta,
	};
};
