import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useTranslation } from 'react-i18next';
import { statisticsApi } from 'api/statistics';
import { Spinner } from 'components/_new/Spinner';
import { Box, BoxSection } from 'components/_new/Box';
import { StatisticsProgramFeedbackItem } from 'types/Statistics';
import { SurveyQuestionType } from 'types/Survey';
import { useAuth } from 'contexts/authContext';

type FeedbackItemGroup = Array<{
	step: number;
	items: Array<Omit<StatisticsProgramFeedbackItem, 'step'>>;
}>;

type FeedbackItemProps = { title: string; value: string };

const FeebackItem = ({ title, value }: FeedbackItemProps) => (
	<BoxSection horizontal>
		<div className="col-7 p-0">{title}</div>
		<div className="col-3 p-0">{value}</div>
	</BoxSection>
);

export const Feedback = () => {
	const { t } = useTranslation();
	const { currentUser } = useAuth();

	const { data: program } = useProgramPanel();

	const { data: feedbackData, isLoading: menteeIsLoading } = useQuery(
		['programStatistics', { type: 'feedback', programId: Number(program?.id), language: currentUser?.language }],
		() => statisticsApi.getFeedback(Number(program?.id)),
		{
			enabled: Boolean(program),
			cacheTime: 10000,
		}
	);

	if (menteeIsLoading || !feedbackData) {
		return <Spinner />;
	}

	const data = feedbackData.answerStats.reduce<FeedbackItemGroup>((current, { step, ...item }) => {
		const idx = current.findIndex((ctg) => ctg.step === step);
		if (idx === -1) {
			current.push({
				step,
				items: [item],
			});
		} else {
			current[idx].items.push(item);
		}
		return current;
	}, []);

	const presentValueByType = (type: SurveyQuestionType, value: number) => {
		switch (type) {
			case SurveyQuestionType.SCALE:
				return `${value}/10`;
			case SurveyQuestionType.BOOLEAN:
				return `${value}%`;
			default:
				return null;
		}
	};

	return (
		<div className="flex flex-column gap-2">
			<div className="mb-2">
				<h3>{t('programPanel.statistics.feedback')}</h3>
				<p>{t('programPanel.statistics.feedbackDesc', { count: feedbackData.count })}</p>
			</div>
			{data.map(({ step, items }) => (
				<Box key={step} variant="white-bordered" slim>
					{items.map(({ type, text, value, choices }) => (
						<>
							<BoxSection horizontal contentClassName="grid grid-nogutter">
								<div className="col md:col-8 p-0 font-bold flex align-items-center">
									<span>{text}</span>
								</div>
								<div className="col-auto md:col-3 p-0 font-bold flex align-items-center">
									{[SurveyQuestionType.MULTI_CHOICE, SurveyQuestionType.SINGLE_CHOICE].includes(
										type
									) ? (
										<div />
									) : (
										<>
											{(value === null || (Array.isArray(value) && !value.length)) && (
												<span className="hidden md:inline text-xxl text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<span className="hidden md:inline text-xxl text-light-purplish-blue">
													{presentValueByType(type, value)}
												</span>
											)}
											{value === null && (
												<span className="inline md:hidden text-lg text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<span className="inline md:hidden text-lg text-light-purplish-blue">
													{presentValueByType(type, value)}
												</span>
											)}
										</>
									)}
								</div>
							</BoxSection>

							{[SurveyQuestionType.TEXT, SurveyQuestionType.LONGTEXT].includes(type) &&
								Array.isArray(value) &&
								value.map((str, idx) => (
									// eslint-disable-next-line react/no-array-index-key
									<BoxSection key={idx} horizontal contentClassName="grid grid-nogutter">
										<div className="col md:col-8 p-0 pl-4 flex align-items-center">{str}</div>
									</BoxSection>
								))}

							{[SurveyQuestionType.MULTI_CHOICE, SurveyQuestionType.SINGLE_CHOICE].includes(type) &&
								(choices || []).map(({ id, text, value }) => (
									<BoxSection key={id} horizontal contentClassName="grid grid-nogutter">
										<div className="col md:col-8 p-0 pl-4 flex align-items-center">
											<span>{text}</span>
										</div>
										<div className="col-auto md:col-3 p-0 font-bold flex align-items-center">
											{value === null && (
												<span className="hidden md:inline text-xl text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<span className="hidden md:inline text-xl text-light-purplish-blue">
													{`${value}%`}
												</span>
											)}
											{value === null && (
												<span className="inline md:hidden text-lg text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<span className="inline md:hidden text-lg text-light-purplish-blue">
													{`${value}%`}
												</span>
											)}
										</div>
									</BoxSection>
								))}
						</>
					))}
				</Box>
			))}
		</div>
	);
};
