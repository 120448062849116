import React, { ChangeEventHandler, useCallback, useRef, useState } from 'react';
import { PostAttachment, PostAttachmentLocal } from 'types/Dashboard';
import { clearInput } from 'utils/clearInput';
import { AttachmentItem } from './AttachmentItem';
import { Button } from '../Button';
import { AttachemntPreviewDialog } from './AttachmentPreviewDialog';

import { DialogConfirm } from '../Dialog';
import './Attachment.scss';

type AttachmentListProps = {
	attachments?: PostAttachment[];
	newAttachments?: PostAttachment[];
	uploadedAttachments?: PostAttachmentLocal[];
	onUpload?: (file: File) => void;
	onRemove?: (attachement: PostAttachment) => Promise<any>;
	isAdding?: boolean;
	options?: {
		addAttachmentLabel?: string;
		removeAttachmentLabel?: string;
		uploadDesc?: string;
	};
};

export const AttachmentList = ({
	attachments,
	newAttachments,
	uploadedAttachments,
	onUpload,
	onRemove,
	isAdding,
	options,
}: AttachmentListProps) => {
	const { addAttachmentLabel, removeAttachmentLabel, uploadDesc } = {
		addAttachmentLabel: 'Add attachment',
		removeAttachmentLabel: 'Remove attachment',
		uploadDesc: '',
		...options,
	};
	const [currentAttachment, setCurrentAttachment] = useState<PostAttachment | null>(null);
	// const [pendingAttachments, setPendingAttachments] = useState<PostAttachmentLocal[]>([]);
	const [displayPreviewDialog, setDisplayPreviewDialog] = useState(false);

	// preview
	const handleItemClick = useCallback((attachment: PostAttachment) => {
		setCurrentAttachment(attachment);
		setDisplayPreviewDialog(true);
	}, []);
	const handleHidePreviewDialog = useCallback(() => {
		setCurrentAttachment(null);
		setDisplayPreviewDialog(false);
	}, []);

	// handle remove
	const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
	const handleRemoveClick = useCallback((attachement: PostAttachment) => {
		setCurrentAttachment(attachement);
		setConfirmRemoveDialog(true);
	}, []);
	const handleRemoveConfirm = useCallback(() => {
		if (onRemove && currentAttachment) {
			onRemove(currentAttachment).finally(() => {
				setCurrentAttachment(null);
				setConfirmRemoveDialog(false);
			});
		}
	}, [currentAttachment, onRemove]);
	const handleRemoveReject = useCallback(() => {
		setConfirmRemoveDialog(false);
		setCurrentAttachment(null);
	}, []);

	// handle add new
	const inputRef = useRef<HTMLInputElement>(null);
	const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		const files = event.currentTarget?.files;
		const file = files?.[0] || null;
		if (file) {
			onUpload?.(file);
			if (inputRef?.current) {
				clearInput(inputRef.current);
			}
		}
	};

	return (
		<>
			<AttachemntPreviewDialog
				attachement={currentAttachment}
				visible={displayPreviewDialog && Boolean(currentAttachment)}
				onHide={handleHidePreviewDialog}
			/>
			{onRemove && (
				<DialogConfirm
					visible={confirmRemoveDialog}
					// onHide={handleConfirmRemoveDialogHide}
					onConfirm={handleRemoveConfirm}
					onReject={handleRemoveReject}
				/>
			)}
			<div className="flex flex-row gap-2 flex-wrap justify-content-start align-items-start">
				{(attachments || []).map((attachment) => (
					<AttachmentItem
						key={attachment.id}
						attachment={attachment}
						onClick={handleItemClick}
						onRemove={onRemove ? handleRemoveClick : undefined}
						options={{
							removeAttachmentLabel,
						}}
					/>
				))}
				{(newAttachments || []).map((attachment) => (
					<AttachmentItem
						key={attachment.id}
						attachment={attachment}
						onClick={handleItemClick}
						onRemove={onRemove ? handleRemoveClick : undefined}
						isNew
						options={{
							removeAttachmentLabel,
						}}
					/>
				))}

				{(uploadedAttachments || []).map((attachment) => (
					<AttachmentItem key={attachment.localUuid} attachment={attachment} isUploaded />
				))}

				{onUpload && (
					<>
						<input
							ref={inputRef}
							type="file"
							name="flle"
							accept="application/pdf,image/*"
							onChange={handleInputChange}
							className="hidden-accessible"
						/>
						<Button
							onClick={() => {
								if (!isAdding) {
									inputRef?.current?.click();
								}
							}}
							label={addAttachmentLabel}
							icon="plus"
							rounded
							variantSize="sm"
							loading={isAdding}
						/>
						{uploadDesc && String(uploadDesc).length > 0 && (
							<span className="text-muted py-1">{uploadDesc}</span>
						)}
					</>
				)}
			</div>
		</>
	);
};
