import React, { useEffect, useState, Dispatch } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { userFilesApi } from 'api';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import TextField from 'components/_common/forms/TextField';
import { SessionFile } from 'types/SessionFile';
import { FileUpload } from 'primereact/fileupload';
import { urlValidator } from 'utils/urlValidator';
import { usePanel } from 'contexts/panelContext';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from './forms/Dropdown';
import { MarkdownEditor } from './MarkdownEditor/MarkdownEditor';
import ValidateCheckbox from './forms/Checkbox';
import CustomLabel from './forms/Label';

interface UserFileFormObject {
	name: string;
	type: string;
	forAuthorOnly: boolean;
	file: File | null;
	category: string;
	url: string;
	content: string;
	sendMailNotification?: boolean;
}

type UploadUserFilesProps = {
	userFile?: SessionFile;
	setUserFile: Dispatch<SessionFile | undefined>;
	onFinish?: () => void;
	setHasChanges?: (hasChanges: boolean) => void;
	session?: any;
	onClose?: () => void;
};

export const UploadUserFiles = ({
	onClose,
	userFile,
	setUserFile,
	onFinish,
	setHasChanges,
	session,
}: UploadUserFilesProps) => {
	const { t } = useTranslation();
	const { pairId } = useParams();
	const { currentProgramMembership, currentApplicationData } = usePanel();

	const defaultValues = {
		name: '',
		type: 'html',
		forAuthorOnly: userFile?.forAuthorOnly || false,
		category: 'note',
		file: null,
		url: '',
		content: '',
		sendMailNotification: false,
	};
	const { control, handleSubmit, watch, reset, setValue } = useForm<UserFileFormObject>({
		defaultValues,
	});
	useEffect(() => {
		if (userFile) {
			reset({
				name: userFile?.name || '',
				type: userFile?.type || 'html',
				forAuthorOnly: userFile?.forAuthorOnly || false,
				category: userFile?.category || 'note',
				file: null,
				url: userFile?.url || '',
				content: userFile?.content || '',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userFile]);

	const fileTypeOptions = [
		{ name: t('userPanel.userFiles.file.typeOptions.pdf'), value: 'pdf' },
		{ name: t('userPanel.userFiles.file.typeOptions.video'), value: 'video' },
		{ name: t('userPanel.userFiles.file.typeOptions.link'), value: 'link' },
		{ name: t('userPanel.userFiles.file.typeOptions.html'), value: 'html' },
		{ name: t('userPanel.userFiles.file.typeOptions.file'), value: 'file' },
	];

	const categoryOptions = [
		{ name: t('userPanel.userFiles.file.categoryOptions.tool'), value: 'tool' },
		{ name: t('userPanel.userFiles.file.categoryOptions.knowledge'), value: 'knowledge' },
		{ name: t('userPanel.userFiles.file.categoryOptions.forms'), value: 'forms' },
		{ name: t('userPanel.userFiles.file.categoryOptions.note'), value: 'note' },
		{ name: t('userPanel.userFiles.file.categoryOptions.other'), value: 'other' },
	];

	const name = watch('name');
	const category = watch('category');
	const type = watch('type');
	const url = watch('url');
	const content = watch('content');
	const file = watch('file');
	const forAuthorOnly = watch('forAuthorOnly');
	const sendMailNotification = watch('sendMailNotification');

	useEffect(() => {
		if (setHasChanges) {
			const nameChanged = userFile ? userFile.name !== name : name !== defaultValues.name;
			const categoryChanged = userFile ? userFile.category !== category : category !== defaultValues.category;
			const urlChanged = userFile ? userFile.url !== url : url !== defaultValues.url;
			const typeChanged = userFile ? userFile.type !== type : type !== defaultValues.type;
			const contentChanged = userFile ? userFile.content !== content : content !== defaultValues.content;
			const hasFile = Boolean(file);
			setHasChanges(nameChanged || categoryChanged || urlChanged || typeChanged || contentChanged || hasFile);
		}
	}, [
		name,
		category,
		setHasChanges,
		userFile,
		type,
		content,
		file,
		url,
		defaultValues.name,
		defaultValues.category,
		defaultValues.url,
		defaultValues.type,
		defaultValues.content,
	]);

	const {
		mutate: uploadUserFileMutate,
		isSuccess: uploadUserFileSuccess,
		isLoading: uploadUserFileLoading,
		reset: uploadUserFileReset,
	} = useMutation(
		(data: any) =>
			userFilesApi.uploadUserFile(currentProgramMembership.id, currentApplicationData.id, Number(pairId), data),
		{
			onSuccess: (data: any) => {
				// reset();
				if (setUserFile) {
					setUserFile(data);
				}
				if (onFinish) {
					onFinish();
				}
			},
		}
	);

	const {
		mutate: editUserFileMutate,
		isSuccess: editUserFileSuccess,
		isLoading: editUserFileLoading,
		reset: editUserFileReset,
	} = useMutation((data: any) => userFilesApi.editUserFile(Number(userFile?.id), data), {
		onSuccess: () => {
			// reset();
			if (onFinish) {
				onFinish();
			}
		},
	});
	const handleCloseClick = () => {
		if (onClose) {
			onClose();
		}
		setUserFile(undefined);
	};

	const resetModal = () => {
		setUserFile(undefined);
		uploadUserFileReset();
		editUserFileReset();
		reset(defaultValues);
	};
	const fileType = watch('type');
	const isSuccess = uploadUserFileSuccess || editUserFileSuccess;
	const isLoading = uploadUserFileLoading || editUserFileLoading;
	const showForm = type === 'html' || !isSuccess;
	const renderUrlField = () => {
		switch (fileType) {
			case 'video':
				return (
					<TextField
						name="url"
						placeholder={t('userPanel.userFiles.file.urlVideo')}
						control={control}
						required
						rules={{
							validate: (value) => {
								if (!urlValidator(value as string)) {
									return t('userPanel.userFiles.file.invalidUrl');
								}
								return true;
							},
						}}
					/>
				);
			case 'link':
				return (
					<TextField
						name="url"
						placeholder={t('userPanel.userFiles.file.urlLink')}
						control={control}
						required
						rules={{
							validate: (value) => {
								if (!urlValidator(value as string)) {
									return t('userPanel.userFiles.file.invalidUrl');
								}
								return true;
							},
						}}
					/>
				);
			default:
				return null;
		}
	};

	const handleSubmitForm = handleSubmit((values) => {
		if (userFile) {
			editUserFileMutate(values);
		} else {
			uploadUserFileMutate({ sessionId: session?.id, ...values });
		}
	});

	const [buttonDisabled, setButtonDisabled] = useState(false);
	useEffect(() => {
		if (isLoading) {
			setButtonDisabled(true);
			setTimeout(() => {
				setButtonDisabled(false);
			}, 2000);
		}
	}, [isLoading]);

	const handleAddNewClick = () => {
		resetModal();
	};

	useEffect(() => {
		const handleWindowKeydown = (event: KeyboardEvent) => {
			if (event.ctrlKey && String(event.key).toLowerCase() === 's') {
				event.preventDefault();
				handleSubmitForm(undefined);
			}
		};
		window.addEventListener('keydown', handleWindowKeydown);
		return () => {
			window.removeEventListener('keydown', handleWindowKeydown);
		};
	});

	const accept = (
		type === 'pdf'
			? ['application/pdf']
			: [
					'application/vnd.ms-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.ms-powerpoint',
					'application/vnd.openxmlformats-officedocument.presentationml.presentation',
					'application/msword',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
					'application/vnd.oasis.opendocument.presentation',
					'application/vnd.oasis.opendocument.spreadsheet',
					'application/vnd.oasis.opendocument.text',
			  ]
	).join(',');

	return (
		<form onSubmit={handleSubmitForm} className="flex flex-column row-gap-4">
			{showForm && (
				<>
					<TextField
						name="name"
						placeholder={t('userPanel.userFiles.file.name')}
						control={control}
						required
					/>

					<Controller
						rules={{
							required: {
								value: true,
								message: t('userPanel.userFiles.file.categoryRequired'),
							},
						}}
						name="category"
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<div>
								<Dropdown
									value={value}
									onChange={({ value }) => {
										onChange(value);
									}}
									options={categoryOptions}
									optionLabel="name"
									placeholder={t('userPanel.userFiles.file.category')}
									className="w-full"
									hasError={Boolean(error)}
								/>
								{error && <small className="p-error font-bold ml-3">{error.message}</small>}
							</div>
						)}
					/>

					<Controller
						rules={{
							required: {
								value: true,
								message: t('userPanel.userFiles.file.typeRequired'),
							},
						}}
						name="type"
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<div>
								<Dropdown
									value={value}
									onChange={({ value }) => {
										onChange(value);
									}}
									options={fileTypeOptions}
									optionLabel="name"
									placeholder={t('userPanel.userFiles.file.type')}
									className="w-full"
									hasError={Boolean(error)}
								/>
								{error && <small className="p-error font-bold ml-3">{error.message}</small>}
							</div>
						)}
					/>

					{['pdf', 'file'].includes(fileType) && (
						<Controller
							name="file"
							control={control}
							rules={
								{
									// required: {
									// 	value: ['pdf'].includes(fileType),
									// 	message: t('userPanel.userFiles.file.fileRequired'),
									// },
								}
							}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<>
									{!value && (
										<>
											{userFile && (
												<div>
													{t('userPanel.userFiles.file.originalFilename')}:{' '}
													{userFile?.originalFilename || '-'}
												</div>
											)}
											<FileUpload
												mode="basic"
												accept={accept}
												customUpload
												onSelect={({ originalEvent: event }) => {
													if (((event?.target as any)?.files?.length || 0) > 0) {
														onChange((event.target as any).files[0]);
													}
												}}
												chooseLabel={t('misc.choose')}
											/>
											{error && <small className="p-error font-bold ml-3">{error.message}</small>}
										</>
									)}
									{value && (
										<div className="flex gap-4 align-items-center justify-content-start">
											<span>{value.name}</span>
											<Button
												onClick={() => {
													onChange(null);
												}}
												icon="pi pi-times"
												className="p-button-danger p-button-xs"
												label={t('actions.remove')}
											/>
										</div>
									)}
								</>
							)}
						/>
					)}

					{renderUrlField()}

					{['link', 'video', 'html', 'pdf', 'file'].includes(fileType) && (
						<Controller
							rules={{
								required: {
									value: ['html'].includes(fileType),
									message: t('userPanel.userFiles.file.contentRequired'),
								},
							}}
							name="content"
							control={control}
							render={({ field: { onChange }, fieldState: { error } }) => (
								<>
									{['link', 'video', 'pdf'].includes(fileType) && (
										<CustomLabel
											name="content"
											label={`${t('userPanel.userFiles.file.optionalDescription')}:`}
										/>
									)}
									<MarkdownEditor
										defaultValue={userFile?.content || ''}
										onChange={(value) => {
											onChange(value);
										}}
									/>
									{error && <small className="p-error font-bold ml-3">{error.message}</small>}
								</>
							)}
						/>
					)}

					<div className="flex flex-column gap-2 justify-items-start align-items-start">
						{sendMailNotification === true && (
							<Tooltip target="#forAuthorOnly" position="bottom">
								{t('userPanel.userFiles.file.forAuthorOnlyHint')}
							</Tooltip>
						)}
						<ValidateCheckbox
							name="forAuthorOnly"
							control={control}
							text={t('userPanel.userFiles.file.forAuthorOnly')}
							disabled={sendMailNotification}
						/>

						{forAuthorOnly === true && (
							<Tooltip target="#sendMailNotification" position="bottom">
								{t('userPanel.userFiles.file.sendMailNotificationHint')}
							</Tooltip>
						)}
						{!userFile && (
							<ValidateCheckbox
								control={control}
								name="sendMailNotification"
								text={t('userPanel.userFiles.file.sendMailNotification')}
								disabled={forAuthorOnly}
							/>
						)}
					</div>
				</>
			)}
			{editUserFileSuccess ? (
				<div>
					<Message
						severity="success"
						className="flex w-full mt-3 p-3"
						text={t('userPanel.userFiles.editedSuccessfully')}
					/>
				</div>
			) : (
				uploadUserFileSuccess && (
					<div>
						<Message
							severity="success"
							className="flex w-full mt-3 p-3"
							text={t('userPanel.userFiles.addedSuccessfully')}
						/>
					</div>
				)
			)}

			<div className="flex justify-content-between">
				{!showForm ? (
					<>
						<Button
							className="justify-content-center p-button-text w-5 mt-7"
							onClick={handleAddNewClick}
							label={t('userPanel.userFiles.addNewFileButton')}
							type="button"
						/>
						<Button
							className="justify-content-center w-5 mt-7 p-button-text"
							onClick={handleCloseClick}
							label={t('actions.close')}
							type="button"
						/>
					</>
				) : (
					<>
						<div />
						<Button
							className="align-self-end justify-content-center w-5 mt-3"
							type="submit"
							loading={isLoading}
							label={userFile ? t('actions.save') : t('actions.add')}
							disabled={isLoading || buttonDisabled}
						/>
					</>
				)}
			</div>
		</form>
	);
};
