import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { chatApi } from 'api/chat';
import { Spinner } from 'components/_new/Spinner';
import { Avatar } from 'components/_new/Avatar';
import { userDisplayName } from 'utils/userDisplayName';
import { Divider } from 'components/_new/Divider';
import { useScope } from 'contexts/scopeContext';
import { ProgramMembership } from 'types/Membership';
import { useChat } from './useChat';
import { ChatConversationWindow } from './ChatConversationWindow';

type ChatConversationProps = {
	threadId: number;
	onThreadLoad?: (interlocutorProgramMembership: ProgramMembership) => void;
};

export const ChatConversation = (props: ChatConversationProps) => {
	const { threadId, onThreadLoad } = props;
	const { t } = useTranslation();

	const { currentProgramMembership } = useScope();
	const { queryUniqueParam } = useChat();

	const {
		data: threadData,
		isLoading: threadLoading,
		isSuccess: threadSuccess,
		isError: threadError,
	} = useQuery(
		['chat-thread', { threadId, ...queryUniqueParam }],
		() => chatApi.getThread(Number(currentProgramMembership?.id), threadId),
		{
			enabled: Boolean(currentProgramMembership?.id),
			onSuccess: ({ interlocutorProgramMembership }) => {
				onThreadLoad?.(interlocutorProgramMembership);
			},
		}
	);

	return threadLoading ? (
		<Spinner />
	) : (
		<>
			{threadError && <div>{t('chat.canNotWriteToThisUser')}</div>}
			{threadData && threadSuccess && (
				<div className="flex-1 flex flex-column gap-2 h-full overflow-y-hidden">
					<div className="flex-0 flex flex-row gap-3 w-full">
						<Avatar
							name={userDisplayName(threadData.interlocutorProgramMembership.user, null)}
							src={threadData.interlocutorProgramMembership.user.avatar}
							size="lg"
						/>
						<div className="flex flex-column gap-1">
							<span className="text-lg text-primary font-bold">
								{userDisplayName(threadData.interlocutorProgramMembership.user)}
							</span>
							<span>{threadData.interlocutorProgramMembership.user.email}</span>
						</div>
						<Divider />
					</div>
					<ChatConversationWindow thread={threadData} />
				</div>
			)}
		</>
	);
};
