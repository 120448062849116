import React, { FC, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { closestTo } from 'date-fns';
import Fuse from 'fuse.js';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { RouterUrlParams } from 'App';
import { Folder, File, FileType } from 'types/File';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { FilterMatchMode } from 'primereact/api';
import FileDisplay from 'components/knowledgeDatabase/FileDisplay';
import FilesSearchbar from 'components/knowledgeDatabase/FilesSearchbar';
import DateCell from 'components/knowledgeDatabase/DateCell';
import { matchFiles } from 'components/knowledgeDatabase/helpers';
import { slugify, deslugify } from 'utils/helpers';
import { usePanel } from 'contexts/panelContext';

interface Props {
	filesData: Folder[];
	enablePing?: boolean;
	breadcrumb: {
		home: string;
	};
}
const Files: FC<Props> = ({ filesData, enablePing = false, breadcrumb }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName, type, '*': filePath } = useParams() as RouterUrlParams;

	const { currentApplicationData, currentProgramMembership } = usePanel();

	const { mutate: pingFileMutate } = useMutation(['pingFile'], (filePath: string) =>
		userPanelApi.checkFile(currentApplicationData.id, currentProgramMembership.id, filePath)
	);
	useEffect(() => {
		// ping file
		if (enablePing && filePath.length > 0) {
			pingFileMutate(filePath);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enablePing, filePath]);

	const homeBreadcrumb = {
		icon: 'pi pi-book',
		command: () => {
			setSearchValue('');
			navigate(breadcrumb.home);
		},
	};
	const [breadcrumbs, setBreadcrumbs] = useState<{ label: string; command: () => void }[]>([]);
	const [viewableFiles, setViewableFiles] = useState<Folder[] | File[] | Array<File | Folder>>([]);
	const [selectedFile, setSelectedFile] = useState<File>();
	const [searchValue, setSearchValue] = useState('');
	const [filters, setFilters] = useState({
		type: { value: '', matchMode: FilterMatchMode.EQUALS },
	});
	const getFolderFiles = useCallback(
		(searchedName: string) => filesData.find((folder) => folder.name === searchedName)?.files as unknown as File[],
		[filesData]
	);
	const flattenedFiles = useMemo(
		() =>
			filesData.reduce(
				(flatFilesArr: Array<File | Folder>, folder: Folder): Array<File | Folder> => [
					...flatFilesArr,
					...folder.files,
					folder,
				],
				[]
			),
		[filesData]
	);

	useEffect(() => {
		if (!filePath) {
			setViewableFiles(filesData);
			setSelectedFile(undefined);
		} else {
			const requestedData = matchFiles(filesData, filePath);

			if (requestedData && requestedData.type !== 'directory') {
				setSelectedFile(requestedData as File);
			} else {
				setSelectedFile(undefined);
				setViewableFiles(((requestedData as Folder)?.files as unknown as File[]) ?? []);
			}
		}

		const breadcrumsData = filePath.split('/').reduce((current: any, currentItem: any, currentIndex) => {
			const label =
				flattenedFiles.find((file) => slugify(file.name) === currentItem)?.name ?? deslugify(currentItem);

			const prevSlug = current[currentIndex - 1]?.slug || null;
			const slug = slugify(currentItem);
			const finalSlug = [prevSlug, slug].filter(Boolean).join('/');
			const navigatePath = `/panel/${organizationName}/${programName}/${type}/files/${finalSlug}`;

			current.push({
				label,
				slug,
				navigatePath,
			});

			return current;
		}, []);

		setBreadcrumbs(
			breadcrumsData.map(({ label, navigatePath, slug }: any) => {
				return {
					label,
					command: () => {
						navigate(navigatePath);
						setSearchValue('');
						if (navigatePath !== slug) {
							setViewableFiles(getFolderFiles(slug));
						}
					},
				};
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filePath, navigate, filesData, getFolderFiles, flattenedFiles]);

	const handleRowClick = (rowData: Folder | File) => {
		switch (rowData.type) {
			case 'link':
				window.open((rowData as File).url as string, '_blank');
				break;
			default:
				navigate(
					(rowData as File).directory
						? `${slugify((rowData as File).directory)}/${slugify(rowData.name)}`
						: slugify(rowData.name)
				);
				setViewableFiles((rowData as Folder).files);
				break;
		}
	};

	const handleSearch = useCallback(
		(value: string) => {
			const fileNameFromUrl = filePath.split('/').slice(-1)[0];
			const previouslyDisplayedFiles = getFolderFiles(fileNameFromUrl) ?? filesData;

			const fuse = new Fuse(flattenedFiles, {
				threshold: 0.5,
				findAllMatches: true,
				ignoreLocation: true,
				keys: ['name'], // TODO: add search by description when added by backend
			});

			setSearchValue(value);

			const filteredFiles = fuse.search(value).map((result) => result.item);

			setViewableFiles(value ? filteredFiles : previouslyDisplayedFiles);
		},
		[filesData, flattenedFiles, filePath, getFolderFiles]
	);

	const handleFilter = (value: string) => {
		const newFilters = { ...filters };
		newFilters.type.value = value;

		setFilters(newFilters);
	};

	const renderIcon = (type: FileType | undefined) => {
		switch (type) {
			case 'pdf':
				return <i className="pi pi-file-pdf" />;
			case 'link':
				return <i className="pi pi-globe" />;
			case 'html_wyswig':
			case 'html':
				return <i className="pi pi-file" />;
			case 'file':
				return <i className="pi pi-cloud-download" />;
			case 'video':
				return <i className="pi pi-video" />;
			default:
				return <i className="pi pi-folder" />;
		}
	};

	const renderName = (rowData: Folder | File) => {
		switch (rowData.type) {
			case 'link':
				return (
					<>
						<Tooltip target=".tooltip" position="right" />
						<div className="flex align-items-center">
							<div
								data-pr-tooltip={t('userPanel.files.externalLinkTooltip')}
								data-pr-at="right+30 center"
								className="tooltip"
							>
								{rowData.name}
							</div>
							<i
								className="pi pi-external-link text-xs tooltip pl-2"
								data-pr-tooltip={t('userPanel.files.externalLinkTooltip')}
								data-pr-at="right+10 center"
							/>
						</div>
					</>
				);
			default:
				return rowData.name;
		}
	};

	const renderDate = (item: Folder | File) => {
		if (item.type === 'directory') {
			const dates = item.files.map((file) => new Date(file.updatedAt));
			const closestDate = closestTo(new Date(), dates);

			return closestDate ? <DateCell date={closestDate} /> : null;
		}

		return <DateCell date={new Date((item as File).updatedAt)} />;
	};

	const currentItem = breadcrumbs[breadcrumbs.length - 1]?.label || null;

	return (
		<>
			{currentItem && <Helmet title={currentItem} />}
			<BreadCrumb model={breadcrumbs} home={homeBreadcrumb} className="mb-3" />

			{selectedFile ? (
				<FileDisplay selectedFile={selectedFile} />
			) : (
				<DataTable
					dataKey="id"
					header={
						<FilesSearchbar
							searchValue={searchValue}
							onSearch={handleSearch}
							selectedFilterOption={filters.type.value}
							onFilter={handleFilter}
						/>
					}
					value={viewableFiles as File[] | Folder[]}
					emptyMessage={t('userPanel.files.fileNotFound')}
					onRowClick={({ data }) => handleRowClick(data)}
					rowClassName={() => 'cursor-pointer h-4rem'}
					loading={!filesData}
					filters={filters}
					filterDisplay="menu"
					rowHover
					className="table-hiddable"
				>
					<Column
						body={(item) => renderIcon(item.type)}
						style={{ width: '5%' }}
						className="table-hiddable-cell"
						headerClassName="table-hiddable-cell"
					/>
					<Column
						body={(item) => {
							return (
								<div>
									<span className="inline-block lg:hidden mr-2">{renderIcon(item.type)}</span>
									<span>{renderName(item)}</span>
								</div>
							);
						}}
						header={t('userPanel.files.name')}
						style={{ width: '60%' }}
						className="text-left"
					/>
					<Column
						body={(item) => renderDate(item)}
						header={t('userPanel.files.lastModification')}
						style={{ width: '35%' }}
						className="table-hiddable-cell"
						headerClassName="table-hiddable-cell"
					/>
				</DataTable>
			)}
		</>
	);
};

export default Files;
