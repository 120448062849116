import React, { Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';
import { Dropdown as DropdownOriginal, DropdownProps as DropdownPropsOriginal } from 'primereact/dropdown';

export type DropdownProps = DropdownPropsOriginal & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rounded?: boolean;
	hasError?: any;
	inline?: boolean;
};

export const Dropdown = forwardRef(
	({ hasError, rounded, className, inline, ...props }: DropdownProps, ref: Ref<DropdownOriginal>) => {
		const finalClassName = classNames(className, {
			'w-full': !inline,
			'border-round-3xl px-3': rounded,
			'p-invalid': Boolean(hasError),
		});
		return <DropdownOriginal ref={ref} className={finalClassName} {...props} />;
	}
);
