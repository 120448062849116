import React, { HTMLAttributes, Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';

import './Line.scss';

export type LineVariant = 'grey';

export type LineProps = HTMLAttributes<HTMLDivElement> & {
	variant?: LineVariant;
	vertical?: boolean;
	minWidth?: number;
};

export const Line = forwardRef(
	({ variant = 'grey', vertical, minWidth = 1, className, ...restProps }: LineProps, ref: Ref<HTMLDivElement>) => {
		return (
			<div
				ref={ref}
				className={classNames('line', `line-${variant}`, { 'line-vertical': vertical }, className)}
				style={{ minWidth: `${minWidth}px` }}
				{...restProps}
			/>
		);
	}
);
