/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useMutation } from '@tanstack/react-query';
import { programsApi } from 'api';
import { UpdateProgramRecruitmentParametersPayload } from 'types/Program';
import { Link } from 'react-router-dom';
import { Panel, PanelItem } from '../../partials/panel';
import { Icon } from '../../../../_new/Icon';

type ProgramRecruitmentForm = {
	menteeRecruitment: boolean;
	mentorRecruitment: boolean;
	requiresApplicationApproval: boolean;
};

export const ProgramRecruitment = () => {
	const { t } = useTranslation();
	const { data: programData, refetch: programRefetch } = useProgramPanel();

	const editModeState = useState(false);
	const form = useForm<ProgramRecruitmentForm>({
		defaultValues: {
			menteeRecruitment: programData?.menteeRecruitment || false,
			mentorRecruitment: programData?.mentorRecruitment || false,
			requiresApplicationApproval: programData?.requiresApplicationApproval || false,
		},
	});

	const { mutateAsync, isLoading } = useMutation(
		(data: UpdateProgramRecruitmentParametersPayload) =>
			programsApi.putProgramAdminRecruitmentParameters(Number(programData?.id), data),
		{
			onSuccess: () => {
				programRefetch?.();
			},
		}
	);

	const handleSave = (values: ProgramRecruitmentForm) => {
		mutateAsync(values).then(() => {
			editModeState[1](false);
		});
	};

	return (
		<Panel
			title={t('programPanel.dashboard.recruitment.title')}
			form={form}
			editModeState={editModeState}
			isLoading={isLoading}
			onSave={handleSave}
		>
			<>
				<PanelItem title={t('programPanel.dashboard.recruitment.title')} />
				<PanelItem
					title={t('misc.mentees')}
					value={programData?.menteeRecruitment}
					type="boolean"
					controller={{ control: form.control, name: 'menteeRecruitment' }}
					transformValue="on/off"
				/>
				<PanelItem
					title={t('misc.mentors')}
					value={programData?.mentorRecruitment}
					type="boolean"
					controller={{ control: form.control, name: 'mentorRecruitment' }}
					transformValue="on/off"
				/>
			</>
			<>
				<PanelItem
					title={t('programPanel.dashboard.recruitment.requiresApplicationApproval')}
					value={programData?.requiresApplicationApproval}
					type="boolean"
					controller={{ control: form.control, name: 'requiresApplicationApproval' }}
					transformValue="yes/no"
				/>
			</>
			<>
				<div className="flex flex-row justify-content-between">
					<PanelItem title={t('programPanel.dashboard.recruitment.forms')} />
					<Link
						className="button button-primary-outlined button-icononly button-size-md"
						to={`/program/${programData?.name}/edit-questions`}
					>
						<Icon name="pencil" iconSet="fa" iconStyle="solid" />
					</Link>
				</div>
				<PanelItem
					title={t('misc.mentees')}
					value={
						Number(programData?.questionStats?.applicationQuestionMenteeCount) > 0
							? programData?.questionStats?.applicationQuestionMenteeCount
							: undefined
					}
					transformValue={({ value, editMode }) => (
						<div>
							{value && value > 0 ? (
								<span>
									{t('programPanel.dashboard.recruitment.questions', {
										questions: value,
									})}
								</span>
							) : (
								<span className="bold text-danger">
									{t('programPanel.editQuestion.noQuestions')}
									<i className="ml-1 fa fa-regular fa-circle-xmark" />
								</span>
							)}
						</div>
					)}
					withEmptyText
					editable
					bold
				/>
				<PanelItem
					title={t('misc.mentors')}
					value={
						Number(programData?.questionStats?.applicationQuestionMentorCount) > 0
							? programData?.questionStats?.applicationQuestionMentorCount
							: undefined
					}
					transformValue={({ value, editMode }) => (
						<div>
							{value && value > 0 ? (
								<span>
									{t('programPanel.dashboard.recruitment.questions', {
										questions: value,
									})}
								</span>
							) : (
								<span className="bold text-danger">
									{t('programPanel.editQuestion.noQuestions')}
									<i className="ml-1 fa fa-regular fa-circle-xmark" />
								</span>
							)}
						</div>
					)}
					withEmptyText
					editable
					bold
				/>
			</>
		</Panel>
	);
};
