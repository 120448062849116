import React from 'react';
import { ModuleType } from 'types/Contract';
import { ContractModule, ContractModuleCommonProps } from './ContractModule';

type TextModuleProps = ContractModuleCommonProps & {
	module: ModuleType;
};

// eslint-disable-next-line arrow-body-style
export const TextModule = ({ module, ...contractModuleBoxProps }: TextModuleProps) => {
	return <ContractModule module={module} {...contractModuleBoxProps} className="contract-module-box-text" />;
};
