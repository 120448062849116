import { httpClient } from 'api';
import { EditMyAvatarPayload } from 'types/payloads/EditMyAvatarPayload';
import { EditMyUserDataPayload } from 'types/payloads/EditMyUserDataPayload';
import { User } from 'types/User';
import { Memberships } from 'types/Memberships';
import { CreateUserAsAdminPayload } from '../types/payloads/CreateUserAsAdminPayload';

export const usersApi = {
	getMemberships: () => httpClient.get<Memberships[]>('users/memberships'),
	editMyData: (payload: EditMyUserDataPayload) => httpClient.put<User>('users', payload),
	editTwoFactorAuth: (enabled: boolean) => httpClient.put<User>(`users/2fa/${String(enabled)}`),
	editMyAvatar: (payload: EditMyAvatarPayload) => {
		const formData = new FormData();
		formData.append('avatar', payload.avatar);
		return httpClient.put<User>('users/avatar', formData);
	},
	deleteMyAvatar: () => httpClient.delete<boolean>('users/avatar'),
	createUser: (programId: number, payload: CreateUserAsAdminPayload) =>
		httpClient.post(`users/admin/create/${programId}`, payload),
	diableWelcomePopup: () => httpClient.put(`users/popup`),
};
