import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { dayjs } from 'utils/dayjs';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';
import { Session } from 'types/Session';
import { usePanel } from 'contexts/panelContext';
import { SessionPayload } from 'types/payloads/SessionPayload';
import Calendar from 'components/_common/forms/Calendar';
import TextArea from 'components/_common/forms/TextArea';
import { useGlobal } from 'contexts/globalContext';
import ValidateCheckbox from 'components/_common/forms/Checkbox';
import { Checkbox } from 'primereact/checkbox';
import { CalendarMeetingPayload } from 'types/payloads/CalendarMeetingPayload';
import { Button } from 'components/_new/Button';
import { PairCalendarDialog } from 'components/myCalendar/PairCalendarDialog';
import { useSessionsData } from '../Sessions';
import TextField from '../../_common/forms/TextField';

type EditSessionDetailsDialogProps = DialogBaseExtendProps & {
	previousSession?: Session;
	session: Session;
	nextSession?: Session;
};

export const EditSessionDetailsDialog = ({
	previousSession,
	nextSession,
	session,

	...restProps
}: EditSessionDetailsDialogProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();
	const { todosRefetch, currentProgramMembership } = usePanel();
	const [sendIcsDialogOpen, setIcsDialogOpen] = useState(false);
	const [saveMode, setSaveMode] = useState(false);

	const [openCalendarDialog, setOpenCalendarDialog] = useState(false);

	const { sessiosRefetch } = useSessionsData();

	const { date, goal, pairId, id, finished, link } = session;

	const {
		control,
		handleSubmit,
		reset: formReset,
		watch,
		setValue,
		getValues,
	} = useForm<Pick<SessionPayload, 'date' | 'goal' | 'finished' | 'link'>>();
	const dateFromForm = watch('date');
	const currentDate = dateFromForm ? dayjs(dateFromForm).toDate() : undefined;
	useEffect(() => {
		setSaveMode(false);
		if (restProps.visible) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restProps.visible]);

	const {
		mutate: updateSession,
		isLoading: isSubmitting,
		reset: updateSessionReset,
	} = useMutation(
		({ date, ...payload }: Omit<SessionPayload, 'id'>) => {
			const data = {
				...payload,
				date: date && String(date).length > 0 ? new Date(date).toISOString() : null,
			};
			return userPanelApi.updateSession(+pairId, currentProgramMembership.id, id, data);
		},
		{
			onSuccess: ({ date }) => {
				todosRefetch();
				sessiosRefetch();
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.successfullSessionEditTooltip'),
				});

				handleHide();
				if (date) {
					setIcsDialogOpen(true);
				}
				setSaveMode(false);
			},
		}
	);

	const reset = () => {
		const calendarDate = date ? new Date(date) : undefined;
		formReset({
			date: calendarDate,
			goal,
			finished,
			link,
		} as Pick<SessionPayload, 'date' | 'goal' | 'finished' | 'link'>);
		updateSessionReset();
	};

	const { mutate: sendCalendarMutate, isLoading: sendCalendarLoading } = useMutation(
		(forMeOnly: CalendarMeetingPayload) =>
			userPanelApi.sendCalendarEmail(session.pairId, currentProgramMembership.id, session.id, forMeOnly),
		{
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.sendICS.success'),
				});
				setIcsDialogOpen(false);
			},
		}
	);

	const [forMeOnly, setForMeOnly] = useState(false);

	const handleSubmitFormCalendar = (event: any) => {
		event.preventDefault();
		sendCalendarMutate({ forMeOnly });
	};

	const handleSubmitForm = handleSubmit(({ date, goal, finished, link }) => {
		setSaveMode(true);
		const currentDate = date ? new Date(date).getTime() : null;
		const prevTime = previousSession?.date ? new Date(previousSession?.date).getTime() : null;
		const nextTime = nextSession?.date ? new Date(nextSession?.date).getTime() : null;
		if (prevTime && currentDate && prevTime > currentDate) {
			toastRef?.current?.show({
				severity: 'error',
				life: 3000,
				summary: t('userPanel.sessionsNew.prvSession'),
				detail: t('userPanel.sessionsNew.prvSessionSub'),
			});
		} else if (currentDate && nextTime && currentDate > nextTime) {
			toastRef?.current?.show({
				severity: 'error',
				life: 3000,
				summary: t('userPanel.sessionsNew.nxtSession'),
				detail: t('userPanel.sessionsNew.nxtSessionSub'),
			});
		} else {
			updateSession({
				date,
				goal,
				finished,
				link,
			});
		}
	});

	const handleHide = () => {
		if (!saveMode) {
			const values = getValues();

			const sessionDateChange =
				(session.date ? new Date(session.date).toISOString() : null) !==
				(values.date ? new Date(values.date || '').toISOString() : null);
			const hasChanges = sessionDateChange || session.goal !== values.goal;

			if (hasChanges) {
				if (window.confirm(t('misc.confirmReject'))) {
					restProps?.onHide?.();
					reset();
				}
			} else {
				reset();
				restProps?.onHide?.();
			}
		} else {
			restProps?.onHide?.();
		}
	};

	return (
		<>
			<PairCalendarDialog
				selectedSession={session}
				visible={openCalendarDialog}
				onHide={() => setOpenCalendarDialog(false)}
				pairId={pairId}
				defaultCurrentDate={currentDate}
				highlightEventSession={id}
				onAccept={(date) => setValue('date', date as unknown as string)}
			/>
			<DialogAction
				title={t('userPanel.sessions.editSessionAndObjective')}
				actions={[
					{
						key: 'send',
						label: t('userPanel.myCalendar.sendToCalendar'),
						submit: true,
						form: 'send-ics-form',
						loading: sendCalendarLoading,
					},
				]}
				visible={sendIcsDialogOpen}
				onHide={() => setIcsDialogOpen(false)}
			>
				<form id="send-ics-form" onSubmit={handleSubmitFormCalendar}>
					<p>{t('userPanel.sessions.sendICS.description')}</p>
					<div className="mb-5">
						<Checkbox
							checked={!forMeOnly}
							className="mt-4 mr-2"
							onChange={(e) => setForMeOnly(!e.target.checked)}
						/>
						{t('userPanel.sessions.sendICS.forMeOnly')}
					</div>
				</form>
			</DialogAction>
			<DialogAction
				title={t('userPanel.sessions.editSessionAndObjective')}
				actions={[
					{
						key: 'save',
						label: t('actions.save'),
						submit: true,
						form: 'edit-session-form',
						loading: isSubmitting,
					},
				]}
				{...restProps}
				onHide={handleHide}
			>
				<form id="edit-session-form" onSubmit={handleSubmitForm} className="flex flex-column row-gap-3">
					<div className="flex flex-column gap-1">
						<Calendar control={control} name="date" label={t('userPanel.sessions.dateLabel')} withHours />
						<div className="flex flex-row justify-content-end">
							<Button
								onClick={() => setOpenCalendarDialog(true)}
								label={t('userPanel.sessions.findDate')}
								variant="primary-text"
								variantSize="sm"
							/>
						</div>
					</div>
					<TextField control={control} name="link" label={t('userPanel.sessions.meetingLinkLabel')} />
					<TextArea control={control} name="goal" label={t('userPanel.sessions.goalLabel')} />
					<ValidateCheckbox
						control={control}
						name="finished"
						text={t('userPanel.sessions.markSessionAsFinished')}
					/>
				</form>
			</DialogAction>
		</>
	);
};
