import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { dateFormat } from 'utils/dateFormat';
import { Spinner } from 'components/_new/Spinner';
import { useContractContext } from '../ContractContext';
import { ContractModule, ContractModuleCommonProps } from './ContractModule';

type HarmonogramModuleProps = ContractModuleCommonProps;

const HarmonogramModule = ({ module, ...contractModuleBoxProps }: HarmonogramModuleProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();
	const { pairId } = useContractContext();
	const { currentProgramMembership } = usePanel();

	const {
		data: sessionsData,
		isLoading: sessionsLoading,
		isError: sessionsError,
		isSuccess: sessionsSuccess,
		// refetch: getSessions,
	} = useQuery(['sessions', { pairId }], () => userPanelApi.getSessions(pairId, currentProgramMembership.id), {
		enabled: true,
	});

	return (
		<ContractModule
			module={module}
			headerRenderRight={() => (
				<Trans
					t={t}
					i18nKey="userPanel.contract.meetingsInfo"
					components={[
						<Link
							to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session`}
							className="inline p-link underline ml-1"
						/>,
					]}
				/>
			)}
			{...contractModuleBoxProps}
		>
			{() => (
				<>
					{sessionsLoading && <Spinner />}
					{sessionsError && <p>Wystąpił błąd</p>}
					{sessionsSuccess && (
						<>
							<p>
								<Trans
									i18nKey="userPanel.contract.harmonogramTip"
									components={[
										<Link
											to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session`}
											className="inline p-link underline"
										/>,
									]}
								/>
							</p>
							<ul>
								{(sessionsData || [])?.map((session) => (
									<li key={session.id} className="flex flex-col align-items-center">
										{`${t('userPanel.sessions.session')} ${session.sessionNumber}`}:{' '}
										{session.date ? (
											<span className="font-bold mx-2 text-purplish-blue">
												{dateFormat(session.date, 'full')}
											</span>
										) : (
											<span className="pi pi-exclamation-triangle text-rose mx-2" />
										)}
									</li>
								))}
							</ul>
						</>
					)}
				</>
			)}
		</ContractModule>
	);
};

export default HarmonogramModule;
