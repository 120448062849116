import { useRef, useState } from 'react';

type DispatchCallback<T> = (prevValue: T) => T;
type Dispatch<T> = T | DispatchCallback<T>;
type DispatchHandler<T> = (dispatch: Dispatch<T>) => void;
type GetValueHandler<T> = () => T;

export const useRefValue = <T = any>(defaultValue: T): [T, DispatchHandler<T>, GetValueHandler<T>] => {
	const valueRef = useRef(defaultValue);
	const value = valueRef.current;
	const [idx, setIdx] = useState<number>(0);

	const setValueInternal = (newValue: T): T => {
		valueRef.current = newValue;
		setIdx((prev) => prev + 1);
		return newValue;
	};

	const setValue: DispatchHandler<T> = (dispatch: Dispatch<T>) => {
		const newValue = dispatch instanceof Function ? dispatch(valueRef.current) : dispatch;
		setValueInternal(newValue);
	};

	const getValue = (): T => valueRef.current;

	return [value, setValue, getValue];
};
