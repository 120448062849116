import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { File } from 'types/File';

import { Message } from 'primereact/message';
import { Box, BoxSection } from 'components/_new/Box';
import { MessageBox } from 'components/_new/MessageBox';
import FileDisplayModule from './FileDisplayModule';

interface Props {
	selectedFile: File;
}
const FileDisplay: FC<Props> = ({ selectedFile }) => {
	const { t } = useTranslation();
	return (
		<Box variant="white-bordered">
			<BoxSection header={selectedFile.name}>
				{selectedFile.menteeAccess && selectedFile.mentorAccess && (
					<Message
						severity="info"
						text={t('userPanel.files.accessBoth')}
						className="w-full justify-content-start pl-4 mb-4"
					/>
				)}
				{selectedFile.menteeAccess && !selectedFile.mentorAccess && (
					<Message
						severity="info"
						text={t('userPanel.files.accessMenteesOnly')}
						className="w-full justify-content-start pl-4 mb-4"
					/>
				)}
				{!selectedFile.menteeAccess && selectedFile.mentorAccess && (
					<MessageBox
						icon="info-circle"
						iconSet="pi"
						variant="purple"
						message={t('userPanel.files.accessMentorsOnly')}
					/>
				)}
				{/* <Message
					severity="warn"
					text={t('userPanel.files.contentCopyWarning')}
					className="w-full justify-content-start pl-4 mb-4"
				/> */}
				<FileDisplayModule selectedFile={selectedFile} />
			</BoxSection>
		</Box>
	);
};

export default FileDisplay;
