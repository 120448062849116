import React, { useCallback, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { calendarApi } from 'api/calendar';
import { Calendar, CalendarEventBase } from 'components/_new/Calendar/Calendar';
import { Spinner } from 'components/_new/Spinner';
import { Dialog, DialogBaseProps } from 'components/_new/Dialog';
import { Button } from 'components/_new/Button';
import { Session } from 'types/Session';
import { dateFormat } from 'utils/dateFormat';
import { CalendarEvent } from 'types/Calendar';
import { usePair } from 'contexts/pairContext';
import { userDisplayName } from 'utils/userDisplayName';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { useGranularEffect } from 'hooks/useGranularEffect';
import { mapEvent } from './utils';
import { ManageCalendars } from './ManageCalendars';
import { dayjs } from '../../utils/dayjs';

type PairCalendarDialogProps = DialogBaseProps & {
	selectedSession: Session;
	pairId: number;
	defaultCurrentDate?: Date;
	highlightEventSession?: number;
	onAccept?: (date: Date) => void;
};

export const PairCalendarDialog = (props: PairCalendarDialogProps) => {
	const { selectedSession, defaultCurrentDate, highlightEventSession, pairId, onAccept, ...restProps } = props;
	const { visible } = restProps;

	// hooks
	const { t } = useTranslation();
	const { application, contract } = usePair();

	// state
	const [mode, setMode] = useState<'calendar' | 'manage'>('calendar');
	const [addMode, setAddMode] = useState<boolean>(false);
	const [bump, setBump] = useState<number>(0);
	const [lastSync, setLastSync] = useState<{ date: number; calendars: number; init: boolean }>({
		date: 0,
		calendars: 0,
		init: false,
	});
	const [createdEvent, setCreatedEvent] = useState<CalendarEventBase | null>(null);

	// handler
	const handleAddCalendar = useCallback(() => {
		setAddMode(true);
	}, []);
	const handleCancelAddCalendar = useCallback(() => {
		setAddMode(false);
	}, []);
	const handleTimeClick = useCallback(
		(meta: any) => {
			const { time } = meta;
			const summary = t('userPanel.sessions.sessionNoWith', {
				no: selectedSession.sessionNumber,
				with: userDisplayName(application.programMembership.user),
			});
			setCreatedEvent({
				key: 'ceatedSession',
				id: 'createdSession',
				summary,
				startTime: dayjs(time).toDate(),
				endTime: dayjs(time)
					.add((contract.sessionTime || 45) + 1, 'minutes')
					.toDate(),
				dateOnly: false,
				color: { bg: '#f674cb', text: 'white' },
				source: 'new',
				onClick: () => {
					setCreatedEvent(null);
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedSession]
	);
	const handleAccept = useCallback(() => {
		if (onAccept && createdEvent) {
			onAccept(createdEvent.startTime);
			restProps.onHide?.();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createdEvent]);

	// on update
	useEffect(() => {
		if (mode === 'calendar' || !visible) {
			setAddMode(false);
			setCreatedEvent(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, visible]);
	useEffect(() => {
		if (visible) {
			userEventsRefetch();
			pairEventsRefetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	const {
		data: userEventsData,
		isLoading: userEventsLoading,
		refetch: userEventsRefetch,
	} = useQuery(['calendar-events'], () => calendarApi.getCalendarEvents(), {
		cacheTime: 1000,
		// enabled: visible && mode === 'calendar',
		enabled: false, // prevent evry-time refetch
	});

	const {
		data: pairEventsData,
		isLoading: pairEventsLoading,
		refetch: pairEventsRefetch,
	} = useQuery(['calendar-events-pair', pairId], () => calendarApi.getCalendarEventsPair(pairId), {
		cacheTime: 1000,
		// enabled: visible && mode === 'calendar',
		enabled: false, // prevent evry-time refetch
	});

	// sync info handle
	const { data: syncInfoData, refetch: syncInfoRefetch } = useQuery(
		['calendars-info'],
		calendarApi.getCalendarSyncInfo,
		{
			enabled: false,
			// enabled: visible && !userEventsLoading && !pairEventsLoading, // do not refetch sync info while events refetching
			refetchInterval: 5000,
			cacheTime: 3000,
			initialData: { whileSync: true, calendars: 0, lastSyncDate: null },
		}
	);
	useEffect(() => {
		if (visible) {
			const syncInfoDate = syncInfoData.lastSyncDate ? new Date(syncInfoData.lastSyncDate).getTime() : 0;
			if (syncInfoDate !== lastSync.date || syncInfoData.calendars !== lastSync.calendars) {
				userEventsRefetch();
				pairEventsRefetch();
				setBump((prev) => prev + 1);
				setLastSync({
					calendars: syncInfoData.calendars,
					date: syncInfoDate,
					init: true,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible, syncInfoData, lastSync]);

	const { mutate: syncMutate, isLoading: syncLoading } = useMutation(calendarApi.syncAllCalendars, {
		onSuccess: () => {
			syncInfoRefetch();
		},
	});

	// computed
	const isEventsLoading = userEventsLoading || pairEventsLoading;
	const highlightEventSessionItem: CalendarEventBase | null =
		userEventsData &&
		userEventsData?.findIndex((item) => Number(item.meta?.sessionId) === highlightEventSession) !== -1
			? {
					...(userEventsData.find(
						(item) => Number(item.meta?.sessionId) === highlightEventSession
					) as CalendarEvent),
					key: 'selectedEvent',
					id: 'selectedEvent',
					color: {
						bg: '#f674cb',
						text: 'white',
					},
			  }
			: null;
	const events: CalendarEventBase[] = [
		...(userEventsData || [])
			.filter((item) => Number(item.meta?.sessionId) !== highlightEventSession)
			.map((item) =>
				mapEvent(item, ({ source }) => ({
					bg: source === 'internal' ? '#6466e9' : '#bdc6ff',
					text: source === 'internal' ? 'white' : 'black',
				}))
			),
		...(pairEventsData || []).map((item) => mapEvent(item, () => ({ bg: '#e7e7ff', text: 'black' }))),
		// eslint-disable-next-line no-unneeded-ternary
		createdEvent ? createdEvent : highlightEventSessionItem,
	].filter(Boolean) as CalendarEventBase[];
	const canSync =
		syncInfoData?.calendars > 0 &&
		(!syncInfoData.lastSyncDate ||
			(syncInfoData.lastSyncDate && dayjs().diff(syncInfoData.lastSyncDate, 'minutes') >= 5));

	return (
		<Dialog
			title={t('userPanel.myCalendar.pairDialogTitle', {
				sessionNumber: selectedSession.sessionNumber,
				sessionDate: selectedSession.date ? dateFormat(selectedSession.date, 'date') : '-',
			})}
			size="lg"
			bodyClassName={mode === 'calendar' ? 'p-0 overflow-hidden' : undefined}
			headerRight={
				<div className="flex flex-row justify-content-between gap-2">
					{mode === 'calendar' && (
						<>
							<span
								className="font-normal text-gray text-sm text-right hidden md:block"
								style={{ maxWidth: 200 }}
							>
								<span>{t('userPanel.myCalendar.lastSync')}: </span>
								<span className="no-break">
									{syncInfoData.calendars === 0 && t('userPanel.myCalendar.noCalendars')}
									{syncInfoData.lastSyncDate ? dayjs(syncInfoData.lastSyncDate).fromNow() : ''}
								</span>
								{syncInfoData.calendars > 0 && (
									<Button
										variant="primary-text"
										variantSize="sm"
										label={t('userPanel.myCalendar.syncNow')}
										className="inline-flex p-0 ml-2"
										onClick={() => syncMutate()}
										loading={syncLoading || syncInfoData.whileSync}
										disabled={!canSync}
									/>
								)}
							</span>
							<Button
								onClick={() => setMode('manage')}
								label={t('userPanel.myCalendar.manage')}
								variant="primary"
							/>
						</>
					)}
					{mode === 'manage' && (
						<>
							{!addMode && <Button label={t('userPanel.myCalendar.add')} onClick={handleAddCalendar} />}
							{addMode && <Button label={t('actions.cancel')} onClick={handleCancelAddCalendar} />}
							<Button
								onClick={() => setMode('calendar')}
								label={t('userPanel.myCalendar.backToCalendar')}
							/>
						</>
					)}
				</div>
			}
			{...props}
		>
			<div className="flex flex-column overflow-hidden h-full">
				{mode === 'calendar' && (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{isEventsLoading ? (
							<div className="flex flex-row justify-content-center">
								<Spinner />
							</div>
						) : (
							<>
								<Calendar
									events={events}
									defaultCurrentDate={defaultCurrentDate}
									onTimeClick={handleTimeClick}
								/>
								{createdEvent && (
									<div className="flex flex-row justify-content-end gap-2 pb-4 px-4">
										<Button label={t('actions.cancel')} onClick={() => setCreatedEvent(null)} />
										<Button label={t('actions.choose')} onClick={handleAccept} />
									</div>
								)}
							</>
						)}
					</>
				)}
				{mode === 'manage' && (
					<ManageCalendars
						bump={bump}
						addMode={addMode}
						setAddMode={setAddMode}
						onAddCalendar={() => {
							syncInfoRefetch();
						}}
						onRemoveCalendar={() => {
							syncInfoRefetch();
						}}
					/>
				)}
			</div>
		</Dialog>
	);
};
