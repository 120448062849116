import React, { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { fixedT } from 'locale';
import { landingPagesApi } from 'api';
import { RouterUrlParams } from 'App';
import { ApplicationType } from 'types/Application';
import { colors } from 'theme';
import { Message } from 'primereact/message';
import FormQuestions from 'components/landingPages/components/FormQuestions';
import FormSubmitted from 'components/_common/FormSubmitted';
import { Divider } from 'primereact/divider';
import ProgressSteps from 'components/_common/ProgressSteps/ProgressSteps';
import PageMissing from 'components/PageMissing';
import { Loader } from 'components/_new/Loader';

interface LandingPageFormProps {
	type: 'mentor' | 'mentee';
	requiresApplicationApproval: boolean;
	programId: number;
	programLanguage: string;
	applicationType: ApplicationType;
	currentProgramMembershipId: number;
	recruitmentEnabled: boolean;
}

const LandingPageForm: FC<LandingPageFormProps> = (props) => {
	const {
		type,
		requiresApplicationApproval,
		programId,
		programLanguage,
		applicationType,
		currentProgramMembershipId,
		recruitmentEnabled,
	} = props;
	const { organizationName, programName } = useParams() as RouterUrlParams;

	const [isSubmitted, setIsSubmitted] = useState(false);

	const {
		refetch: getMembership,
		data: membershipData,
		isLoading: isMembershipLoading,
		isError: isMembershipError,
		isRefetching: isMembershipRefetching,
	} = useQuery(['membership'], () =>
		landingPagesApi.getProgramApplications(currentProgramMembershipId, { applicationType, applicationRole: type })
	);
	const currentMembership = membershipData?.[0] || null;

	const {
		refetch: getQuestions,
		data: questionsData,
		isLoading: isQuestionsLoading,
		isError: isQuestionsError,
		isRefetching: isQuestionsRefetching,
	} = useQuery(
		['questions', { programId: Number(programId), applicationType, applicationRole: type }],
		() => landingPagesApi.getFormQuestions(programId, { applicationType, applicationRole: type }),
		{
			onSuccess: () => {
				getMembership();
			},
		}
	);

	useEffect(() => {
		getQuestions();
	}, [getQuestions, programId, type, applicationType]);

	const formApproved = () => (
		<>
			<span>{fixedT(programLanguage, 'landingPages.formAlreadySubmitted1')}</span>
			{currentMembership?.applicationType === 'proper' && (
				<>
					<span> {fixedT(programLanguage, 'landingPages.formAlreadySubmitted2')}</span>
					<Link
						className="underline"
						style={{ color: colors.purplishBlue }}
						to={`/panel/${organizationName}/${programName}`}
					>
						{fixedT(programLanguage, 'landingPages.formAlreadySubmittedLink')}
					</Link>
					<span>{fixedT(programLanguage, 'landingPages.formAlreadySubmitted3')}</span>
				</>
			)}
		</>
	);

	useEffect(() => {
		setIsSubmitted(false);
	}, [type]);

	if (isQuestionsError || isMembershipError) {
		return <PageMissing />;
	}

	if (
		isMembershipLoading ||
		isMembershipRefetching ||
		isQuestionsLoading ||
		isQuestionsRefetching ||
		!membershipData ||
		!questionsData
	) {
		return <Loader />;
	}

	return (
		<div className="landing-page-background">
			<div className="landing-page-box">
				{isSubmitted ? (
					<FormSubmitted
						requiresApplicationApproval={requiresApplicationApproval}
						applicationType={applicationType}
					/>
				) : (
					<>
						{currentMembership && (
							<div className="landing-page-content pt-4 pb-0">
								<Message
									severity="info"
									text={
										// eslint-disable-next-line no-nested-ternary
										currentMembership?.approved === 'not accepted'
											? fixedT(programLanguage, 'landingPages.formAlreadySubmittedAndWaiting')
											: currentMembership?.approved !== 'rejected'
											? formApproved()
											: fixedT(programLanguage, 'landingPages.formRejected')
									}
									className="w-full justify-content-start pl-4"
								/>
							</div>
						)}

						<div className="landing-page-content text-4xl comfortaa-font font-bold line-height-2 pb-0 mb-4">
							{applicationType === 'proper'
								? fixedT(programLanguage, 'landingPages.properFormHeader', {
										type:
											type === 'mentor'
												? fixedT(programLanguage, 'misc.mentors')
												: fixedT(programLanguage, 'misc.mentees'),
								  })
								: fixedT(programLanguage, 'landingPages.entryFormHeader', {
										type:
											type === 'mentor'
												? fixedT(programLanguage, 'misc.mentors')
												: fixedT(programLanguage, 'misc.mentees'),
								  })}
						</div>

						{!recruitmentEnabled && (
							<div className="landing-page-content pb-0 pt-0 my-6">
								<div className="mb-6" />
								<p>{fixedT(programLanguage, 'landingPages.recruitmentDisabled')}</p>
								<Link
									to={`/${organizationName}/${programName}`}
									className="p-link underline text-center"
								>
									{fixedT(programLanguage, 'landingPages.recruitmentDisabledReturn')}
								</Link>
							</div>
						)}

						{recruitmentEnabled && (
							<>
								{!currentMembership && (
									<div className="landing-page-content pb-0 pt-0">
										<Divider className="hidden md:flex" />
										<div>{fixedT(programLanguage, 'landingPages.formStepsInfo1')}</div>
										<div className="mt-2">
											{fixedT(programLanguage, `landingPages.formStepsInfo2${type}`)}
										</div>
										<div className="text-red-600 mt-2">
											{fixedT(programLanguage, 'landingPages.formStepsInfo3')}
										</div>
									</div>
								)}

								<FormQuestions
									setIsSubmitted={setIsSubmitted}
									key={type}
									type={type}
									programMembershipId={currentProgramMembershipId}
									questionsData={questionsData}
									membershipData={membershipData[0]}
									applicationType={applicationType}
									// getMembership={getMembership}
								/>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default LandingPageForm;
