/* eslint-disable arrow-parens */
import React, { useEffect, useContext, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { userPanelApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import { Dropdown } from 'components/_common/forms/Dropdown';
import { InputTextarea } from 'components/_common/forms/InputTextarea';
import { ContractType, ModuleType } from 'types/Contract';
import { parseHtml } from 'utils/htmlParser';
import { EditContractPayload } from 'types/payloads/ContractPayload';
import { usePair } from 'contexts/pairContext';
import { ContractModule, ContractModuleCommonProps } from './ContractModule';

type EditTextModuleEditHandler = (module: ModuleType, hasChanges?: boolean) => boolean;
type EditTextModuleCancelHandler = (module: ModuleType) => void;
type EditTextModuleSaveHandler = (module: ModuleType, value: any) => Promise<boolean>;

type GuideToMeetingsModuleProps = ContractModuleCommonProps & {
	pairId?: number;
	contractData: ContractType;
	onEdit?: EditTextModuleEditHandler;
	onCancel?: EditTextModuleCancelHandler;
	onSave?: EditTextModuleSaveHandler;
};

const GuideToMeetingsModule = ({
	onInit,
	// pairId,
	contractData,
	module,
	onEdit,
	onCancel,
	onSave,
	...contractModuleBoxProps
}: GuideToMeetingsModuleProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();
	const { currentProgramMembership } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const { refetch: sessiosRefetch, data: sessionsData } = useQuery(['sessions', { pairId }], () =>
		userPanelApi.getSessions(+pairId, currentProgramMembership.id)
	);

	useEffect(() => {
		sessiosRefetch();
	}, []);

	const { mutateAsync: mutateUpdateContract } = useMutation((data: EditContractPayload) =>
		userPanelApi.putContract(+pairId, currentProgramMembership.id, data)
	);

	const { watch, setValue, trigger, reset, getValues } = useForm({
		defaultValues: {
			preferredContactAndPlace: contractData.prefContactAndPlace ? String(contractData.prefContactAndPlace) : '',
			preferredContact: contractData.prefContact ? String(contractData.prefContact) : '',
			durationOfSession: contractData.sessionTime,
		},
	});

	const dropdownOptions = Array(9)
		.fill(undefined)
		.map((x, y) => ({
			label: `${(y + 1) * 15} ${t('misc.minutes')}`,
			value: (y + 1) * 15,
		}));

	const getHasChanges = () => {
		const durationOfSessionChanges = Number(getValues('durationOfSession')) !== contractData.sessionTime;
		const preferredContactChanges = getValues('preferredContact') !== contractData.prefContact;
		const prefContactAndPlaceChanges = getValues('preferredContactAndPlace') !== contractData.prefContactAndPlace;
		return durationOfSessionChanges || preferredContactChanges || prefContactAndPlaceChanges;
	};

	const handleDropdownChange = useCallback(
		({ value }: any) => {
			setValue('durationOfSession', value);
			if (onEdit) {
				onEdit(module, getHasChanges());
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleSave = useCallback(
		async () =>
			trigger(['durationOfSession', 'preferredContactAndPlace', 'preferredContact']).then(
				async (isValid: any) => {
					if (isValid) {
						const values = getValues();
						const success = await mutateUpdateContract({
							id: Number(pairId),
							sessionTime: values.durationOfSession,
							prefContact: values.preferredContact,
							prefContactAndPlace: values.preferredContactAndPlace,
						})
							.then(() => true)
							.catch(() => false);
						if (onSave) {
							onSave(module, values);
						}
						return success;
					}
					return false;
				}
			),
		[getValues, pairId, module, mutateUpdateContract, onSave, trigger]
	);

	const durationOfSession = watch('durationOfSession');
	const preferredContactAndPlaceType = watch('preferredContactAndPlace');
	const preferredContact = watch('preferredContact');

	const handleEdit = useCallback(() => {
		if (onEdit) {
			const success = onEdit(module);
			return success;
		}
		return false;
	}, [onEdit, module]);

	const handleCancel = useCallback(() => {
		reset({
			durationOfSession: contractData.sessionTime,
			preferredContactAndPlace: String(contractData.prefContactAndPlace),
			preferredContact: String(contractData.prefContact),
		});
		if (onCancel) {
			onCancel(module);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contractData, onCancel, module]);

	// pseudo-ref
	useEffect(() => {
		if (onInit) {
			onInit({
				save: handleSave,
			});
		}
	}, [handleSave, onInit]);

	return (
		<ContractModule
			module={module}
			editable
			onEdit={handleEdit}
			onSave={handleSave}
			onCancel={handleCancel}
			{...contractModuleBoxProps}
		>
			{({ editMode }) => (
				<div className="flex flex-column gap-1">
					<div className="flex flex-column gap-4">
						<div>
							<Trans
								t={t}
								i18nKey="userPanel.contract.assumedNumberOfMeetings"
								count={(sessionsData || []).length}
								components={[<span className="strong text-purplish-blue" />]}
							/>{' '}
							{editMode && (
								<Trans
									t={t}
									i18nKey="userPanel.contract.numberOfMeetingsChangePossibilityInfo"
									components={[
										<Link
											to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/session`}
											className="p-link"
										/>,
									]}
								/>
							)}
						</div>
						<div>
							<span>
								{t('userPanel.contract.durationOfSession')}
								{': '}
							</span>
							{editMode ? (
								<span>
									<Dropdown
										value={durationOfSession}
										options={dropdownOptions}
										onChange={handleDropdownChange}
										className="flex-0 text-left mt-3 ml-3"
										rounded
										inline
									/>
								</span>
							) : (
								<span className="strong text-purplish-blue">
									{durationOfSession || '-'} {t('misc.minutes')}
								</span>
							)}
						</div>
						<div>
							<span>
								{t('userPanel.contract.preferredContactAndPlaceType')}
								{': '}
							</span>
							{editMode ? (
								<InputTextarea
									value={preferredContactAndPlaceType}
									autoResize
									rounded
									onChange={({ target: { value } }) => {
										setValue('preferredContactAndPlace', value);
										if (onEdit) {
											onEdit(module, getHasChanges());
										}
									}}
									// @ts-ignore - TODO: remove after primereact version bump (curr @8.5.0), error caused by some mistake in types for library, attribute works normally
									rows={3}
									cols={70}
									maxLength={250}
								/>
							) : (
								<span className="strong text-purplish-blue">
									{parseHtml(contractData.prefContactAndPlace || '-')}
								</span>
							)}
						</div>
						<div>
							<span>
								{t('userPanel.contract.preferredContactType')}
								{': '}
							</span>
							{editMode ? (
								<InputTextarea
									value={preferredContact}
									autoResize
									rounded
									onChange={({ target: { value } }) => {
										setValue('preferredContact', value);
										if (onEdit) {
											onEdit(module, getHasChanges());
										}
									}}
									// @ts-ignore - TODO: remove after primereact version bump (curr @8.5.0), error caused by some mistake in types for library, attribute works normally
									rows={3}
									cols={70}
									maxLength={250}
								/>
							) : (
								<span className="strong text-purplish-blue">
									{parseHtml(contractData.prefContact || '-')}
								</span>
							)}
						</div>
					</div>
				</div>
			)}
		</ContractModule>
	);
};

export default GuideToMeetingsModule;
