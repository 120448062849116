import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/_common/Button';
import { classNames } from 'primereact/utils';
import './ContractModuleBox.scss';

type ContractModuleBoxProps = PropsWithChildren<{
	className?: string;
	num: number;
	title: string;
	mergeLayout?: false | 'top' | 'bottom' | 'both';
	contentInsideHeader?: boolean;
	editable?: boolean;
	editMode?: boolean;
	loading?: boolean;
	headerRenderRight?: () => React.ReactNode;
	handleSaveClick?: any;
	handleEditClick?: any;
	handleCancelEditClick?: any;
}>;

export const ContractModuleBox = ({
	className,
	num,
	title,
	mergeLayout = false,
	contentInsideHeader = false,
	editable = false,
	editMode = false,
	loading = false,
	headerRenderRight,
	handleSaveClick,
	handleEditClick,
	handleCancelEditClick,
	children,
}: ContractModuleBoxProps) => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames('contract-module-box', className, {
				'rounded-xl my-2': mergeLayout === false,
			})}
		>
			<div
				className={classNames('contract-module-box-inside bg-new-gray py-2 px-3', {
					'rounded-b-xl mb-3': contentInsideHeader && mergeLayout === 'top',
					'rounded-t-xl mt-4': contentInsideHeader && mergeLayout === 'bottom',
					'': contentInsideHeader && mergeLayout === 'both',
					'rounded-xl mt-4 mb-3': !contentInsideHeader || (contentInsideHeader && mergeLayout === false),
				})}
			>
				<div className="contract-module-box-header flex flex-ro flex-wrap align-items-center gap-1">
					<div className="contract-module-box-header-left flex-1 flex flex-row align-items-center">
						<span className="header-num mr-3">{num}</span> <span className="strong">{title}</span>
					</div>
					<div className="contract-module-box-header-right">
						{headerRenderRight && headerRenderRight()}
						{editable && (
							<div className="flex-0 flex align-items-center justify-content-center">
								{!editMode ? (
									<Button
										className="p-button-primary p-button-text p-1"
										onClick={handleEditClick}
										label={t('actions.edit')}
										icon="pi pi-pencil color-purple"
										disabled={loading}
										// disabled={type === 'mentor' || loading === true}
										// tooltip={type === 'mentor' ? t('userPanel.editableOnlyForMentee') : undefined}
										// tooltipOptions={{ showOnDisabled: true }}
									/>
								) : (
									<div className="flex flex-row gap-1 ml-auto">
										<Button
											className="p-button-primary p-button-text p-1"
											onClick={handleCancelEditClick}
											disabled={loading}
											label={t('actions.cancel')}
											icon="pi pi-times color-purple"
										/>
										<Button
											className="p-button-primary p-button-text p-1"
											onClick={handleSaveClick}
											disabled={loading}
											label={t('actions.save')}
											icon="pi pi-save color-purple"
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				{contentInsideHeader && <div className="px-2">{children}</div>}
			</div>
			{!contentInsideHeader && <div className="px-2 mt-2">{children}</div>}
		</div>
	);
};
