import React, { CSSProperties, ReactNode } from 'react';

interface Props {
	title: ReactNode;
	additional?: string;
	right?: ReactNode | (() => ReactNode);
	additionalStyles?: CSSProperties;
}

const SubPageTitle = ({ title, additional, right, additionalStyles }: Props) => (
	<div
		className="flex flex-row flex-wrap align-items-center justify-content-between mb-1 ml-2 font-bold text-black"
		style={{
			...additionalStyles,
		}}
	>
		<div className="py-3">
			<span className="font-bold text-base">{title}</span>
			{additional && additional.length && (
				<span
					className="text-sm text-500 font-light ml-3 text-bold text-sm"
					style={{
						...additionalStyles,
					}}
				>
					{additional}
				</span>
			)}
		</div>
		{right && typeof right === 'function' ? right() : right}
	</div>
);

export default SubPageTitle;
