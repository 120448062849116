import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { surveyApi } from 'api/survey';
import { Box, BoxSection } from 'components/_new/Box';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'components/_new/Spinner';
import { FinalSurveyPresenter } from 'components/_common/FnalSurveyPresenter';
import { useTranslation } from 'react-i18next';
import PageMissing from 'components/PageMissing';

export const FinalSurveyAccess = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const params = useParams();
	const uuid = params?.uuid || null;

	const { data, isLoading, isSuccess } = useQuery(
		['FINAL_SURVEY', { uuid }],
		() => surveyApi.getSurveyByUuid(String(uuid)),
		{
			enabled: Boolean(uuid),
			onError: () => {},
		}
	);

	const queryClient = useQueryClient();
	const { mutate, isLoading: updating } = useMutation(
		({ step, answers }: { step: number; answers: Array<any> }) =>
			surveyApi.updateSurveyByUuid(String(uuid), { step, answers }),
		{
			onSuccess: () => {
				queryClient.refetchQueries(['FINAL_SURVEY', { uuid }]);
			},
		}
	);
	const handleSave = (step: number, answers: Array<any>) => {
		window?.scrollTo({ top: 0, behavior: 'smooth' });
		mutate({ step, answers });
	};

	const handleEnd = useCallback(() => {
		if (data) {
			navigate({ pathname: `/${data?.program.name}` });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (!isLoading && !data) {
		return <PageMissing />;
	}

	return (
		<div className="final-survey-container">
			<h2 className="px-4 text-lg font-bold">{t('userPanel.finalSurvey.title')}</h2>
			<Box variant="white-bordered" dropShadow>
				<BoxSection>
					{isLoading && <Spinner center />}
					{isSuccess && (
						<FinalSurveyPresenter
							survey={data.survey}
							updating={updating}
							onSave={handleSave}
							onEnd={handleEnd}
						/>
					)}
				</BoxSection>
			</Box>
		</div>
	);
};
