import React, { Ref, HTMLAttributes, forwardRef } from 'react';
import { classNames } from 'primereact/utils';

import './List.scss';

export type ArrowListProps = HTMLAttributes<HTMLUListElement>;

export const ArrowList = forwardRef(
	({ children, className, ...restProps }: ArrowListProps, ref: Ref<HTMLUListElement>) => {
		return (
			<ul ref={ref} className={classNames('list arrow-list', className)} {...restProps}>
				{children}
			</ul>
		);
	}
);
