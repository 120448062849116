import { useCallback, useEffect, useState } from 'react';

export const useIsWindowFocused = () => {
	const [windowIsActive, setWindowIsActive] = useState(true);

	const handleActivity = useCallback((event: { type: string }) => {
		if (event?.type === 'focus') {
			return setWindowIsActive(true);
		}
		if (event?.type === 'blur') {
			return setWindowIsActive(false);
		}
		if (event?.type === 'visibilitychange') {
			return setWindowIsActive(!document.hidden);
		}
		return null;
	}, []);
	// const handleActivityDebounced = useDebounce(handleActivity, 250);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleActivity);
		document.addEventListener('blur', handleActivity);
		window.addEventListener('blur', handleActivity);
		window.addEventListener('focus', handleActivity);
		document.addEventListener('focus', handleActivity);

		return () => {
			window.removeEventListener('blur', handleActivity);
			document.removeEventListener('blur', handleActivity);
			window.removeEventListener('focus', handleActivity);
			document.removeEventListener('focus', handleActivity);
			document.removeEventListener('visibilitychange', handleActivity);
		};
	}, []);

	return windowIsActive;
};
