import React, { useRef, useState } from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import composeRefs from '@seznam/compose-react-refs';

import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import CustomLabel from './Label';
import { Button } from '../Button';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	placeholder?: string;
	label?: string;
	required?: boolean;
	withHours?: boolean;
}
const ValidateCalendar = <T extends FieldValues>(props: Props<T>) => {
	const { name, label, placeholder, control, required = false, rules, withHours } = props;

	const { t } = useTranslation();

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: {
			required: required ? `${label ?? placeholder} ${t('misc.forms.isRequired')}` : false,
			...rules,
		},
	});

	const [comingValue, setComingValue] = useState<Date | undefined>(undefined);

	const calendarRef = useRef<Calendar>();
	const value = comingValue || field.value;

	return (
		<div>
			{label && <CustomLabel name={name}>{label}</CustomLabel>}

			<Calendar
				dateFormat="dd/mm/yy"
				{...field}
				ref={composeRefs(field.ref, calendarRef)}
				value={value}
				id={field.name}
				inputClassName="border-round-3xl"
				className={classNames({ 'p-invalid': error }, 'w-full p-datepicker-tiny')}
				placeholder={placeholder}
				showTime={withHours}
				hideOnDateTimeSelect={false}
				onChange={({ value }) => {
					if (value instanceof Date) {
						setComingValue(value);
					}
				}}
				onHide={() => {
					setComingValue(undefined);
				}}
				stepMinute={15}
				panelClassName="p-datepicker-tiny"
				footerTemplate={() => (
					<div className="flex justify-content-between p-2">
						<Button
							label={t('actions.cancel')}
							onClick={() => {
								if (calendarRef?.current) {
									calendarRef.current.hide();
								}
							}}
							className="p-button-outlined"
						/>
						<div className="flex flex-row gap-1">
							<Button
								label={t('actions.clear')}
								onClick={() => {
									setComingValue(undefined);
									if (calendarRef?.current) {
										const date = calendarRef.current?.getCurrentDateTime();
										if (date instanceof Date) {
											field.onChange('');
										}
										calendarRef.current.hide();
									}
								}}
								className="p-button-outlined"
							/>
							<Button
								label={t('actions.save')}
								onClick={() => {
									if (comingValue !== undefined) {
										const date = comingValue;
										field.onChange(date);
									}
									calendarRef?.current?.hide();
								}}
							/>
						</div>
					</div>
				)}
			/>
			{error && <small className="p-error font-bold ml-3">{error.message}</small>}
		</div>
	);
};

export default ValidateCalendar;
